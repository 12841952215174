import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment-timezone";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { useInventoryAvailabilityQuery } from "../../../api/inventory.api";
import { InventoryItem } from "../../../api/inventory.models"
import "./inventory-calendar.css"

interface InventoryCalendarProps {
	inventoryItem: InventoryItem;
	selectedRange?: DateRange;
	fixedDates?: boolean;
}

const InventoryCalendar = ({ inventoryItem, selectedRange, fixedDates = false }: InventoryCalendarProps) => {
	const [year, setYear] = useState<Year>();

	useEffect(() => {
		if (selectedRange !== undefined) {
			setYear(new Year(moment(selectedRange.From).year(), selectedRange));
		} else {
			setYear(new Year(moment().year(), selectedRange));
		}
	}, [inventoryItem]);

	const showPreviousYear = () => {
		if (fixedDates)
			return false;

		return year !== undefined && year.year > moment().year();
	}

	const showNextYear = () => {
		if (fixedDates)
			return false;

		return year !== undefined && year.year <= moment().year();
	}

	const previousYear = () => {
		setYear(new Year(year!.year - 1, selectedRange));
	}

	const nextYear = () => {
		setYear(new Year(year!.year + 1, selectedRange));
	}

	return <div className="inventory-calendar">
		<div className="controls">
			{showPreviousYear() && 
			<div className="float-left">
				<button onClick={previousYear} className="btn btn-link font-weight-bold">
					<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
					{year && <>{year.year-1}</>}
				</button>
			</div>
			}
			{showNextYear() &&
			<div className="float-right">
				<button onClick={nextYear} className="btn btn-link font-weight-bold">
				{year && <>{year.year+1}</>}
				<FontAwesomeIcon icon={faArrowRight} className="ml-2" />
				</button>
			</div>
			}
		</div>

		{year &&
		<InventoryCalendarInner inventoryItemId={inventoryItem.Id} year={year} />
		}
	</div>
}

export default InventoryCalendar

interface InventoryCalendarInnerProps {
	inventoryItemId: number;
	year: Year;
}

const InventoryCalendarInner = ({ inventoryItemId, year }: InventoryCalendarInnerProps) => {
	const dateFrom = moment().year(year.year).startOf("year").toDate();
	const dateTo = moment().year(year.year).endOf("year").toDate();
	
	const { data: availability } = useInventoryAvailabilityQuery(inventoryItemId, dateFrom, dateTo);
	const dayNames = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
	const columns = 4;

	const isAvailable = (date: Date) : boolean => {
		if (availability == null)
			return true;

		var index = moment(date).diff(moment(availability.From), "days");

		if (index < 0 || index > availability.Availability.length)
			return false;
			
		return availability.Availability[index] === 1;
	}

	const gridSizeClass = () => {
		return columns === 4 ? "grid-responsive" : "grid-responsive-sm";
	}

	return <>
		<div className={classNames(["grid-container", gridSizeClass()])}>
			{year.months.map((month, monthIndex) => {
			return <div className="grid-item" key={monthIndex}>
			
				<div className="container">
					<div style={{ textAlign: "center" }}>
						<strong>{month.Description} {year.year}</strong>
					</div>
					<table>
						<thead>
							<tr>
								{dayNames.map((dayName, dayNameIndex) => {
									return <th className="p-0" key={dayNameIndex}>
										<div><strong>{dayName}</strong></div>
									</th>
								})}
							</tr>
						</thead>
						<tbody>
							{month.Weeks.map((week, weekIndex) => {
								return <tr key={weekIndex}>
									{week.DaysOfWeek.map((day, dayIndex) => {
										return <td className="p-0" key={dayIndex}>
										{day.init && 
										<div className={classNames([day.selected ? "selected":"", day.start ? "start":"", day.end ? "end":"", !isAvailable(day.day) ? "unavailable":""])}>
											{!isAvailable(day.day) && 
												<OverlayTrigger placement="top" overlay={<Tooltip>Unavailable</Tooltip>}>
													<span>{day.day.getDate()}</span>
												</OverlayTrigger>
											}
											{isAvailable(day.day) && <>{day.day.getDate()}</>}
										</div>
										}
									</td>
									})}
								</tr>
							})}
						</tbody>
					</table>
				</div>
			</div>
			})}
		</div>
	</>
}


export interface DateRange {
	From: Date;
	To: Date;
}

export enum DayOfWeek {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    THURSDAY = "THURSDAY",
    WEDNESDAY = "WEDNESDAY",
    FRIDAY = "FRIDAY",
    SUNDAY = "SUNDAY",
    SATURDAY = "SATURDAY"
}

export class Day {
	day: Date = new Date();
    dayOfWeek: string = "";
    init: boolean = false;
    // color:string;
    // tooltip:string;
    // ranges:Range[];
    isDisabled?: boolean = false;
	selected: boolean = false;
	start: boolean = false;
	end: boolean = false;
	available: boolean = true;
}

export class Week {
	DaysOfWeek: Day[] = [];
	constructor() {
		for (let i = 0; i < 7; i++) {
            this.DaysOfWeek.push(new Day());
        }
	}
	isFull(): boolean {
        return this.DaysOfWeek[6].init;
    }
    add(day: Day): void {
        this.DaysOfWeek[day.day.getDay()] = day;
    }
}

export class Month {

	Index:number = 0;
    MonthValue: number = 0;
    Description: string = "";
    // Days: Day[] = [];
    Weeks: Week[] = [];

	constructor(daysOfmonth: Day[]) {
        for (let i = 0; i < 6; i++) {
            this.Weeks.push(new Week());
        }
        let weekIndex = 0;
        for (let d of daysOfmonth) {
            d.init = true;
            if (this.Weeks[weekIndex].isFull()) {
                if (++weekIndex > 5) {
                    break;
                }
                this.Weeks[weekIndex].add(d);
            } else {
                this.Weeks[weekIndex].add(d);
            }
        }
    }
}

export class Year {

    year: number;
    months: Month[] = [];
	selectedDateRange?: DateRange;

    constructor(y: number, dateRange?: DateRange) {
		this.year = y;

		if (dateRange) {
			this.selectedDateRange = {
				From: moment(dateRange.From).startOf("day").toDate(),
				To: moment(dateRange.To).startOf("day").toDate()
			}
		} else {
			this.selectedDateRange = {
				From: new Date(),
				To: new Date()
			}
		}
        this._initYear();
    }

    private _initYear(): void {
        for (let i = 0; i < 12; i++) {
            let days:Day[] = this.getMonthDays(i)
            let month = new Month(days);
			month.Description = moment(new Date(this.year, i, 1)).format("MMMM");
            month.Index = i;
            // month.Days = days;
            this.months.push(month);
        }
    }

    getMonthDays(month: number): Day[] {
        let date = new Date(this.year, month, 1);
        let days: Day[] = [];
        while (date.getMonth() === month) {
            let day = new Day();
            day.day = new Date(date);
            day.dayOfWeek = this.getDayOfWeek(day.day.getDay());
			if (this.selectedDateRange !== undefined) {
				day.selected =  moment(day.day).startOf("day").toDate() >= this.selectedDateRange.From && moment(day.day).startOf("day").toDate() <= this.selectedDateRange.To;
				day.start = moment(day.day).startOf("day").toISOString() === this.selectedDateRange.From.toISOString();
				day.end = moment(day.day).startOf("day").toISOString() === this.selectedDateRange.To.toISOString();
			}
            days.push(day);
            date.setDate(date.getDate() + 1);
        }
        return days;
    }

    private getDayOfWeek(day: number): DayOfWeek {
        switch (day) {
            case 0: default: return DayOfWeek.SUNDAY;
            case 1: return DayOfWeek.MONDAY;
            case 2: return DayOfWeek.TUESDAY;
            case 3: return DayOfWeek.WEDNESDAY;
            case 4: return DayOfWeek.THURSDAY;
            case 5: return DayOfWeek.FRIDAY;
            case 6: return DayOfWeek.SATURDAY;
        }
    }
}