import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import classNames from "classnames";
import _ from "lodash";
import { Collapse, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { isInventoryItemSelected } from "../functions/inventory-selection";
import { getPerNightCostForInventoryItem } from "../functions/inventory-data";
import { useInventoryCategoryState, useInventoryState } from "../functions/inventory-state";
import { useEventState } from "../functions/event-state";
import { InventoryCategory, InventoryItem } from "../../../api/inventory.models";
import ExploreInventoryItem from "../../explore/components/explore-inventory";
import { currency, linkTitle } from "../../../common/helpers";
import { useReservationInventoryPoliciesQuery } from "../../../api/reservation.api";
import { getReservationInventoryPolicyResult } from "../functions/reservation-functions";
import './event-inventory-selections.css';

export interface EventInventorySelectionsProps {
	inventoryCategory: InventoryCategory;
	open: boolean;
}

const EventInventorySelections = ({ inventoryCategory, open }: EventInventorySelectionsProps) => {
	const history = useHistory();
	const location = useLocation();
	const { getSelectedInventory, toggleInventoryItem, selectAllInventoryItems, deselectAllInventoryItems } = useInventoryState();
	const { event, selectedDateRange } = useEventState();
	const [ detailsItem, setDetailsItem ] = useState<InventoryItem>();

	const { data: reservationInventoryPolicies } = useReservationInventoryPoliciesQuery(event.Id ?? 0);
	let reservationInventoryPolicyResult = getReservationInventoryPolicyResult(event?.Id ?? 0, selectedDateRange[0], selectedDateRange[1], reservationInventoryPolicies ?? []);

	const isInventoryPolicyApplicable = reservationInventoryPolicyResult != null && reservationInventoryPolicyResult.DatesValid == false &&
		_.findIndex(reservationInventoryPolicyResult?.Policy?.InventoryCategories, category => category == inventoryCategory.Id) >= 0;

	const allowMultipleInventory = event?.AllowMultipleInventory ?? true;

	const { } = useInventoryCategoryState(inventoryCategory);
	let selectedInventory = getSelectedInventory();

	const selectedInventoryChanged = (inventoryItem: InventoryItem, allowMultipleInventory: boolean) => {
		if (isInventoryPolicyApplicable)
			return;
		
		toggleInventoryItem(inventoryItem, allowMultipleInventory);
	}

	const handleChangeDates = () => {
		history.push({ pathname: `/events/${linkTitle(event?.Title ?? "")}`, search: location.search });
	}
	
	const unavailableMessage = () => {
		return selectedDateRange ? `Unavailable for ${moment(selectedDateRange[0]).format("MMM D")} - ${moment(selectedDateRange[1]).format("MMM D, YYYY")}` : '';
	}
	return <>
		<Collapse in={open}>
		<div className="row">
			<div className="col-12">
				<div className="card border-0">
					<div className="card-body">
						<h5 className="card-title">{inventoryCategory.Metadata?.UnitDescription} Selection</h5>
						{allowMultipleInventory &&
						<div className="mb-2">
							<button onClick={() => selectAllInventoryItems(inventoryCategory)} disabled={isInventoryPolicyApplicable} className="btn btn-link p-0 border-0">Select All</button>
							<button onClick={() => deselectAllInventoryItems(inventoryCategory)} disabled={isInventoryPolicyApplicable} className="btn btn-link p-0 border-0 ml-3">Select None</button>
						</div>
						}
						{!allowMultipleInventory &&
						<div className="mb-3">
							<FontAwesomeIcon icon={faInfoCircle} /> You can only select one {inventoryCategory.Metadata?.UnitDescription.toLowerCase()} per reservation.
						</div>
						}

						<div className="row">
							<div className="col-sm-12">
								<div className="row">
									{inventoryCategory.InventoryItems.map((inventoryItem: any, index: number) => {
										return <div className="col-6 col-sm-3" key={index}>
										<OverlayTrigger overlay={!inventoryItem.Available ? <Popover body placement="top">{unavailableMessage()}</Popover> : <></>}>
										<div className={classNames(["custom-control custom-checkbox custom-control-inline", !inventoryItem.Available ? 'unavailable':''])} style={{ display: 'block'}}>
											<input type="checkbox" checked={isInventoryItemSelected(selectedInventory, inventoryItem)} onChange={() => selectedInventoryChanged(inventoryItem, allowMultipleInventory)} disabled={!inventoryItem.Available || isInventoryPolicyApplicable} className="custom-control-input" id={`inventoryItem${inventoryItem.Id}`} />
											<label className={classNames(["custom-control-label", !inventoryItem.Available ? 'unavailable':''])} htmlFor={`inventoryItem${inventoryItem.Id}`}>
												<div>{inventoryItem.Metadata != null ? inventoryItem.Metadata.Title : inventoryItem.Description}</div>
												{currency.format(getPerNightCostForInventoryItem(inventoryItem))}
											</label>
										</div>
										</OverlayTrigger>
										<small><button onClick={() => { setDetailsItem(inventoryItem !== detailsItem ? inventoryItem : undefined) }} className="btn btn-link p-0 border-0" style={{marginTop: '-5px', marginLeft: '24px'}}>Details</button></small>
									</div>
									})}
								</div>
							</div>

							<Collapse in={detailsItem !== undefined} className="col-sm-12 mt-3">
								<div>
								<div className="border-top pt-3">
									<OverlayTrigger placement="top" overlay={<Popover body placement="top">Close Details</Popover>}>		
										<button onClick={() => setDetailsItem(undefined)} className="btn btn-link p-0 text-body float-right"><FontAwesomeIcon icon={faTimesCircle} /></button>
									</OverlayTrigger>
									<h5 className="cart-title">Details for {detailsItem?.Metadata?.Title ?? detailsItem?.Description}</h5>
									{detailsItem &&
									<ExploreInventoryItem inventoryItem={detailsItem} selectedDateRange={{ dateFrom: moment(selectedDateRange![0] ?? "").toDate(), dateTo: moment(selectedDateRange![1] ?? "").toDate() }} onChangeDates={handleChangeDates} />
									}
								</div>
								</div>
							</Collapse>
						</div>

						
					</div>
				</div>
			</div>
		</div>
		</Collapse>
	</>
}

export default EventInventorySelections