import Card from '../../../common/components/Card';
import { linkTitle } from '../../../common/helpers';
import { ProgramGroup } from '../../../api/programs.models';

export const dateFormats = {
	'default': 'LL',
	'long': 'dddd, LL'
}

interface ProgramGroupCardProps {
	programGroup: ProgramGroup;
}

const ProgramGroupCard = ({ programGroup }: ProgramGroupCardProps) => {
	return <Card title={programGroup.Title} description={programGroup.ShortDescription} imageUrl={programGroup.ImageURL} link={`/programs/${linkTitle(programGroup.Title)}`} />
}

export default ProgramGroupCard