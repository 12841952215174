import Card from '../../../common/components/Card';
import { currency, linkTitle } from '../../../common/helpers';
import { PriceType, Program, ProgramGroup } from '../../../api/programs.models';

export const dateFormats = {
	'default': 'LL',
	'long': 'dddd, LL'
}

interface ProgramCardProps {
	programGroup: ProgramGroup;
	program: Program;
}

const ProgramCard = ({ programGroup, program }: ProgramCardProps) => {

	const cost = program.Price > 0 ? <>
		<div className="text-muted small">
			{currency.format(program.Price)} {program.PriceType === PriceType.PerGroup ? " per group" : " per person"}
		</div>
	</> : <></>

	return <div className="event">
		<Card title={program.Title} description={program.ShortDescription} content={cost} imageUrl={program.ImageURL} link={`/programs/${linkTitle(programGroup.Title)}/${linkTitle(program.Title)}`} />
	</div>
}

export default ProgramCard