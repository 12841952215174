import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import { api } from './api';
import { linkTitle } from '../common/helpers';
import { Event, EventCharge, EventDate, EventDeposit, EventMeal, EventMealDiscount, EventMealPackage, EventMealRequirementOverride } from './events.models';
import { InventoryType } from './inventory.models';

export const useEventsQuery = (includeHidden: boolean = false) => { 
	return useQuery(['events', includeHidden], async () => {
		let res = await api.get<Event[]>('/api/events');
		return includeHidden ? res.data : res.data.filter(e => e.Hidden === false);
	}, { staleTime: Infinity, cacheTime: 600000 });
}

export const useEventsSearchQuery = (dateRange: Date[]) => { 
	return useQuery(['events-search', dateRange], async () => {
		let res = await api.get<{ Events: Event[], Upcoming: Event[] }>('/api/events/search', { params: { dateFrom: dateRange.length === 2 ? moment(dateRange[0]).toISOString() : moment().toISOString(), dateTo: dateRange.length === 2 ? moment(dateRange[1]).toISOString() : moment().toISOString() }});
		return { 
			Events: res.data.Events.filter(e => e.Hidden === false),
			Upcoming: res.data.Upcoming.filter(e => e.Hidden === false)
		}
	}, { staleTime: Infinity, cacheTime: 60000, enabled: true });
}

export const useEventFromTitleQuery = (title: string) => { 
	return useQuery(['events-by-title', linkTitle(title)], async () => {
		let res = await api.get<Event[]>('/api/events');
		return res.data?.find((event: Event) => linkTitle(event.Title) === title);
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useFeaturedEventsQuery = () => { 
	return useQuery('events-featured-events', async () => {
		let res = await api.get<Event[]>('/api/events/featured');
		return res.data.filter(e => e.Hidden === false);
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useEventDatesQuery = (eventId: number) => { 
	return useQuery(['event-dates', eventId], async () => {
		let res = await api.get<EventDate[]>(`/api/events/${eventId}/dates`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: eventId > 0 });
}

export const useEventDepositsQuery = (eventId: number) => { 
	return useQuery(['event-deposits', eventId], async () => {
		let res = await api.get<EventDeposit[]>(`/api/events/${eventId}/deposits`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: eventId > 0 });
}

export const useEventInventoryQuery = (eventDate?: EventDate, dateRange?: string[]) => {
	const params = new URLSearchParams();
	if (dateRange && dateRange.length === 2) {
		params.append("dateFrom", moment(dateRange[0]).toISOString());
		params.append("dateTo", moment(dateRange[1]).toISOString());
	}

	return useQuery(['event-inventory', eventDate?.Id ?? 0, dateRange], async () => {
		let res = await api.get<InventoryType[]>(`/api/events/${eventDate?.EventId ?? 0}/${eventDate?.Id ?? 0}/inventory?${params.toString()}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: eventDate != null && dateRange != null });
}

export const useEventInventoryByIdQuery = (eventId: number, eventDateId: number, dateRange?: Date[]) => {
	const params = new URLSearchParams();
	if (dateRange && dateRange.length === 2) {
		params.append("dateFrom", moment(dateRange[0]).toISOString());
		params.append("dateTo", moment(dateRange[1]).toISOString());
	}

	return useQuery(['event-inventory', eventDateId, dateRange], async () => {
		let res = await api.get<InventoryType[]>(`/api/events/${eventId}/${eventDateId}/inventory?${params.toString()}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: eventId > 0 && eventDateId > 0 && dateRange != null });
}

export const useEventMealsQuery = (eventId: number, eventDateId: number, enabled: boolean = true) => {
	return useQuery(['event-meals', eventId, eventDateId], async () => {
		let res = await api.get<EventMeal[]>(`/api/events/${eventId}/${eventDateId}/meals`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: enabled && eventId > 0 });
}

export const useEventMealPackagesQuery = (eventId: number, eventDateId: number, enabled: boolean = true) => {
	return useQuery(['event-meal-packages', eventId, eventDateId], async () => {
		let res = await api.get<EventMealPackage[]>(`/api/events/${eventId}/${eventDateId}/mealpackages`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: enabled && eventId > 0 && eventDateId > 0 });
}

export const useEventMealDiscountsQuery = (eventId: number, eventDateId: number, enabled: boolean = true) => {
	return useQuery(['event-meal-discounts', eventId, eventDateId], async () => {
		let res = await api.get<EventMealDiscount[]>(`/api/events/${eventId}/${eventDateId}/mealdiscounts`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: enabled && eventId > 0 });
}

export const useEventMealRequirementOverridesQuery = (eventId: number, eventDateId: number, enabled: boolean = true) => {
	return useQuery(['event-meal-requirement-overrides', eventId, eventDateId], async () => {
		let res = await api.get<EventMealRequirementOverride[]>(`/api/events/${eventId}/${eventDateId}/mealrequirementoverrides`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: enabled && eventId > 0 });
}

export const useEventChargesQuery = (eventId: number, eventDateId: number, enabled: boolean = true) => {
	return useQuery(['event-charges', eventId, eventDateId], async () => {
		let res = await api.get<EventCharge[]>(`/api/events/${eventId}/${eventDateId}/charges`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: enabled && eventId > 0 });
}