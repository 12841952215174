import { Link } from "react-router-dom"
import "./Card.css"

export interface CardProps {
	title: string;
	description: string;
	content?: JSX.Element;
	link: string;
	imageUrl: string;
}

const Card = ({ title, description, content, link, imageUrl }: CardProps) => {
	function createDescriptionMarkup() { return { __html: description }; };

	return <>
		<div className="custom-card card border-0 shadow h-100">
			<Link to={link}>
				<img className="card-img-top" src={imageUrl} alt={title} />
				<div className="card-body">
					<h5 className="card-title font-weight-bold">{title}</h5>
					<p className="card-text" dangerouslySetInnerHTML={createDescriptionMarkup()}></p>	
					{content}				
				</div>
			</Link>
		</div>
	</>
}

export default Card