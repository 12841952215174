import { extend, hookstate, State, useHookstate } from "@hookstate/core";
import { devtools } from '@hookstate/devtools'
import { localstored, StoreEngine } from "@hookstate/localstored";
import _ from "lodash";
import { Cart, PaymentMethod, ValidationError } from "../../../api/cart.models";
import { ParentGuardian, Address, Registration, AdultParticipant, ChildParticipant } from "../../../api/program.registration.models";
import { Reservation } from "../../../api/reservation.models";
import { createEmtpyBillingContact } from "./cart-functions";
import { validate as validateReservation } from "../../../api/reservation.api";
import { validate as validateRegistration } from "../../../api/programregistration.api";

export interface CartState {
	cart: Cart,
	validationErrors: ValidationError[]
}

export const CART_STATE = hookstate(
	{ 
		cart: { 
			Reservations: [],
			ProgramRegistrations: [],
			BillingContact: createEmtpyBillingContact(),
			PaymentMethod: PaymentMethod.DepositOnline,
			PaymentToken: null,
			PaymentRequired: true
		},
		validationErrors: []
	} as CartState, 
	
	extend(
		localstored({
			key: 'cart.v2',
			engine: localStorage as StoreEngine,
			initializer: () => new Promise<CartState>((resolve, reject) => { 
				resolve({ 
					cart: { 
						Reservations: [], 
						ProgramRegistrations: [],
						BillingContact: createEmtpyBillingContact(), 
						PaymentMethod: PaymentMethod.DepositOnline,
						PaymentToken: null,
						PaymentRequired: true
					},
					validationErrors: []
				})
			})
		}),
		devtools({ key: 'cart' })
	)
);

export const useCartState = () => {
	let state = useHookstate<CartState>(CART_STATE);
		
	return {
		cart: state.cart.get({ noproxy: true }) as Cart,
		resetCart: () => { 
			state.cart.set({ 
				Reservations: [], 
				ProgramRegistrations: [],
				BillingContact: createEmtpyBillingContact(), 
				PaymentMethod: PaymentMethod.DepositOnline,
				PaymentToken: null,
				PaymentRequired: true
			}) 
		},
		updateCart: (cart: Cart) => { 		
			state.cart.set(cart);
		},
		validationErrors: state.validationErrors.get({ noproxy: true }) as ValidationError[] ?? [],
		setValidationErrors: (validationErrors: ValidationError[]) => { state.validationErrors.set(validationErrors); },
		validateCart: async () => {
			let validationErrors: ValidationError[] = [];
			let reservations = state.cart.Reservations.get({ noproxy: true });
			if (reservations !== undefined) {
				for (let reservation of reservations) {
					let errors = await validateReservation(reservation as Reservation);
					if (errors && errors.length > 0)
						validationErrors = [...validationErrors, ...errors];
				}
			}
			let registrations = state.cart.ProgramRegistrations.get({ noproxy: true });
			if (registrations !== undefined) {
				for (let registration of registrations) {
					let errors = await validateRegistration(registration as Registration);
					if (errors && errors.length > 0)
						validationErrors = [...validationErrors, ...errors];
				}
			}
			state.validationErrors.set(validationErrors);
			return validationErrors;
		},
		getCartCost: () => getCartCost(state),
		getCartDeposit: () => getCartDeposit(state),
		getFullPaymentRequired: () => getFullPaymentRequired(state),
		getNoPaymentRequired: () => getNoPaymentRequired(state),
		getCartSize: () => cartGetSize(state),
		isCartEmpty: () => cartIsEmpty(state),
		isInCart: (reservation: Reservation) => cartIsInCart(state, reservation),
		addToCart: (reservation: Reservation) => cartAddReservation(state, reservation),
		removeFromCart: (reservation: Reservation) => cartRemoveReservation(state, reservation),

		addProgramRegistration: (registration: Registration) => cartAddProgramRegistration(state, registration),
		removeProgramRegistration: (registration: Registration) => cartRemoveProgramRegistration(state, registration),
		isProgramRegistrationInCart: (registration: Registration) => cartIsProgramRegistrationInCart(state, registration),

		getCartAutofillParentGuardians: () => cartGetAutofillParentGuardians(state),
		getCartAutofillAdultParticipants: () => cartGetAutofillAdultParticipants(state),
		getCartAutofillChildParticipants: () => cartGetAutofillChildParticipants(state),
		getCartAutofillAddresses: () => cartGetAutofillAddresses(state)
	}
}

const cartIsInCart = (state: State<CartState>, reservation: Reservation) => {
	let reservationIndex = state.cart.Reservations.value?.findIndex(r => r.Id === reservation.Id) ?? -1;
	return reservationIndex >= 0;
}

const cartIsProgramRegistrationInCart = (state: State<CartState>, registration: Registration) => {
	let registrationIndex = state.cart.ProgramRegistrations.value?.findIndex(r => r.Id === registration.Id) ?? -1;
	return registrationIndex >= 0;
}

const cartIsEmpty = (state: State<CartState>) => {
	if (state.cart.Reservations && state.cart.Reservations.length > 0)
		return false;

	if (state.cart.ProgramRegistrations && state.cart.ProgramRegistrations.length > 0)
		return false;

	return true;
}

const cartGetSize = (state: State<CartState>) => {
	let size = 0;

	size += state.cart?.value.Reservations?.length ?? 0;
	size += state.cart?.value.ProgramRegistrations?.length ?? 0;
	
	return size;
}

export const getCartCost = (state: State<CartState>) => {
	var total = 0;

	if (state.cart.Reservations.value) {
		for (var i = 0; i < state.cart.Reservations.value.length; i++) {
			total += state.cart.Reservations.value[i].Metadata?.cost ?? 0;
		}
	}

	if (state.cart.ProgramRegistrations.value) {
		for (i = 0; i < state.cart.ProgramRegistrations.value.length; i++) {
			total += state.cart.ProgramRegistrations.value[i].Metadata?.cost ?? 0;
		}
	}
	
	return total;
}

export const getCartDeposit = (state: State<CartState>) => {
	var deposit = 0;

	if (state.cart.Reservations.value) {
		for (var i = 0; i < state.cart.Reservations.value.length; i++) {
			deposit += state.cart.Reservations.value![i].Metadata?.deposit ?? 0;
		}
	}

	if (state.cart.ProgramRegistrations.value) {
		for (i = 0; i < state.cart.ProgramRegistrations.value.length; i++) {
			deposit += (state.cart.ProgramRegistrations.value![i].Metadata?.cost ?? 0) * 1.13;
		}
	}
	
	return deposit;
}

export const getFullPaymentRequired = (state: State<CartState>) => {
	let totalCost = getCartCost(state);
	let totalDeposit = getCartDeposit(state);
	
	return totalDeposit === 0 || _.round(totalDeposit, 2) === _.round(totalCost * 1.13, 2);
}

export const getNoPaymentRequired = (state: State<CartState>) => {
	let totalCost = getCartCost(state);
	let totalDeposit = getCartDeposit(state);
	
	if (totalDeposit > 0)
		return false;

	let programRegistrations = state.cart.ProgramRegistrations.get({ noproxy: true });
	let savePaymentMethod = (programRegistrations?.filter(r => (r.Metadata?.savePaymentMethod ?? false) === true) ?? []).length > 0;

	if (savePaymentMethod)
		return false;

	return totalCost === 0;
}

const cartAddReservation = (state: State<CartState>, reservation: Reservation) => {
	let cart = state.cart.get({ noproxy: true }) as Cart;
	let reservationIndex = cart.Reservations.findIndex(r => r.Id === reservation.Id) ?? -1;

	if (reservationIndex >= 0) {
		cart.Reservations[reservationIndex] = reservation;
	} else {
		cart.Reservations.push(reservation);
		// state.cart.Reservations.merge([reservation]);
	}
	state.cart.set(cart);
}

const cartRemoveReservation = (state: State<CartState>, reservation: Reservation) => {
	let cart = state.cart.get({ noproxy: true }) as Cart;
	let reservationIndex = cart.Reservations.findIndex(r => r.Id === reservation.Id) ?? -1;
	if (reservationIndex >= 0) {
		cart.Reservations.splice(reservationIndex, 1);
		state.cart.set(cart);
	}
}

const cartAddProgramRegistration = (state: State<CartState>, registration: Registration) => {
	let cart = state.cart.get({ noproxy: true }) as Cart;
	if (cart.ProgramRegistrations === undefined)
		cart.ProgramRegistrations = [];

	let registrationIndex = cart.ProgramRegistrations.findIndex(r => r.Id === registration.Id) ?? -1;

	if (registrationIndex >= 0) {
		cart.ProgramRegistrations[registrationIndex] = registration;
	} else {
		cart.ProgramRegistrations.push(registration);
		// state.cart.Reservations.merge([reservation]);
	}
	state.cart.set(cart);
}

const cartRemoveProgramRegistration = (state: State<CartState>, registration: Registration) => {
	let cart = state.cart.get({ noproxy: true }) as Cart;
	let registrationIndex = cart.ProgramRegistrations.findIndex(r => r.Id === registration.Id) ?? -1;
	if (registrationIndex >= 0) {
		cart.ProgramRegistrations.splice(registrationIndex, 1);
		state.cart.set(cart);
	}
}

const cartGetAutofillParentGuardians = (state: State<CartState>) => {
	let cart = state.cart.get({ noproxy: true }) as Cart;
	let parentGuardians: ParentGuardian[] = [];
	try {
		for (let programRegistration of cart.ProgramRegistrations) {
			for (let parentGuardian of programRegistration.ParentGuardians) {
				if (parentGuardians.filter(p => p.FirstName === parentGuardian.FirstName && p.LastName === parentGuardian.LastName).length === 0)
					parentGuardians.push(_.cloneDeep(parentGuardian));
			}
		}
		return parentGuardians;
	} catch { }
	return [];
}

const cartGetAutofillAdultParticipants = (state: State<CartState>) => {
	let cart = state.cart.get({ noproxy: true }) as Cart;
	let participants: AdultParticipant[] = [];
	try {
		for (let programRegistration of cart.ProgramRegistrations) {
			for (let participant of programRegistration.Participants) {
				if (participant.Type !== "adult")
					continue;
				if (participants.filter(p => p.FirstName === participant.FirstName && p.LastName === participant.LastName).length === 0)
					participants.push(_.cloneDeep(participant as AdultParticipant));
			}
		}
		return participants;
	} catch { }
	return [];
}

const cartGetAutofillChildParticipants = (state: State<CartState>) => {
	let cart = state.cart.get({ noproxy: true }) as Cart;
	let participants: ChildParticipant[] = [];
	try {
		for (let programRegistration of cart.ProgramRegistrations) {
			for (let participant of programRegistration.Participants) {
				if (participant.Type !== "child")
					continue;
				if (participants.filter(p => p.FirstName === participant.FirstName && p.LastName === participant.LastName).length === 0)
					participants.push(_.cloneDeep(participant as ChildParticipant));
			}
		}
		return participants;
	} catch { }
	return [];
}

const cartGetAutofillAddresses = (state: State<CartState>) => {
	let cart = state.cart.get({ noproxy: true }) as Cart;
	let addresses: Address[] = [];
	try {
		for (let programRegistration of cart.ProgramRegistrations) {
			for (let parentGuardian of programRegistration.ParentGuardians) {
				if (parentGuardian.Address !== undefined && addresses.filter(a => a.Street1 === parentGuardian.Address?.Street1).length === 0)
				addresses.push(_.cloneDeep(parentGuardian.Address));
			}
			for (let participant of programRegistration.Participants) {
				if (participant.Address !== undefined && addresses.filter(a => a.Street1 === participant.Address?.Street1).length === 0)
				addresses.push(_.cloneDeep(participant.Address));
			}
		}
		return addresses;
	} catch { }
	return [];
}