import { Dispatch, SetStateAction, useState } from "react";
import _ from "lodash";
import { Formik, ErrorMessage, Field, Form } from 'formik';
import classnames from "classnames";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useUserAddressDeleteMutation, useUserAddressQuery, useUserAddressSaveMutation } from "../../api/user.api"
import { UserAddress } from "../../api/user.models";
import { AddressSchema, createEmtpyAddress } from "./functions/people-functions";
import { getCountries, getProvincesStates, getProvinceLabel, getPostalCodeLabel } from '../../common/address';
import { toastError, toastSuccess } from "../../common/components/toast";

const AccountAddresses = () => {
	const { data: userAddresses } = useUserAddressQuery();
	const [editingAddress, setEditingAddress] = useState<UserAddress|undefined>();
	
	const addAddress = () => {
		var userAddress: UserAddress = {
			Id: 0,
			DefaultBilling: false,
			Address: createEmtpyAddress()
		}

		setEditingAddress(_.cloneDeep(userAddress));
	}

	const editAddress = (address: UserAddress) => {
		setEditingAddress(address);
	}

	const addressList = userAddresses?.map((userAddress, index) => {
		return <div key={index}>
			<div>
				<a onClick={(e) => { editAddress(userAddress); e.preventDefault(); }} href="">{userAddress.Address.Street1}, {userAddress.Address.City}, {userAddress.Address.Province}, {userAddress.Address.PostalCode}, {userAddress.Address.Country}</a>
				{userAddress.DefaultBilling && <span> (default billing)</span>}
			</div>
		</div>
	});

	return <>
		<h4 className="font-weight-bold">Saved Addresses</h4>
		{(userAddresses && userAddresses.length > 0) ?
			<p className="text-muted">Click on an address to edit or remove it.</p> :
			<p className="text-muted">Click the Add Address button to enter a new saved address.</p>
		}

		{userAddresses && userAddresses.length > 0 ? addressList : <div className="text-muted">No recent addresses</div>}

		{editingAddress && <EditAddress address={editingAddress} setEditingAddress={setEditingAddress} />}

		{editingAddress === undefined &&
		<div className="form-row">
			<div className="col-12 col-sm-3">
				<button onClick={() => addAddress()} className="btn btn-primary font-weight-bold btn-block font-weight-bold mt-3">Add Address</button>
			</div>
		</div>
		}
	</>
}

export default AccountAddresses

interface EditAddressProps {
	address: UserAddress;
	setEditingAddress: Dispatch<SetStateAction<UserAddress | undefined>>;
}

const EditAddress = ({ address, setEditingAddress }: EditAddressProps) => { 
	const { mutate: saveAddress } = useUserAddressSaveMutation();
	const { mutate: deleteAddress } = useUserAddressDeleteMutation();
	const [showRemovePopover, setShowRemovePopover] = useState(false);

	const handleCountryChanged = (country: string, values: UserAddress) => {
		if (country === "Canada") {
			values.Address!.Province = "Ontario";
		}
		else if (country === "United States") {
			values.Address!.Province = "Alabama";
		}
		else
			values.Address!.Province = "";
	}

	const handleSave = (values: UserAddress) => {
		saveAddress(values, { 
			onSuccess: () => {
				toastSuccess('Your changes have been saved.');
			},
			onError: (error) => {
				toastError(null, 'Your changes were not saved. Please reload the page and try again.');
			},
			onSettled: () => {
				setEditingAddress(undefined);
			}
		})
	}

	const handleCancel = () => {
		setEditingAddress(undefined);
	}

	const handleDelete = () => {
		setShowRemovePopover(false);
		deleteAddress(address, {
			onSuccess: () => {
				setEditingAddress(undefined);
			}
		})
	}

	const removePopover = (
		<Popover id="popover-basic" className="shadow" arrowProps={{ className: 'arrow' }}>
			{/* <Popover.Header as="h3">Confirm</Popover.Header> */}
			<Popover.Body>
				<div className="mb-2">Are you sure you want to remove this address ?</div>
				<div className="row">
					<div className="col-6 pr-1">
						<button onClick={handleDelete} type="button" className="btn btn-danger btn-block font-weight-bold">Remove</button>
					</div>
					<div className="col-6 pl-1">
						<button onClick={() => setShowRemovePopover(false)} type="button" className="btn btn-light btn-block font-weight-bold mb-2">Cancel</button>
					</div>
				</div>
			</Popover.Body>
		</Popover>
	);

	return <>
		<hr />
		<h5 className="font-weight-bold">{address.Id === 0 ? "New" : "Edit"} Address</h5>

		<Formik
			validationSchema={AddressSchema}
			enableReinitialize={true}
			initialValues={address}
			onSubmit={(values, { setSubmitting }) => {
				handleSave(values);
			}}
			validateOnBlur={true}
		>
		{({ handleSubmit, isSubmitting, submitCount, isValid, values, errors, handleChange }) => 
			<>
			<Form noValidate onSubmit={handleSubmit}>

				<div className="form-row">
					<div className="col-12 col-sm-4">
						<div className="form-label-group">
							<Field type="text" name={`Address.Street1`} className={`form-control`} placeholder={true ? "required" : ""} autoComplete="off" required />
							<label>Street Address</label>
							<ErrorMessage name="Address.Street1" component="div" className="fw-bold small text-danger" />
						</div>
					</div>
					<div className="col-12 col-sm-4">
						<div className="form-label-group">
							<Field type="text" name={`Address.Street2`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
							<label>Apt / Unit Number</label>
							<ErrorMessage name="Address.Street2" component="div" className="fw-bold small text-danger" />
						</div>
					</div>
					<div className="col-12 col-sm-4">
						<div className="form-label-group">
							<Field type="text" name={`Address.City`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
							<label>City</label>
							<ErrorMessage name="Address.City" component="div" className="error-message fw-bold small text-danger" />
						</div>
					</div>
					<div className={classnames(["col-6 col-sm-4"])}>
						<div className="form-label-group">
							<Field component="select" name="Address.Country" onChange={(e:any) => { handleCountryChanged(e.target.value, values); handleChange(e); }} className={classnames(["custom-select", values.Address?.Country !== "" ? "value-selected":""])}>
								{getCountries().map((country, index) => {
									return <option key={index} value={country}>{country}</option>
								})}
							</Field>
							<label className="text-truncate">Country</label>
						</div>
					</div>
					{(values.Address?.Country === "Canada" || values.Address?.Country === "United States") &&
					<div className={classnames(["col-6 col-sm-4"])}>
						<div className="form-label-group">
							<Field component="select" name="Address.Province" className={classnames(["custom-select", values.Address?.Province !== "" ? "value-selected":""])}>
								{getProvincesStates(values.Address?.Country ?? "").map((province, index) => {
									return <option key={index} value={province}>{province}</option>
								})}
							</Field>
							<label className="text-truncate">{getProvinceLabel(values.Address?.Country ?? "")}</label>
						</div>
					</div>
					}
					{(values.Address?.Country !== "Canada" && values.Address?.Country !== "United States") &&
					<div className="col-12 col-sm-4">
						<div className="form-label-group">
							<Field type="text" name={`Address.Region`} className={`form-control`} placeholder={" "} autoComplete="off" required={false} />
							<label>Region</label>
							<ErrorMessage name="Address.Region" component="div" className="error-message fw-bold small text-danger" />
						</div>
					</div>
					}
					<div className="col-12 col-sm-4">
						<div className="form-label-group">
							<Field type="text" name={`Address.PostalCode`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
							<label className="text-truncate">{getPostalCodeLabel(values.Address?.Country ?? "")}</label>
							<ErrorMessage name="Address.PostalCode" component="div" className="error-message fw-bold small text-danger" />
						</div>
					</div>

				</div>
				<div className="form-row mb-3">
					<div className="col-12 col-sm-6">
						<div className="custom-control custom-checkbox custom-control-inline">
							<Field type="checkbox" name="DefaultBilling" id={`DefaultBilling`} checked={values.DefaultBilling} className="custom-control-input" />
							<label htmlFor={`DefaultBilling`} className="custom-control-label">Default Billing Address</label>
						</div>
					</div>
				</div>

				<div className="form-row">
					<div className="col-12 col-sm-3">
						<button type="submit" disabled={!isValid || isSubmitting} className="btn btn-success btn-block font-weight-bold mb-3 mb-sm-0">
							{isSubmitting && <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />}
							Save {address.Id === 0 ? "Address" : "Changes"}
						</button>
					</div>
					<div className="col-12 col-sm-3">
						<button type="button" onClick={handleCancel} disabled={isSubmitting} className="btn btn-light btn-block font-weight-bold mb-3 mb-sm-0">Cancel {address.Id === 0 ? "" : "Changes"}</button>
					</div>
					<div className="col-sm-3 d-none d-sm-block"></div>
					{address.Id > 0 &&
					<div className="col-12 col-sm-3">
						<OverlayTrigger show={showRemovePopover} trigger="click" placement="top" overlay={removePopover}>
							<button type="button" onClick={() => setShowRemovePopover(true)} disabled={isSubmitting} className="btn btn-danger btn-block float-right font-weight-bold">Remove Address</button>
						</OverlayTrigger>
					</div>
					}
				</div>
			</Form>
			</>}
		</Formik>
	</>
}