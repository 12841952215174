import classnames from 'classnames';
import { Formik, ErrorMessage, Field, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { BillingContact, Cart } from '../../api/cart.models';
import { AdultGuest } from '../../api/reservation.models';
import { useUserAddressQuery, useUserPeopleQuery } from '../../api/user.api';
import { Address, Person } from '../../api/user.models';
import { getCountries, getProvincesStates, getProvinceLabel, getPostalCodeLabel } from '../../common/address';
import { useAuthState } from '../../state/auth.state';
import { useCartState } from './functions/cart-state';
import { PhoneInputField } from '../../common/components/PhoneInputField';
import { AdultParticipant } from '../../api/program.registration.models';

const BillingSchema = Yup.object().shape({
	BillingContact: Yup.object().shape({
		FirstName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Required'),
		LastName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Required'),
		Email: Yup.string().email('Invalid email').required('Required'),
		HomePhone: Yup.string().when('MobilePhone', { is: undefined, then: (schema) => schema.required('Required') }),
		MobilePhone: Yup.string().when('HomePhone', { is: undefined, then: (schema) => schema.required('Required') }),
		Address: Yup.object().shape({
			Street1: Yup.string().required('Required'),
			Street2: Yup.string(),
			City: Yup.string().required('Required'),
			Country: Yup.string().required('Required'),
			Province:  Yup.string(),
			Region: Yup.string().notRequired(),
			PostalCode: Yup.string().required('Required'),
		})
	}, [['HomePhone', 'MobilePhone']])
});


const AutoSaveBillingContactState = () => {
	const { cart, updateCart } = useCartState();
	const { values, isValidating, submitForm } = useFormikContext<{ BillingContact: BillingContact }>();

	const update = useMemo(() => debounce((cart: Cart, values: { BillingContact: BillingContact }) => {
		if (values.BillingContact) {

			cart.BillingContact = values.BillingContact;
			updateCart(cart);
		}
	}, 100), []);

	useEffect(() => {
		update(cart, values);
		submitForm();
	}, [isValidating]);
	
	return <></>
}

interface UpdateFormValidStateProps {
	setFormValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateFormValidState = ({ setFormValid }: UpdateFormValidStateProps) => {
	const { isValid, isValidating } = useFormikContext<{ BillingContact: BillingContact }>();

	useEffect(() => {
		setFormValid(isValid)
	}, [isValid, isValidating]);
	
	return <></>
}

interface CheckoutBillingProps {
	setFormValid: React.Dispatch<React.SetStateAction<boolean>>;
	noPaymentRequired: boolean
}

const CheckoutBilling = ({ setFormValid, noPaymentRequired }: CheckoutBillingProps) => {
	const { cart } = useCartState();
	const salutations = ["", "Mr", "Mrs", "Ms", "Dr", "Rev"];

	const handleCountryChanged = (country: string, values: { BillingContact: BillingContact }) => {
		if (country === "Canada") {
			values.BillingContact.Address!.Province = "Ontario";
			values.BillingContact.Address!.Region = "";
		}
		else if (country === "United States") {
			values.BillingContact.Address!.Province = "Alabama";
			values.BillingContact.Address!.Region = "";
		}
		else
			values.BillingContact.Address!.Province = "";
	}

	return <>
		<div>
			{noPaymentRequired && <p className="lead font-weight-bold mb-2">Your Contact Information</p>}
			{!noPaymentRequired && <p className="lead font-weight-bold mb-2">Billing Information</p>}

			<Formik
				validationSchema={BillingSchema}
				enableReinitialize={true}
				initialValues={{
					BillingContact: cart.BillingContact,
					// GuestType: GuestType
				}}
				onSubmit={(values, { setSubmitting }) => {

				}}
				validateOnBlur={true}
				validateOnMount={true}
			>
			{({ isSubmitting, submitCount, isValid, values, errors, handleChange }) => <>
			
			<AutoSaveBillingContactState />
			<UpdateFormValidState setFormValid={setFormValid} />

			<div className="form-row">
				<div className="col-12 col-sm-6">
					<AutofillBillingContact />
				</div>
			</div>

			<div className="form-row">
				<div className="col-6 col-sm-2">
					<div className="form-label-group">
						<Field component="select" name="BillingContact.Salutation" className={classnames(["custom-select", (values as any).BillingContact?.Salutation !== '' ? "value-selected":""])}>
							{salutations.map((salutation, i) => {
								return <option key={i}>{salutation}</option>
							})}
						</Field>
						<label className="text-truncate">Salutation</label>
					</div>
				</div>
				<div className={classnames(["col-12", "col-sm-4 pl-sm-0-x"])}>
					<div className="form-label-group">
						<Field type="text" name={`BillingContact.FirstName`} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="given-name" />
						<label>First Name</label>
						<ErrorMessage name="BillingContact.FirstName" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				<div className={classnames(["col-12 col-sm-4", "pl-sm-0-x"])}>
					<div className="form-label-group">
						<Field type="text" name={`BillingContact.LastName`} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="family-name" />
						<label>Last Name</label>
						<ErrorMessage name="BillingContact.LastName" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
			</div>

			<div className="form-row">
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`BillingContact.Email`} className={`form-control`} placeholder={true ? "Email (required)" : ""} autoComplete="email" required />
						<label>Email</label>
						<ErrorMessage name="BillingContact.Email" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<PhoneInputField name="BillingContact.HomePhone" className="form-control" placeholder="Required" autoComplete="tel" required={values.BillingContact.MobilePhone === "" || values.BillingContact.MobilePhone === undefined} />
						<label>Home Phone</label>
						<ErrorMessage name="BillingContact.HomePhone" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<PhoneInputField name="BillingContact.MobilePhone" className="form-control" placeholder={values.BillingContact.MobilePhone === "" || values.BillingContact.MobilePhone === undefined ? "required":"required"} autoComplete="tel" required={values.BillingContact.HomePhone === "" || values.BillingContact.HomePhone === undefined }  />
						<label>Cell Phone</label>
						<ErrorMessage name="BillingContact.MobilePhone" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
			</div>

			<div className="form-row">
				<div className="col-12 col-sm-6">
					<AutofillAddress />
				</div>
			</div>

			<div className="form-row">
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`BillingContact.Address.Street1`} className={`form-control`} placeholder={true ? "required" : ""} autoComplete="address-line1" required />
						<label>Street Address</label>
						<ErrorMessage name="BillingContact.Address.Street1" component="div" className="fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`BillingContact.Address.Street2`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="address-line2" required={false} />
						<label>Apt / Unit Number</label>
						<ErrorMessage name="BillingContact.Address.Street2" component="div" className="fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`BillingContact.Address.City`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="address-line-3" required={false} />
						<label>City</label>
						<ErrorMessage name="BillingContact.Address.City" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				<div className={classnames(["col-6 col-sm-4"])}>
					<div className="form-label-group">
						<Field component="select" name="BillingContact.Address.Country" onChange={(e:any) => { handleCountryChanged(e.target.value, values); handleChange(e); }} className={classnames(["custom-select", values.BillingContact.Address?.Country !== "" ? "value-selected":""])} autoComplete="on">
							{getCountries().map((country, index) => {
								return <option key={index} value={country}>{country}</option>
							})}
						</Field>
						<label className="text-truncate">Country</label>
					</div>
				</div>
				{(values.BillingContact.Address?.Country === "Canada" || values.BillingContact.Address?.Country === "United States") &&
				<div className={classnames(["col-6 col-sm-4"])}>
					<div className="form-label-group">
						<Field component="select" name="BillingContact.Address.Province" className={classnames(["custom-select", values.BillingContact.Address?.Province !== "" ? "value-selected":""])} autoComplete="on">
							{getProvincesStates(values.BillingContact.Address?.Country ?? "").map((province, index) => {
								return <option key={index} value={province}>{province}</option>
							})}
						</Field>
						<label className="text-truncate">{getProvinceLabel(values.BillingContact.Address?.Country ?? "")}</label>
					</div>
				</div>
				}
				{(values.BillingContact.Address?.Country !== "Canada" && values.BillingContact.Address?.Country !== "United States") &&
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`BillingContact.Address.Region`} className={`form-control`} placeholder={" "} autoComplete="off" required={false} />
						<label>Region</label>
						<ErrorMessage name="BillingContact.Address.Region" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				}
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`BillingContact.Address.PostalCode`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="postal-code" required={false} />
						<label className="text-truncate">{getPostalCodeLabel(values.BillingContact.Address?.Country ?? "")}</label>
						<ErrorMessage name="BillingContact.Address.PostalCode" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
			</div>
			
			</>}

			</Formik>
		</div>
	</>
}

export default CheckoutBilling

const AutofillBillingContact = () => {
	const { values, setValues, validateForm } = useFormikContext<{ BillingContact: BillingContact }>();
	const { isAuthenticated } = useAuthState();
	const { getCartAutofillAdultParticipants, getCartAutofillParentGuardians } = useCartState();
	const { data: userPeople } = useUserPeopleQuery();
	
	const getAge = (person: Person) : number|null => {
		if (person.Birthdate == null)
			return null;

		return moment().diff(moment(person.Birthdate), "years");
	}
	
	const [autofillPerson, setAutofillPerson] = useState("");
	const autofillPeople = userPeople?.map(p => p.Person).filter(p => (p.Birthdate === null || (p.Birthdate != null && getAge(p) != null && getAge(p)! > 18))).map(p => p) ?? [];
	let autofillCartPeople = getCartAutofillAdultParticipants();
	autofillCartPeople = autofillCartPeople.concat(getCartAutofillParentGuardians());

	const handleAutofillPerson = async (value: string) => {
		let autofillPerson = autofillPeople.find(a => a.Id === parseInt(value));
		if (autofillPerson === undefined)
			autofillPerson = autofillCartPeople.find(a => a.Id === value) as unknown as Person;

		let billingContact = values.BillingContact as AdultGuest;
		billingContact.Salutation = autofillPerson.Salutation;
		billingContact.FirstName = autofillPerson.FirstName;
		billingContact.LastName = autofillPerson.LastName;
		billingContact.Gender = autofillPerson.Gender;
		billingContact.Email = autofillPerson.PersonalEmail ?? (autofillPerson as unknown as AdultParticipant).Email;
		billingContact.HomePhone = autofillPerson.HomePhone;
		billingContact.MobilePhone = autofillPerson.MobilePhone;

		setValues(values);
		

		setAutofillPerson("");

		await validateForm();
		await validateForm();
	}

	useEffect(() => {
		const defaultPerson = userPeople?.find(p => p.DefaultBilling);
		if (defaultPerson !== undefined && values.BillingContact.FirstName === "")
			handleAutofillPerson(defaultPerson.Person.Id.toString());
	}, []);

	return !isAuthenticated() ? <></> : <>
		<select value={autofillPerson} onChange={(e) => handleAutofillPerson(e.target.value)} className="custom-select custom-select-sm w-100 mb-3 text-info font-weight-bold">
			<option>Autofill contact with...</option>
			{autofillPeople?.map((person, i) => {
				return <option key={i} value={person.Id}>{person.FirstName} {person.LastName}, {person.PersonalEmail}, {person.HomePhone}</option>
			})}
			{autofillCartPeople.map((participant, i) => {
				return <option key={i} value={participant.Id}>{participant.FirstName} {participant.LastName}, {participant.Email}, {participant.HomePhone}</option>
			})}
		</select>
	</>
}


interface AutofillAddressProps {
	// ItemIndex: number
	// GuestIndex: number
	// GuestType: "adult"|"child"
}

const AutofillAddress = ({ }: AutofillAddressProps) => {
	const { values, setValues, validateForm } = useFormikContext<{ BillingContact: BillingContact }>();
	const { isAuthenticated } = useAuthState();
	const { getCartAutofillAddresses } = useCartState();
	const { data: userAddresses } = useUserAddressQuery();

	const [autofillAddress, setAutofillAddress] = useState("");
	const autofillAddresses = userAddresses?.map(a => a.Address) ?? [];
	let autofillCartAddresses = getCartAutofillAddresses().filter(a1 => autofillAddresses.filter(a2 => a2.Street1 === a1.Street1).length === 0);

	const handleAutofillAddress = async (value: string) => {
		let autofillAddress = autofillAddresses.find(a => a.Street1 === value);
		if (autofillAddress === undefined) {
			autofillAddress = autofillCartAddresses.find(a => a.Street1 === value) as Address|undefined;
		}
		if (autofillAddress === undefined)
			return;

		values.BillingContact.Address = autofillAddress;
		setValues(values);

		setAutofillAddress("");

		await validateForm();
		await validateForm();
	}

	useEffect(() => {
		const defaultAddress = userAddresses?.find(a => a.DefaultBilling);
		if (defaultAddress !== undefined && values.BillingContact.Address?.Street1 === "")
		handleAutofillAddress(defaultAddress.Address.Id.toString());
	}, []);

	return !isAuthenticated() ? <></> : <>
		<select value={autofillAddress} onChange={(e) => handleAutofillAddress(e.target.value)} className="custom-select custom-select-sm w-100 mb-3 text-info font-weight-bold">
			<option>Autofill with address...</option>
			{autofillAddresses?.map((address, i) => {
				return <option key={i} value={address.Street1}>{address.Street1}, {address.City}, {address.Province}, {address.PostalCode}, {address.Country}</option>
			})}
			{autofillCartAddresses?.map((address, i) => {
				return <option key={i} value={address.Street1}>{address.Street1}, {address.City}, {address.Province}, {address.PostalCode}, {address.Country}</option>
			})}
		</select>
	</>
}