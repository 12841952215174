import { Formik, ErrorMessage, Form, FormikHelpers } from 'formik';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { UpdateEmailModel, UpdateEmailSchema } from "../../api/auth.models"
import { useUpdateEmailMutation } from '../../api/account.api';
import { toastSuccess, toastError } from '../../common/components/toast';

const AccountUpdateEmail = () => {
	const { mutate: updateEmail } = useUpdateEmailMutation();

	const handleUpdateEmail = (values: UpdateEmailModel, { setSubmitting, resetForm } : FormikHelpers<UpdateEmailModel>) => {
		setSubmitting(true);
		updateEmail({ email: values.email }, { 
			onSuccess: (result) => {
				resetForm();
				toastSuccess("Your email has been updated");
			},
			onError: (error) => {
				toastError(error);
			},
			onSettled: () => {
				setSubmitting(false);
			}
		})
	}

	return <>
		<h4 className="font-weight-bold">Update Email</h4>
		<p className="text-muted">Update the email address that all online reservation communications are sent to.</p>

		<Formik
			validationSchema={UpdateEmailSchema}
			// enableReinitialize={true}
			initialValues={{
				email: ""
			}}
			onSubmit={(values, formikHelpers) => {
				handleUpdateEmail(values, formikHelpers);
			}}
			validateOnBlur={true}
		>
		{({ isSubmitting, isValid, dirty, values, errors, getFieldProps }) => <>
		<Form>
			<Row>
				<Col xs="12" sm="6">
					<div className="form-label-group">
						{/* <Field component={FormControl} isInvalid={errors.email} type="email" name="email" placeholder="required" autoComplete="email" required /> */}
						<FormControl {...getFieldProps("email")} type="email" required placeholder="Required" isInvalid={errors.email !== undefined && values.email !== ""} />
						<FormLabel>New Email Address</FormLabel>
						{errors.email !== "Required" && 
						<ErrorMessage name="email" component="div" className="fw-bold small text-danger" />
						}
					</div>
				</Col>
			</Row>

			<Row>
				<Col xs="12" sm="4">
					<Button variant="primary" type="submit" disabled={!isValid || !dirty} className="btn-block font-weight-bold">{isSubmitting && <FontAwesomeIcon icon={faSpinner} spin />} Update Email</Button>
				</Col>
			</Row>
		
		</Form>
		</>}

		</Formik>
	</>
}

export default AccountUpdateEmail