// import { useQuery } from 'react-query';
// import { useAuthState } from '../state/auth.state';
import { useQuery } from 'react-query';
import { useAuthState } from '../state/auth.state';
import { api } from './api';
import { ValidationError } from './cart.models';
import { ProgramRegistrationCustomFieldGroup, ProgramRegistrationPageContent, Registration, RegistrationHistory } from './program.registration.models';

export const validate = async (registration: Registration) => {
	let response = await api.post<ValidationError[]>(`/api/programs/registrations/validate`, registration);
	return response.data;
}

export const useProgramRegistrationHistoryQuery = () => { 
	const { isAuthenticated, userInfo } = useAuthState();

	return useQuery(['registration-history', userInfo?.email], async () => {
		let res = await api.get<RegistrationHistory[]>(`/api/programs/registrations/history`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: isAuthenticated() });
}

export const useProgramRegistrationPageContentQuery = (programId: number) => {
	return useQuery(['program-registration-page-content', programId], async () => {
		let res = await api.get<ProgramRegistrationPageContent[]>(`/api/programs/registrations/pagecontent/${programId}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: programId > 0 });
}

export const useProgramRegistrationCustomFieldsQuery = (programId: number) => {
	return useQuery(['program-registration-custom-fields', programId], async () => {
		let res = await api.get<ProgramRegistrationCustomFieldGroup[]>(`/api/programs/registrations/customfields/${programId}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: programId > 0 });
}
