import LoginDialog from "./LoginDialog"
import LogoutDialog from "./LogoutDialog"
import LoggedOutDialog from "./LoggedOutDialog"
import RegisterDialog from "./RegisterDialog"
import ResetPasswordDialog from "./ResetPasswordDialog"
import SessionTimeout from "./SessionTimeout"

const AuthComponents = () => {
	return <>
		<LoginDialog />
		<LogoutDialog />
		<LoggedOutDialog />
		<RegisterDialog />
		<ResetPasswordDialog />
		<SessionTimeout />
	</>
}

export default AuthComponents