// import { useState } from "react";
import { extend, hookstate, State, useHookstate } from "@hookstate/core";
import { devtools } from '@hookstate/devtools'
import { localstored, StoreEngine } from '@hookstate/localstored';
import moment from "moment-timezone";
// import { createEvent } from "react-event-hook";

import { ParticipantBase, Registration } from "../../../api/program.registration.models";
import { Program, ProgramActivitySession, ProgramSession } from "../../../api/programs.models";
import { ValidationError } from "../../../api/cart.models";
import { useState } from "react";
import { createEvent } from "react-event-hook";
import { validate } from "../../../api/programregistration.api";

export interface RegistrationState {
	registration: Registration|null,
	validationErrors: ValidationError[]
}

export const REGISTRATION_STATE = hookstate(
	{ 
		registration: null,
		validationErrors: []
	} as RegistrationState, 

	extend(
		localstored({
			key: 'program.registration.v1',
			engine: localStorage as StoreEngine,
			initializer: () => new Promise<RegistrationState>((resolve, reject) => { 
				resolve({ 
					registration: null,
					validationErrors: []
				})
			})
		}), 
		devtools({ key: 'program-registration' })
	)
);

const { useRegistrationParticipantsListener, emitRegistrationParticipants } = createEvent("registration-participants")<Registration>();
const { useRegistrationValidationListener, emitRegistrationValidation } = createEvent("registration-validation")();

export const useRegistrationState = () => {
	// const { setSelectedInventory } = useInventoryState();
	let state = useHookstate<RegistrationState>(REGISTRATION_STATE);
	const [isValidating, setIsValidating] = useState(false);

	return { 
		registration: state.registration.get({ noproxy: true }) as Registration,

		updateRegistration: (registration: Registration) => { 
			if (isValidating)
				return;
				
			state.registration.set(JSON.parse(JSON.stringify(registration))); 
		},
		setCurrentRegistration: (registration: Registration) => { 
			state.registration.set(JSON.parse(JSON.stringify(registration))); 
			state.validationErrors.set([]);
		},

		validationErrors: state.validationErrors.get({ noproxy: true }) as ValidationError[] ?? [],
		setValidationErrors: (validationErrors: ValidationError[]) => { state.validationErrors.set(validationErrors); },
		validateRegistration: async () => {
			emitRegistrationValidation();
			setIsValidating(true);
			let validationErrors = await validate(state.registration.get({ noproxy: true }) as Registration);
			setIsValidating(false);
			state.validationErrors.set(validationErrors);
			return validationErrors;
		},

		copyProgramSessionSelections: (program: Program, programSession: ProgramSession, participant: ParticipantBase, programSessions: ProgramSession[], programActivitySessions: ProgramActivitySession[]) => copyProgramSessionSelections(state, program, programSession, participant, programSessions, programActivitySessions),

		useRegistrationValidationListener,
		useRegistrationParticipantsListener,
		emitRegistrationValidation,
		emitRegistrationParticipants
	}
}

const copyProgramSessionSelections = (state: State<RegistrationState>, program: Program, programSession: ProgramSession, participant: ParticipantBase, programSessions: ProgramSession[], programActivitySessions: ProgramActivitySession[]) => {
	let registration = state.registration.get({ noproxy: true }) as Registration;
	if (registration === undefined)
		return;

	let sourceRPS = registration.ProgramSessions.find(r => r.ProgramSessionId === programSession.Id);
	let sourceParticipant = sourceRPS?.Participants.find(p => p.ParticipantId === participant.Id);

	for (let registrationProgramSession of registration.ProgramSessions) {
		if (registrationProgramSession.ProgramSessionId === sourceRPS?.ProgramSessionId)
			continue;

		let participantIndex = registrationProgramSession.Participants?.findIndex(p => p.ParticipantId === participant.Id);
		let targetParticipant = registrationProgramSession.Participants[participantIndex];

		targetParticipant.ActivitySessionIds = [];

		for (let sourceASId of sourceParticipant!.ActivitySessionIds) {
			let sourceAS = programActivitySessions.find(p => p.Id === Number(sourceASId));
console.log(sourceAS);
			let targetAS = programActivitySessions.find(p => p.Id !== Number(sourceASId) && p.ActivityId === sourceAS?.ActivityId &&
				moment(p.StartDate).startOf("day").toISOString() === moment(programSession.StartDate).startOf("day").toISOString()  &&
				moment(p.StartDate).format("h:mm") === moment(sourceAS.StartDate).format("h:mm"));
console.log(targetAS);
			if (targetAS)
				targetParticipant.ActivitySessionIds.push(targetAS.Id);

			console.log(targetParticipant);
		}
	}

	state.registration.set(registration);
}