import { EventDepositType } from "./events.models";
import { PromotionCode } from "./promotions.models";

export enum ReservationType {
	Onsite = "onsite",
	Offsite = "offsite",
	Virtual = "virtual"
}

export interface Reservation {
	Id: number;
	EventId: number;
	EventDateId: number;
	Type: ReservationType;
	DateFrom: string;
	DateTo: string;
	Requests: string;
	PromotionCodeId: number;
	Referral: string;
	DepositType: EventDepositType;
	DepositAmount: number;

	Items: ReservationItemBase[];
	Meals: ReservationMeal[];
	MealPackageId: number|null;
	MealDiscountId: number;
	MealDiscount: number;
	Charges: ReservationCharge[];
	Custom: CustomFieldValue[];

	PromotionCode: PromotionCode|undefined;

	Metadata?: ReservationMetadata|undefined;
}

export interface ReservationMetadata {
	eventTitle: string;
	inventoryType: string;
	inventoryDescriptions: string[];
	adults: string[];
	children: string[];
	cost: number;
	deposit: number;
}

export interface ReservationItemBase {
	Type: ReservationType;
	Adults: number;
	Children: number;

	AdultGuests: AdultGuest[];
	ChildGuests: ChildGuest[];

	// constructor(type: ReservationType) {
	// 	this.Type = type;
	// }
}

export interface ReservationInventoryItem extends ReservationItemBase {
	EventInventoryId: number;
	EventInventoryItemId: number;
	InventoryTypeId: number;
	InventoryCategoryId: number;
	InventoryItemId: number;
}

export interface ReservationOffsiteItem extends ReservationItemBase {
	// constructor(values: Partial<ReservationOffsiteItem> = {}) {
	// 	super(ReservationType.Offsite);
	// 	Object.assign(this, values);
	// }
}

export interface ReservationVirtualItem extends ReservationItemBase {
	// constructor(values: Partial<ReservationVirtualItem> = {}) {
	// 	super(ReservationType.Virtual);
	// 	Object.assign(this, values);
	// }
}

export interface ReservationMeal {
	EventMealId: number;
	Date: string;
	Quantities: ReservationMealQuantities[];
}

export interface ReservationMealQuantities {
	MealAgeGroupId: number;
	Quantity: number;
}

export interface ReservationCharge {
	EventChargeId?: number;
	Items: ReservationChargeItem[];
}

export interface ReservationChargeItem {
	EventChargeItemId: number;
	Quantity: number;
}

export interface GuestBase {
	Type: "adult"|"child";
	Id?: number;
	FirstName?: string;
	LastName?: string;
	Gender?: number;

	Address?: Address;
	SameAddress?: boolean;

	Allergies?: string;
	Dietary?: string;
}

export interface AdultGuest extends GuestBase {
	Salutation?: string;
	Email?: string;
	HomePhone?: string;
	MobilePhone?: string;
}

export interface ChildGuest extends GuestBase {
	Birthdate: Date;
}

export interface Address {
	Id?: number;
	Street1?: string;
	Street2?: string;
	City?: string;
	Province?: string;
	Region?: string;
	PostalCode?: string;
	Country?: string;
}


export interface ReservationHistory {
	EventId: number;
	EventDateId: number;
	DateSubmitted: string;
	DateFrom: string;
	DateTo: string;
	Total: number;
	Payments: number;
	Balance: number;
	ReceiptURL: string;
	ReservationNumber: string;

	Metadata: {
		Event?: { Title: string, Type?: ReservationType };
		Adults?: string[];
		Children?: string[];
		Inventory?: { Type?: string, Items?: { Description?: string, Amount?: number }[] }[];
		Meals?: { Package?: string, Count?: number, Amount?: number, Discount?: number };
		Charges?: { Charge?: string, Amount?: number }[];
		Custom?: { Group?: string, Values?: { Field?: string, Value: string|string[]|boolean }[] }[];
		PromotionCode?: { Code: string, Amount?: number };
	}
}

export interface ReservationPolicy {
	Id: number;
	EventId: number;
	Title: string;
	Description: string;
	Default: boolean;
	Priority: number;
	PolicyFrom?: Date;
	PolicyTo?: Date;
	MinimumNights: number;
	MaximumNights: number;
	DateFrom: Date;
	DateTo: Date;
	StartDayOfWeek?: number;
	EndDayOfWeek?: number;
}

export interface ReservationPolicyResult {
	Title?: string;
	Message?: string;
	MinimumStayValid?: boolean;
	MaximumStayValid?: boolean;
	DatesValid?: boolean;
	Policy?: ReservationPolicy;
}

export interface ReservationInventoryPolicy {
	Id: number;
	EventId: number;
	Title: string;
	Description: string;
	InventoryCategories: number[];
	PolicyFrom?: Date;
	PolicyTo?: Date;
	MinimumNights: number;
	MaximumNights: number;
	DateFrom: Date;
	DateTo: Date;
}

export interface ReservationInventoryPolicyResult {
	Title?: string;
	Message?: string;
	MinimumStayValid?: boolean;
	MaximumStayValid?: boolean;
	DatesValid?: boolean;
	Policy?: ReservationInventoryPolicy;
}

export interface ReservationCustomFieldGroup {
	Id: number;
	Title: string;
	Subtitle: string;
	CustomFields: ReservationCustomField[];
}

export interface ReservationCustomField {
	Id: number;
	Type: ReservationCustomFieldType;
	Required: boolean;
	Title: string;
	Items: string[];

	Value?: string;
}

export interface CustomFieldValue {
	CustomFieldId: number;
	CustomFieldGroupId: number;
	Value: string|string[]|boolean;
}

export enum ReservationCustomFieldType {
	TextSmall				= 1,
	TextMedium				= 2,
	TextLarge				= 3,
	CommentsSmall			= 11,
	CommentsMedium			= 12,
	CommentsLarge			= 13,
	CheckBoxSmall			= 21,
	CheckBoxMedium			= 22,
	CheckBoxLarge			= 23,
	CheckBoxListSmall		= 25,
	CheckBoxListMedium		= 26,
	CheckBoxListLarge		= 27,
	RadioButtonListSmall 	= 31,
	RadioButtonListMedium	= 32,
	RadioButtonListLarge 	= 33,
	DropdownListSmall		= 41,
	DropdownListMedium		= 42,
	DropdownListLarge		= 43
}

export interface ReservationPageContent {
	ComponentType: ReservationPageContentType;
	Content: string
}

export enum ReservationPageContentType {
	GuestInformation 	= 1,
	Meals				= 2,
	Charges 			= 3
}