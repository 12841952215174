import { toast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { AxiosError } from 'axios';
import { ApiError } from '../../api/api';

export const toastDefault = (message: string) => toast(message,
{
	icon: <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />,
	style: {
		marginTop: '20px',
		borderRadius: '30px',
		background: '#333',
		color: '#fff',
	},
});

export const toastSuccess = (message: string) => toast(message,
{
	icon: <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />,
	style: {
		marginTop: '20px',
		borderRadius: '30px',
		background: '#333',
		color: '#fff',
	},
});

export const toastError = (error: unknown, message?: string) => toast((error as AxiosError<ApiError>)?.response?.data.message ?? message ?? "",
{
	icon: <FontAwesomeIcon icon={faTimesCircle} className="ml-2" />,
	style: {
		marginTop: '20px',
		borderRadius: '30px',
		background: '#cc0000',
		color: '#fff',
	},
});