import { useReservationPageContentQuery } from "../../../api/reservation.api";
import { ReservationPageContent, ReservationPageContentType } from "../../../api/reservation.models";
import { useEventState } from "../functions/event-state";

interface EventReservationPageContentProps {
	type: ReservationPageContentType;
}

const EventReservationPageContent = ({ type }: EventReservationPageContentProps) => {
	const { event } = useEventState();
	const { data: pageContent } = useReservationPageContentQuery(event?.Id ?? 0);

	function createMarkup(content: ReservationPageContent) { return { __html: content.Content }; };

	return <>
		{pageContent?.map((content, index) => {
			return content.ComponentType === type ? <div key={index} dangerouslySetInnerHTML={createMarkup(content)}></div> : <div key={index}></div>
		})}
	</>
}

export default EventReservationPageContent