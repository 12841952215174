import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Alert, Button } from 'react-bootstrap';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Field, ErrorMessage, Form } from 'formik';

import { useAuthState } from '../../state/auth.state';
import { NewPasswordModel, NewPasswordSchema } from '../../api/auth.models';
import { getPageContentItem, PageContentComponentType, useConfigurationQuery, usePageContentQuery } from '../../api/configuration.api';
import DefaultLayout from "../../layouts/default-layout"
import "./account.css";

const AccountPasswordReset = () => {
	const location = useLocation();
	const history = useHistory();
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { data: configuration } = useConfigurationQuery();
	const { data: pageContent } = usePageContentQuery();
	const { isAuthenticated, newPassword, isResettingPassword, showLogin } = useAuthState();

	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation`;
	const accountHeader = getPageContentItem(pageContent, "Account", null, PageContentComponentType.HeaderImage);
	const headerImageUrl = accountHeader.Content;

	var params = new URLSearchParams(location.search);
	const key = params.get("key");

	const [invalidLink, setInvalidLink] = useState(key === null);
	const [success, setSuccess] = useState(false);

	const [error, setError] = useState<string|undefined>(undefined);
	const [recaptchaExecuting, setRecaptchaExecuting] = useState(false);

	if (isAuthenticated())
		history.push({ pathname: '/' });

	const handleNewPassword = async (values: NewPasswordModel) => {
		setError(undefined)
		if (!executeRecaptcha) {
			// Recaptcha has not loaded yet.
			return;
		}

		setRecaptchaExecuting(true);
		const recaptchaToken = await executeRecaptcha('form');
		setRecaptchaExecuting(false);
		
		let result = await newPassword({ password: values.password, key: key ?? "", recaptcha: recaptchaToken });
		if (result.success) {
			setSuccess(true);
			setError(undefined)
		} else {
			setError(result.error ?? "Unable to update password at this time.");
			if (result.error === "Password reset key is invalid")
				setInvalidLink(true);
		}
	}

	const handleLogin = () => {
		showLogin();
	}

	return <DefaultLayout pageTitle="Password Reset" subTitle="" size="sm" headerLargeImageUrl={headerImageUrl} headerSmallImageUrl={headerImageUrl}>
		<>
			<Alert variant="danger" show={invalidLink}>The password reset link is not valid</Alert>

			<Formik
					validationSchema={NewPasswordSchema}
					// enableReinitialize={true}
					initialValues={{
						password: "",
						key: key ?? "",
						recaptcha: ""
					}}
					onSubmit={(values, { setSubmitting }) => {
						handleNewPassword(values)
					}}
					validateOnBlur={true}
				>
				{({ isSubmitting, submitCount, isValid, dirty, values, errors }) => <>
				<Form>
				
					<Alert variant="danger" show={error !== undefined && invalidLink === false} dismissible={false}>
						{error}
					</Alert>

					<Alert variant="success" show={success === true} dismissible={false}>
						Your password has been successfully changed.
					</Alert>

					{success === true && <>
					<div className="form-row">
						<div className="col-12 col-sm-3">
							<Button onClick={handleLogin} variant="primary" type="button" className="btn-block font-weight-bold">Login</Button>
						</div>
					</div>
					</>}
					
					{success === false && !invalidLink && <>
					<div className="form-row">
						<div className="col-12 col-sm-6">
							<p>Please enter a new password:</p>
							<div className="form-label-group">
								<Field type="password" className="form-control not-required" name="password" placeholder="required" autoComplete="current-password" required />
								<label htmlFor="password">Password</label>
								<ErrorMessage name="password" component="div" className="fw-bold small text-danger" />
							</div>

							<p className="text-muted small">
								Password must be at least 8 characters in length, and include:
								<br /> - At least 1 uppercase character
								<br /> - At least 1 lowercase character
								<br /> - At least 1 Number
							</p>
						</div>
					</div>

					<div className="form-row">
						<div className="col-12 col-sm-3">
							<Button variant="primary" type="submit" disabled={!isValid || !dirty || recaptchaExecuting} className="btn-block font-weight-bold">{(isResettingPassword || recaptchaExecuting) && <FontAwesomeIcon icon={faCircleNotch} spin />} Reset Password</Button>
						</div>
					</div>
					</>}
				
				</Form>
				</>}

			</Formik>
		</>
	</DefaultLayout>			
}

export default AccountPasswordReset