import { useMemo, createRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Modal, ModalProps, Row } from "react-bootstrap";
import { faCalendarDay, faCheck, faCircleCheck, faCircleNotch, faCirclePlus, faLock, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatesSetArg } from "@fullcalendar/core";
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid'

import moment from "moment-timezone";
import classNames from "classnames";
import { debounce } from "lodash";

import { useConfigurationQuery } from "../../api/configuration.api";
import { useUserAddressQuery, useUserPeopleQuery } from "../../api/user.api";
import { useProgramSessionsByIdQuery, useProgramGroupsQuery, useProgramsQuery, useProgramSessionAccessCodeQuery } from "../../api/programs.api";
import { ProgramSession, ProgramParticipantType, ProgramSessionSelection, PriceType, SessionSelectionType } from "../../api/programs.models";
import { useProgramState } from "./functions/program-state";
import { createEmptyAdult, createEmptyChild, createParentGuardian, createRegistration } from "./functions/program-functions";
import { useRegistrationState } from "./functions/registration-state";
import { ParticipantBase } from "../../api/program.registration.models";
import { currency, linkTitle } from "../../common/helpers";
import ChildParticipantPreSelection from "./components/program-children-selection";
import ProgramParticipantSelection from "./components/program-participant-selection";
import DefaultLayout from "../../layouts/default-layout";
import Breadcrumb from "./components/breadcrumb";
import { useAuthState } from "../../state/auth.state";
import _ from "lodash";

type ProgramDetailsUrlParams = {
	programGroup: string;
	program: string;
}

const ProgramDetails = ({ }) =>  {
	const history = useHistory();

	const { data: configuration } = useConfigurationQuery();
	const { programGroup: programGroupParam, program: programParam } = useParams<ProgramDetailsUrlParams>();

	// const { data: programs, isLoading } = useProgramsQuery();
	// const { data: pageContent } = usePageContentQuery();

	const { data: programGroups, isLoading: isLoadingProgramGroups } = useProgramGroupsQuery();
	const { data: programs, isLoading: isLoadingPrograms } = useProgramsQuery();

	const { selectedSessions, toggleSession, setProgramSessionQuantity, isProgramSessionSelected, selectedParticipants, getAdultParticipants, getChildParticipants } = useProgramState();
	const { setCurrentRegistration } = useRegistrationState();

	const [modalShow, setModalShow] = useState<ProgramSession|undefined>(undefined);
	const [programSessionFullModalShow, setProgramSessionFullModalShow] = useState<ProgramSession|undefined>(undefined);
	const [programSessionAccessCodeShow, setProgramSessionAccessCodeShow] = useState<ProgramSession|undefined>(undefined);

	const programGroup = programGroups?.find(g => linkTitle(g.Title) === programGroupParam);
	const program = programs?.find(p => linkTitle(p.Title) === programParam);

	const { data: sessions } = useProgramSessionsByIdQuery(program?.Id ?? 0);

	let useCutOffDate = import.meta.env.VITE_FEATURE_FLAG_USE_CUTOFF_DATE == 'true';
	let cutOffDate = moment().startOf("week").add(-10, "hours");
	if (moment().isAfter(cutOffDate))
		cutOffDate = moment(cutOffDate).add(1, "week");

	const filteredSessions = programGroup?.SessionSelectionType === SessionSelectionType.Custom && useCutOffDate ? sessions?.filter(s => moment(s.StartDate).isAfter(cutOffDate)) ?? [] : sessions;

	const startDate = filteredSessions && filteredSessions.length > 0 ? moment(filteredSessions[0].StartDate).startOf("week").toISOString() : '2024-06-30';  //selectedSessions.length === 0 ? '2023-07-02' : moment(selectedSessions[0].ProgramSession.StartDate).startOf("week").toISOString();
	const endDate = filteredSessions && filteredSessions.length > 0 ? moment(filteredSessions[filteredSessions.length-1].EndDate).endOf("week").toISOString() : '2025-08-30'; //selectedSessions.length === 0 ? '2023-09-02' : moment(selectedSessions[0].ProgramSession.EndDate).endOf("week").toISOString();

	const { data: userPeople } = useUserPeopleQuery();
	const { data: userAddresses } = useUserAddressQuery();

	const useCalendar = programGroup?.SessionSelectionType == SessionSelectionType.Fixed;
	const calendarRef = createRef<FullCalendar>();

	const [currentStartDate, setCurrentStartDate] = useState<Date|undefined>();//filteredSessions && filteredSessions.length > 0 ? moment(filteredSessions[0].StartDate).toDate() : undefined);
	const [currentEndDate, setCurrentEndDate] = useState<Date|undefined>();//filteredSessions && filteredSessions.length > 0 ? moment(filteredSessions[filteredSessions.length - 1].EndDate).toDate() : undefined);

	const [minTime, setMinTime] = useState("9:00");
	const [maxTime, setMaxTime] = useState("17:00");

	useEffect(() => {
		if (currentStartDate === undefined && filteredSessions !== undefined && filteredSessions.length > 0) {
			// if (!getIsMultipleYears()) {
			// 	setCurrentStartDate(moment(filteredSessions[0].StartDate).toDate());
			// 	setCurrentEndDate(moment(filteredSessions[filteredSessions.length-1].EndDate).toDate());
			// }
			goToCurrentYear();
		}
	}, [sessions, filteredSessions]);

	useEffect(() => {
		if (!useCalendar)
			return;

		let calendarApi = calendarRef.current?.getApi();
		let dateFrom = moment(calendarApi?.view.currentStart).startOf("week");
		let dateTo = moment(calendarApi?.view.currentStart).endOf("week").add(-1, "minute");

		let currentSessions = filteredSessions?.filter((s) => moment(s.StartDate).isSameOrAfter(moment(dateFrom)) &&
			moment(s.EndDate).isSameOrBefore(moment(dateTo))) ?? [];

		if (currentSessions.length > 0) {
			let minDate = moment(currentSessions[0].StartDate);
			let maxDate = moment(currentSessions[0].EndDate);

			for (let session of currentSessions) {
				minDate = moment(session.StartDate).startOf("day").add(minDate.diff(moment(minDate).startOf("day"), "minutes"), "minutes");
				maxDate = moment(session.EndDate).startOf("day").add(maxDate.diff(moment(maxDate).startOf("day"), "minutes"), "minutes");

				if (moment(session.StartDate).isBefore(minDate))
					minDate = moment(session.StartDate);
				if (moment(session.EndDate).isAfter(maxDate))
					maxDate = moment(session.EndDate);
			}

			// maxDate.add(30, "minutes")
			setMinTime(minDate.format("HH:mm"));
			setMaxTime(maxDate.format("HH:mm"));
		}


	}, [sessions, currentStartDate]);

	// useEffect(() => {
	// 	resetProgramState();
	// }, []);

	const isSpaceAvailable = (programSession: ProgramSession) => { 

		return programSession.Limit - programSession.Enrollment > 0 
	}

	const canSelectEvent = () => {
		if (programGroup?.ParticipantType === ProgramParticipantType.ChildrenPreSelected)
			return false;

		return getAdultParticipants() + getChildParticipants() > 0;
	}

	const canRegister = (programSession: ProgramSession) => {
		if (programGroup?.ParticipantType === ProgramParticipantType.ChildrenPreSelected)
			return programSession.Limit - programSession.Enrollment - selectedParticipants.length >= 0;

		const adults = getAdultParticipants();
		const children = getChildParticipants();

		return programSession.Limit - programSession.Enrollment - adults - children >= 0;
	}

	const events = filteredSessions?.map((programSession) => { 
		return { 
			title: canRegister(programSession) ? `${programSession.Limit - programSession.Enrollment} Space${programSession.Limit - programSession.Enrollment === 1 ? '':'s'} available` : (programGroup?.AllowWaitlist ? "Wait list available" : "No space available"), //`${programSession.Limit - programSession.Enrollment - getProgramSessionQuantity(programSession)}/${programSession.Limit} spaces available ${isProgramSessionSelected(programSession) ? ' (selected)':''}`,
			start: moment(programSession.StartDate).toDate(),
			end: moment(programSession.EndDate).toDate(),
			editable: false,
			textColor: !isSpaceAvailable(programSession) ? "#333" : "black",
			backgroundColor: (isSpaceAvailable(programSession) && !canRegister(programSession)) ? "lightblue" : !isSpaceAvailable(programSession) ? "#d6e1d6" : "#a1cba1",
			extendedProps: {
				programSession: programSession,
				// selected: isProgramSessionSelected(programSession),
				// quantity: getProgramSessionQuantity(programSession)
			}
		}
		// return { 
		// 	title: isProgramSessionSelected(programSession) ? "Selected" : isSpaceAvailable(programSession) ? `Only ${programSession.Limit - programSession.Enrollment} Space available` : "No space available", //`${programSession.Limit - programSession.Enrollment - getProgramSessionQuantity(programSession)}/${programSession.Limit} spaces available ${isProgramSessionSelected(programSession) ? ' (selected)':''}`,
		// 	start: moment(programSession.StartDate).toDate(),
		// 	end: moment(programSession.EndDate).toDate(),
		// 	editable: false,
		// 	textColor: canRegister(programSession) ? "white" :"black",
		// 	backgroundColor: isProgramSessionSelected(programSession) ? "green": (isSpaceAvailable(programSession) && !canRegister(programSession)) ? "lightblue" : !isSpaceAvailable(programSession) ? "lightgray":"",
		// 	extendedProps: {
		// 		programSession: programSession,
		// 		selected: isProgramSessionSelected(programSession),
		// 		quantity: getProgramSessionQuantity(programSession)
		// 	}
		// }
	}) ?? [];
	
	function createDescriptionMarkup() { return { __html: program?.Description ?? "" }; };
	
	// const programsHeader = getPageContentItem(pageContent, "Programs", null, PageContentComponentType.HeaderImage);
	const headerImageUrl = program?.ImageURL ?? programGroup?.ImageURL ?? "";//programsHeader.Content;

	const handleEventClick = (info: any) => {
		if (!canSelectEvent())
			return;

		var selectedProgramSession = info.event.extendedProps.programSession;

		if (!canRegister(selectedProgramSession)) {
			setProgramSessionFullModalShow(selectedProgramSession);
			return;
		}

		setModalShow(selectedProgramSession);
	}

	const bookNowEnabled = () => {
		if (!sessions)
			return false;

		if (programGroup?.SessionSelectionType == SessionSelectionType.Custom && selectedSessions.length > 0)
			return true;

		// let calendarApi = calendarRef.current?.getApi();
		let dateFrom = moment(currentStartDate);//moment(calendarApi?.view.currentStart).startOf("week");
		let dateTo = moment(currentEndDate);//moment(calendarApi?.view.currentStart).endOf("week").add(-1, "day").add(1, "millisecond");

		let currentSessions = filteredSessions?.filter((s) => moment(s.StartDate).isSameOrAfter(moment(dateFrom)) &&
			moment(s.EndDate).isSameOrBefore(moment(dateTo))) ?? [];

		return currentSessions.length > 0;
	}

	const bookNow = (accessCodeValid?: boolean) => {
		const params = new URLSearchParams();

		// let dateFrom = moment(selectedSessiones[0].ProgramSession.StartDate).startOf("week");
		// let dateTo = moment(selectedSessiones[0].ProgramSession.StartDate).endOf("week").add(1, "millisecond");
		// let calendarApi = calendarRef.current?.getApi();
		if (selectedSessions.length == 0)
			return;

		let dateFrom = moment(selectedSessions[0].ProgramSession.StartDate); //moment(currentStartDate);//moment(calendarApi?.view.currentStart).startOf("week");
		let dateTo =  moment(selectedSessions[selectedSessions.length-1].ProgramSession.EndDate); //moment(currentEndDate).add(-1, "day");//moment(calendarApi?.view.currentStart).endOf("week").add(-1, "day").add(1, "millisecond");

		// let currentSessions = filteredSessions?.filter((s) => moment(s.StartDate).isSameOrAfter(moment(dateFrom)) &&
		// 	moment(s.EndDate).isSameOrBefore(moment(dateTo))) ?? [];
		let currentSessions = selectedSessions.map(s => s.ProgramSession);

		if (currentSessions.length === 0)
			return;

		currentSessions = currentSessions.sort((a, b) => moment(a.StartDate).isBefore(moment(b.StartDate)) ? -1 : 1);

		dateFrom = moment(currentSessions[0].StartDate);//.startOf("day");
		dateTo = moment(currentSessions[currentSessions.length - 1].EndDate);//.endOf("day");

		let sessionWithAccessCodeRequired = currentSessions.find(s => s.AccessCodeRequired === true);
		if (accessCodeValid !== true && sessionWithAccessCodeRequired !== undefined) {
			setProgramSessionAccessCodeShow(sessionWithAccessCodeRequired);
			return;
		}

		let adults = userPeople?.filter(p => p.Person.Birthdate === null).map(p => p.Person) ?? [];
		let addresses = userAddresses?.map(a => a.Address) ?? [];
		let parentGuardian = adults.length > 0 && addresses.length > 0 ? createParentGuardian(adults[0], addresses[0]) : null;

		let registration = createRegistration(program!, programGroup!, parentGuardian, selectedParticipants, selectedSessions, dateFrom, dateTo);
		setCurrentRegistration(registration);

		params.set("dateFrom", dateFrom.toISOString());
		params.set("dateTo", dateTo.toISOString());

		history.push({ pathname: `/programs/${linkTitle(programGroup?.Title ?? "")}/${linkTitle(program?.Title ?? "")}/registration`, search: params.toString() });
	}

	const datesChanged = (arg: DatesSetArg) => {
		if (moment(arg.start).isSame(moment(currentStartDate)) === false || 
			moment(arg.end).isSame(moment(currentEndDate)) === false) {

			setCurrentStartDate(new Date(arg.start));
			setCurrentEndDate(new Date(arg.end));
		}
	}

	const getIsMultipleYears = () => {
		let isMultipleYears = false;

		let currentYearEvent = events.find(e => moment(e.start).isSame(moment(), "year"));
		let nextYearEvent = events.find(e => moment(e.start).isSame(moment().add(1, "year"), "year"));
		isMultipleYears = currentYearEvent !== undefined && nextYearEvent !== undefined;

		return isMultipleYears;
	}

	const goToCurrentYear = () => { 
		if (!useCalendar) {
			let currentYearEvent = events.find(e => moment(e.start).isSame(moment(), "year"));
			if (currentYearEvent != null) {
				setCurrentStartDate(currentYearEvent?.start);
				setCurrentEndDate(moment(currentYearEvent?.start).endOf("year").toDate());
			} else {
				let nextYearEvent = events.find(e => moment(e.start).isSame(moment().add(1, "year"), "year"));
				if (nextYearEvent != null) {
					setCurrentStartDate(nextYearEvent?.start);
					setCurrentEndDate(moment(nextYearEvent?.start).endOf("year").toDate());
				}
			}

			deselectAllEvents();
			return;
		}
		let firstEvent = events.find(e => moment(e.start).year() === moment().year());
		let calendarApi = calendarRef.current?.getApi();
		if (firstEvent)
			calendarApi?.gotoDate(moment(firstEvent.start).startOf("week").toDate());
	}

	const goToNextYear = () => { 
		if (!useCalendar) {
			let nextYearEvent = events.find(e => moment(e.start).isSame(moment().add(1, "year"), "year"));
			setCurrentStartDate(nextYearEvent?.start);
			setCurrentEndDate(moment(nextYearEvent?.start).endOf("year").toDate());
			deselectAllEvents();
			return;
		}
		let firstEvent = events.find(e => moment(e.start).year() === moment().add(1, "year").year());
		let calendarApi = calendarRef.current?.getApi();
		if (firstEvent)
			calendarApi?.gotoDate(moment(firstEvent.start).startOf("week").toDate());
	}

	const isCurrentYear = () => {
		return currentStartDate !== undefined && moment(currentStartDate).isSame(moment(), "year");
	}

	const isNextYear = () => {
		return currentStartDate !== undefined && moment(currentStartDate).isSame(moment().add(1, "year"), "year");
	}

	// const programSessionsForCurrentDates = sessions?.filter(s => 
	// 	currentStartDate !== undefined && moment(s.StartDate).isAfter(moment(currentStartDate)) && 
	// 	currentEndDate !== undefined && moment(s.EndDate).isBefore(moment(currentEndDate)));

	const selectedSessionsDateRange = useMemo(() => {
		if (selectedSessions.length == 0)
			return "";

		let sortedSessions = selectedSessions.sort((a, b) => moment(a.ProgramSession.StartDate).isSameOrAfter(b.ProgramSession.StartDate) ? 1 : -1);
		return `${moment(sortedSessions[0].ProgramSession.StartDate).format("LL")} - ${moment(sortedSessions[sortedSessions.length - 1].ProgramSession.StartDate).format("LL")}`;
	}, [selectedSessions.length]);

	const eventsGroupedByWeek = useMemo(() => {
		var weeks: { week: string, events: any[] }[] = [];

		_.forEach(events, event => {
			var eventWeek = moment(event.start).startOf('week').toISOString();
			if (weeks.length == 0 || weeks[weeks.length - 1].week != eventWeek)
				weeks.push({
					week: eventWeek,
					events: [event]
				});
			else {
				weeks[weeks.length-1].events.push(event); 
			}	
		});

		weeks = weeks.filter(w => moment(w.events[0].start).isSameOrAfter(moment(currentStartDate)) && moment(w.events[w.events.length -1].end).isSameOrBefore(moment(currentEndDate)));

		return weeks;
	}, [events]);


	const selectAllEventsForWeek = (weekIndex: number) => {
		_.forEach(eventsGroupedByWeek[weekIndex].events, event => {
			setProgramSessionQuantity(event.extendedProps.programSession, 1);
		});
	}

	const deselectAllEventsForWeek = (weekIndex: number) => {
		_.forEach(eventsGroupedByWeek[weekIndex].events, event => {
			setProgramSessionQuantity(event.extendedProps.programSession, 0);
		});
	}

	const deselectAllEvents = () => {
		_.forEach(eventsGroupedByWeek, week => {
			_.forEach(week.events, event => {
				setProgramSessionQuantity(event.extendedProps.programSession, 0);
			});
		});		
	}

	const renderGrid = <>
		<Container>
			{eventsGroupedByWeek.map((week, weekIndex) => {
				return <div key={weekIndex}>
					<div className="sessionGroup">
						{moment(week.events[0].start).format("MMMM D, YYYY")} - {moment(week.events[week.events.length-1].start).format("MMMM D, YYYY")}
						<div className="btn btn-secondary btn py-1 d-none d-sm-inline" style={{ float: 'right' }} onClick={() => deselectAllEventsForWeek(weekIndex)}>
							Select None
						</div>
						<div className="btn btn-success btn py-1 mr-2 d-none d-sm-inline" style={{ float: 'right' }} onClick={() => selectAllEventsForWeek(weekIndex)}>
							Select All
						</div>
						<div className="row my-2">
							<div className="col-6">
								<div className="btn btn-success d-block d-sm-none" onClick={() => selectAllEventsForWeek(weekIndex)}>
									Select All
								</div>
							</div>
							<div className="col-6">
								<div className="btn btn-secondary d-block d-sm-none" onClick={() => deselectAllEventsForWeek(weekIndex)}>
									Select None
								</div>
							</div>
						</div>
					</div>
					<Row>
						{week.events.map((event, index) => {
							return <Col key={index} sm={4}>
								<div onClick={() => toggleSession(event.extendedProps.programSession)} className={classNames(["sessionButton", isProgramSessionSelected(event.extendedProps.programSession) ? "sessionButtonSelected":""])}>
									<div className="dateTime">
										{isProgramSessionSelected(event.extendedProps.programSession) &&
										<div className="iconCheck">
											<FontAwesomeIcon icon={faCircleCheck} size="2xl" />
										</div>
										}
										{!isProgramSessionSelected(event.extendedProps.programSession) &&
										<div className="iconPlus">
											<FontAwesomeIcon icon={faCirclePlus} size="2xl" />
										</div>
										}
										{moment(event.start).format('dddd, MMMM D, YYYY')}<br />
										{moment(event.start).format('h:mma')} - {moment(event.end).format('h:mma')}
									</div>
									<div className="title">
										{event.title}
										{/* <br />
										{event.extendedProps?.programSession?.Id} */}
									</div>
								</div>
							</Col>
						})}
					</Row>
				</div>
			})}
		</Container>
	
	</>;
	
	const renderCalendar = useMemo(() => {
		return <>
			<FullCalendar
				ref={calendarRef}
				plugins={[ timeGridPlugin ]}
				initialView="timeGridSevenDay"
				// height={500}
				eventMinWidth={200}
				// eventMinHeight={200}
				expandRows={true}
				initialDate={startDate}
				validRange={{ start: startDate, end: endDate }}
				headerToolbar={{
					left: 'prev,next',
					center: 'title',
					right: ''
				}}
				slotMinTime={minTime}
				slotMaxTime={maxTime}
				allDaySlot={false}
				views={{
					timeGridSevenDay: {
					type: 'timeGrid',
					slotEventOverlap: false,
					// eventMaxStack: 1,
					eventTimeFormat: {
						hour: 'numeric',
						minute: '2-digit',
						meridiem: 'narrow'
					},
					duration: { days: 7 }
					// eventMinHeight: 100
					}
				}}
				
				// plugins={[ dayGridPlugin ]}
				// initialView="dayGridTwoMonth"
				// validRange={{ start: '2023-07-01', end: '2023-09-03' }}
				// views={{
				// 	dayGridTwoMonth: {
				// 	  type: 'dayGridYear',
				// 	  duration: { months: 2 }
				// 	}
				// }}


				// multiMonthMaxColumns={1}
				// views={{
				// 	'multiMonthFourMonth': {
				// 		type: 'multiMonth',
				// 		startParam: "2023-07-01",
				// 		endParam: "2023-09-02",
				// 		duration: { months: 2 }
				// 	}
				// }}
				events={events}
				eventContent={renderEventContent}
				selectable={false}
				eventClick={handleEventClick}
				datesSet={datesChanged}
			/>
		</>
	}, [sessions, selectedSessions, events])

	// a custom render function
	function renderEventContent(eventInfo: any) {
		let programSession = eventInfo.event.extendedProps.programSession as ProgramSession;
		return (
		<>
			<div className="d-flex flex-column justify-content-between p-2" style={{ height: '100%', cursor: canSelectEvent() ? 'pointer':'' }}>
			<div className="fc-event-time">{eventInfo.timeText}</div>
			<div className="fc-event-title-container">
				<div className="fc-event-title fc-sticky">
					{eventInfo.event.title}
				</div>
			</div>

			{programSession.AccessCodeRequired === true && <div className="text-center">
				<FontAwesomeIcon icon={faLock} className="text-black-50" />
				<div>Access Code Required</div>
			</div>}

			<div className="text-center">
				{isSpaceAvailable(programSession) && canRegister(programSession) && <>
				{/* <a href="" onClick={(e) => { e.preventDefault(); setModalShow(programSession) }}><FontAwesomeIcon icon={faPlusCircle} size="3x" className="mr-2 text-white" /></a> */}
				{/* {!isProgramSessionSelected(programSession) && selectedParticipants.length > 0 &&
				<a href="" onClick={(e) => { e.preventDefault(); toggleSession(programSession) }}><FontAwesomeIcon icon={faPlusCircle} size="3x" className="mr-2 text-white" /></a>
				} */}
				
				{/* {isProgramSessionSelected(programSession) && 
				<a href="" onClick={(e) => { e.preventDefault(); toggleSession(programSession) }}><FontAwesomeIcon icon={faCheckCircle} size="3x" className="mr-2 text-white" /></a>
				} */}
				</>}
				{/* {isSpaceAvailable(programSession) && !canRegister(programSession) && selectedParticipants.length > 0 &&
				<FontAwesomeIcon icon={faCircleXmark} size="3x" className="mr-2 text-white-50" />
				} */}
			</div>
			{/* {eventInfo.event.extendedProps.quantity} */}
			{/* <a href="javascript:void(0);" onClick={(e) => decrememntProgramSession(eventInfo.event.extendedProps.programSession)}><FontAwesomeIcon icon={faMinusCircle} size="xl" className="ml-2" /></a> */}
			</div>
		</>
		)
	}

	if (isLoadingProgramGroups || isLoadingPrograms) {
		return <>
			<DefaultLayout pageTitle="" headerLargeImageUrl="" headerSmallImageUrl="">
				<h4 className="mb-4">
					<FontAwesomeIcon icon={faCircleNotch} spin /> 
					<span> Loading...</span>
				</h4>
			</DefaultLayout>
		</>
	}

	if ((programGroup === undefined && !isLoadingProgramGroups) || (program === undefined && !isLoadingPrograms)) {
		history.push({ pathname: "/programs" });
		return <></>;
	}

	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | ${program?.Title}`;

	if (program && program.AvailableDate !== null && moment(program.AvailableDate).isAfter(moment()))
		return <DefaultLayout pageTitle={`${program?.Title}`} headerLargeImageUrl={headerImageUrl} headerSmallImageUrl={headerImageUrl}>
			<Breadcrumb step={2} />
			<div className="row">
				<div className="col-sm-12">
					{program?.Description !== "" && <>
					<div dangerouslySetInnerHTML={createDescriptionMarkup()}></div>
					<hr />
					</>}
					
					<div className="text-center">
						<FontAwesomeIcon size="10x" icon={faCalendarDay} className="text-muted mt-4 mb-4" />
						<h4 className="text-slim">This will be available for online registration beginning {moment(program.AvailableDate).format("dddd, LL")}</h4>
					</div>
				</div>
			</div>
		</DefaultLayout>

	return <DefaultLayout pageTitle={`${program?.Title}`} headerLargeImageUrl={headerImageUrl} headerSmallImageUrl={headerImageUrl}>
		<Breadcrumb step={2} />
		<div className="row">
			<div className="col-sm-12">
				{program?.Description !== "" && <>
				<div dangerouslySetInnerHTML={createDescriptionMarkup()}></div>
				<hr />
				</>}

				{filteredSessions?.length === 0 && 
				<div className="text-center">
					<FontAwesomeIcon size="10x" icon={faCircleXmark} className="text-muted mt-4 mb-4" />
					<h4 className="text-slim">There are currently no sessions available for this program.</h4>
				</div>
				}
				{(filteredSessions?.length ?? 0) > 0 &&
				<>
					{getIsMultipleYears() && <>
						<div className="mb-3">
							<h5 className="font-weight-bold">Which year are you registering for:</h5>
							<button onClick={goToCurrentYear} type="button" className={classNames(["btn mr-1", isCurrentYear() ? "btn-primary" : "btn-secondary"])}>{ isCurrentYear() && <FontAwesomeIcon icon={faCheck} className="mr-2" />}{moment().format("YYYY")}</button>
							<button onClick={goToNextYear} type="button" className={classNames(["btn", isNextYear() ? "btn-primary" : "btn-secondary"])}>{ isNextYear() && <FontAwesomeIcon icon={faCheck} className="mr-2" />}{moment().add(1, "year").format("YYYY")}</button>
							{currentStartDate && <hr />}
						</div>
					</>}
					{programGroup?.ParticipantType !== ProgramParticipantType.ChildrenPreSelected && <>
						<ProgramParticipantSelection />
					</>}

					{currentStartDate && programGroup?.ParticipantType === ProgramParticipantType.ChildrenPreSelected && <>
						<h5 className="font-weight-bold">Select the children you want to register:</h5>
						{program && <ChildParticipantPreSelection program={program} date={currentStartDate} />}
						<hr />
					</>}
					
					{programGroup?.ParticipantType !== ProgramParticipantType.ChildrenPreSelected && <>
						<h5 className="font-weight-bold">Select the session you want to register for:</h5>
					</>}

					{currentStartDate && !useCalendar && <>
						{programGroup?.ParticipantType === ProgramParticipantType.ChildrenPreSelected && <>
						<h5 className="font-weight-bold">Select the sessions you want to register for:</h5>
						</>}
						<div className="mt-3 programDetailsGrid" style={{ padding: '15px 0 0 0', borderRadius: '5px' }}>
							{renderGrid}
						</div>
					</>}

					{useCalendar && <>
						{programGroup?.ParticipantType === ProgramParticipantType.ChildrenPreSelected && <>
						<h5 className="font-weight-bold">Select the week you want to register for:</h5>
						</>}
						<div className="mt-3" style={{ width: '100%', overflowX: 'scroll' }}>
							<div style={{ minWidth: '690px' }}>
							{renderCalendar}
							</div>
						</div>
					</>}
						
					<ProgramSessionAccessCodeModal show={programSessionAccessCodeShow !== undefined} onHide={() => setProgramSessionAccessCodeShow(undefined)} programSession={programSessionAccessCodeShow} onAccessCodeValid={() => bookNow(true)} />
					<ProgramSessionFullModal show={programSessionFullModalShow !== undefined} onHide={() => setProgramSessionFullModalShow(undefined)} programSession={programSessionFullModalShow} />
					<ProgramSelectionModal show={modalShow !== undefined} onHide={() => setModalShow(undefined)} programSession={modalShow} />

					{useCalendar && selectedParticipants.length > 0 && 
					<div className="row">
						<div className="col-12 col-sm-5 offset-sm-4">
							<button type="button" className="btn btn-primary btn-lg btn-block font-weight-bold mt-4" disabled={!bookNowEnabled()} onClick={() => bookNow()}>Book Now</button>
						</div>
					</div>
					}

				</>
				}
			</div>
		</div>
		{selectedSessions.length > 0 && selectedParticipants.length > 0 &&
		<div className="programDetailsBookingBar">
			<div className="container pb-3 pb-sm-0">
				<div className="row">
					<div className="col-xs-6 col-sm-8 pb-2 pb-sm-0">
						<div className="summary">
							{selectedParticipants.length} participant{selectedParticipants.length != 1 ? 's':''}, {selectedSessions.length} session{selectedSessions.length != 1 ? 's':''} selected
						</div>
						<div>
							{selectedSessionsDateRange}
						</div>
					</div>
					<div className="col-xs-6 col-sm-4">
						<button className="btn btn-primary btn-lg btn-block font-weight-bold" disabled={!bookNowEnabled()} onClick={() => bookNow()}>Book Now</button>
					</div>
				</div>

			</div>
		</div>
		}
	</DefaultLayout>
}

export default ProgramDetails

interface ProgramSelectionModalProps {
	programSession: ProgramSession|undefined;
}

const ProgramSelectionModal = ({ programSession, ...props }: ProgramSelectionModalProps & ModalProps) => {
	const history = useHistory();
	const { data: programGroups } = useProgramGroupsQuery();
	const { data: programs } = useProgramsQuery();
	const { getAdultParticipants, getChildParticipants } = useProgramState();
	const { setCurrentRegistration } = useRegistrationState();
	const { getIsAuthenticated, showLogin } = useAuthState();

	const program = programs?.find(p => p.Id === programSession?.ProgramId);
	const programGroup = programGroups?.find(g => g.Id === program?.ProgramGroupId);
	
	const formatDates = () => {
		
		// let nights = moment(eventDate.DateTo).diff(moment(eventDate.DateFrom), 'days');
		return `${moment(programSession?.StartDate).format('MMM D, YYYY')} - ${moment(programSession?.StartDate).format('h:mm A')} to ${moment(programSession?.EndDate).format('h:mm A')}`;
	}

	const adults = getAdultParticipants()
	const children = getChildParticipants();

	const bookNow = () => {
		if (!getIsAuthenticated()) {
			showLogin();
			return;
		}
		const params = new URLSearchParams();

		// let dateFrom = moment(selectedSessiones[0].ProgramSession.StartDate).startOf("week");
		// let dateTo = moment(selectedSessiones[0].ProgramSession.StartDate).endOf("week").add(1, "millisecond");
		// let calendarApi = calendarRef.current?.getApi();

		let dateFrom = moment(programSession?.StartDate);//moment(calendarApi?.view.currentStart).startOf("week");
		let dateTo = moment(programSession?.EndDate);//moment(calendarApi?.view.currentStart).endOf("week").add(-1, "day").add(1, "millisecond");

		//let adults = userPeople?.filter(p => p.Person.Birthdate === null).map(p => p.Person) ?? [];
		let selectedSessions: ProgramSessionSelection[] = [
			{
				ProgramSession: programSession!,
				Quantity: adults + children
			}
		];

		let selectedParticipants: ParticipantBase[] = [];
		for (let i = 0; i < adults; i++) {
			selectedParticipants.push(createEmptyAdult());
		}
		for (let i = 0; i < children; i++) {
			selectedParticipants.push(createEmptyChild());
		}
		// let addresses = userAddresses?.map(a => a.Address) ?? [];
		// let parentGuardian = adults.length > 0 && addresses.length > 0 ? createParentGuardian(adults[0], addresses[0]) : null;

		let registration = createRegistration(program!, programGroup!, null, selectedParticipants, selectedSessions, dateFrom, dateTo);
		setCurrentRegistration(registration);

		params.set("dateFrom", dateFrom.toISOString());
		params.set("dateTo", dateTo.toISOString());

		history.push({ pathname: `/programs/${linkTitle(programGroup?.Title ?? "")}/${linkTitle(program?.Title ?? "")}/registration`, search: params.toString() });
	}

	return <>
		<Modal centered {...props}>
			{/* <Modal.Header closeButton>
				<Modal.Title>{program?.Title}</Modal.Title>

			</Modal.Header> */}
			<Modal.Body className="p-4">
				<button type="button" onClick={props.onHide} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3>{program?.Title}</h3>
				<div className="mb-3">{formatDates()}</div>
				<div>
					<strong>
						<span>Participants: </span>
						{adults > 0 && <>{adults} Adult{adults !== 1 ? 's':''}</>}
						{adults > 0 && children > 0 && ", "}
						{children > 0 && <>{children} Child{children !== 1 ? 'ren':''}</>}
					</strong>
				</div>
				{program && Number(program.Price) > 0 &&
				<div>
					<strong>
						Cost: {currency.format(program.Price)}{program.PriceType === PriceType.PerPerson ? " per person" : " per group"}
					</strong>
				</div>
				}
				<hr />
				{!getIsAuthenticated() &&
				<Button onClick={showLogin}>Login to book this</Button>
				}
				{getIsAuthenticated() &&
				<Button onClick={bookNow}>Book Now</Button>
				}
			</Modal.Body>
			{/* <Modal.Footer>
			</Modal.Footer> */}
		</Modal>
	</>
}

const ProgramSessionFullModal = ({ programSession, ...props }: ProgramSelectionModalProps & ModalProps) => {
	const { data: programs } = useProgramsQuery();

	const program = programs?.find(p => p.Id === programSession?.ProgramId);
	
	const formatDates = () => {
		return `${moment(programSession?.StartDate).format('MMM D, YYYY')} - ${moment(programSession?.StartDate).format('h:mm A')} to ${moment(programSession?.EndDate).format('h:mm A')}`;
	}

	return <>
		<Modal centered {...props}>
			<Modal.Body className="p-4">
				<button type="button" onClick={props.onHide} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3>{program?.Title}</h3>
				<div className="mb-3">{formatDates()}</div>
				<div>
					{programSession && <>
					{programSession.Limit - programSession.Enrollment <= 0 &&
					<strong>
						This session has no space available.
					</strong>
					}
					{programSession.Limit - programSession.Enrollment > 0 &&
					<strong>
						This session only has space for {programSession.Limit - programSession.Enrollment} participants.
					</strong>
					}
					</>}
				</div>
				<hr />
				<Button onClick={props.onHide}>Close</Button>
			</Modal.Body>
		</Modal>
	</>
}

interface ProgramSessionAccessCodeModalProps {
	programSession: ProgramSession|undefined;
	onAccessCodeValid: () => void;
}

const ProgramSessionAccessCodeModal = ({ programSession, onAccessCodeValid, ...props }: ProgramSessionAccessCodeModalProps & ModalProps) => {
	const { data: programs } = useProgramsQuery();
	const { data: configuration } = useConfigurationQuery();

	const [accessCode, setAccessCode] = useState("");
	const [accessCodeCheck, setAccessCodeCheck] = useState("");
	const { data: accessCodeValid } = useProgramSessionAccessCodeQuery(programSession?.Id ?? 0, accessCodeCheck);

	useEffect(() => {
		setAccessCode("");
		setAccessCodeCheck("");
	}, [props.show])

	const program = programs?.find(p => p.Id === programSession?.ProgramId);
	
	const formatDates = () => {
		return `${moment(programSession?.StartDate).format('MMM D, YYYY')} - ${moment(programSession?.StartDate).format('h:mm A')} to ${moment(programSession?.EndDate).format('h:mm A')}`;
	}

	const handleAccessCodeChanged = (code: string) => {
		setAccessCode(code);
		checkAccessCode(code);
	}

	const checkAccessCode = useMemo(() => debounce((code: string) => {
		setAccessCodeCheck(code);
	}, 1000), []);

	return <>
		<Modal centered {...props}>
			<Modal.Body className="p-4">
				<button type="button" onClick={props.onHide} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3>{program?.Title}</h3>
				<div className="mb-3">{formatDates()}</div>
				{programSession && 
				<div>
					<p><strong>One or more of the sessions for the selected dates requires a registration access code, which is provided by {configuration?.OrganizationShortform}.</strong></p>
				</div>
				}

				<Form.Label>Please enter the access code:</Form.Label>
				<Form.Control type="text" value={accessCode} onChange={(e) => handleAccessCodeChanged(e.target.value)} placeholder="Registration access code" required isInvalid={accessCodeValid === false} isValid={accessCodeValid === true} />
				<Form.Control.Feedback type="invalid">
					The provided access code is not correct.
				</Form.Control.Feedback>
				<hr />
				<Button onClick={onAccessCodeValid} disabled={accessCodeValid !== true} className="mr-2 font-weight-bold">Book Now</Button>
				<Button onClick={props.onHide} variant="light" className="font-weight-bold">Cancel</Button>
			</Modal.Body>
		</Modal>
	</>
}