import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useConfigurationQuery } from '../api/configuration.api';
import { ThemeMode, useThemeState } from '../pages/events/functions/theme-state';
import HeaderProgramMenu from '../common/components/header-program-menu';
import ExploreProgramMenu from '../common/components/header-explore-menu';
import HeaderUserMenu from '../common/components/header-user-menu';
import './header.css';

const AppHeader = () => {
	const { data: configuration } = useConfigurationQuery();
	const { currentTheme } = useThemeState();

	const theme = currentTheme === ThemeMode.Light ? "theme-light" : "theme-dark";
	const title = (configuration?.OrganizationShortform ?? "") + " Online Reservation";

	return <>
		<nav className={classNames(["navbar navbar-expand-md navbar-light-x navbar-dark-x bg-dark-x fixed-top shadow bg-white-x", theme])}>
			<Link to="/" className="navbar-brand mr-sm-5">{title}</Link>
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>

			<div className="collapse navbar-collapse" id="navbarsExampleDefault">
				<ul className="navbar-nav mr-auto">
					<ExploreProgramMenu />
					<HeaderProgramMenu />
				</ul>

				<ul className="navbar-nav">
					<HeaderUserMenu />
				</ul>
			</div>
		</nav>
	</>
}

export default AppHeader