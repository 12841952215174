import { useState } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { useAuthState } from '../../../state/auth.state';
import { useAuthUIState } from '../../../state/authui.state';
import { LoginModel, LoginSchema } from '../../../api/auth.models';
import { useConfigurationQuery } from '../../../api/configuration.api';
import { toastSuccess } from '../toast';

interface LoginDialogProps {

}

const LoginDialog = ({ }: LoginDialogProps) => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { login, isLoggingIn } = useAuthState();
	const { isLoginOpen, hideLogin, showRegister, showResetPassword } = useAuthUIState();
	const { data: configuration } = useConfigurationQuery();

	const [error, setError] = useState<string|undefined>(undefined);
	const [recaptchaExecuting, setRecaptchaExecuting] = useState(false);

	const handleLogin = async (values: LoginModel) => {
		setError(undefined)
		if (!executeRecaptcha) {
			// Recaptcha has not loaded yet.
			return;
		}

		setRecaptchaExecuting(true);
		const recaptchaToken = await executeRecaptcha('form');
		setRecaptchaExecuting(false);
		
		let result = await login({ username: values.username, password: values.password, recaptcha: recaptchaToken });
		if (result.success) {
			toastSuccess('You are now signed in');
			setError(undefined)
			hideLogin();
		} else {
			if (result.error === "")
				result.error = "Unable to sign in at this time."
			setError(result.error);
		}
	}

	const handleClose = () => {
		setError(undefined);
		hideLogin();
	}

	const handleRegister = () => {
		setError(undefined);
		showRegister();
	}

	const handlePasswordReset = () => {
		setError(undefined);
		showResetPassword();
	}

	return <>
		<Modal show={isLoginOpen} onHide={handleClose} centered>
			{/* <Modal.Header closeButton>
				<Modal.Title>Modal heading</Modal.Title>
			</Modal.Header> */}
			<Modal.Body className="p-4">

				<Formik
					validationSchema={LoginSchema}
					// enableReinitialize={true}
					initialValues={{
						username: "",
						password: "",
						recaptcha: ""
					}}
					onSubmit={(values) => {
						handleLogin(values)
					}}
					validateOnBlur={true}
				>
				{({ isValid, dirty }) => <>
				<Form>
				<button type="button" onClick={handleClose} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3>Login to your {configuration?.OrganizationShortform ?? ""} Account</h3>
				
				<div className="form-row mt-4">
					<div className="col-12">
						<div className="form-label-group">
							<Field type="email" className="form-control not-required" name="username" placeholder="required" autoComplete="email" required />
							<label htmlFor="email">Email Address</label>
							<ErrorMessage name="username" component="div" className="fw-bold small text-danger" />
							{/* <div className="small text-danger error-message">Email Address is requried</div> */}
						</div>
					</div>
				</div>
				<div className="form-row">
					<div className="col-12">
						<div className="form-label-group">
							<Field type="password" className="form-control not-required" name="password" placeholder="required" autoComplete="current-password" required />
							<label htmlFor="password">Password</label>
							<ErrorMessage name="password" component="div" className="fw-bold small text-danger" />
							{/* <div className="small text-danger error-message">Password is requried</div> */}
						</div>
					</div>
				</div>

				<Alert variant="danger" show={error !== undefined} dismissible={false}>
					{error}
				</Alert>

				<div className="form-row">
					<div className="col-12">
						<Button variant="primary" type="submit" disabled={!isValid || !dirty || recaptchaExecuting || isLoggingIn} className="btn-block font-weight-bold">{(isLoggingIn || recaptchaExecuting) && <FontAwesomeIcon icon={faCircleNotch} spin />} Login</Button>
						{/* <button type="submit" className="btn btn-primary btn-block font-weight-bold">{isLoggingIn && <FontAwesomeIcon icon={faSpinner} spin />} Login</button> */}

						<p className="m-0 text-center text-muted">
							<button onClick={handlePasswordReset} className="btn btn-default btn-link btn-sm mt-3">Forgot your password?</button>
						</p>

						<hr className="mt-4" />

						<p className="m-0 text-center text-muted">
							Don't have an account yet?
							<button onClick={handleRegister} className="btn btn-default btn-outline-secondary btn-sm font-weight-bold ml-3 pl-3 pr-3">Join Now</button>
						</p>
					</div>
				</div>
				
				</Form>
				</>}

				</Formik>

			</Modal.Body>
			{/* <Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={handleLogin}>
					{isLoggingIn && <FontAwesomeIcon icon={faSpinner} spin />} Login
				</Button>
			</Modal.Footer> */}
      </Modal>
	</>
}

export default LoginDialog