import { useHistory, useLocation } from "react-router-dom";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DefaultLayout from "../../layouts/default-layout";
import { getPageContentItem, PageContentComponentType, useConfigurationQuery, usePageContentQuery } from "../../api/configuration.api";

const CheckoutSuccess = () => {
	const history = useHistory();
	const location = useLocation();
	const { data: configuration } = useConfigurationQuery();
	const { data: pageContent } = usePageContentQuery();
	
	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | Checkout Success`;
	const checkoutHeader = getPageContentItem(pageContent, "Checkout", null, PageContentComponentType.HeaderImage);

	var headerLargeImageUrl = checkoutHeader.Content;
	var headerSmallImageUrl = checkoutHeader.Content;

	var redirectToProgramRegistrations = false;
	let params = new URLSearchParams(location.search);
	if (params.get("redirect") === "registrations")
		redirectToProgramRegistrations = true;

	const checkStyles: React.CSSProperties = {
		fontSize: '100px',
		marginBottom: '15px'
	}

	const handleViewReservations = () => {
		history.push({ pathname: redirectToProgramRegistrations ? '/account/registrations' : '/account/reservations' });
	}

	return <>
		<DefaultLayout pageTitle="Checkout Success" subTitle="" headerLargeImageUrl={headerLargeImageUrl} headerSmallImageUrl={headerSmallImageUrl}>
			<div className="row">
				<div className="col-12 text-center">
					<FontAwesomeIcon icon={faCheckCircle} className="success-check text-success" style={checkStyles} />
					<div className="font-weight-bold">
						<h5>Your {redirectToProgramRegistrations ? "registrations" : "reservations"} have been submitted to {configuration?.OrganizationShortform ?? ""}.</h5>
					</div>
					<div>
						You will receive an email shortly confirming your {redirectToProgramRegistrations ? "registration" : "reservation"} details.
					</div>

					<button onClick={handleViewReservations} className="btn btn-default btn-outline-secondary btn-sm font-weight-bold pl-3 pr-3 mt-4 mb-5">View Your {redirectToProgramRegistrations ? "Registrations" : "Reservations"}</button>
				</div>
			</div>
		</DefaultLayout>
	</>
}

export default CheckoutSuccess;