import { useEffect, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment-timezone';
import classNames from 'classnames';
import _ from "lodash";
import { useEventsQuery } from '../../../api/events.api';
import { Event, EventDate } from '../../../api/events.models';
import { linkTitle } from '../../../common/helpers';
import { useConfigurationQuery } from '../../../api/configuration.api';
import { ThemeMode, useThemeState } from '../../events/functions/theme-state';
import { useCartState } from '../../cart/functions/cart-state';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import './event-search.css'

const EventSearch = () => {
	const history = useHistory();
	const { data: configuration } = useConfigurationQuery();
	const { currentTheme } = useThemeState();
	const { getCartSize } = useCartState();
	const { data: events, isLoading: isLoadingEvents } = useEventsQuery();

	const [selectedEvent, setSelectedEvent] = useState<Event|undefined>();
	const [selectedEventDate, setSelectedEventDate] = useState<EventDate|undefined>();
	const [selectedDateRange, setSelectedDateRange] = useState<Date[]>([]);
	const [minDate, setMinDate] = useState<Date|undefined>(moment().toDate());
	const [maxDate, setMaxDate] = useState<Date|undefined>(moment().add(1, 'year').toDate());
	const [canSearch, setCanSearch] = useState(false);
	const keyRef = useRef(Date.now());

	const title = configuration?.SearchTitle ?? "";
	const subtitle = configuration?.SearchDescription ?? "";
	const shortForm = configuration?.OrganizationShortform ?? "";
	const theme = currentTheme === ThemeMode.Light ? "bg-light" : "bg-dark";
	let cartItemCount = getCartSize();

	useEffect(() => {
		keyRef.current = Date.now();
	}, []);

	const eventSelected = (eventId: string) => {
		let event = events?.find(e => e.Id === parseInt(eventId));
		setSelectedEvent(event);
		setCanSearch(false);

		var min: Date;
		var max: Date;

		if (event === undefined) {
			min = moment().toDate();
			max = moment().add(1, 'year').toDate();
			setSelectedDateRange([]);
			setSelectedEventDate(undefined);
			setMinDate(min);
			setMaxDate(max);
		} else {
			if (event.FixedDates === false) {
				if (event.Dates.length > 0) {
					min = new Date(event.Dates[0].DateFrom);
					max = new Date(event.Dates[event.Dates.length -1].DateTo);
					if (min < moment().toDate())
						min = moment().toDate();
					// setCanSearch(true);
				} else {
					min = new Date();
					max = new Date();
				}
				setSelectedDateRange([]);
				setMinDate(min);
				setMaxDate(max);
			} else {
				if (event.Dates.length > 0) {
					setSelectedEventDate(event.Dates[0]);
					// console.log(`selected event date: `, event.Dates[0]);
				} else {
					setSelectedEventDate(undefined);
				}
				setCanSearch(true);
			}
		}

		keyRef.current = Date.now();
	}

	const eventDateSelected = (index: string) => {
		let eventDate = selectedEvent?.Dates[parseInt(index)];
		setSelectedEventDate(eventDate);
		// console.log(`selected event date:`, eventDate);
	}

	const handleCallback = (start: moment.Moment, end: moment.Moment, label: any) => {
		// console.log('dates selected: ', start, end, label);
		setSelectedDateRange([start.toDate(),end.toDate()]);
		setCanSearch(true);
	}

	const handleApply = (e: any, picker: any) => {
		setSelectedDateRange([picker.startDate.toDate(),picker.endDate.toDate()]);
	}

	const showDatePicker = (e: any, picker: any) => {
		keyRef.current = Date.now();
	}

	const isInvalidDate = (date: Date) => {
		if (selectedEvent === undefined || selectedEvent.FixedDates === true)
			return false;

		var datesDisabled : Date[] = [];
		for (var i = 1; i < selectedEvent.Dates.length; i++) {
			var disableFrom = moment(selectedEvent.Dates[i-1].DateTo).add(1, "day");
			var disableTo = moment(selectedEvent.Dates[i].DateFrom);
			
			while (disableFrom.isBefore(disableTo)) {
				datesDisabled.push(disableFrom.toDate());
				disableFrom = disableFrom.add(1, "day");
			}
		}

		return _.some(datesDisabled, d => moment(date).isSame(moment(d), "date"));

		// return moment(date).isSame(moment('2023-07-05'), "date");
	}

	const getDisplayDateRange = () => {
		return selectedDateRange.length > 0 ? `${moment(selectedDateRange[0]).format('MMM D, YYYY')} - ${moment(selectedDateRange[1]).format('MMM D, YYYY')}` : '';
	}

	const search = () => {
		const params = new URLSearchParams();

		if (selectedEvent !== undefined && selectedEvent.FixedDates && selectedEventDate !== undefined) {
			params.append("dateFrom", moment(selectedEventDate.DateFrom).toISOString());
			params.append("dateTo", moment(selectedEventDate.DateTo).toISOString());
			history.push({ pathname: `/events/${linkTitle(selectedEvent.Title)}`, search: params.toString() })
		} else {
			params.append("dateFrom", moment(selectedDateRange[0]).toISOString());
			params.append("dateTo", moment(selectedDateRange[1]).toISOString());
			history.push({ pathname: `/events`, search: params.toString() })
		}
	}

	return <div className={classNames(["shadow p-4 rounded event-search", theme])}>

		<div className="d-sm-none d-block">
			<h4 className="text-center">{title}</h4>
			<p className="text-center">{subtitle}</p>
		</div>

		<div className="d-none d-sm-block">
			<h1 className="text-center">{title}</h1>
			<p className="lead text-center">{subtitle}</p>
		</div>

		<p><strong>Choose your getaway</strong></p>
		
		<select className="custom-select d-block w-100" id="country" onChange={(e) => eventSelected(e.target.value)}>
			<option>Search all {shortForm} Getaways</option>
			{!isLoadingEvents && events?.map((e, i) => {
				return <option key={i} value={e.Id} disabled={e.Dates.length === 0}>{e.Title}</option>
			})}
		</select>
		

		<p className="mt-3"><strong>When will you arrive and depart?</strong></p>
		
		{(selectedEvent === undefined || (selectedEvent && selectedEvent.FixedDates === false)) &&
		<>
			<DateRangePicker key={keyRef.current} onCallback={handleCallback} onApply={handleApply} onShow={showDatePicker} initialSettings={{autoApply: true, opens: 'center', showDropdowns: true, autoUpdateInput: false, startDate: selectedDateRange[0], endDate: selectedDateRange[1], minDate: moment(minDate), maxDate: moment(maxDate), isInvalidDate: isInvalidDate }}>
				<input type="text" className="form-control" readOnly={true} value={getDisplayDateRange()} placeholder="Arrival and Departure" />
			</DateRangePicker>
		</>	
		}

		{selectedEvent && selectedEvent.FixedDates &&
			<select className="custom-select d-block w-100" onChange={e => eventDateSelected(e.target.value)}>
				{selectedEvent.Dates.map((date, index) => {
					return <option key={index} value={index}>{moment(date.DateFrom).format('MMM D, YYYY')} - {moment(date.DateTo).format('MMM D, YYYY')} {date.Description}</option>
				})}
			</select>
		}
		
		<p className="mb-0">
			<button onClick={search} className="btn btn-lg mt-4 w-100 btn-primary" disabled={!canSearch}>Search Getaways</button>
		</p>
		
		{cartItemCount > 0 &&
		<p className="mt-3 mb-0 text-center">
			<Link to="/cart">You have {cartItemCount} reservation in your cart</Link>
		</p>
		}
	</div>
}

export default EventSearch