import { useEffect } from "react";
import { ErrorMessage, Field, Formik, useFormikContext } from "formik";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import _, { debounce } from "lodash";
import { getCustomFieldSize, isCheckBox, isCheckBoxList, isDropdownList, isInputField, isMultifield, isRadioButtonList, isTextAreaField } from "../functions/program-registration-functions";
import { useProgramRegistrationCustomFieldsQuery } from "../../../api/programregistration.api";
import { useProgramState } from "../functions/program-state";
import { useRegistrationState } from "../functions/registration-state";
import { ProgramRegistrationCustomField, ProgramRegistrationCustomFieldGroup, Registration } from "../../../api/program.registration.models";

interface AutoSaveStateProps {
	group: ProgramRegistrationCustomFieldGroup;
}
const AutoSaveState = ({ group }: AutoSaveStateProps) => {
	const { program } = useProgramState();
	const { registration, updateRegistration, useRegistrationValidationListener } = useRegistrationState();
	const { data: customFieldGroups } = useProgramRegistrationCustomFieldsQuery(program?.Id ?? 0);
	const { values, submitForm, isValidating } = useFormikContext();

	useRegistrationValidationListener(() => {
		submitForm();
	});

	// const update = useMemo(() => throttle((res: Reservation, values: any) => {	
	const update = debounce((registration: Registration, values: any) => {
		for (let customFieldGroup of customFieldGroups?.filter(g => g.Id === group.Id) ?? []) {
			for (let customField of customFieldGroup.CustomFields) {
				if (registration.Custom === undefined)
					registration.Custom = [];
				var reservationCustomField = registration.Custom.find(c => c.CustomFieldId === customField.Id);
				if (reservationCustomField) {
					reservationCustomField.Value = _.get(values, `customField_${customField.Id}`) ?? "";
				} else {
					registration.Custom.push({
						CustomFieldGroupId: customFieldGroup.Id,
						CustomFieldId: customField.Id,
						Value: _.get(values, `customField_${customField.Id}`)
					})
				}
			}
		}

		updateRegistration(registration);
	}, 100);
	// }, 1000), []);
	
	useEffect(() => {
		update(registration, values);
	}, [isValidating])
	
	return <></>
}

const ProgramRegistrationCustomFields = () => {
	const { program } = useProgramState();
	const { registration } = useRegistrationState();
	const { data: customFieldGroups } = useProgramRegistrationCustomFieldsQuery(program?.Id ?? 0);

	const getInitialValues = (customFieldGroup: ProgramRegistrationCustomFieldGroup) => {
		var values = {};

		for (let field of customFieldGroup.CustomFields) {
			var value = registration?.Custom?.find(c => c.CustomFieldId === field.Id);
			if (value)
				_.set(values, `customField_${field.Id}`, value.Value);
			else {
				if (isMultifield(field)) {
					_.set(values, `customField_${field.Id}`, []);
				} else {
					_.set(values, `customField_${field.Id}`, '');
				}
			}
		}

		return values;
	}

	const validate = (values: any, customFieldGroup: ProgramRegistrationCustomFieldGroup) => { 
		var errors = {};

		for (let field of customFieldGroup.CustomFields) {
			if (field.Required && (_.get(values, `customField_${field.Id}`) === undefined || _.get(values, `customField_${field.Id}`).length === 0)) {
				_.set(errors, `customField_${field.Id}`, 'Required');
			}
		}

		return errors;
	}

	return <>
		{customFieldGroups?.map((customFieldGroup, groupIndex) => { 
		return <div className="card shadow mb-3" key={groupIndex}>
			<h5 className="card-header"><FontAwesomeIcon icon={faClipboardList} className="text-muted mr-2" />{customFieldGroup.Title}</h5>

			<div className="card-body">
				<p>{customFieldGroup.Subtitle}</p>

				<Formik
					// validationSchema={AdditionalInformationSchema}
					// enableReinitialize={true}
					initialValues={getInitialValues(customFieldGroup)}
					onSubmit={() => { }}
					validateOnBlur={true}
					validate={(values) => validate(values, customFieldGroup)}
				>
				{() => <>
					<AutoSaveState group={customFieldGroup} />

					<div className="row">
						{customFieldGroup.CustomFields.map((customField, customFieldIndex) => {
							return <div className={getCustomFieldSize(customField)} key={customFieldIndex}>
								<CustomField field={customField} group={customFieldGroup} />
							</div>
						})}
					</div>

				</>}
				</Formik>

			</div>
		</div>
		})}
	</>
}

export default ProgramRegistrationCustomFields

interface CustomFieldProps {
	field: ProgramRegistrationCustomField;
	group: ProgramRegistrationCustomFieldGroup;
}

const CustomField = ({ field, group }: CustomFieldProps) => {
	const { getFieldMeta, getFieldHelpers } = useFormikContext();
	const { value } = getFieldMeta<string|string[]|boolean>(`customField_${field.Id}`);
	const { setValue } = getFieldHelpers(`customField_${field.Id}`);

	if (isMultifield(field) && !Array.isArray(value)) {
		setValue([]);
	}

	const isSelected = (item: string) => {
		if (isRadioButtonList(field))
			return value === item;

		if (isCheckBoxList(field) && Array.isArray(value))
			return (value as string[]).includes(item);

		return false;
	}

	const setSelected = (item: string, selected: boolean) => {
		var values = _.clone(value);
		if (isCheckBoxList(field) && Array.isArray(values)) {
			if (selected !== true && values.includes(item) === false) {
				values.push(item);
			}
			if (selected === true && values.includes(item) === true) {
				values.splice(values.indexOf(item), 1);
			}
		}
		if (isRadioButtonList(field)) {
			values = item;
		}
		setValue(values);
	}

	return <>
		{isInputField(field) &&
			<div className="form-label-group mb-2">
				<Field type="text" name={`customField_${field.Id}`} className={classNames([`form-control`, field.Required ? 'required':'' ])} placeholder="Please Specify" autoComplete="off" />
				<label className="mt-2 mt-sm-0 text-truncate">{field.Title}</label>
				<ErrorMessage name={`customField_${field.Id}`} component="div" className="fw-bold small text-danger error-message" />
			</div>
		}
		{isTextAreaField(field) &&
			<div className="form-label-group mb-2">
				<Field component="textarea" rows="3" name={`customField_${field.Id}`} className={classNames([`form-control`, field.Required ? 'required':'' ])} placeholder="Please Specify" autoComplete="off" />
				<label className="mt-2 mt-sm-0 text-truncate">{field.Title}</label>
				<ErrorMessage name={`customField_${field.Id}`} component="div" className="fw-bold small text-danger error-message" />
			</div>
		}
		{isDropdownList(field) &&
			<div className="form-label-group mb-2">
				<Field component="select" name={`customField_${field.Id}`} className={classNames(["custom-select", value !== "" ? "value-selected" : "" ])}>
					{(value === "" || value === undefined) && <option></option>}
					{field.Items.map((item, index) => {
						return <option value={item} key={index}>{item}</option>
					})}
				</Field>
				<label className="text-truncate">{field.Title}</label>
				<ErrorMessage name={`customField_${field.Id}`} component="div" className="fw-bold small text-danger error-message" />
			</div>
		}
		{isCheckBox(field) &&
			<div className="custom-control custom-checkbox mt-2 mb-2">					
				<Field type="checkbox" name={`customField_${field.Id}`} id={`customField_${field.Id}`} checked={value === true} className="custom-control-input" />
				<label htmlFor={`customField_${field.Id}`} className="custom-control-label">{field.Title}</label>
			</div>	
		}
		{isCheckBoxList(field) &&
			<div className="form-group">
				<div className="mb-2">
					<p className="text-muted mb-0">{field.Title}</p>
				</div>
				{field.Items.map((item, index) => {
					return <div key={index} className="custom-control custom-checkbox mb-2">
						<Field type="checkbox" name={`customField_${field.Id}`} id={`customField_${field.Id}_${index}`} checked={isSelected(item)} onChange={(e: any) => setSelected(item, !e.target.checked)} className="custom-control-input" />
						<label htmlFor={`customField_${field.Id}_${index}`} className="custom-control-label">{item}</label>
					</div>
				})}
				{field.Required && ((value as string[]).length === 0) &&
					<div className="small text-danger error-message mt-1">Please make a selection</div>
				}
			</div>	
		}
		{isRadioButtonList(field) &&
			<div className="form-group">
				<div className="mb-2">
					<p className="text-muted mb-0">{field.Title}</p>
				</div>
				{field.Items.map((item, index) => {
					return <div key={index} className="custom-control custom-radio mb-2">
						<Field type="radio" name={`customField_${field.Id}`} id={`customField_${field.Id}_${index}`} checked={isSelected(item)} onChange={(e: any) => setSelected(item, !e.target.checked)} className="custom-control-input" />
						<label htmlFor={`customField_${field.Id}_${index}`} className="custom-control-label">{item}</label>
					</div>
				})}
				{field.Required && ((value as string) === '') &&
					<div className="small text-danger error-message mt-1">Please make a selection</div>
				}
			</div>	
		}
	</>
}