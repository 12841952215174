export interface PromotionCode {
	Id: number;
	EventId: number;
	Code: string;
	DiscountType: PromotionCodeDiscountType;
	DiscountAmount: number;
	MinimumDays?: number;
}

export enum PromotionCodeDiscountType {
	Amount = 1,
	Percent = 2,
	AccommodationAmount = 3,
	AccommodationPercent = 4
}

export interface PromotionCodeResult {
	Success: boolean;
	PromotionCode: PromotionCode;
	Message: string;
}

export interface ProgramPromotionCode {
	Id: number;
	ProgramId: number;
	Code: string;
	DiscountType: ProgramPromotionCodeDiscountType;
	DiscountAmount: number;
}

export enum ProgramPromotionCodeDiscountType {
	Amount = 1,
	Percent = 2
}

export interface ProgramPromotionCodeResult {
	Success: boolean;
	PromotionCode: ProgramPromotionCode;
	Message: string;
}