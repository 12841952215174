import { hookstate, State, useHookstate } from "@hookstate/core";
import { devtools } from '@hookstate/devtools'
import _ from "lodash";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ChildParticipant, ParticipantBase } from "../../../api/program.registration.models";
import { useProgramFromTitleQuery, useProgramGroupFromTitleQuery } from "../../../api/programs.api";
import { Program, ProgramSession, ProgramSessionSelection, ProgramGroup } from "../../../api/programs.models";
import { Person } from "../../../api/user.models";
import { createParticipant } from "./program-functions";

export interface ProgramState {
	selectedParticipants: ParticipantBase[],
	adultParticipantCount: number,
	childParticipantCount: number,
	programGroup?: ProgramGroup,
	program?: Program,
	selectedSessions: ProgramSessionSelection[]
}

const PROGRAM_STATE = hookstate<ProgramState>(
	{ 
		selectedParticipants: [],
		adultParticipantCount: 0,
		childParticipantCount: 0,
		programGroup: undefined,
		program: undefined,
		selectedSessions: []
	},
	devtools({ key: "program" })
);

type ProgramUrlParams = {
	programGroup: string;
	program: string;
}

export const useProgramState = () => {
	const history = useHistory();
	// const search = useLocation().search;
	const { programGroup: programGroupTitle, program: programTitle } = useParams<ProgramUrlParams>();

	let state = useHookstate<ProgramState>(PROGRAM_STATE);

	const { data: programGroup, isLoading: isLoadingProgramGroup, refetch: refetchProgramGroup } = useProgramGroupFromTitleQuery(programGroupTitle ?? "");
	const { data: program, isLoading: isLoadingProgram, refetch: refetchProgram } = useProgramFromTitleQuery(programTitle ?? "");


	useEffect(() => {
		return history.listen(async (location) => { 
			// console.log(`You changed the page to: ${location.pathname}${location.search}`) 
			let programGroup = await refetchProgramGroup();
			let program = await refetchProgram();

			if (programGroup.data?.Id !== state.programGroup.value?.Id ||
				program.data?.Id !== state.program.value?.Id) {
				state.programGroup.set(programGroup.data);
				state.program.set(program.data);

				state.selectedSessions.set([]);
				state.selectedParticipants.set([]);
				state.adultParticipantCount.set(0);
				state.childParticipantCount.set(0);
			}
		 }) 
	}, [history]);

	useEffect(() => {
		state.programGroup.set(programGroup);
	}, [isLoadingProgramGroup])

	useEffect(() => {
		// history.listen(async (location) => {

		// })
		if (state.program.value?.Id !== program?.Id) {
			state.selectedSessions.set([]);
			state.selectedParticipants.set([]);
		}
		state.program.set(program);
	}, [isLoadingProgram])

	return {
		selectedParticipants: state.selectedParticipants.get({ noproxy: true }) as ParticipantBase[],
		setSelectedParticipants: (participants: ParticipantBase[]) => setSelectedParticipants(state, participants),
		addParticipant: (person: Person, ageOverrideReason = "") => addParticipant(state, person, ageOverrideReason),
		removeParticipant: (person: Person) => removeParticipant(state, person),
		getAdultParticipants: () => state.adultParticipantCount.get({ noproxy: true }),
		getChildParticipants: () => state.childParticipantCount.get({ noproxy: true }),
		setAdultParticipants: (adults: number) => state.adultParticipantCount.set(adults),
		setChildParticipants: (children: number) => state.childParticipantCount.set(children),
		programGroup: state.programGroup.get({ noproxy: true }) as ProgramGroup,
		isLoadingProgramGroup: isLoadingProgramGroup,
		program: state.program.get({ noproxy: true }) as Program,
		isLoadingProgram: isLoadingProgram,
		selectedSessions: state.selectedSessions.get({ noproxy: true }) as ProgramSessionSelection[],
		isProgramSessionSelected: (programSession: ProgramSession) => isProgramSessionSelected(state, programSession),
		getProgramSessionQuantity: (programSession: ProgramSession) => getProgramSessionQuantity(state, programSession),
		getProgramSessionAvailable: (programSession: ProgramSession) => getProgramSessionAvailable(state, programSession),
		setProgramSessionQuantity: (programSession: ProgramSession, quantity: number) => setProgramSessionQuantity(state, programSession, quantity),
		isProgramSessionSpaceAvailable: (programSession: ProgramSession) => isProgramSessionSpaceAvailable(state, programSession),
		incrememntProgramSession: (programSession: ProgramSession) => selectProgramSession(state, programSession),
		decrememntProgramSession: (programSession: ProgramSession) => deselectProgramSession(state, programSession),
		toggleSession: (programSession: ProgramSession) => {
			if (isProgramSessionSelected(state, programSession)) {
				deselectProgramSession(state, programSession);
			} else {
				selectProgramSession(state, programSession);
			}
		},
		resetProgramState: () => { 
			state.set({
				program: undefined,
				programGroup: undefined,
				selectedParticipants: [],
				adultParticipantCount: 0,
				childParticipantCount: 0,
				selectedSessions: []
			});
		},
	}
}

const setSelectedParticipants = (programState: State<ProgramState>, participants: ParticipantBase[]) => {
	programState.selectedParticipants.set(participants);
}

const addParticipant = (programState: State<ProgramState>, person: Person, ageOverrideReason: string) => {
	let selectedParticipants = programState.selectedParticipants.get({ noproxy: true }) as ParticipantBase[];
	let index = selectedParticipants.findIndex(p => p.FirstName === person.FirstName && p.LastName === person.LastName);
	if (index < 0) {
		let participant = createParticipant(person);
		if (participant.Type === "child") {
			let childParticipant = participant as ChildParticipant;
			childParticipant.AgeOverrideReason = ageOverrideReason;
		}
			
		selectedParticipants.push(participant);

		let selectedSessions = programState.selectedSessions.get({ noproxy: true }) as ProgramSessionSelection[];
		selectedSessions = selectedSessions.filter(s => s.ProgramSession.Limit - s.ProgramSession.Enrollment > selectedParticipants.length);

		programState.selectedParticipants.set(selectedParticipants);
		programState.selectedSessions.set(selectedSessions);
	}
}

const removeParticipant = (programState: State<ProgramState>, person: Person) => {
	let selectedParticipants = programState.selectedParticipants.get({ noproxy: true }) as ParticipantBase[];
	let index = selectedParticipants.findIndex(p => p.FirstName === person.FirstName && p.LastName === person.LastName);
	if (index >= 0) {
		selectedParticipants.splice(index, 1);

		let selectedSessions = programState.selectedSessions.get({ noproxy: true }) as ProgramSessionSelection[];
		if (selectedParticipants.length === 0) {
			selectedSessions = [];
			programState.selectedSessions.set(selectedSessions);
		}

		programState.selectedParticipants.set(selectedParticipants);
	}
}

const isProgramSessionSelected = (programState: State<ProgramState>, programSession: ProgramSession) => {
	let selectedProgramSessions = programState.selectedSessions.get({ noproxy: true }) as ProgramSessionSelection[];
	return selectedProgramSessions.filter(i => i.ProgramSession.Id === programSession.Id).length > 0;
}

const getProgramSessionQuantity = (programState: State<ProgramState>, programSession: ProgramSession) => {
	let selectedProgramSessions = programState.selectedSessions.get({ noproxy: true }) as ProgramSessionSelection[];
	let programSessionSelection = selectedProgramSessions.find(i => i.ProgramSession.Id === programSession.Id);
	return programSessionSelection?.Quantity ?? 0;
}

const getProgramSessionAvailable = (programState: State<ProgramState>, programSession: ProgramSession) => {
	let selectedProgramSessions = programState.selectedSessions.get({ noproxy: true }) as ProgramSessionSelection[];
	let programSessionSelection = selectedProgramSessions.find(i => i.ProgramSession.Id === programSession.Id);
	return programSessionSelection?.Quantity ?? 0;
}

const setProgramSessionQuantity = (programState: State<ProgramState>, programSession: ProgramSession, quantity: number) => {
	let selectedProgramSessions = programState.selectedSessions.get({ noproxy: true }) as ProgramSessionSelection[];
	let programSessionSelection = selectedProgramSessions.find(p => p.ProgramSession.Id === programSession.Id);
	if (programSessionSelection) {
		programSessionSelection.Quantity = quantity;
		_.remove(selectedProgramSessions, (s) => s.Quantity === 0);
		programState.selectedSessions.set(selectedProgramSessions);
	} else if (quantity > 0) {
		selectedProgramSessions.push({ ProgramSession: programSession, Quantity: quantity });
		programState.selectedSessions.set(selectedProgramSessions);
	}
}

const isProgramSessionSpaceAvailable = (programState: State<ProgramState>, programSession: ProgramSession) => {
	let selectedProgramSessions = programState.selectedSessions.get({ noproxy: true }) as ProgramSessionSelection[];
	let selectedProgramSession = selectedProgramSessions.find(i => i.ProgramSession.Id === programSession.Id);
	if (selectedProgramSession === undefined)
		return programSession.Enrollment < programSession.Limit;

	return selectedProgramSession.Quantity + programSession.Enrollment <= programSession.Limit;
}

const selectProgramSession = (programState: State<ProgramState>, programSession: ProgramSession) => {
	let selectedProgramSessions = programState.selectedSessions.get({ noproxy: true }) as ProgramSessionSelection[];

	// if (_.some(selectedProgramSessions, (i) => i.Id === programSession.Id) === false) {
	// 	selectedProgramSessions.push(programSession);
	// }
	let pc = selectedProgramSessions.find(p => p.ProgramSession.Id === programSession.Id);
	if (pc) {
		pc.Quantity++;
	} else {
		selectedProgramSessions.push({ ProgramSession: programSession, Quantity: 1 });
	}
	programState.selectedSessions.set(selectedProgramSessions);
}

const deselectProgramSession = (programState: State<ProgramState>, programSession: ProgramSession) => {
	let selectedProgramSessions = programState.selectedSessions.get({ noproxy: true }) as ProgramSessionSelection[];
	// if (_.some(selectedProgramSessions, (i) => i.Id === programSession.Id) === true) {
	// 	_.remove(selectedProgramSessions, (i) => i.Id === programSession.Id);
	// }
	let pc = selectedProgramSessions.find(p => p.ProgramSession.Id === programSession.Id);
	if (pc) {
		pc.Quantity--;
	}
	_.remove(selectedProgramSessions, (i) => i.Quantity === 0);
	programState.selectedSessions.set(selectedProgramSessions);
}