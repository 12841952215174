import { Formik, ErrorMessage, Form, FormikHelpers } from "formik";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import FormLabel from "react-bootstrap/FormLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { UpdatePasswordSchema, UpdatePasswordModel } from "../../api/auth.models"
import { useUpdatePasswordMutation } from "../../api/account.api";
import { toastSuccess, toastError } from "../../common/components/toast";

const AccountUpdatePassword = () => {
	const { mutate: updatePassword } = useUpdatePasswordMutation();
	const handleUpdatePassword = (values: UpdatePasswordModel, { setSubmitting, resetForm } : FormikHelpers<UpdatePasswordModel>) => {
		setSubmitting(true);
		updatePassword({ currentPassword: values.currentPassword, newPassword: values.newPassword }, { 
			onSuccess: (result) => {
				resetForm();
				toastSuccess("Your password has been updated");
			},
			onError: (error) => {
				toastError(error);
			},
			onSettled: () => {
				setSubmitting(false);
			}
		})
	}

	return <>
		<h4 className="font-weight-bold">Update Password</h4>
		<p className="text-muted">Update your online reservation account password.</p>

		<Formik
			validationSchema={UpdatePasswordSchema}
			// enableReinitialize={true}
			initialValues={{
				currentPassword: "",
				newPassword: ""
			}}
			onSubmit={(values, formikHelpers) => {
				handleUpdatePassword(values, formikHelpers);
			}}
			validateOnBlur={true}
		>
		{({ isSubmitting, isValid, dirty, errors, getFieldProps }) => <>
		<Form>

			<Row>
				<Col xs="12" sm="4">
					<div className="form-label-group">
						<FormControl {...getFieldProps("currentPassword")} type="password" required placeholder="Required" isInvalid={errors.currentPassword !== undefined} />
						<FormLabel>Current Password</FormLabel>
						<ErrorMessage name="currentPassword" component="div" className="fw-bold small text-danger" />
					</div>
				</Col>
				<Col xs="12" sm="4">
					<div className="form-label-group">
						<FormControl {...getFieldProps("newPassword")} type="password" required placeholder="Required" isInvalid={errors.newPassword !== undefined} />
						<FormLabel>New Password</FormLabel>
						<ErrorMessage name="newPassword" component="div" className="fw-bold small text-danger" />
					</div>
				</Col>
			</Row>

			<p className="text-muted small">
				New Password must be at least 8 characters in length, and include:
				<br /> - At least 1 uppercase character
				<br /> - At least 1 lowercase character
				<br /> - At least 1 Number
			</p>

			{/* <Alert variant="danger" show={error != undefined} dismissible={false}>
				{error}
			</Alert> */}

			<Row>
				<Col xs="12" sm="4">
					<Button variant="primary" type="submit" disabled={!isValid || !dirty || isSubmitting} className="btn-block font-weight-bold">{isSubmitting && <FontAwesomeIcon icon={faSpinner} spin />} Update Password</Button>
				</Col>
			</Row>
		
		</Form>
		</>}

		</Formik>
	</>
}

export default AccountUpdatePassword