import React, { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

interface SetupReCaptchaProps {
	children: JSX.Element;
}

const ReCaptchaProvider = ({ children }: SetupReCaptchaProps) => {
	const [reCaptchaKey, setReCaptchaKey] = useState<string|null>(null);

	useEffect(() => {
		if (import.meta.env.VITE_RECAPTCHA_KEY) {
			setReCaptchaKey(import.meta.env.VITE_RECAPTCHA_KEY);
		}
	}, []);

	return reCaptchaKey !== null ? 
		<GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
			{children}
		</GoogleReCaptchaProvider> : <>{children}</>	
}

export default ReCaptchaProvider