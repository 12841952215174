import { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { currency, linkTitle } from "../../common/helpers";
import { useCartState } from "./functions/cart-state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { getProgramValidationErrors, getRegistrationOtherValidationErrors, getRegistrationValidationErrors, isProgramInvalid } from "./functions/cart-functions";
import { Registration } from "../../api/program.registration.models";
import { useRegistrationState } from "../programs/functions/registration-state";

export interface CartItemProgramRegistrationProps {
	registration: Registration;
	// metadata: CartStateMetadata;
}

const CartItemProgramRegistration = ({ registration }: CartItemProgramRegistrationProps) => {
	const history = useHistory();
	const { setCurrentRegistration } = useRegistrationState();
	const { removeProgramRegistration, validationErrors } = useCartState();
	const [showRemovePopover, setShowRemovePopover] = useState(false);

	const formatDateRange = (registration: Registration) => {
		if (moment(registration.DateFrom).isSame(moment(registration.DateTo), "date")) {
			return `${moment(registration.DateFrom).format('MMM D, YYYY')} - ${moment(registration.DateFrom).format('h:mm A')} to ${moment(registration.DateTo).format('h:mm A')}`;	
		}
		
		return `${moment(registration.DateFrom).format('MMM D, YYYY')} - ${moment(registration.DateTo).format('MMM D, YYYY')}`;
	}

	const formatCost = (registration: Registration) => {
		const cost = registration.Metadata?.cost ?? 0;
		return cost > 0 ? ` - ${currency.format(cost)}` : '';
	}

	const handleModify = () => {
		setCurrentRegistration(registration);

		const program = linkTitle(registration.Metadata?.program ?? "");
		const programGroup = linkTitle(registration.Metadata?.programGroup ?? "");
		const params = new URLSearchParams();
		params.append("dateFrom", moment(registration.DateFrom).toISOString());
		params.append("dateTo", moment(registration.DateTo).toISOString());

		history.push(`/programs/${programGroup}/${program}/registration?` + params.toString());
	}

	const handleRemove = () => {
		setShowRemovePopover(false);
		removeProgramRegistration(registration);
	}

	const removePopover = (
		<Popover id="popover-basic" className="shadow" arrowProps={{ className: 'arrow' }}>
			{/* <Popover.Header as="h3">Confirm</Popover.Header> */}
			<Popover.Body>
				<div className="mb-2">Are you sure you want to remove this reservation from your cart ?</div>
				<div className="row">
					<div className="col-6 pr-1">
						<button onClick={handleRemove} type="button" className="btn btn-danger btn-block font-weight-bold">Remove</button>
					</div>
					<div className="col-6 pl-1">
						<button onClick={() => setShowRemovePopover(false)} type="button" className="btn btn-light btn-block font-weight-bold mb-2">Cancel</button>
					</div>
				</div>
			</Popover.Body>
		</Popover>
	);

	return <>
		<div className="row">
			<div className="col-xs-10 col-md-12 col-lg-10">		
				
				{/* <div className="text-muted font-weight-bold"><small>PROGRAM</small></div> */}
				<div className="font-weight-bold">{registration.Metadata?.programGroup}: {registration.Metadata?.program}{formatCost(registration)}</div>
				<div className="text-muted font-weight-bold mb-3"><small>{formatDateRange(registration)}</small></div>
				{getProgramValidationErrors(validationErrors, registration).map((validationError, index) => {
					return <div key={index} className="text-danger font-weight-bold mb-3">
						<FontAwesomeIcon icon={faExclamationCircle} /> {validationError.Message}
					</div>
				})}
				<div className="mb-3">
					<div className="text-muted font-weight-bold"><small>Participants</small></div>
					{registration.Metadata?.participants.map((participant, index) => {
						return <div key={index} className="">{participant}</div>
					})}
				</div>

				{(registration.Metadata?.activities?.length ?? 0) > 0 &&
				<div className="mb-3">
					<div className="text-muted font-weight-bold"><small>Activities</small></div>
					{registration.Metadata?.activities?.map((activity, index, arr) => {
						return <div key={index}>
							{arr.length > 1 &&
							<span>{activity.name}: </span>
							}
							{activity.activities.map((a, index2, arr2) => {
								return <span key={index2} className="">{a}{index2 < arr2.length - 1 ? ', ':''}</span>
							})}
						</div>
					})}
				</div>
				}				

				{getRegistrationValidationErrors(validationErrors, registration).map((validationError, index) => {
					return <div key={index} className="text-danger font-weight-bold mb-2">
						<FontAwesomeIcon icon={faExclamationCircle} /> {validationError.Message}
					</div>
				})}

				{getRegistrationOtherValidationErrors(validationErrors, registration).length > 0 &&
					<div className="text-danger font-weight-bold mb-2">
						<FontAwesomeIcon icon={faExclamationCircle} /> There are errors on this registration that need to be corrected.
					</div>
				}
		
			</div>

			<div className="col-lg-2 col-sm-12">
				<div className="row">
					<div className="col-6 col-xs-12 col-md-6 col-lg-12">
						<button onClick={handleModify} disabled={isProgramInvalid(validationErrors, registration)} className="btn btn-block btn-link btn-sm-x font-weight-bold pl-0 pr-0">Modify</button>
					</div>
					<div className="col-6 col-xs-12 col-md-6 col-lg-12">
						<OverlayTrigger show={showRemovePopover} trigger="click" placement="top" overlay={removePopover}>
							<button onClick={() => setShowRemovePopover(true)} className="btn btn-block btn-link btn-sm-x font-weight-bold pl-0 pr-0">Remove</button>
						</OverlayTrigger>
					</div>
				</div>
			</div>
		</div>
	</>
}

export default CartItemProgramRegistration