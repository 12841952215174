import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuthState } from '../state/auth.state';
import { api } from './api';
import { UpdateEmailModel, UpdateEmailResponse, UpdatePasswordResponse, UpdatePasswordModel, AccountInfo } from './auth.models';

// export const updateEmail = async (updateEmailModel: UpdateEmailModel) => {
// 	let response = await api.post<UpdateEmailResponse>(`/api/auth/update-email`, updateEmailModel);
// 	return response.status === 200;
// }

export const useUpdateEmailMutation = () => {
	const queryClient = useQueryClient();

	return useMutation((updateEmailModel: UpdateEmailModel) => api.post<UpdateEmailResponse>(`/api/auth/update-email`, updateEmailModel), {
		onSuccess: () => {
			queryClient.invalidateQueries('account-info');
		}
	})
}

// export const updatePassword = async (updatePasswordModel: UpdatePasswordModel) => {
// 	try {
// 		await api.post<UpdatePasswordResponse>(`/api/auth/update-password`, updatePasswordModel);
// 		return { success: true };
// 	} catch (error) {
// 		let message = (error as AxiosError<ApiError>).response?.data.message ?? "";
// 		return { success: false, error: message };
// 	}
// }

export const useUpdatePasswordMutation = () => {
	return useMutation((updatePasswordModel: UpdatePasswordModel) => api.post<UpdatePasswordResponse>(`/api/auth/update-password`, updatePasswordModel), {
		onSuccess: () => {
			// queryClient.invalidateQueries('account-info');
		}
	})
}

export const useAccountInfoQuery = () => {
	const { isAuthenticated } = useAuthState();
	
	return useQuery('account-info', async () => {
		let res = await api.get<AccountInfo>('/api/auth/account-info');
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: isAuthenticated() })
}

// export function useUpdateEmailMutation(userName: string, load: boolean) { 
// 	return useQuery(['account-update-email', userName], async () => {
// 		let res = await api.get<UpdateEmailModel>(`/api/auth/update-email`);
// 		return res.data;
// 	}, { staleTime: Infinity, cacheTime: 60000, enabled: load });
// }
