import { useState } from "react";
import { extend, hookstate, useHookstate } from "@hookstate/core";
import { devtools } from '@hookstate/devtools'
import { localstored, StoreEngine } from '@hookstate/localstored';
import { createEvent } from "react-event-hook";
import _ from "lodash";
import { Reservation, ReservationInventoryItem } from "../../../api/reservation.models";
import { InventoryItem } from "../../../api/inventory.models";
import { ValidationError } from "../../../api/cart.models";
import { validate } from "../../../api/reservation.api";
import { useInventoryState } from "./inventory-state";

export interface ReservationState {
	reservation: Reservation|null,
	validationErrors: ValidationError[],
	inventorySelections: InventoryItem[]
}

export const RESERVATION_STATE = hookstate(
	{ 
		reservation: null,
		validationErrors: [],
		inventorySelections: []
	} as ReservationState, 

	extend(
		localstored({
			key: 'reservation.v2',
			engine: localStorage as StoreEngine,
			initializer: () => new Promise<ReservationState>((resolve, reject) => { 
				resolve({ 
					reservation: null,
					inventorySelections: [],
					validationErrors: []
				})
			})
		}), 
		devtools({ key: 'reservation' })
	)
);

export function localstoredtest() { 
	return () => {	
		return { 
			onInit: (s: any, e: any) => {
				console.log("onInit")
			},
			onSet: (s: any) => {
				console.log("onSet")
			}
		} 
	}
}

const { useReservationGuestsListener, emitReservationGuests } = createEvent("reservation-guests")<Reservation>();
const { useReservationValidationListener, emitReservationValidation } = createEvent("reservation-validation")();

export const useReservationState = () => {
	const { setSelectedInventory } = useInventoryState();
	let state = useHookstate<ReservationState>(RESERVATION_STATE);
	const [isValidating, setIsValidating] = useState(false);

	return { 
		reservation: state.reservation.get({ noproxy: true }) as Reservation,
		validationErrors: state.validationErrors.get({ noproxy: true }) as ValidationError[] ?? [],
		setValidationErrors: (validationErrors: ValidationError[]) => { state.validationErrors.set(validationErrors); },
		validateReservation: async () => {
			emitReservationValidation();
			setIsValidating(true);
			let validationErrors = await validate(state.reservation.get({ noproxy: true }) as Reservation);
			setIsValidating(false);
			state.validationErrors.set(validationErrors);
			return validationErrors;
		},
		clearReservation: () => { 
			state.reservation.set(null);
			state.validationErrors.set([]); 
			state.inventorySelections.set([]); 
		},
		setCurrentReservation: (reservation: Reservation) => { 
			state.reservation.set(JSON.parse(JSON.stringify(reservation))); 
			state.validationErrors.set([]);
		},
		updateReservation: (reservation: Reservation) => { 
			if (isValidating)
				return;
				
			state.reservation.set(JSON.parse(JSON.stringify(reservation))); 
		},

		inventorySelections: state.inventorySelections.get({ noproxy: true }) as InventoryItem[],
		clearInventorySelections: () => { 
			state.inventorySelections.set([]);
			setSelectedInventory([]);
		},
		updateInventorySelections: (inventoryItems: InventoryItem[]) => {
			let reservation = state.reservation.get({ noproxy: true }) as Reservation;
			var itemIds = reservation.Items?.flatMap(item => (item as ReservationInventoryItem).InventoryItemId);
			var items = inventoryItems.filter(item => _.some(itemIds, i => i === item.Id));
			state.inventorySelections.set(items);
			setSelectedInventory(items);
		},

		useReservationValidationListener,
		useReservationGuestsListener,
		emitReservationGuests
	}
}