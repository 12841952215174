import { Link } from "react-router-dom";
import classNames from "classnames";
import AppFooter from "./footer";
import AppHeader from "./header";
import { ThemeMode, useThemeState } from "../pages/events/functions/theme-state";
import './default-layout.css'

export interface DefaultLayoutProps {
	pageTitle: string;
	pageTitleLink?: string;
	subTitle?: string|JSX.Element;
	size?: "sm"|"lg";
	headerLargeImageUrl: string;
	headerSmallImageUrl: string;
	customClasses?: string|undefined;
	children: React.ReactNode;
}
const DefaultLayout = ({ pageTitle, pageTitleLink, subTitle, size, headerLargeImageUrl, headerSmallImageUrl, customClasses = "mb-4", children }: DefaultLayoutProps) => {
	const { currentTheme } = useThemeState();

	const theme = currentTheme === ThemeMode.Light ? "theme-light" : "theme-dark";

	const headerDesktop = <header className={classNames(['events-layout d-none d-sm-block', theme, customClasses, size === "sm" ? "masthead-sm" : "masthead"])} style={{ backgroundImage: `url(${headerLargeImageUrl})` }}>
			<div className="container h-100">
				<div className="row h-100 align-items-center-x align-items-end justify-content-center">
					<div className="col-12 header-text">
						<h1 className="mb-0">
							{pageTitleLink && 
							<>
							<Link to={pageTitleLink}>
							{pageTitle}
							</Link>
							</>
							}
							{!pageTitleLink && <>{pageTitle}</>}
							
						</h1>
						<p className="lead font-weight-bold mb-0">{subTitle}</p>
					</div>
				</div>
			</div>
		</header>

	const headerMobile = <header className={classNames(['events-layout masthead masthead-mobile d-block d-sm-none', theme, customClasses])} style={{ backgroundImage: `url(${headerSmallImageUrl})`, height: '250px', minHeight: '250px' }}>
			<div className="container h-100">
				<div className="row h-100 align-items-center-x align-items-end justify-content-center">
					<div className="col-12 header-text">
						<h1 className="mb-0">{pageTitle}</h1>
						<p className="lead font-weight-bold mb-0">{subTitle}</p>
					</div>
				</div>
			</div>
		</header>

	return <>
		<AppHeader />

		{headerDesktop}
		{headerMobile}

		<main role="main" className="container">
			{children}
		</main>

		<AppFooter />
	</>
}

export default DefaultLayout