import { useQuery } from 'react-query';
import { api } from './api';

export interface MediaGallery {
	Id: number;
	Name: string;
	Columns: number;
	Images: MediaGalleryImage[];
}

export interface MediaGalleryImage {
	ImageURL: string;
}

export const useMediaGalleryQuery = (galleryId: number, load: boolean) => { 
	return useQuery(['media-gallery', galleryId], async () => {
		let res = await api.get<MediaGallery>(`/api/common/media/gallery/${galleryId}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: load });
}