export interface Program {
	Id: number;
	ProgramGroupId: number;
	Title: string;
	ShortDescription: string;
	Description: string;
	Price: number;
	PriceType: PriceType;
	AvailableDate: string|null;
	MinimumAgeMonths: number|null;
	MinimumAgeYears: number|null;
	MaximumAgeMonths: number|null;
	MaximumAgeYears: number|null;
	ImageURL: string;
	Hidden: boolean;
}

export enum PriceType {
	PerPerson = 0,
	PerGroup = 1
}

export interface ProgramGroup {
	Id: number;
	Title: string;
	ShortDescription: string;
	Description: string;
	ImageURL: string;
	Policies: string;
	SavePaymentMethod: boolean;
	ParticipantType: ProgramParticipantType;
	AllowWaitlist: boolean;
	SessionSelectionType: SessionSelectionType;
	ActivitySelectionType: ActivitySelectionType;
	Hidden: boolean;
}

export enum ProgramParticipantType {
	AdultsAndChildren = 0,
	Adults = 1,
	Children = 2,
	ChildrenPreSelected = 3
}

export enum SessionSelectionType {
	Fixed = 0, // Session selections cannot be customized
	Custom = 1 // Allows customizing session selections
}

export enum ActivitySelectionType {
	Single = 0, // The same activity must be selected for each session during the same time period
	Multi = 1 // Different activities can be selected for any session during the same time period
}

export interface ProgramSession {
	Id: number;
    ProgramId: number;
    StartDate: string;
    EndDate: string;
    Enrollment: number;
    Limit: number;
	AccessCodeRequired: boolean;
}

export interface ProgramSessionSelection {
	ProgramSession: ProgramSession;
	Quantity: number;
}

export interface ProgramActivity {
	Id: number;
	ProgramGroupId: number;
	Title: string;
	ShortDescription: string;
	Description: string;
	Price: number;
	PriceType: ProgramActivityPriceType;
	PriceDescription: string;
	Hidden: boolean;
	Disabled: boolean;
}

export enum ProgramActivityPriceType {
	None = 0,
	PerPerson = 1,
	PerSession = 2
}

export interface ProgramActivitySession {
	Id: number;
	ActivityId: number;
	ProgramIds: number[];
	StartDate: string;
	EndDate: string;
	Enrollment: number;
	Limit: number;
}