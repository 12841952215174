import { useHistory, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../pages/programs/components/breadcrumb";
import DefaultLayout from "./default-layout"
import { useEventState } from "../pages/events/functions/event-state";
import { linkTitle } from "../common/helpers";
import { getDefaultHeaderImage, usePageContentQuery } from "../api/configuration.api";

interface EventsLayoutProps {
	children: JSX.Element;
	showEditDates?: boolean;
}

const EventsLayout = ({ children, showEditDates = false }: EventsLayoutProps) => {
	const history = useHistory();
	const location = useLocation();
	const {	event, selectedDateRange, datesSelected, step } = useEventState();
	const { data: pageContent } = usePageContentQuery();

	const defaultHeaderImage = getDefaultHeaderImage(pageContent);

	const formatDateRange = () => {
		if (!event)
			return '';
		if (!datesSelected)
			return '';

		// let nights = moment(eventDate.DateTo).diff(moment(eventDate.DateFrom), 'days');
		return `Dates: ${moment(selectedDateRange![0]).format('MMM D, YYYY')} - ${moment(selectedDateRange![1]).format('MMM D, YYYY')}`;
	}

	const handleEditDates = () => {
		var params = new URLSearchParams(location.search);
		params.append("edit", "0");
		history.push({ pathname: `/events/${linkTitle(event?.Title ?? "")}`, search: params.toString() });
	}

	const subTitle = <>
		{formatDateRange()}
		{showEditDates &&
		<button onClick={handleEditDates} className="btn btn-link p-0 text-body">
			<FontAwesomeIcon icon={faEdit} className="ml-2 text-primary" />
		</button>
		}
	</>

	return <DefaultLayout pageTitle={event?.Title ?? "Explore All Events"} subTitle={subTitle} headerLargeImageUrl={event?.ImageURL ?? defaultHeaderImage.Content} headerSmallImageUrl={event?.ImageURLSmall ?? defaultHeaderImage.Content}>
		<>
		<Breadcrumb step={step} />
		{children}
		</>
	</DefaultLayout>
}

export default EventsLayout