import _ from "lodash";
import { EventInventorySelectionType } from "../../../api/events.models";
import { InventoryCategory } from "../../../api/inventory.models";
import { useEventState } from "../functions/event-state";
import { useInventoryCategoryState, useInventoryState } from "../functions/inventory-state";
import { useReservationInventoryPoliciesQuery } from "../../../api/reservation.api";
import { getReservationInventoryPolicyResult } from "../functions/reservation-functions";

export interface EventInventoryCategoryPickerProps {
	inventoryCategory: InventoryCategory;
	selectionOpen: boolean;
	toggleSelectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EventInventoryCategoryPicker = ({ inventoryCategory, selectionOpen, toggleSelectionOpen }: EventInventoryCategoryPickerProps) => {
	const { event, selectedDateRange } = useEventState();
	const { getSelectedInventory, addInventoryItems, deselectAllInventoryItems } = useInventoryState();
	const { availableCount } = useInventoryCategoryState(inventoryCategory);

	const { data: reservationInventoryPolicies } = useReservationInventoryPoliciesQuery(event.Id ?? 0);
	let reservationInventoryPolicyResult = getReservationInventoryPolicyResult(event?.Id ?? 0, selectedDateRange[0], selectedDateRange[1], reservationInventoryPolicies ?? []);

	const isInventoryPolicyApplicable = reservationInventoryPolicyResult != null && reservationInventoryPolicyResult.DatesValid == false &&
		_.findIndex(reservationInventoryPolicyResult?.Policy?.InventoryCategories, category => category == inventoryCategory.Id) >= 0;

	const allowMultipleInventory = event?.AllowMultipleInventory ?? true;
	
	let count = getSelectedInventory().filter(i => i.InventoryCategoryId === inventoryCategory.Id).length;

	const range = _.range(1, availableCount + 1, 1);

	const selectedInventoryChanged = (value: string) => {
		if (isInventoryPolicyApplicable)
			return;
		
		if (inventoryCategory !== undefined && inventoryCategory.EventInventory![0].SelectionType === EventInventorySelectionType.Quantity) {
			var availableItems = inventoryCategory.InventoryItems.filter(item => item.Available === true);
			var selectedItems = [];

			for (var i = 0; i < parseInt(value); i++) {
				selectedItems.push(availableItems[i]);
			}

			addInventoryItems(inventoryCategory, selectedItems, true, allowMultipleInventory);
		} else if (inventoryCategory !== undefined && inventoryCategory.EventInventory![0].SelectionType === EventInventorySelectionType.Specific) {
			if (value === "show-all") {
				if (toggleSelectionOpen) {
					toggleSelectionOpen(true);
				}
			} else {
				deselectAllInventoryItems(inventoryCategory);
				var selectedItem = inventoryCategory.InventoryItems.filter(item => item.Id === parseInt(value));
				addInventoryItems(inventoryCategory, selectedItem, false, allowMultipleInventory);
			}
		}
	}

	const getSelectedInventoryItemsDescription = (category: InventoryCategory) => {
		return getSelectedInventory().filter(i => i.InventoryCategoryId === inventoryCategory.Id).map(i => i.Metadata != null ? i.Metadata.Title : i.Description).join(', ');
	}

	const QuantitySelection = () => {
		return <select value={count} onChange={e => selectedInventoryChanged(e.target.value)} className="custom-select custom-select-sm d-block w-100 mb-1" disabled={availableCount === 0 || isInventoryPolicyApplicable}>
			{availableCount === 0 && 
			<option value={0}>No {inventoryCategory.Metadata?.UnitDescription}s Available</option>
			}
			{availableCount > 0 && 
			<option value={0}>Select {inventoryCategory.Metadata?.UnitDescription}s...</option>
			}
			{range.map((number, index) => {
				return <option value={number} key={index}>
					{number} {inventoryCategory.Metadata?.UnitDescription}{number === 1 ? "" : "s"}
				</option>
			})}
		</select>
	}

	const SpecificSelection = () => {
		const inventoryItems = inventoryCategory.InventoryItems.filter(i => i.Available === true);

		return <select value={count > 1 ? -2 : count === 1 ? getSelectedInventory()[0].Id : 0} onChange={e => selectedInventoryChanged(e.target.value)} className="custom-select custom-select-sm d-block w-100 mb-1" disabled={availableCount === 0 || isInventoryPolicyApplicable}>
			{availableCount === 0 && 
			<option value={0} key="0">No {inventoryCategory.Metadata?.UnitDescription}s Available</option>
			}
			{availableCount > 0 && <>
			<option value="0" key="0">Select {inventoryCategory.Metadata?.UnitDescription}s...</option>
			{!selectionOpen &&
			<option value="show-all" key="show-all">Show All {inventoryCategory.Metadata?.UnitDescription}s...</option>
			}
			</>}
			{count > 1 &&
			<option value="-2" key="-2">{getSelectedInventoryItemsDescription(inventoryCategory)}</option>
			}
			{inventoryItems.map((inventoryItem, index) => {
				return <option value={`${inventoryItem.Id}`} key={`${inventoryItem.Id}`}>{inventoryItem.Metadata?.Title ?? inventoryItem.Description}</option>
			})}
		</select>
	}

	return <>
		{inventoryCategory!.EventInventory![0].SelectionType === EventInventorySelectionType.Quantity && <QuantitySelection />}
		{inventoryCategory!.EventInventory![0].SelectionType === EventInventorySelectionType.Specific && <SpecificSelection />}
	</>
}

export default EventInventoryCategoryPicker