import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthState } from '../../../state/auth.state';
import { useAuthUIState } from '../../../state/authui.state';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

interface LoggedOutDialogProps {

}

const LoggedOutDialog = ({ }: LoggedOutDialogProps) => {
	const { showLogin } = useAuthState();
	const { isLoggedOutOpen, hideLoggedOut } = useAuthUIState();

	const handleLogin = () => {
		hideLoggedOut();
		showLogin();
	}
	const handleClose = () => {
		hideLoggedOut();
	}
	return <>
		<Modal size="sm" show={isLoggedOutOpen} onHide={handleClose} centered>
			<Modal.Body className="pb-4 text-center">
				<button type="button" onClick={handleClose} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3 className="text-left">Logged Out</h3>
				<p className="font-weight-bold">You have been logged out of your account due to inactivity.</p>
				<button onClick={handleLogin} type="button" className="btn btn-outline-primary mr-3 pl-4 pr-4">Log back in</button>
			</Modal.Body>
      </Modal>
	</>
}

export default LoggedOutDialog