import { useState } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { useAuthState } from '../../../state/auth.state';
import { useAuthUIState } from '../../../state/authui.state';
import { RegisterModel, RegisterSchema } from '../../../api/auth.models';
import { PageContentComponentType, useConfigurationQuery, usePageContentQuery } from '../../../api/configuration.api';
import { toastSuccess } from '../toast';

interface RegisterDialogProps {

}

const RegisterDialog = ({ }: RegisterDialogProps) => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { register, isRegistering } = useAuthState();
	const { isRegisterOpen, hideRegister, showLogin } = useAuthUIState();
	const { data: configuration } = useConfigurationQuery();
	const { data: pageContent } = usePageContentQuery();
	
	const [error, setError] = useState<string|undefined>(undefined);
	const [recaptchaExecuting, setRecaptchaExecuting] = useState(false);

	const tosLink = pageContent?.find(c => c.Page === "Register" && c.Item === "TOS-Link" && c.ComponentType === PageContentComponentType.Text)?.Content ?? "";

	const handleRegister = async (values: RegisterModel) => {
		setError(undefined)
		if (!executeRecaptcha) {
			// Recaptcha has not loaded yet.
			return;
		}

		setRecaptchaExecuting(true);
		const recaptchaToken = await executeRecaptcha('form');
		setRecaptchaExecuting(false);

		let result = await register({ name: values.name, email: values.email, password: values.password, recaptcha: recaptchaToken });
		if (result) {
			toastSuccess('You are now registered');
			setError(undefined)
			hideRegister();
		} else {
			setError("Unable to register at this time.");
		}
	}

	const handleClose = () => {
		setError(undefined);
		hideRegister();
	}

	const handleLogin = () => {
		setError(undefined);
		showLogin();
	}

	return <>
		<Modal show={isRegisterOpen} onHide={handleClose} centered>
			{/* <Modal.Header closeButton>
				<Modal.Title>Modal heading</Modal.Title>
			</Modal.Header> */}
			<Modal.Body className="p-4">

				<Formik
					validationSchema={RegisterSchema}
					// enableReinitialize={true}
					initialValues={{
						name: "",
						email: "",
						password: "",
						recaptcha: ""
					}}
					onReset={() => {
						setError(undefined)
					}}
					onSubmit={(values, { setSubmitting }) => {
						handleRegister(values)
					}}
					validateOnBlur={true}
				>
				{({ handleSubmit, isSubmitting, submitCount, isValid, dirty, values, errors }) => <>
				<Form noValidate onSubmit={handleSubmit}>
				<button type="button" onClick={handleClose} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3>Sign up for a {configuration?.OrganizationShortform ?? ""} Account</h3>
				
				<div className="form-row mt-4">
					<div className="col-12">
						<div className="form-label-group">
							<Field type="text" className="form-control not-required" name="name" placeholder="required" autoComplete="name" required />
							<label htmlFor="email">Full Name</label>
							<ErrorMessage name="name" component="div" className="fw-bold small text-danger" />
							{/* <div className="small text-danger error-message">Email Address is requried</div> */}
						</div>
					</div>
				</div>
				<div className="form-row">
					<div className="col-12">
						<div className="form-label-group">
							<Field type="email" className="form-control not-required" name="email" placeholder="required" autoComplete="email" required />
							<label htmlFor="email">Email Address</label>
							<ErrorMessage name="email" component="div" className="fw-bold small text-danger" />
							{/* <div className="small text-danger error-message">Email Address is requried</div> */}
						</div>
					</div>
				</div>
				<div className="form-row">
					<div className="col-12">
						<div className="form-label-group">
							<Field type="password" className="form-control not-required" name="password" placeholder="required" autoComplete="current-password" required />
							<label htmlFor="password">Password</label>
							<ErrorMessage name="password" component="div" className="fw-bold small text-danger" />
							{/* <div className="small text-danger error-message">Password is requried</div> */}
							<p className="text-muted small mt-2"> 
								Password must be at least 8 characters in length, and include:
								<br /> - At least 1 uppercase character
								<br /> - At least 1 lowercase character
								<br /> - At least 1 number
							</p>
						</div>
					</div>
				</div>

				<Alert variant="danger" show={error !== undefined} dismissible={false}>
					{error}
				</Alert>

				<div className="form-row">
					<div className="col-12">
						<p className="text-muted small">By signing up you affirm you are at least 25 years old and agree to the {configuration?.OrganizationShortform ?? ""} <a target="_blank" rel="noreferrer" href={tosLink}>Terms of Service</a>.</p>
					</div>
				</div>

				<div className="form-row">
					<div className="col-12">
						<Button variant="primary" type="submit" disabled={!isValid || !dirty || recaptchaExecuting || isRegistering} className="btn-block font-weight-bold">{(isRegistering || recaptchaExecuting) && <FontAwesomeIcon icon={faSpinner} spin />} Create your {configuration?.OrganizationShortform ?? ""} Account</Button>
						{/* <button type="submit" className="btn btn-primary btn-block font-weight-bold">{isLoggingIn && <FontAwesomeIcon icon={faSpinner} spin />} Login</button> */}

						{/* <p className="m-0 text-center text-muted">
							<button onClick={handlePasswordReset} className="btn btn-default btn-link btn-sm mt-3">Forgot your password?</button>
						</p> */}

						<hr className="mt-4" />

						<p className="m-0 text-center text-muted">
							Already a {configuration?.OrganizationShortform ?? ""} member?
							<button type="button" onClick={handleLogin} className="btn btn-default btn-outline-secondary btn-sm font-weight-bold ml-3 pl-3 pr-3">Login</button>
						</p>
					</div>
				</div>
				
				</Form>
				</>}

				</Formik>

			</Modal.Body>
			{/* <Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={handleLogin}>
					{isLoggingIn && <FontAwesomeIcon icon={faSpinner} spin />} Login
				</Button>
			</Modal.Footer> */}
      </Modal>
	</>
}

export default RegisterDialog