import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { LightgalleryProvider } from "react-lightgallery";

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import "lightgallery.js/dist/css/lightgallery.css";
import './App.css';

import Home from "./pages/home/home";
import EventRoutes from "./pages/events/event-routes";
import ProgramRoutes from "./pages/programs/program-routes";
import AccountRoutes from "./pages/account/account-routes";
import CartRoutes from "./pages/cart/cart-routes";
import ExploreRoutes from "./pages/explore/explore-routes";
import AuthComponents from "./common/components/auth/AuthComponents";
import { ThemeMode, useThemeState } from "./pages/events/functions/theme-state";
import { Toaster } from "react-hot-toast";
import SetupGoogleAnalytics from "./common/components/GoogleAnalytics";
import SetupSentry from "./common/components/Sentry";
import ReCaptchaProvider from "./common/components/Recaptcha";

// const AuthWrapper = ({ children }) => {
// 	const { isAuthenticationExpired, logout } = useAuthState();
// 	const { showLoggedOut } = useAuthUIState();
// 	const history = useHistory();

// 	let _isAuthenticationExpired = isAuthenticationExpired();

// 	useEffect(() => {
// 		console.log(history)
// 		if (_isAuthenticationExpired && history !== undefined) {
// 			showLoggedOut();
// 			logout();
// 			history.push({ pathname: '/' });
// 			// window.location.replace('/');//push({ pathname: '/' });
// 		}
// 	}, [_isAuthenticationExpired]);

// 	useEffect(() => {
// 		return history.listen((location) => { 
// 			console.log(`You changed the page to: ${location.pathname}${location.search} - ${isAuthenticationExpired() ? 'expired':'not expired'}`) 
// 			if (_isAuthenticationExpired && history !== undefined) {
// 				showLoggedOut();
// 				logout();
// 				history.push({ pathname: '/' });
// 				// window.location.replace('/');//push({ pathname: '/' });
// 			}
// 		});
// 	}, [history])

// 	return <>
// 		{children}
// 	</>
// }

const ScrollToTop = () => {
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	  }, [pathname]);
	
	  return null;
}

function App() {
	const { currentTheme } = useThemeState();

	const [queryClient] = useState(() => new QueryClient({
		defaultOptions: { 
			queries: { 
				// refetchInterval: 30000,
				refetchIntervalInBackground: true,
				// staleTime: 5000
			} 
		} 
	}));

	useEffect(() => {
		document.body.className = currentTheme === ThemeMode.Light ? "theme-light body-light" : "theme-dark body-dark"
	}, [currentTheme]);

	return (
		<div className="App">
			<LightgalleryProvider>
			<QueryClientProvider client={queryClient}>
			<ReCaptchaProvider>
				<AuthComponents />
				<SetupGoogleAnalytics />
				<SetupSentry />
				<Toaster position="top-center" />
					<Router>
						<ScrollToTop />
						<Switch>
							<Route exact path="/" component={Home} />
							<Route path="/events" component={EventRoutes} />
							<Route path="/programs" component={ProgramRoutes} />
							<Route path="/account" component={AccountRoutes} />
							<Route path="/cart" component={CartRoutes} />
							<Route path="/explore" component={ExploreRoutes} />
							{/* <Route path="/events/:eventTitle" component={EventDetails} /> */}
						</Switch>
					</Router>
			</ReCaptchaProvider>
			</QueryClientProvider>
			</LightgalleryProvider>
		</div>
	);
}

export default App;
