import { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

function DateInput({ date, onDateSelected, minDate, maxDate, disabled = false }) {
	const [selectedDate, setSelectedDate] = useState(date ?? moment().toDate());
	const [_minDate, setMinDate] = useState(minDate);
	const [_maxDate, setMaxDate] = useState(maxDate);
	const pickerRef = useRef(undefined);
	
	useEffect(() => {
		setSelectedDate(date ?? moment().toDate());
		pickerRef.current.setStartDate(moment(date).toDate());
		pickerRef.current.setEndDate(moment(date).toDate());
	}, [date]);
	
	useEffect(() => {
		setMinDate(minDate);
		setMaxDate(maxDate);
	}, [minDate, maxDate]);

	function handleCallback(start) {
		// console.log('dates selected: ', start, end, label);
		setSelectedDate(start.toDate());
		// onDateRangeSelected([start.toDate(),end.toDate()]);
		if (onDateSelected !== undefined)
			onDateSelected(moment(start).toISOString());
		// setCanSearch(true);
	}

	function handleApply(e, picker) {
		setSelectedDate(picker.startDate.toDate());
		// onDateRangeSelected([picker.startDate.toDate(),picker.endDate.toDate()]);
		if (onDateSelected !== undefined)
			onDateSelected(moment(picker.startDate).toISOString());
	}

	function showDatePicker(e, picker) {
		
	}

	function getDisplayDate() {
		return moment(selectedDate).format('YYYY-MM-DD'); // MMM D, YYYY
	}

	return <>
		<DateRangePicker ref={pickerRef} onCallback={handleCallback} onApply={handleApply} onShow={showDatePicker} initialSettings={{singleDatePicker: true, autoApply: true, opens: 'center', class: 'shadow', showDropdowns: true, autoUpdateInput: false, startDate: moment(selectedDate).toDate(), minDate: moment(_minDate), maxDate: moment(_maxDate)}}>
			<input type="text" className="form-control" disabled={disabled} readOnly={true} value={getDisplayDate()} placeholder="Date" style={disabled ? {} : {backgroundColor: 'white'}} />
		</DateRangePicker>
	</>
}

export default DateInput;