import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup } from "react-bootstrap";
import { useProgramState } from "../functions/program-state";
import moment from "moment-timezone";

const ProgramParticipantSelection = () => {
	const { program, getAdultParticipants, setAdultParticipants, getChildParticipants, setChildParticipants } = useProgramState();
	
	const adults = getAdultParticipants();
	const children = getChildParticipants();

	const incrementAdults = () => setAdultParticipants(Math.min(10, adults + 1));
	const decrementAdults = () => setAdultParticipants(Math.max(0, adults - 1));
	const buttonVariantAdults = adults > 0 ? "primary" : "secondary";

	const incrementChildren = () => setChildParticipants(Math.min(10, children + 1));
	const decrementChildren = () => setChildParticipants(Math.max(0, children - 1));
	const buttonVariantChildren = children > 0 ? "primary" : "secondary";

	const showMinimumAge = program?.MinimumAgeMonths !== null || program?.MinimumAgeYears !== null;
	const minimumAge = moment.duration(program?.MinimumAgeMonths ?? 0, "month").add(program?.MinimumAgeYears ?? 0, "year").humanize();

	return <>
		<h5 className="font-weight-bold">Select how many participants you want to register</h5>
		{showMinimumAge &&
		<div className="mb-4">
			Participants must be a minimum of {minimumAge} old.
		</div>
		}
		<div className="mb-4">
			<ButtonGroup className="mr-4 mb-2 mb-sm-0">
				<Button onClick={decrementAdults} type="button" variant={buttonVariantAdults}><FontAwesomeIcon icon={faMinusCircle} /></Button>
				<Button disabled style={{ fontSize: 20, width: '120px' }} variant={buttonVariantAdults}>{adults} Adult{adults !== 1 ? "s":""}</Button>
				<Button onClick={incrementAdults} type="button" variant={buttonVariantAdults}><FontAwesomeIcon icon={faPlusCircle} /></Button>
			</ButtonGroup>

			<ButtonGroup>
				<Button onClick={decrementChildren} type="button" variant={buttonVariantChildren}><FontAwesomeIcon icon={faMinusCircle} /></Button>
				<Button disabled style={{ fontSize: 20, width: '120px' }} variant={buttonVariantChildren}>{children} Child{children !== 1 ? "ren":""}</Button>
				<Button onClick={incrementChildren} type="button" variant={buttonVariantChildren}><FontAwesomeIcon icon={faPlusCircle} /></Button>
			</ButtonGroup>
		</div>
		<hr />
	</>
}

export default ProgramParticipantSelection