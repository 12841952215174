import { Link } from "react-router-dom";
import { useProgramGroupsQuery, useProgramsQuery } from "../../api/programs.api";
import { ProgramGroup } from "../../api/programs.models";
import { linkTitle } from "../helpers";

const HeaderProgramMenu = () => {

	const { data: programGroups } = useProgramGroupsQuery();
	const { data: programs, isLoading } = useProgramsQuery();

	const programLinks = (programGroup: ProgramGroup) => {
		if (isLoading)
			return <></>

		let filteredPrograms = programs?.filter(p => p.ProgramGroupId === programGroup.Id) ?? [];

		return filteredPrograms.map((program, index) => {
			return <li key={index} className="nav-item"><Link to={`/programs/${linkTitle(programGroup.Title)}/${linkTitle(program.Title)}`} className="nav-link text-small d-inline-block pb-0">{program.Title}</Link></li>
		});
	}

	return <>
		<li className="nav-item dropdown megamenu d-none d-sm-block ml-2">
			<a id="megamenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Programs &amp; Activities</a>

			<div aria-labelledby="megamenu2" className="dropdown-menu border-0 p-0 m-0">
				<div className="container">
					<div className="row rounded-bottom m-0 shadow megamenu-bg w-100 {{megaMenuThemeClass}}">
						<div className="col-lg-12 col-xl-8">
							<div className="p-4">
								<div className="row">
									{programGroups?.map((programGroup, index) => {
										return <div key={index} className="col-sm-4">
											<h6 className="font-weight-bold text-uppercase"><Link to={`/programs/${linkTitle(programGroup.Title)}`} className="nav-link text-small d-inline-block pb-0">{programGroup.Title}</Link></h6>
											<ul className="list-unstyled">
												{programLinks(programGroup)}
											</ul>
										</div>
									})}
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</li>

		<li className="nav-item dropdown megamenu d-block d-sm-none">
			<div className="small text-uppercase font-weight-bold">Programs &amp; Activities</div>
			{programGroups?.map((programGroup, index) => {
				return <div key={index}>
					<Link to={`/programs/${linkTitle(programGroup.Title)}`} className="nav-link text-small d-inline-block pb-0 mb-1">{programGroup.Title}</Link>
				</div>
			})}
			<div className="dropdown-divider"></div>
		</li>
	</>
}

export default HeaderProgramMenu