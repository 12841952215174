import { useState } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { useAuthState } from '../../../state/auth.state';
import { useAuthUIState } from '../../../state/authui.state';
import { PasswordResetModel, PasswordResetSchema } from '../../../api/auth.models';
import { toastSuccess } from '../toast';

interface ResetPasswordDialogProps {

}

const ResetPasswordDialog = ({ }: ResetPasswordDialogProps) => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { resetPassword, isResettingPassword } = useAuthState();
	const { isResetPasswordOpen, hideResetPassword, showLogin} = useAuthUIState();

	const [error, setError] = useState<string|undefined>(undefined);
	const [recaptchaExecuting, setRecaptchaExecuting] = useState(false);

	const handleResetPassword = async (values: PasswordResetModel) => {
		setError(undefined)
		if (!executeRecaptcha) {
			// Recaptcha has not loaded yet.
			return;
		}

		setRecaptchaExecuting(true);
		const recaptchaToken = await executeRecaptcha('form');
		setRecaptchaExecuting(false);

		let result = await resetPassword({ email: values.email, recaptcha: recaptchaToken });
		if (result.success) {
			toastSuccess('A password reset link has been sent to your email');
			setError(undefined)
			hideResetPassword();
		} else {
			setError(result.error ?? "Unable to reset password at this time.");
		}
	}

	const handleClose = () => {
		setError(undefined);
		hideResetPassword();
	}

	const handleLogin = () => {
		setError(undefined);
		showLogin();
	}

	return <>
		<Modal show={isResetPasswordOpen} onHide={handleClose} centered>
			{/* <Modal.Header closeButton>
				<Modal.Title>Modal heading</Modal.Title>
			</Modal.Header> */}
			<Modal.Body className="p-4">

				<Formik
					validationSchema={PasswordResetSchema}
					// enableReinitialize={true}
					initialValues={{
						email: "",
						recaptcha: ""
					}}
					onSubmit={(values, { setSubmitting }) => {
						handleResetPassword(values)
					}}
					validateOnBlur={true}
				>
				{({ isSubmitting, submitCount, isValid, dirty, values, errors }) => <>
				<Form>
				<button type="button" onClick={handleClose} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3>Reset your password</h3>
				
				<div className="form-row mt-4">
					<div className="col-12">
						<div className="form-label-group">
							<Field type="email" className="form-control not-required" name="email" placeholder="required" autoComplete="email" required />
							<label htmlFor="email">Email Address</label>
							<ErrorMessage name="email" component="div" className="fw-bold small text-danger" />
						</div>
					</div>
				</div>

				<Alert variant="danger" show={error !== undefined} dismissible={false}>
					{error}
				</Alert>

				<div className="form-row">
					<div className="col-12">
						<Button variant="primary" type="submit" disabled={!isValid || !dirty || recaptchaExecuting || isResettingPassword} className="btn-block font-weight-bold">{(isResettingPassword || recaptchaExecuting) && <FontAwesomeIcon icon={faSpinner} spin />} Reset Password</Button>

						<hr className="mt-4" />

						<p className="m-0 text-center text-muted">
							Remember your password?
							<Button onClick={handleLogin} variant="outline-secondary" size="sm" className="ml-3 pl-3 pr-3 font-weight-bold">Login</Button>
							{/* <button onClick={handleLogin} className="btn btn-default btn-outline-secondary btn-sm font-weight-bold ml-3 pl-3 pr-3">Login</button> */}
						</p>
					</div>
				</div>
				
				</Form>
				</>}

				</Formik>

			</Modal.Body>
			{/* <Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={handleLogin}>
					{isLoggingIn && <FontAwesomeIcon icon={faSpinner} spin />} Login
				</Button>
			</Modal.Footer> */}
      </Modal>
	</>
}

export default ResetPasswordDialog