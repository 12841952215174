import classNames from "classnames";
import { InventoryCategory } from "../../../api/inventory.models";
import { currency } from "../../../common/helpers";
import { useInventoryState } from "../functions/inventory-state";
import { 
	getAvailableInventoryItemsForCategory,
	getPerNightCostForCategory,
	getTotalPerNightForCategory,
	getPriceDescriptionForCategory
} from "../functions/inventory-data";
import { getSelectedInventoryItemsForCategory } from "../functions/inventory-selection";

export interface EventInventoryCategoryPriceProps {
	inventoryCategory: InventoryCategory;
}

const EventInventoryCategoryPrice = ({ inventoryCategory }: EventInventoryCategoryPriceProps) => {
	const { getSelectedInventory } = useInventoryState();

	let available = getAvailableInventoryItemsForCategory(inventoryCategory).length > 0;
	let selectedInventory = getSelectedInventoryItemsForCategory(getSelectedInventory(), inventoryCategory);

	return <>
		<div className={classNames([`text-sm-center mb-0`, !available ? 'text-muted':''])}>
			{selectedInventory.length === 0 &&
				<span className="font-weight-bold no-wrap" style={{ fontSize: 'x-large' }}>
					{currency.format(getPerNightCostForCategory(inventoryCategory))}
				</span>
			}
			{selectedInventory.length > 0 &&
				<span className="font-weight-bold no-wrap" style={{ fontSize: 'x-large'}}>
					{currency.format(getTotalPerNightForCategory(selectedInventory, inventoryCategory))}
				</span>
			}
			
			<span className="small no-wrap"> {getPriceDescriptionForCategory(inventoryCategory)}</span>

		</div>
	</>
}

export default EventInventoryCategoryPrice