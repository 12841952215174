import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useConfigurationQuery, usePageContentQuery, PageContentComponentType, getPageContentItem } from "../../api/configuration.api";
import { useEventChargesQuery, useEventInventoryQuery, useEventMealDiscountsQuery, useEventMealPackagesQuery, useEventMealRequirementOverridesQuery, useEventMealsQuery } from "../../api/events.api";
import { useEventState } from "./functions/event-state";
import { useInventoryState } from "./functions/inventory-state";
import { useReservationState } from "./functions/reservation-state";
import EventsLayout from "../../layouts/events-layout";
import EventInventoryCategory from "./components/event-inventory-category";
import EventInventoryOffsite from "./components/event-inventory-offsite";
import EventInventoryVirtual from "./components/event-inventory-virtual";
import { useReservationCustomFieldsQuery, useReservationPageContentQuery, useReservationPoliciesQuery } from "../../api/reservation.api";
import { useEventHasPromotionCodesQuery } from "../../api/promotions.api";
import { getReservationPolicyResult } from "./functions/reservation-functions";
import { linkTitle } from "../../common/helpers";

const EventInventory = () => {
	const history = useHistory();
	const { data: configuration } = useConfigurationQuery();
	const { event, isLoadingEvent, isLoadingEventDates, datesSelected, selectedEventDate, selectedDateRange, setCurrentStep } = useEventState();
	const { data: inventoryTypes, isLoading: isLoadingInventory } = useEventInventoryQuery(selectedEventDate, selectedDateRange);
	const { data: pageContent } = usePageContentQuery();
	const { data: reservationPolicies } = useReservationPoliciesQuery(event?.Id ?? 0);
	const { getSelectedInventory, setSelectedInventory } = useInventoryState();
	const { inventorySelections } = useReservationState();

	// Pre-load event meals & charges
	useEventChargesQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0, inventoryTypes !== undefined);
	useEventMealsQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0, inventoryTypes !== undefined);
	useEventMealPackagesQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0, inventoryTypes !== undefined);
	useEventMealDiscountsQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0, inventoryTypes !== undefined);
	useEventMealRequirementOverridesQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0, inventoryTypes !== undefined);
	
	// Pre-load reservation content
	useReservationPageContentQuery(event?.Id ?? 0, inventoryTypes !== undefined);
	useReservationCustomFieldsQuery(event?.Id ?? 0, inventoryTypes !== undefined);
	useEventHasPromotionCodesQuery(event?.Id ?? 0, inventoryTypes !== undefined);


	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | Availability`;

	let reservationPolicyResult = getReservationPolicyResult(event?.Id ?? 0, selectedDateRange[0], selectedDateRange[1], reservationPolicies ?? []);

	if (reservationPolicyResult != null && reservationPolicyResult.DatesValid === false) {
		history.push({ pathname: `/events/${linkTitle(event.Title)}`})
	}

	useEffect(() => setCurrentStep(2), []);

	let content = getPageContentItem(pageContent, "Event-Inventory", event?.Title, PageContentComponentType.HTML);

	const createMarkup = () => { return { __html: content?.Content ?? "" }; };

	let selectedInventory = getSelectedInventory();

	const showOnSite = selectedEventDate?.AllowOnsite ?? false;
	const showOffSite = selectedEventDate?.AllowOffsite ?? false;
	const showVirtual = selectedEventDate?.AllowVirtual ?? false;
	
	useEffect(() => {
		if (selectedInventory.length === 0 && inventorySelections.length > 0) {
			setSelectedInventory(inventorySelections);
		}
	}, [])

	if (isLoadingEvent || isLoadingEventDates || isLoadingInventory)
		return <>
			<EventsLayout>
				<h4 className="mb-4">
					<FontAwesomeIcon icon={faCircleNotch} spin /> 
					<span> Loading Availability...</span>
				</h4>
			</EventsLayout>
		</>

	return <>
		<EventsLayout showEditDates={true}>
			{!datesSelected &&
				<>No dates selected</>
			}
			{showOnSite && (showOffSite || showVirtual) && 
				<div className="row mb-3">
					<div className="col-12">
						{showOffSite &&
						<span><a className="font-weight-bold" href="#offsite">View off site stay options</a></span>
						}
						{showOffSite && showVirtual && <span> | </span>}
						{showVirtual &&
						<span><a className="font-weight-bold" href="#virtual">View virtual options {showVirtual}</a></span>
						}
					</div>
				</div>
			}

			<div className="row">
				<div className="col-12">
					{inventoryTypes && inventoryTypes.map((inventoryType, index) => {
						return <div key={index}>
							<div className="mb-4 row">
								<div className={classNames(["col-sm-12", index > 0 ? 'mt-4':''])}>
									<h4>Available {inventoryType.Description} Choices</h4>
									<p dangerouslySetInnerHTML={createMarkup()}></p>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12">
									{inventoryType.InventoryCategories.map((inventoryCategory, index2) => {
										return <EventInventoryCategory inventoryCategory={inventoryCategory} key={index2} />
									})}
								</div>
							</div>
						</div>
					})}

					{inventoryTypes && inventoryTypes.length === 0 && <>
						<div className="text-center">
							<h1 style={{ fontSize: '7rem' }}><FontAwesomeIcon icon={faTimesCircle} className="text-black-50" /></h1>
							<h3>Sorry, there is no availability for the selected dates.</h3>
						</div>
					</>}
				</div>
			</div>

			{showOffSite && 
			<EventInventoryOffsite showDivider={showOnSite} inventoryTypeDescription={inventoryTypes && inventoryTypes.length > 0 ? inventoryTypes[0].Description : null} />
			}
			
			{showVirtual && 
			<EventInventoryVirtual showDivider={showOnSite} />
			}

		</EventsLayout>
	</>
}

export default EventInventory;