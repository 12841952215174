import { useHistory } from "react-router-dom";
import { useAuthState } from "../../../state/auth.state";

interface ProtectedProps {
	children: JSX.Element;
}
const Protected = ({ children }: ProtectedProps): JSX.Element => {
	const history = useHistory();
	const { getIsAuthenticated, logout } = useAuthState();
	if (getIsAuthenticated())
		return children;
	
	logout();
	history.push({ pathname: '/' });
	return <></>
}

export default Protected;