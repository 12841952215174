import { Switch, Route, useRouteMatch } from "react-router-dom";
import Events from "./events";
import EventDetails from "./event-details";
import EventInventory from "./event-inventory";
import EventReservation from "./event-reservation";

function EventRoutes() {
	let { path } = useRouteMatch();

	return <>
		<Switch>
			<Route path={path} exact component={Events} />
			<Route path={`${path}/:eventTitle`} exact component={EventDetails} />
			<Route path={`${path}/:eventTitle/inventory`} exact component={EventInventory} />
			<Route path={`${path}/:eventTitle/reservation`} exact component={EventReservation} />
		</Switch>
	</>
}

export default EventRoutes