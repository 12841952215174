import React, { useEffect, useMemo } from 'react';
import { Field, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { throttle } from 'lodash';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRegistrationState } from '../functions/registration-state';
import { ProgramRegistrationPageContentType, Registration } from '../../../api/program.registration.models';
import RegistrationPageContent from './program-registration-pagecontent';

const ProgramRegistrationRequestsSchema = Yup.object().shape({
	Requests: Yup.string()
		.max(200, 'Too Long!')
	// Referral: Yup.string()
	// 	.max(100, 'Too Long!'),
	// SelectedReferral: Yup.string()
});

const AutoSaveState = () => {
	const { registration, updateRegistration } = useRegistrationState();
	const { values, isValidating } = useFormikContext<{ Requests: string }>();

	const update = useMemo(() => throttle((registration: Registration, values: { Requests: string }) => {	
		registration.Requests = values.Requests;
		// if (values.SelectedReferral !== "none" && values.SelectedReferral !== "other")
		// 	reservation.Referral = values.SelectedReferral;
		// else if (values.SelectedReferral === "none")
		// 	reservation.Referral = "";
		// else
		// 	reservation.Referral = values.Referral;

		updateRegistration(registration);
	}, 1000), []);
	
	useEffect(() => {
		update(registration, values);
	}, [isValidating])
	
	return <></>
}

const ProgramRegistrationRequests = () => {
	const { registration } = useRegistrationState();
	// const { data: referrals } = useReferralsQuery();
	// const { data: configuration } = useConfigurationQuery();

	// const getSelectedReferral = () => {
	// 	if (reservation.Referral === "")
	// 		return "none";
	// 	if (!_.find(referrals, r => r.Title === reservation.Referral))
	// 		return "other";
	// 	return reservation.Referral;
	// }
	
	return <>
		<div className="card shadow mb-3">
			<h5 className="card-header">
				<FontAwesomeIcon icon={faInfoCircle} className="text-muted mr-2" />
				Additional Information
			</h5>
			
			<div className="card-body">

				<RegistrationPageContent type={ProgramRegistrationPageContentType.Additional} />

				<Formik
					validationSchema={ProgramRegistrationRequestsSchema}
					enableReinitialize={true}
					initialValues={{
						Requests: registration.Requests
					}}
					onSubmit={() => { }}
					validateOnBlur={true}
				>
				{({ values, setFieldValue }) => <>
					<AutoSaveState />
					<div className="row">
						<div className="col-12">
							<div className="form-label-group mb-0">
								<Field component="textarea" rows="3" maxLength="200" name={`Requests`} className={`form-control`} placeholder={false ? "Special Requests" : " "} autoComplete="off" required={false} />
								<label htmlFor="">Special Requests</label>
								<span className="text-muted small">({200-(values.Requests != null ? values.Requests.length : 0)}/200 characters remaining)</span>
							</div>
						</div>
					</div>
					{/* <div className="row mt-3">
						<div className="col-12 col-sm-6">
							<div className="form-label-group mb-0">
								<Field component="select" name="SelectedReferral" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setFieldValue("SelectedReferral", e.target.value); if (e.target.value === "other") setFieldValue("Referral", ""); }} className="custom-select value-selected">
									<option value="none">Please select...</option>
									{referrals?.map((referral, index) => {
										return <option key={index} value={referral.Title}>{referral.Title}</option>
									})}
									<option value="other">Other...</option>
								</Field>
								<label>How did you hear about {configuration?.OrganizationShortform}?</label>
							</div>
						</div>
						{values.SelectedReferral === "other" && 
						<div className="col-12 col-sm-6">
							<div className="form-label-group mb-0">
								<Field type="text" name={`Referral`} className={`form-control not-required`} maxLength={100} placeholder="Please Specify" autoComplete="off" />
								<label className="mt-2 mt-sm-0">Please specify</label>
							</div>
						</div>
						}
					</div> */}

					</>}

				</Formik>

			</div>
		
		</div>
	</>
}

export default ProgramRegistrationRequests;