import { hookstate, useHookstate } from "@hookstate/core";
import { localstored, StoreEngine } from "@hookstate/localstored";

export enum ThemeMode {
	Light,
	Dark
}

export interface ThemeState {
	theme: ThemeMode;
}

const THEME_STATE = hookstate<ThemeState>(
	{
		theme: ThemeMode.Light
	}, 
	localstored({
		key: 'theme.v2',
		engine: localStorage as StoreEngine,
		initializer: () => new Promise<ThemeState>((resolve, reject) => { 
			resolve({ 
				theme: ThemeMode.Light
			})
		})
	})
);

export const useThemeState = () => {
	let state = useHookstate<ThemeState>(THEME_STATE);

	return { 
		currentTheme: state.theme.get({ noproxy: true }),
		setTheme: (theme: ThemeMode) => { state.theme.set(theme) },
		isLightTheme: () => { return state.theme.value === ThemeMode.Light; },
		isDarkTheme: () => { return state.theme.value === ThemeMode.Light; }
	};
}