import { usePageContentQuery, PageContentComponentType, getPageContentItem } from "../api/configuration.api";
import EventSearch from "../pages/home/components/event-search";
import AppFooter from "./footer";
import AppHeader from "./header"
import './home-layout.css'

export interface HomeLayoutProps {
	children: React.ReactNode;
}

const HomeLayout = ({ children }: HomeLayoutProps) => {
	const { data: pageContent } = usePageContentQuery();

	const welcomeImage = getPageContentItem(pageContent, "Index", null, PageContentComponentType.WelcomeImage);
	const backgroundImage = welcomeImage?.Content;

	return <>
		<AppHeader />

		<header className="home-layout masthead shadow" style={{ backgroundImage: `url(${backgroundImage})` }}>
			<div className="container h-100">
				<div className="row h-100 align-items-center justify-content-center">
					<div className="col-sm-7 col-xs-12">
						<EventSearch />
					</div>
				</div>
			</div>
		</header>

		<main role="main" className="container">
			{children}
		</main>

		<AppFooter />
	</>
}

export default HomeLayout