import { ProgramRegistrationCustomField, ProgramRegistrationCustomFieldType } from "../../../api/program.registration.models";

export const getCustomFieldSize = (field: ProgramRegistrationCustomField) => {
	switch (field.Type) {
		case ProgramRegistrationCustomFieldType.TextSmall:
		case ProgramRegistrationCustomFieldType.CommentsSmall:
		case ProgramRegistrationCustomFieldType.DropdownListSmall:
		case ProgramRegistrationCustomFieldType.RadioButtonListSmall:
		case ProgramRegistrationCustomFieldType.CheckBoxListSmall:
		case ProgramRegistrationCustomFieldType.CheckBoxSmall:
			return "col-4";
		case ProgramRegistrationCustomFieldType.TextMedium:
		case ProgramRegistrationCustomFieldType.CommentsMedium:
		case ProgramRegistrationCustomFieldType.DropdownListMedium:
		case ProgramRegistrationCustomFieldType.RadioButtonListMedium:
		case ProgramRegistrationCustomFieldType.CheckBoxListMedium:
		case ProgramRegistrationCustomFieldType.CheckBoxMedium:
			return "col-6";
		case ProgramRegistrationCustomFieldType.TextLarge:
		case ProgramRegistrationCustomFieldType.CommentsLarge:
		case ProgramRegistrationCustomFieldType.DropdownListLarge:
		case ProgramRegistrationCustomFieldType.RadioButtonListLarge:
		case ProgramRegistrationCustomFieldType.CheckBoxListLarge:
		case ProgramRegistrationCustomFieldType.CheckBoxLarge:
			return "col-12";
		default:
			return "";
	}
}

export const isInputField = (field: ProgramRegistrationCustomField) => {
	switch (field.Type) {
		case ProgramRegistrationCustomFieldType.TextSmall:
		case ProgramRegistrationCustomFieldType.TextMedium:
		case ProgramRegistrationCustomFieldType.TextLarge:
			return true;
		default:
			return false;
	}
}

export const isTextAreaField = (field: ProgramRegistrationCustomField) => {
	switch (field.Type) {
		case ProgramRegistrationCustomFieldType.CommentsSmall:
		case ProgramRegistrationCustomFieldType.CommentsMedium:
		case ProgramRegistrationCustomFieldType.CommentsLarge:
			return true;
		default:
			return false;
	}
}

export const isDropdownList = (field: ProgramRegistrationCustomField) => {
	switch (field.Type) {
		case ProgramRegistrationCustomFieldType.DropdownListSmall:
		case ProgramRegistrationCustomFieldType.DropdownListMedium:
		case ProgramRegistrationCustomFieldType.DropdownListLarge:
			return true;
		default:
			return false;
	}
}

export const isCheckBox = (field: ProgramRegistrationCustomField) => {
	switch (field.Type) {
		case ProgramRegistrationCustomFieldType.CheckBoxSmall:
		case ProgramRegistrationCustomFieldType.CheckBoxMedium:
		case ProgramRegistrationCustomFieldType.CheckBoxLarge:
			return true;
		default:
			return false;
	}
}

export const isMultifield = (field: ProgramRegistrationCustomField) => {
	switch (field.Type) {
		// case ProgramRegistrationCustomFieldType.RadioButtonListSmall:
		// case ProgramRegistrationCustomFieldType.RadioButtonListMedium:
		// case ProgramRegistrationCustomFieldType.RadioButtonListLarge:
		case ProgramRegistrationCustomFieldType.CheckBoxListSmall:
		case ProgramRegistrationCustomFieldType.CheckBoxListMedium:
		case ProgramRegistrationCustomFieldType.CheckBoxListLarge:
			return true;
		default:
			return false;
	}
}

export const isRadioButtonList = (field: ProgramRegistrationCustomField) => {
	switch (field.Type) {
		case ProgramRegistrationCustomFieldType.RadioButtonListSmall:
		case ProgramRegistrationCustomFieldType.RadioButtonListMedium:
		case ProgramRegistrationCustomFieldType.RadioButtonListLarge:
			return true;
		default:
			return false;
	}
}

export const isCheckBoxList = (field: ProgramRegistrationCustomField) => {
	switch (field.Type) {
		case ProgramRegistrationCustomFieldType.CheckBoxListSmall:
		case ProgramRegistrationCustomFieldType.CheckBoxListMedium:
		case ProgramRegistrationCustomFieldType.CheckBoxListLarge:
			return true;
		default:
			return false;
	}
}
