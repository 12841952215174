import _ from "lodash";
import classNames from "classnames";
import { faExclamationCircle, faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEventChargesQuery } from "../../../api/events.api";
import { useEventState } from "../functions/event-state";
import { useReservationState } from "../functions/reservation-state";
import { Reservation, ReservationPageContentType } from "../../../api/reservation.models";
import { getChargeTotal, getEventChargeItemQuantity, getEventChargeMaximumQuantity, getEventChargeMinimumQuantity, getFilteredChargesForReservationType, updateCharges, updateEventChargeItemQuantity } from "../functions/reservation-functions";
import { currency } from "../../../common/helpers";
import EventReservationPageContent from "./event-reservation-pagecontent";

const EventReservationCharges = () => {
	const { event, selectedEventDate } = useEventState();
	const { reservation, updateReservation, useReservationGuestsListener, validationErrors } = useReservationState();
	const { data: eventCharges } = useEventChargesQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);

	let errors = validationErrors.filter(v => v.Type === "charge");
	var charges = getFilteredChargesForReservationType(eventCharges, reservation.Type);

	const range = (start: number, end: number) : number[] => {
		return _.range(start, end + 1, 1);
	}

	const handleUpdateQuantity = (eventChargeIndex: number, eventChargeItemIndex: number, quantity: number) => {
		updateEventChargeItemQuantity(reservation, eventChargeIndex, eventChargeItemIndex, quantity);
		updateReservation(reservation);
	}

	useReservationGuestsListener((res: Reservation) => {
		if (charges.length > 0) {
			updateCharges(res, charges);
			updateReservation(res);
		}
	})

	if (reservation.Charges?.length === 0)
		return <></>

	return <>
		<div className="card shadow mb-3">
			<h5 className="card-header"><FontAwesomeIcon icon={faFileInvoiceDollar} className="text-muted mr-2" />Charges</h5>

			<div className="card-body">
				<EventReservationPageContent type={ReservationPageContentType.Charges} />

				{charges.map((eventCharge, eventChargeIndex) => {
					var last = eventChargeIndex === charges.length-1;
					return <div key={eventChargeIndex}>
						<div className={classNames([!last && "mb-4"])}>
							<div key={eventChargeIndex} className="row mb-2">
								<div className="col-9 col-sm-10">
									<strong>{eventCharge.Title}</strong>
									<br />
									{eventCharge.Description}
								</div>
								<div className="col-3 col-sm-2 pl-0 text-right">
									<strong>{currency.format(getChargeTotal(eventCharge, reservation))}</strong>
								</div>
							</div>
							{eventCharge?.Items.map((eventChargeItem, eventChargeItemIndex) => {
								return <div key={eventChargeItemIndex} className="row mb-1">
									<div className="col-2 col-sm-1">
										<select className="custom-select custom-select-sm w-auto mr-2" value={getEventChargeItemQuantity(reservation, eventChargeIndex, eventChargeItemIndex)} onChange={(e) => handleUpdateQuantity(eventChargeIndex, eventChargeItemIndex, parseInt(e.target.value))}>
											{range(getEventChargeMinimumQuantity(eventChargeItem, reservation),getEventChargeMaximumQuantity(eventChargeItem, reservation)).map((value, index3) => {
												return <option value={value} key={index3}>{value}</option>
											})}
										</select>
									</div>
									<div className="col-7 col-sm-9 pt-1 pl-sm-4">
										<strong>{eventChargeItem.Title}</strong> 
										<span className="text-nowrap"> - {currency.format(eventChargeItem.Price)} {eventChargeItem.PriceDescription}</span>
									</div>
								</div>
							})}
						</div>

						{!last && <hr />}
					</div>
				})}

				{charges.length === 0 &&
					<div>
						No additional charges for this event
					</div>
				}

				{errors.map((error, errorIndex) => {
					return <div key={errorIndex} className="text-danger font-weight-bold mt-3">
						<FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
						{error.Message}
					</div>
				})}
			</div>
		</div>
	</>
}

export default EventReservationCharges;