import * as Yup from 'yup';
import { Address, Person } from "../../../api/user.models";

export const createEmtpyAdult = () : Person => {
	let adult: Person = {
		
		Id: 0,
		FirstName: "",
		LastName: "",
		Gender: 0,

		Birthdate: null,

		Salutation: "",
		PersonalEmail: "",
		HomePhone: "",
		MobilePhone: ""
	};

	return adult;
}

export const createEmptyChild = () : Person => {
	let child: Person = {
		
		Id: 0,
		FirstName: "",
		LastName: "",
		Gender: 0,

		Birthdate: new Date(),

		Salutation: "",
		PersonalEmail: "",
		HomePhone: "",
		MobilePhone: ""
	};

	return child;
}

export const createEmtpyAddress = () : Address => {
	let address: Address = {
		Id: 0,
		Street1: "",
		Street2: "",
		// Street3?: string;
		City: "",
		Province: "Ontario",
		PostalCode: "",
		Country: "Canada"
	}

	return address;
}

export const PersonSchema = Yup.object().shape({
	Person: Yup.object().shape({
		FirstName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Required'),
		LastName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Required'),
			
		PersonalEmail: Yup.string().email('Invalid email').when('Birthdate', { is: null, then: (schema) => schema.required('Required') }),
		HomePhone: Yup.string().min(10, 'Not a valid phone number').when('Birthdate', { is: null, then: (schema) => schema.when('MobilePhone', { is: undefined, then: (schema) => schema.required('Required') }) }),
		MobilePhone: Yup.string().min(10, 'Not a valid phone number').when('Birthdate', { is: null, then: (schema) => schema.when('HomePhone', { is: undefined, then: (schema) => schema.required('Required')}) })

		}, [['HomePhone', 'MobilePhone']])
	}
);

export const AddressSchema = Yup.object().shape({
	Address: Yup.object().shape({
		Street1: Yup.string().required('Required'),
		Street2: Yup.string(),
		City: Yup.string().required('Required'),
		Country: Yup.string().required('Required'),
		Province: Yup.string(),
		PostalCode: Yup.string().required('Required'),
	})
});