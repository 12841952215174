import { Fragment } from 'react';
import moment from 'moment-timezone';
import { linkTitle } from '../../../common/helpers';
import Card from '../../../common/components/Card';
import './event.css'

export const dateFormats = {
	'default': 'LL',
	'long': 'dddd, LL'
}

function Event({ event, dateFormat = dateFormats.default }) {

	const availability = <>
		<div className="text-muted small">
			{event.Dates.length > 0 &&
				<div>
					Available Dates:<br /> 
					{event.Dates.map((date, index) => {
						return <Fragment key={index}>
							<span>{moment(date.DateFrom).format(dateFormat)} to {moment(date.DateTo).format(dateFormat)}</span><br />
							</Fragment>
					})}
				</div>
			}

			{event.Dates.length === 0 && 
				<div>
					Not currently available
				</div>
			}
		</div>
	</>

	return <Card title={event.Title} description={event.ShortDescription} content={availability} imageUrl={event.ImageURLSmall} link={`/events/${linkTitle(event.Title)}`} />

		{/* <div className="card border-0 shadow mb-4">
			<Link to={`/events/${linkTitle(event.Title)}`}>
				<img className="card-img-top" src={event.ImageURLSmall} alt={event.Title} />
				<div className="card-body">
					<h5 className="card-title font-weight-bold">{event.Title}</h5>
					<p className="card-text">{event.ShortDescription}</p>
					
					<div className="text-muted small">
						{event.Dates.length > 0 &&
							<div>
								Available Dates:<br /> 
								{event.Dates.map((date, index) => {
									return <Fragment key={index}>
										<span>{moment(date.DateFrom).format(dateFormat)} to {moment(date.DateTo).format(dateFormat)}</span><br />
										</Fragment>
								})}
							</div>
						}

						{event.Dates.length == 0 && 
							<div>
								Not currently available
							</div>
						}
					</div>
					
				</div>
			</Link>
		</div> */}
	// </div>
}

export default Event