import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationTriangle, faTags } from "@fortawesome/free-solid-svg-icons";
import { useProgramState } from "../functions/program-state";
import { useRegistrationState } from "../functions/registration-state";
import { useHasProgramGroupPromotionCodesQuery, useProgramPromotionCodeMutation } from "../../../api/promotions.api";
import { ProgramPromotionCodeDiscountType, ProgramPromotionCodeResult } from "../../../api/promotions.models";
import { currency } from "../../../common/helpers";

const ProgramRegistrationPromotionCode = () => {
	const { program } = useProgramState();
	const { registration, updateRegistration } = useRegistrationState();
	const { data: showPromotionCode } = useHasProgramGroupPromotionCodesQuery(registration.ProgramGroupId);

	const { mutate: getPromotionCode } = useProgramPromotionCodeMutation();
	const [code, setCode] = useState("");
	const [result, setResult] = useState<ProgramPromotionCodeResult|undefined>();

	useEffect(() => {
		loadPromotionCode();
	}, []);

	const loadPromotionCode = () => {
		if (registration === undefined)
			return;

		if (registration.PromotionCodeId !== undefined && registration.PromotionCodeId !== 0 && registration.ProgramSessions.length == 1) {
			setCode(registration.PromotionCode?.Code ?? "");
			getPromotionCode({ programSessionId: registration.ProgramSessions[0].ProgramSessionId, code: registration.PromotionCode?.Code ?? "" }, { 
				onSuccess: (r) => {
					setResult(r.data);
					setCode(registration.PromotionCode?.Code ?? "");
				}
			});
		}
	}

	const handleApplyCode = () => {
		if (registration.ProgramSessions.length < 1)
			return;
		
		getPromotionCode({ programSessionId: registration.ProgramSessions[0].ProgramSessionId, code: code }, { 
			onSuccess: (r) => {
				setResult(r.data);

				if (r.data.Success) {
					registration.PromotionCodeId = r.data.PromotionCode.Id;
					registration.PromotionCode = r.data.PromotionCode;
					updateRegistration(registration);
				}
			}
		})
	}

	const handleClearCode = () => {
		registration.PromotionCodeId = undefined;
		registration.PromotionCode = undefined;
		updateRegistration(registration);
		setCode("");
		setResult(undefined);
	}

	if (showPromotionCode !== true)
		return <></>

	return <>
		<div className="card shadow mb-3">
			<h5 className="card-header"><FontAwesomeIcon icon={faTags} className="text-muted mr-2" /> Discounts</h5>

			<div className="card-body">
				<p>If you have a promotional code, please enter it here:</p>
				<div className="row">
					<div className="col-12 col-sm-6">
						<div className="form-group mb-0">
							<input className="form-control" value={code} disabled={result?.Success === true} onChange={(e) => { setCode(e.target.value) }} name="promotionCode" maxLength={100} />
						</div>
					</div>
					<div className="col-6 col-sm-3">
						<button type="button" onClick={handleApplyCode} disabled={code.length === 0 || result?.Success === true} className="btn btn-primary font-weight-bold btn-block mt-2 mt-sm-0">Apply Code</button>
					</div>
					{result !== undefined && result.Success === true &&
					<div className="col-6 col-sm-3">
						<button type="button" onClick={handleClearCode} className="btn btn-link mt-2 mt-sm-0">Remove</button>
					</div>
					}
				</div>

				{result && result.Success === false &&
				<div className="alert alert-warning pt-1 pb-1 pl-2 pr-2 mt-3 mb-0" role="alert">
					<FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 text-warning" />
					{result.Message}
				</div>
				}
				{result && result.Success === true &&
					<div className="alert alert-success pt-1 pb-1 pl-2 pr-2 mt-3 mb-0" role="alert">
						<FontAwesomeIcon icon={faCheck} className="mr-2 text-success" />
						{result.PromotionCode.DiscountType === ProgramPromotionCodeDiscountType.Amount &&
							<span>A discount of {currency.format(result.PromotionCode.DiscountAmount)} has been applied to your registration!</span>
						}
						{result.PromotionCode.DiscountType === ProgramPromotionCodeDiscountType.Percent &&
							<span>A discount of {result.PromotionCode.DiscountAmount * 1}% has been applied to your registration!</span>
						}
					</div>
				}

			</div>
		</div>
	</>
}

export default ProgramRegistrationPromotionCode