import { useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import { debounce } from "lodash";
import { AdultParticipant, ChildParticipant, Registration } from "../../../api/program.registration.models";
import { useProgramSessionsByIdQuery, useProgramsQuery, useProgramGroupsQuery, useProgramActivitySessionsForProgramQuery, useProgramActivitiesQuery } from "../../../api/programs.api";
import { ProgramActivity, ProgramSession } from "../../../api/programs.models";
import { useProgramState } from "../functions/program-state";
import { useRegistrationState } from "../functions/registration-state";
import ProgramRegistrationActivitySelection from "./program-registration-activity-selection";

interface Props {
	participant: AdultParticipant|ChildParticipant;
}
const ProgramRegistrationParticipantSessions = ({ participant }: Props) => {
	const { registration, updateRegistration, useRegistrationValidationListener } = useRegistrationState();
	const { selectedSessions, incrememntProgramSession, decrememntProgramSession, getProgramSessionQuantity, isProgramSessionSpaceAvailable } = useProgramState();

	const [initiallySelectedSessions, setInitiallySelectedSessions] = useState<ProgramSession[]>([]);

	const { data: programs } = useProgramsQuery();
	const { data: programGroups } = useProgramGroupsQuery();
	const { data: programSessions } = useProgramSessionsByIdQuery(registration?.ProgramId ?? 0);
	// const { data: programActivitySessions } = useProgramActivitySessionsQuery();
	const { data: programActivities } = useProgramActivitiesQuery();
	const { data: programActivitySessions } = useProgramActivitySessionsForProgramQuery(registration.ProgramId, registration.DateFrom, registration.DateTo);
	
	const [showNoSelectionError, setShowNoSelectionError] = useState(false);

	useRegistrationValidationListener(() => {
		setShowNoSelectionError(registration.ProgramSessions.length === 0);
	});

	const getProgram = (programId: number) => programs?.find(p => p.Id === programId);
	// const getProgramGroup = (programGroupId: number) => programGroups?.find(g => g.Id === programGroupId);
	const getProgramSession = (programSessionId: number) => programSessions?.find(c => c.Id === programSessionId);
	// const getProgramForProgramSession = (programSessionId: number) => getProgram(getProgramSession(programSessionId)?.ProgramId ?? 0);

	// const activitySelectionType = getProgramGroup(registration.ProgramGroupId)?.ActivitySelectionType as ActivitySelectionType ?? ActivitySelectionType.Single;

	useEffect(() => {
		if (selectedSessions.length > 0 && initiallySelectedSessions.length == 0)
			setInitiallySelectedSessions(selectedSessions.map(s => s.ProgramSession).sort((a, b) => moment(a.StartDate).isSameOrAfter(moment(b.StartDate)) ? 1 : -1));
	}, [selectedSessions.length]);

	const programSessionsForReservation = initiallySelectedSessions.sort((a, b) => moment(a.StartDate).isSameOrAfter(moment(b.StartDate)) ? 1 : -1);

	const [activitySelectionMap, setActivitySelectionMap] = useState<Map<string,ProgramActivity>>(new Map<string,ProgramActivity>());

	const updateActivitySelectionMap = () => {
		let map = generateActivitySelectionMap();
		setActivitySelectionMap(map);
	}
	useEffect(() => {
		updateActivitySelectionMap();
	}, [programActivitySessions, registration]);

	const updateRegistrationDebounced = useMemo(() => debounce((registration: Registration) => {
		updateRegistration(registration);
	}, 1000), []);
	
	const generateActivitySelectionMap = () => {
		let activitySelectionMap = new Map<string, ProgramActivity>();
		if (programActivitySessions === undefined)
			return activitySelectionMap;

		for (let rps of registration.ProgramSessions) {
			for (let par of rps.Participants) {
				if (par.ParticipantId !== participant.Id)
					continue;

				for (let asid of par.ActivitySessionIds) {
					let as = programActivitySessions.flatMap(f => f).find(a => a.Id === asid);
					if (as === undefined)
						continue;
					let pa = programActivities?.find(p => p.Id === as?.ActivityId);
					let key = `${moment(as.StartDate).startOf('week').toISOString() ?? ""}-${moment(as.StartDate).format("h:mm a") ?? ""}-${moment(as.EndDate).format("h:mm a") ?? ""}`;
					if (pa && !activitySelectionMap.has(`"${key}"`))
						activitySelectionMap.set(key, pa);
				}
			}
		}

		// console.log(activitySelectionMap);
		return activitySelectionMap;
	}

	const programSessionChanged = (programSession: ProgramSession, selected: boolean) => {
		let rpsIndex = registration.ProgramSessions.findIndex(s => s.ProgramSessionId === programSession.Id);
		if (rpsIndex >= 0) {
			let rpsParticipant = registration.ProgramSessions[rpsIndex].Participants.findIndex(p => p.ParticipantId === participant.Id);
			if (rpsParticipant >= 0) {
				registration.ProgramSessions[rpsIndex].Participants.splice(rpsParticipant, 1);
				if (registration.ProgramSessions[rpsIndex].Participants.length === 0) {
					registration.ProgramSessions.splice(rpsIndex, 1);
					decrememntProgramSession(programSession);
				}
			} else {
				registration.ProgramSessions[rpsIndex].Participants.push({ 
					ParticipantId: participant.Id,
					ActivitySessionIds: []
				});
				incrememntProgramSession(programSession);
			}
		} else {
			registration.ProgramSessions.push({
				ProgramSessionId: programSession.Id,
				Participants: [
					{
						ParticipantId: participant.Id,
						ActivitySessionIds: []
					}
				]
			})
			incrememntProgramSession(programSession);
		}

		// TODO: Make an setting for this
		if (selected) {
			let sameDaySession = programSessionsForReservation?.find(s => s.Id !== programSession.Id && moment(s.StartDate).isSame(moment(programSession.StartDate), "date"));
			let isSunday = moment(programSession.StartDate).day() == 0;
			if (sameDaySession && isSunday) {
				let index = registration.ProgramSessions.findIndex(s => s.ProgramSessionId === sameDaySession?.Id);
				if (index >= 0) {
					registration.ProgramSessions.splice(index, 1);
					decrememntProgramSession(sameDaySession);
				}
			}
		}

		if (selected)
			setShowNoSelectionError(false);

		registration.ProgramSessions.sort((a, b) => {
			if (getProgramSession(a.ProgramSessionId) === undefined ||
				getProgramSession(b.ProgramSessionId) === undefined)
				return 0;
			return moment(getProgramSession(a.ProgramSessionId)!.StartDate).isBefore(moment(getProgramSession(b.ProgramSessionId)!.StartDate)) ? -1 : 1
		});

		updateRegistrationDebounced(registration);
	}

	return <>
		<div className="mb-3"><strong>{participant.FirstName} {participant.LastName}</strong> will be enrolled in 
			<span> {getProgram(registration.ProgramId)?.Title} </span> for the following dates that are checked.
		</div>
		
		{showNoSelectionError &&
		<div className="text-danger font-weight-bold mb-3">
			Please enroll {participant.FirstName} {participant.LastName} in at least one session.
		</div>
		}
		
		{programSessionsForReservation?.map((programSession, index) => {
			let registrationProgramSession = registration.ProgramSessions.find(s => s.ProgramSessionId === programSession.Id);
			let selected = registrationProgramSession !== undefined && registrationProgramSession?.Participants.find(p => p.ParticipantId === participant.Id) !== undefined;
			let program = programs?.find(p => p.Id === registration.ProgramId);
			let programGroup = programGroups?.find(g => g.Id === registration.ProgramGroupId);
			let multipleSameDay = programSessionsForReservation.length > index + 1 && moment(programSession.StartDate).isSame(moment(programSessionsForReservation[index+1].StartDate), "date");
			let isSunday = moment(programSession.StartDate).day() == 0;

			return <div key={index}>
				{multipleSameDay && isSunday && <>
					<div className="small text-muted">Select one choice for {moment(programSession.StartDate).format("dddd")}</div>
				</>}
				<div className="mb-2">
					<div className="custom-control custom-checkbox mb-2">					
						<input type="checkbox" id={`ps${index}_${participant.Id}`} className="custom-control-input" checked={selected} onChange={(e) => programSessionChanged(programSession, e.target.checked)} disabled={programGroup?.AllowWaitlist === false && !selected && !isProgramSessionSpaceAvailable(programSession)} />
						<label htmlFor={`ps${index}_${participant.Id}`} className="custom-control-label">
							{moment(programSession.StartDate).format("dddd, LL")}, {moment(programSession.StartDate).format("h:mm A")} - {moment(programSession.EndDate).format("h:mm A")}
							{/* - ({programSession.Enrollment + getProgramSessionQuantity(programSession)}/{programSession.Limit}) */}
							{isProgramSessionSpaceAvailable(programSession) && <>
							<span className="small text-muted"> ({programSession.Limit - programSession.Enrollment - getProgramSessionQuantity(programSession)} spaces available)</span>
							</>}
							{programGroup?.AllowWaitlist === true && !isProgramSessionSpaceAvailable(programSession) && <>
							<span className="small text-muted"> (join wait list)</span>
							</>}
						</label>
						{selected && programGroup?.AllowWaitlist === true && !isProgramSessionSpaceAvailable(programSession) && <>
							<div className="alert alert-warning d-inline-block p-1 pl-2 pr-2 mb-0 small">This session is over capacity. Participant will be placed on wait list.</div>
						</>}
					</div>	
				
				</div>
				{selected &&
				<ProgramRegistrationActivitySelection program={program} programGroup={programGroup} programSession={getProgramSession(registrationProgramSession?.ProgramSessionId ?? 0)} participantId={participant.Id} activitySelectionMap={activitySelectionMap} />
				}
			</div>
		})}

{/* <hr />
		{registration.ProgramSessions.map((registrationProgramSession, index2) => {
			let programSession = getProgramSession(registrationProgramSession.ProgramSessionId);
			let program = getProgram(programSession?.ProgramId ?? 0);
			return <div key={index2}>
				<div className="mb-2">
					<div className="custom-control custom-checkbox mb-2">					
						<input type="checkbox" id={`rps${index2}`} className="custom-control-input" />
						<label htmlFor={`rps${index2}`} className="custom-control-label">
							{moment(getProgramSession(registrationProgramSession.ProgramSessionId)?.StartDate).format("dddd, LL")}, {moment(getProgramSession(registrationProgramSession.ProgramSessionId)?.StartDate).format("h:mm A")} - {moment(getProgramSession(registrationProgramSession.ProgramSessionId)?.EndDate).format("h:mm A")}
						</label>
					</div>	
					
					<button type="button" className="btn btn-link btn-sm p-0 ml-3" onClick={(e) => copySelection(program, programSession, registrationProgramSession, participant)}>Copy selections to other days</button>
				</div>

				<ProgramRegistrationActivitySelection programId={program?.Id ?? 0} programSessionId={registrationProgramSession.ProgramSessionId} participantId={participant.Id} date={programSession?.StartDate} />
				
			</div>
		})} */}
	</>
}

export default ProgramRegistrationParticipantSessions