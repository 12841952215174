import moment from "moment-timezone";
import ThemeToggle from "../common/components/ThemeToggle";
import { useConfigurationQuery } from "../api/configuration.api";

const AppFooter = () => {
	const { data: configuration, isLoading: isConfigurationLoading } = useConfigurationQuery();
	const year = moment().format('yyyy');

	const scrollToTop = () => window.scrollTo(0, 0);

	return <>
		<footer className="shadow-lg py-sm-4 mt-5">
			<div className="container">
				<div className="row">
					{!isConfigurationLoading && configuration &&
					<div className="col-12 col-sm-8 pt-3 pt-sm-0">
						<p className="font-weight-bold">&copy; {year}  {configuration.OrganizationTitle}</p>
						<p className="text-muted">
							{configuration.OrganizationAddress}<br />
							Phone: {configuration.OrganizationPhone}<br />
							Email: <a href={`mailto:${configuration.OrganizationEmail}`}>{configuration.OrganizationEmail}</a>
						</p>
						<p className="m-0">New to {configuration.OrganizationShortform}? <a href={configuration.OrganizationWebsite} target="_blank" rel="noreferrer">Visit the {configuration.OrganizationShortform} website</a> to learn more.</p>
					</div>
					}
					<div className="col-12 col-sm-4">
						<div className="row mt-3 mt-sm-0">
							<div className="col-6 col-sm-12 text-sm-right">
								<p>
									<button onClick={scrollToTop} className="btn btn-link pl-0 pr-0">Back to top</button>
								</p>
							</div>
							<div className="col-6 col-sm-12 text-right">
								<ThemeToggle />
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</>
}

export default AppFooter