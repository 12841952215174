import { Formik, ErrorMessage, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { faAddressCard } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _, { debounce } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import { AdultParticipant, ChildParticipant, Registration } from '../../../api/program.registration.models';
import { DateInputField } from '../../../common/components/DateInputField';
import { getCountries, getProvincesStates, getProvinceLabel, getPostalCodeLabel } from '../../../common/address';
import { useEffect, useMemo, useState } from 'react';
import { useUserAddressQuery, useUserPeopleQuery } from '../../../api/user.api';
import { useAuthState } from '../../../state/auth.state';
import { useRegistrationState } from '../functions/registration-state';
import { Address, Person } from '../../../api/user.models';
import { useProgramState } from '../functions/program-state';
import { useCartState } from '../../cart/functions/cart-state';
import { ProgramParticipantType } from '../../../api/programs.models';
import { useConfigurationQuery } from '../../../api/configuration.api';

const ChildSchema = Yup.object().shape({
	Child: Yup.object().shape({
		FirstName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Required'),
		LastName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Required'),
		// Email: Yup.string().email('Invalid email').required('Required'),
		// HomePhone: Yup.string().when('MobilePhone', { is: undefined, then: (schema) => schema.required('Required') }),
		// MobilePhone: Yup.string().when('HomePhone', { is: undefined, then: (schema) => schema.required('Required') }),
		SameAddress: Yup.boolean(),
		Address: Yup.object().shape({
			Street1: Yup.string().required('Required'),
			Street2: Yup.string(),
			City: Yup.string().required('Required'),
			Country: Yup.string().required('Required'),
			Province: Yup.string(),
			PostalCode: Yup.string().required('Required'),
		})
	}, [['HomePhone', 'MobilePhone']])
});

interface ProgramRegistrationChildProps {
	participant: ChildParticipant;
	index: number;
	onRemove: (index: number) => void;
}

const ProgramRegistrationChild = ({ participant, index, onRemove }: ProgramRegistrationChildProps) => {
	const { registration } = useRegistrationState();
	const { programGroup } = useProgramState();
	const { data: configuration } = useConfigurationQuery();

	const isAdult = false;
	const isChild = true;

	const showDiaperChange = isChild && moment.duration(moment().diff(moment((participant as ChildParticipant).Birthdate))).asMonths() < 36;

	const childLocked = programGroup?.ParticipantType === ProgramParticipantType.ChildrenPreSelected;
	
	const handleCountryChanged = (country: string, values: { Child: ChildParticipant }) => {
		if (country === "Canada") {
			values.Child.Address!.Province = "Ontario";
			values.Child.Address!.Region = "";
		}
		else if (country === "United States") {
			values.Child.Address!.Province = "Alabama";
			values.Child.Address!.Region = "";
		}
		else
			values.Child.Address!.Province = "";
	}

	// const showRemove = registration.Children.length > 1;

	const showUseSameAddress = true;//index > 0;
	const hasParentGuardian = registration.ParentGuardians.length > 0;
	
	return <>
		<p className="lead font-weight-bold mb-2">
			<FontAwesomeIcon icon={faAddressCard} className="mr-2" />
			Please provide information for Child Participant #{index+1}: {participant.FirstName} {participant.LastName}
		</p>

		<Formik
			validationSchema={ChildSchema}
			enableReinitialize={true}
			initialValues={{
				Child: _.cloneDeep(participant),
				GuestType: "child"
			}}
			onSubmit={(values, { setSubmitting }) => {

			}}
			validateOnBlur={true}
		>
		{({ isSubmitting, submitCount, isValid, values, errors, handleChange }) => <>

			<AutoSaveChildState GuestIndex={index} GuestType={"child"} />
			{!childLocked && <>
			<div className="form-row">
				<div className="col-12 col-sm-6">
					<AutofillPerson />
				</div>
				{/* {showRemove &&
				<div className="col-12 col-sm-6">
					<button type="button" onClick={() => onRemove(index)} className="btn btn-link">Remove this Parent/Guardian</button>
				</div>
				} */}
			</div>
			</>}
			<div className="form-row">
				{/* {isAdult && 
				<div className={classNames(["col-6 col-sm-2", isChild ? 'pr-sm-0-x':''])}>
					<div className="form-label-group">
						<Field component="select" name="Child.Salutation" className={classNames(["custom-select", values.Child.Salutation !== '' ? "value-selected":""])}>
							{salutations.map((salutation, i) => {
								return <option key={i}>{salutation}</option>
							})}
						</Field>
						<label className="text-truncate">Salutation</label>
					</div>
				</div>
				} */}
				<div className={classNames(["col-12", isAdult ? "col-sm-4 pl-sm-0-x":"", isChild ? "col-sm-3 pr-sm-0-x":""])}>
					<div className="form-label-group">
						<Field type="text" name={`Child.FirstName`} disabled={childLocked} className={classNames([`form-control`, errors.Child?.FirstName ? 'is-invalid':''])} placeholder={true ? "Required" : ""} autoComplete="off" />
						<label htmlFor={`firstName_${index}`}>First Name</label>
						<ErrorMessage name="Child.FirstName" component="div" className="error-message fw-bold small text-danger" />
						{/* {!isUnique(values) &&
						<div className="error-message fw-bold small text-danger">Name is duplicated</div>
						} */}
					</div>
				</div>
				<div className={classNames(["col-12 col-sm-4", isAdult ? "pl-sm-0-x":"", isChild ? "pr-sm-0-x":""])}>
					<div className="form-label-group">
						<Field type="text" name={`Child.LastName`} disabled={childLocked} className={classNames([`form-control`, errors.Child?.LastName ? 'is-invalid':''])} placeholder={true ? "Required" : ""} autoComplete="off" />
						<label>Last Name</label>
						<ErrorMessage name="Child.LastName" component="div" className="error-message fw-bold small text-danger" />
						{/* {!isUnique(values) &&
						<div className="error-message fw-bold small text-danger">Name is duplicated</div>
						} */}
					</div>
				</div>
				<div className={classNames(["col-6 col-sm-2", isAdult ? "pl-sm-0-x":"", isChild ? 'pr-sm-0-x':''])}>
					<div className="form-label-group">
						<Field component="select" name="Child.Gender" disabled={childLocked} className={classNames(["custom-select", values.Child.Gender !== 0 ? "value-selected":""])}>
							<option value="0"></option>
							<option value="1">Male</option>
							<option value="2">Female</option>
						</Field>
						<label className="text-truncate">Gender</label>
					</div>
				</div>
				{isChild &&
					<div className={classNames(["col-12 col-sm-3", "pr-sm-0-x"])}>
						<div className="form-label-group">
							<DateInputField name={`Child.Birthdate`} disabled={childLocked} minDate={moment().add(-100, "years").toDate()} maxDate={moment().toDate()} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="off" />
							<label>Date of Birth</label>
							<ErrorMessage name="Child.Birthdate" component="div" className="error-message fw-bold small text-danger" />
						</div>
					</div>
				}

			</div>

			{/* {isAdult && 
			<div className="form-row">
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Child.Email`} className={`form-control`} placeholder={true ? "Email (required)" : ""} autoComplete="off" required />
						<label>Email</label>
						<ErrorMessage name="Child.Email" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<PhoneInputField name="Child.HomePhone" className="form-control" placeholder="Required" autoComplete="off" required={values.Child.MobilePhone === "" || values.Child.MobilePhone == null} />
						<label>Home Phone</label>
						<ErrorMessage name="Child.HomePhone" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<PhoneInputField name="Child.MobilePhone" className="form-control" placeholder={values.Child.MobilePhone === "" || values.Child.MobilePhone == null ? "required":"required"} autoComplete="off" required={values.Child.HomePhone === "" || values.Child.HomePhone == null}  />
						<label>Cell Phone</label>
						<ErrorMessage name="Child.MobilePhone" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
			</div>
			} */}


			{showUseSameAddress && <>
			<div className="form-row">
				<div className="col-12">
					<div className="custom-control custom-checkbox mb-2">					
						<Field type="checkbox" name="Child.SameAddress" id={`Child.SameAddress${index}`} checked={values.Child.SameAddress} className="custom-control-input" />
						<label htmlFor={`Child.SameAddress${index}`} className="custom-control-label">
							Use same address as {hasParentGuardian ? "Parent/Guardian #1" : "Adult Participant #1"}
						</label>
					</div>		
				</div>
			</div>

			{values.Child.SameAddress &&
			<div className="form-row">
				<div className="col-12 mb-2">
					<span>Address: </span>
					{values.Child.Address?.Street1}{values.Child.Address?.Street1 ? ", " : ""}
					{values.Child.Address?.Street2}{values.Child.Address?.Street2 ? ", " : ""}
					{values.Child.Address?.City}{values.Child.Address?.City ? ", " : ""}
					{values.Child.Address?.Province}{values.Child.Address?.Province ? ", " : ""}
					{values.Child.Address?.PostalCode}{values.Child.Address?.PostalCode ? ", " : ""}
					{values.Child.Address?.Country}
				</div>
			</div>
			}
			</>}
			
			{(values.Child.SameAddress === false) && 
			<>
			<div className="form-row">
				<div className="col-12 col-sm-6">
					<AutofillAddress />
				</div>
			</div>

			<div className="form-row">
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Child.Address.Street1`} className={classNames([`form-control`, _.get(errors.Child, "Address.Street1") ? 'is-invalid':''])} placeholder={true ? "required" : ""} autoComplete="off" required={false} />
						<label>Street Address</label>
						<ErrorMessage name="Child.Address.Street1" component="div" className="fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Child.Address.Street2`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
						<label>Apt / Unit Number</label>
						<ErrorMessage name="Child.Address.Street2" component="div" className="fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Child.Address.City`} className={classNames([`form-control`, _.get(errors.Child, "Address.City") ? 'is-invalid':''])} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
						<label>City</label>
						<ErrorMessage name="Child.Address.City" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				<div className={classNames(["col-6 col-sm-4"])}>
					<div className="form-label-group">
						<Field component="select" name="Child.Address.Country" onChange={(e:any) => { handleCountryChanged(e.target.value, values); handleChange(e); }} className={classNames(["custom-select", values.Child.Address?.Country !== "" ? "value-selected":""])}>
							{getCountries().map((country, index) => {
								return <option key={index} value={country}>{country}</option>
							})}
						</Field>
						<label className="text-truncate">Country</label>
					</div>
				</div>
				{(values.Child.Address?.Country === "Canada" || values.Child.Address?.Country === "United States") &&
				<div className={classNames(["col-6 col-sm-4"])}>
					<div className="form-label-group">
						<Field component="select" name="Child.Address.Province" className={classNames(["custom-select", values.Child.Address?.Province !== "" ? "value-selected":""])}>
							{getProvincesStates(values.Child.Address?.Country ?? "").map((province, index) => {
								return <option key={index} value={province}>{province}</option>
							})}
						</Field>
						<label className="text-truncate">{getProvinceLabel(values.Child.Address?.Country ?? "")}</label>
					</div>
				</div>
				}
				{(values.Child.Address?.Country !== "Canada" && values.Child.Address?.Country !== "United States") &&
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Child.Address.Region`} className={`form-control`} placeholder={" "} autoComplete="off" required={false} />
						<label>Region</label>
						<ErrorMessage name="Child.Address.Region" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				}
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Child.Address.PostalCode`} className={classNames([`form-control`, _.get(errors.Child, "Address.PostalCode") ? 'is-invalid':''])} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
						<label className="text-truncate">{getPostalCodeLabel(values.Child.Address?.Country ?? "")}</label>
						<ErrorMessage name="Child.Address.PostalCode" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
			</div>

			</>}

			<div className="form-row">
				<div className="col-12">
					<p className="form-text font-weight-bold mb-2">Health Information</p>
				</div>	
			</div>
			{childLocked &&
			<div className="form-row">
				<div className="col-12 col-sm-6">
					<AutofillHealth />
				</div>
			</div>
			}
			<div className="form-row">
				<div className="col-12 col-sm-6">
					<div className="form-label-group mb-sm-0">
						<Field component="textarea" rows="3" maxLength="100" name={`Child.HealthConcerns`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
						<label>Health Concerns<br />{(values.Child.HealthConcerns === "" || values.Child.Allergies === null) && <small>(leave blank if none)</small>}</label>
						<span className="text-muted small">({100-(values.Child.HealthConcerns != null ? values.Child.HealthConcerns.length : 0)}/100 characters remaining)</span>
					</div>
					<ErrorMessage name="Child.HealthConcerns" component="div" className="fw-bold small text-danger" />
				</div>
				<div className="col-12 col-sm-6">
					<div className="form-label-group mb-0">
						<Field component="textarea" rows="3" maxLength="100" name={`Child.Allergies`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
						<label>Allergies<br />{(values.Child.Allergies === "" || values.Child.Allergies === null) && <small>(leave blank if none)</small>}</label>
						<span className="text-muted small">({100-(values.Child.Allergies != null ? values.Child.Allergies.length : 0)}/100 characters remaining)</span>
					</div>
					<ErrorMessage name="Child.Allergies" component="div" className="fw-bold small text-danger" />
				</div>
				<div className="col-12 col-sm-6">
					<div className="form-label-group mb-0">
						<Field component="textarea" rows="3" maxLength="100" name={`Child.SpecialNeeds`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
						<label>Special Needs<br />{(values.Child.SpecialNeeds === "" || values.Child.SpecialNeeds === null) && <small>(leave blank if none)</small>}</label>
						<span className="text-muted small">({100-(values.Child.SpecialNeeds != null ? values.Child.SpecialNeeds.length : 0)}/100 characters remaining)</span>
					</div>
					<ErrorMessage name="Child.SpecialNeeds" component="div" className="fw-bold small text-danger" />
				</div>
			</div>

			{showDiaperChange &&
			<div className="form-row mt-3">
				<div className="col-12">
					<p><strong>Diaper Changes</strong></p>
					<p>Some parents prefer that they are the only ones to change the diapers of their children. Please indicate below if you are comfortable with {configuration?.OrganizationShortform} staff changing diapers for your child: </p>
					<div className="custom-control custom-checkbox mb-2">					
						<Field type="checkbox" name="Child.ChangeDiaper" id={`Child.ChangeDiaper${index}`} checked={values.Child.ChangeDiaper} className="custom-control-input" />
						<label htmlFor={`Child.ChangeDiaper${index}`} className="custom-control-label">
							{configuration?.OrganizationShortform} team members may change the diapers of my child
						</label>
					</div>		
				</div>
			</div>
			}

		</>}

		</Formik>
	</>
}

export default ProgramRegistrationChild


interface AutoSaveChildStateProps {
	// ItemIndex: number,
	GuestIndex: number,
	GuestType: "adult"|"child"
}

const AutoSaveChildState = ({ GuestIndex, GuestType }: AutoSaveChildStateProps) => {
	const { registration, updateRegistration, useRegistrationParticipantsListener, useRegistrationValidationListener } = useRegistrationState();
	const { values, submitForm, isValidating } = useFormikContext<{ Child: AdultParticipant|ChildParticipant }>();

	useRegistrationValidationListener(() => {
		submitForm();
	});

	const update = useMemo(() => debounce((registration: Registration, values: { Child: AdultParticipant|ChildParticipant }) => {
		if (values.Child) {		
			// if (GuestType === "adult") { 
			// 	registration.Items[ItemIndex].Participant = values.Child as AdultParticipant;
			// }
			if (GuestType === "child") {
				registration.Participants[GuestIndex] = values.Child as ChildParticipant;
			}
			
	
			updateRegistration(registration);
			// emitRegistrationParticipants(registration);
				
		}
	}, 1000), []);

	useEffect(() => {
		update(registration, values);
	}, [isValidating])

	const updateSameAddress = useMemo(() => debounce((res: Registration ,values: { Child: AdultParticipant|ChildParticipant }) => {
		if (values.Child.SameAddress) {
			values.Child.Address = res.ParentGuardians.length > 0 ? res.ParentGuardians[0].Address : res.Participants[0].Address;
		}
		updateRegistration(res);
	}, 1000), []);

	useRegistrationParticipantsListener((reg: Registration) => {
		updateSameAddress(reg, values);
	});

	useEffect(() => {
		if (GuestIndex > 0 || GuestType === "child") {
			updateSameAddress(registration, values);
		}
	}, [values.Child.SameAddress])
	
	return <></>
}


interface AutofillPersonProps {
	// ItemIndex: number
	// GuestIndex: number
	// GuestType: "adult"|"child"
}

const AutofillPerson = ({ }: AutofillPersonProps) => {
	const { values, setValues, validateForm } = useFormikContext<{ Child: AdultParticipant|ChildParticipant, GuestType: string }>();
	// const { validateReservation } = useRegistrationState();
	const { isAuthenticated } = useAuthState();
	const { getCartAutofillChildParticipants } = useCartState();
	const { data: userPeople } = useUserPeopleQuery();

	const getAge = (person: Person) : number|null => {
		if (person.Birthdate == null)
			return null;

		return moment().diff(moment(person.Birthdate), "years");
	}
	
	// const autofillAdults = userPeople?.map(p => p.Person)?.filter(p => { return p != null && (p.Birthdate == null || (getAge(p) != null && getAge(p)! > 18 )) }) ?? [];
	// const autofillChildren = userPeople?.map(p => p.Person)?.filter(p => { return p != null && (p.Birthdate != null && (getAge(p) != null && getAge(p)! <= 18 )) }) ?? [];

	const [autofillPerson, setAutofillPerson] = useState("");
	let autofillPeople = userPeople?.map(p => p.Person).filter(p => ((values.GuestType === "adult" && p.Birthdate === null) || (values.GuestType === "child" && p.Birthdate != null && getAge(p) != null && getAge(p)! <= 18))).map(p => p) ?? [];
	let autofillCartPeople = getCartAutofillChildParticipants();

	const handleAutofillPerson = async (value: string) => {
		let autofillPerson = autofillPeople.find(a => a.Id === parseInt(value));
		if (autofillPerson === undefined)
			return handleAutofillParticipant(value);

		if (values.GuestType === "adult") {
			let guest = values.Child as AdultParticipant;
			guest.Salutation = autofillPerson.Salutation;
			guest.FirstName = autofillPerson.FirstName;
			guest.LastName = autofillPerson.LastName;
			guest.Gender = autofillPerson.Gender;
			guest.Email = autofillPerson.PersonalEmail;
			guest.HomePhone = autofillPerson.HomePhone;
			guest.MobilePhone = autofillPerson.MobilePhone;

			setValues(values);
		}
		if (values.GuestType === "child") {
			let guest = values.Child as ChildParticipant;
			guest.FirstName = autofillPerson.FirstName;
			guest.LastName = autofillPerson.LastName;
			guest.Gender = autofillPerson.Gender;
			guest.Birthdate = autofillPerson.Birthdate!;

			setValues(values);
		}

		setAutofillPerson("");

		await validateForm();
		await validateForm();

		// await validateReservation();
	}

	const handleAutofillParticipant = async (value: string) => {
		let autofillParticipant = autofillCartPeople.find(a => a.Id === value);
		if (autofillParticipant === undefined)
			return;

		// if (values.GuestType === "adult") {
		// 	let guest = values.Child as AdultParticipant;
		// 	guest.Salutation = autofillParticipant.Salutation;
		// 	guest.FirstName = autofillParticipant.FirstName;
		// 	guest.LastName = autofillParticipant.LastName;
		// 	guest.Gender = autofillParticipant.Gender;
		// 	guest.Email = autofillParticipant.Email;
		// 	guest.HomePhone = autofillParticipant.HomePhone;
		// 	guest.MobilePhone = autofillParticipant.MobilePhone;
		// 	guest.EmergencyContact = autofillParticipant.EmergencyContact;
		// 	guest.Location = autofillParticipant.Location;

		// 	setValues(values);
		// }
		if (values.GuestType === "child") {
			let participant = values.Child as ChildParticipant;
			participant.FirstName = autofillParticipant.FirstName;
			participant.LastName = autofillParticipant.LastName;
			participant.Gender = autofillParticipant.Gender;
			participant.Birthdate = autofillParticipant.Birthdate!;
			participant.HealthConcerns = autofillParticipant.HealthConcerns;
			participant.Allergies = autofillParticipant.Allergies;
			participant.SpecialNeeds = autofillParticipant.SpecialNeeds;

			setValues(values);
		}

		setAutofillPerson("");

		await validateForm();
		await validateForm();
	}

	return !isAuthenticated() ? <></> : <>
		<select value={autofillPerson} onChange={(e) => handleAutofillPerson(e.target.value)} className="custom-select custom-select-sm w-100 mb-3 text-info font-weight-bold">
			<option>Autofill with person...</option>
			{autofillPeople?.map((person, i) => {
				return values.GuestType === "adult" ? 
					<option key={i} value={person.Id}>{person.FirstName} {person.LastName}, {person.PersonalEmail}, {person.HomePhone}</option> : 
					<option key={i} value={person.Id}>{person.FirstName} {person.LastName}</option>
			})}
			{autofillCartPeople.map((participant, i) => {
				return <option key={i} value={participant.Id}>{participant.FirstName} {participant.LastName}</option>
			})}
		</select>
	</>
}

interface AutofillHealthProps {
	// ItemIndex: number
	// GuestIndex: number
	// GuestType: "adult"|"child"
}

const AutofillHealth = ({ }: AutofillHealthProps) => {
	const { values, setValues, validateForm } = useFormikContext<{ Child: ChildParticipant, GuestType: string }>();
	// const { validateReservation } = useRegistrationState();
	const { isAuthenticated } = useAuthState();
	const { getCartAutofillChildParticipants } = useCartState();
	
	// const autofillAdults = userPeople?.map(p => p.Person)?.filter(p => { return p != null && (p.Birthdate == null || (getAge(p) != null && getAge(p)! > 18 )) }) ?? [];
	// const autofillChildren = userPeople?.map(p => p.Person)?.filter(p => { return p != null && (p.Birthdate != null && (getAge(p) != null && getAge(p)! <= 18 )) }) ?? [];

	const [autofillPerson, setAutofillPerson] = useState("");
	let autofillCartPeople = getCartAutofillChildParticipants();

	const handleAutofillHealth = async (value: string) => {
		let autofillParticipant = autofillCartPeople.find(a => a.Id === value);
		if (autofillParticipant === undefined)
			return;

		if (values.GuestType === "child") {
			let participant = values.Child as ChildParticipant;
			participant.HealthConcerns = autofillParticipant.HealthConcerns;
			participant.Allergies = autofillParticipant.Allergies;
			participant.SpecialNeeds = autofillParticipant.SpecialNeeds;

			setValues(values);
		}

		setAutofillPerson("");

		await validateForm();
		await validateForm();
	}
	if (autofillCartPeople.length === 0)
		return <></>

	return !isAuthenticated() ? <></> : <>
		<select value={autofillPerson} onChange={(e) => handleAutofillHealth(e.target.value)} className="custom-select custom-select-sm w-100 mb-3 text-info font-weight-bold">
			<option>Autofill with person...</option>
			{autofillCartPeople.map((participant, i) => {
				return <option key={i} value={participant.Id}>{participant.FirstName} {participant.LastName}</option>
			})}
		</select>
	</>
}

interface AutofillAddressProps {
	// ItemIndex: number
	// GuestIndex: number
	// GuestType: "adult"|"child"
}

const AutofillAddress = ({ }: AutofillAddressProps) => {
	const { values, setValues, validateForm } = useFormikContext<{ Child: AdultParticipant|ChildParticipant }>();
	// const { validateReservation } = useRegistrationState();
	const { isAuthenticated } = useAuthState();
	const { getCartAutofillAddresses } = useCartState();
	const { data: userAddresses } = useUserAddressQuery();

	const [autofillAddress, setAutofillAddress] = useState("");
	let autofillAddresses = userAddresses?.map(a => a.Address) ?? [];
	let autofillCartAddresses = getCartAutofillAddresses();

	const handleAutofillAddress = async (value: string) => {
		let autofillAddress = autofillAddresses.find(a => a.Street1 === value);
		if (autofillAddress === undefined) {
			autofillAddress = autofillCartAddresses.find(a => a.Street1 === value) as Address|undefined;
		}
		if (autofillAddress === undefined)
			return;

		values.Child.Address = autofillAddress;
		setValues(values);

		setAutofillAddress("");

		await validateForm();
		await validateForm();

		// await validateReservation();
	}

	return !isAuthenticated() ? <></> : <>
		<select value={autofillAddress} onChange={(e) => handleAutofillAddress(e.target.value)} className="custom-select custom-select-sm w-100 mb-3 text-info font-weight-bold">
			<option>Autofill with address...</option>
			{autofillAddresses?.map((address, i) => {
				return <option key={i} value={address.Street1}>{address.Street1}, {address.City}, {address.Province}, {address.PostalCode}, {address.Country}</option>
			})}
			{autofillCartAddresses?.map((address, i) => {
				return <option key={i} value={address.Street1}>{address.Street1}, {address.City}, {address.Province}, {address.PostalCode}, {address.Country}</option>
			})}
		</select>
	</>
}