import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import { getPageContentItem, PageContentComponentType, useConfigurationQuery, usePageContentQuery } from "../../api/configuration.api";
import { useEventsQuery, useEventsSearchQuery } from "../../api/events.api"
import DefaultLayout from "../../layouts/default-layout";
import Event from "./components/event";

const Events = () => {
	const search = useLocation().search;
	const params = new URLSearchParams(search);
	const { data: configuration } = useConfigurationQuery();

	var allEvents = params.get("dateFrom") === null || params.get("dateTo") === null;

	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | Explore All Events`;

	return allEvents ? <AllEvents /> : <SearchEvents />
}

export default Events

const AllEvents = () => {
	const { data: events, isLoading } = useEventsQuery();
	const { data: pageContent } = usePageContentQuery();
	
	const eventsHeader = getPageContentItem(pageContent, "Events", null, PageContentComponentType.HeaderImage);
	const headerImageUrl = eventsHeader.Content;

	return <DefaultLayout pageTitle="Explore All Events" headerLargeImageUrl={headerImageUrl} headerSmallImageUrl={headerImageUrl}>
		<div className="row">
			{!isLoading && events?.map((event, index) => {
				return <div key={index} className="col-sm-4 mb-4">
					<Event key={index} event={event} />
				</div>
			})}
		</div>
	</DefaultLayout>
}

const SearchEvents = () => {
	const search = useLocation().search;
	const params = new URLSearchParams(search);
	const { data: pageContent } = usePageContentQuery();
	
	const eventsHeader = getPageContentItem(pageContent, "Events", null, PageContentComponentType.HeaderImage);
	const headerImageUrl = eventsHeader.Content;

	var selectedDateRange = [];
	
	if (params.get("dateFrom") && params.get("dateTo"))
		selectedDateRange = [params.get("dateFrom"), params.get("dateTo")];

	const { data: results, isLoading } = useEventsSearchQuery(selectedDateRange);

	const formatDateRange = () => {
		if (selectedDateRange.length !== 2)
			return '';
		return `Dates: ${moment(selectedDateRange[0]).format('MMM D, YYYY')} - ${moment(selectedDateRange[1]).format('MMM D, YYYY')}`;
	}

	return <DefaultLayout pageTitle="Explore All Events" subTitle={formatDateRange()} headerLargeImageUrl={headerImageUrl} headerSmallImageUrl={headerImageUrl}>
		{!isLoading && results.Events.length === 0 &&
		<h5 className="font-weight-bold">There are no events currently scheduled for the searched date range.</h5>
		}

		{!isLoading && results.Events.length > 0 && <>
			<h5 className="font-weight-bold">{results.Events.length} event{results.Events.length === 1 ? '':'s'} found</h5>
			<div className="row mt-4">
				{results.Events.map((event, index) => {
					return <div key={index} className="col-sm-4 mb-4">
						<Event key={index} event={event} />
					</div>
				})}
			</div>
		</>
		}

		{!isLoading && results.Upcoming && results.Upcoming.length > 0 &&
		<div className="border-top mt-4 pt-4">
			<h3>Upcoming Events</h3>

			<div className="row mt-4">
				{!isLoading && results.Upcoming.map((event, index) => {
					return <div key={index} className="col-sm-4 mb-4">
						<Event key={index} event={event} />
					</div>
				})}
			</div>
		</div>
		}
	</DefaultLayout>
}