import { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Reservation, ReservationType } from "../../api/reservation.models";
import { currency, linkTitle } from "../../common/helpers";
import { useCartState } from "./functions/cart-state";
import { useReservationState } from "../events/functions/reservation-state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { getEventValidationErrors, getReservationValidationErrors, getInventoryValidationErrors, getOtherValidationErrors, isEventInvalid } from "./functions/cart-functions";

export interface CartItemProps {
	reservation: Reservation;
	// metadata: CartStateMetadata;
}

const CartItem = ({ reservation }: CartItemProps) => {
	const history = useHistory();
	const { setCurrentReservation } = useReservationState();
	const { removeFromCart, validationErrors } = useCartState();
	const [showRemovePopover, setShowRemovePopover] = useState(false);

	const formatDateRange = (reservation: Reservation) => {
		return `${moment(reservation.DateFrom).format('MMM D, YYYY')} - ${moment(reservation.DateTo).format('MMM D, YYYY')}`;
	}

	const formatCost = (reservation: Reservation) => {
		const cost = reservation.Metadata?.cost ?? 0;
		return currency.format(cost);
	}

	const handleModify = () => {
		setCurrentReservation(reservation);

		const eventTitle = linkTitle(reservation.Metadata?.eventTitle ?? "");
		const params = new URLSearchParams();
		params.append("dateFrom", moment(reservation.DateFrom).toISOString());
		params.append("dateTo", moment(reservation.DateTo).toISOString());

		history.push(`/events/${eventTitle}/reservation?` + params.toString());
	}

	const handleRemove = () => {
		setShowRemovePopover(false);
		removeFromCart(reservation);
	}

	const removePopover = (
		<Popover id="popover-basic" className="shadow" arrowProps={{ className: 'arrow' }}>
			{/* <Popover.Header as="h3">Confirm</Popover.Header> */}
			<Popover.Body>
				<div className="mb-2">Are you sure you want to remove this reservation from your cart ?</div>
				<div className="row">
					<div className="col-6 pr-1">
						<button onClick={handleRemove} type="button" className="btn btn-danger btn-block font-weight-bold">Remove</button>
					</div>
					<div className="col-6 pl-1">
						<button onClick={() => setShowRemovePopover(false)} type="button" className="btn btn-light btn-block font-weight-bold mb-2">Cancel</button>
					</div>
				</div>
			</Popover.Body>
		</Popover>
	);

	return <>
		<div className="row">
			<div className="col-xs-10 col-md-12 col-lg-10">		
				
				<div className="font-weight-bold">{reservation.Metadata?.eventTitle} - {formatCost(reservation)}</div>
				<div className="text-muted font-weight-bold mb-3"><small>{formatDateRange(reservation)}</small></div>

				{getEventValidationErrors(validationErrors, reservation).map((validationError, index) => {
					return <div key={index} className="text-danger font-weight-bold mb-3">
						<FontAwesomeIcon icon={faExclamationCircle} /> {validationError.Message}
					</div>
				})}

				{getReservationValidationErrors(validationErrors, reservation).map((validationError, index) => {
					return <div key={index} className="text-danger font-weight-bold mb-3">
						<FontAwesomeIcon icon={faExclamationCircle} /> {validationError.Message}
					</div>
				})}
				
				<div className="mb-3">
					<div className="text-muted"><small>{reservation.Metadata?.inventoryType}</small></div>
					<div>
						{reservation.Metadata?.inventoryDescriptions?.map((description, index) => {
							var last = index === (reservation.Metadata?.inventoryDescriptions.length ?? 0) - 1;
							return <span key={index}>{description}{!last && <>, </>}</span>
						})}
					</div>

					{getInventoryValidationErrors(validationErrors, reservation).map((validationError, index) => {
						return <div key={index} className="text-danger font-weight-bold mb-3">
							<FontAwesomeIcon icon={faExclamationCircle} /> {validationError.Message}
						</div>
					})}
				</div>

				{reservation.Metadata?.children.length === 0 &&
				<div className="row">
					<div className="col-12 col-sm-6 mb-3 mb-sm-0">
						<div className="small text-muted">Guest{(reservation.Metadata?.adults?.length ?? 0) === 1 ? '' : 's'}</div>
						{reservation.Metadata.adults.map((adult, index) => {
							var last = index === (reservation.Metadata?.adults.length ?? 0) - 1;
							return <div key={index}>
								{adult}{!last && <br />}
							</div>
						})}
					</div>
					{reservation.Type !== ReservationType.Onsite &&
					<div className="col-12 col-sm-6 mb-3 mb-sm-0">
						<div className="small text-muted">Attendance Type</div>
						{reservation.Type === ReservationType.Offsite && <span>Staying offsite</span>}
						{reservation.Type === ReservationType.Virtual && <span>Virtual</span>}
					</div>
					}
				</div>
				}
				{(reservation.Metadata?.children.length ?? 0) > 0 && 
				<>
					<div className="row">
						<div className="col-12 col-sm-6 mb-3 mb-sm-0">
							<div className="small text-muted">Adult{(reservation.Metadata?.adults?.length ?? 0) === 1 ? '' : 's'}</div>
							{reservation.Metadata?.adults.map((adult, index) => {
								var last = index === (reservation.Metadata?.adults.length ?? 0) - 1;
								return <div key={index}>
									{adult}{!last && <br />}
								</div>
							})}
						</div>
						{reservation.Type !== ReservationType.Onsite &&
						<div className="col-12 col-sm-6 d-none d-sm-block mb-3 mb-sm-0">
							<div className="small text-muted">Attendance Type</div>
							{reservation.Type === ReservationType.Offsite && <span>Staying offsite</span>}
							{reservation.Type === ReservationType.Virtual && <span>Virtual</span>}
						</div>
						}
					</div>
					<div className="row">
						<div className="col-12 col-sm-6">
							<div className="small text-muted">Child{(reservation.Metadata?.children?.length ?? 0) === 1 ? '' : 'ren'}</div>
							{reservation.Metadata?.children.map((child, index) => {
								var last = index === (reservation.Metadata?.children.length ?? 0) - 1;
								return <div key={index}>
									{child}{!last && <br />}
								</div>
							})}
						</div>
					</div>

					{reservation.Requests && 
					<div className="mt-3">
						<div className="small text-muted">Special Requests</div>
						{reservation.Requests}
					</div>
					}

					{getOtherValidationErrors(validationErrors, reservation).map((validationError, index) => {
						return <div key={index} className="text-danger font-weight-bold mb-3">
							<FontAwesomeIcon icon={faExclamationCircle} /> {validationError.Message}
						</div>
					})}
				</>
				}
				
			</div>

			<div className="col-lg-2 col-sm-12">
				<div className="row">
					<div className="col-6 col-xs-12 col-md-6 col-lg-12">
						<button onClick={handleModify} disabled={isEventInvalid(validationErrors, reservation)} className="btn btn-block btn-link btn-sm-x font-weight-bold pl-0 pr-0">Modify</button>
					</div>
					<div className="col-6 col-xs-12 col-md-6 col-lg-12">
						<OverlayTrigger show={showRemovePopover} trigger="click" placement="top" overlay={removePopover}>
							<button onClick={() => setShowRemovePopover(true)} className="btn btn-block btn-link btn-sm-x font-weight-bold pl-0 pr-0">Remove</button>
						</OverlayTrigger>
					</div>
				</div>
			</div>
		</div>
	</>
}

export default CartItem