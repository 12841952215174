import { useEffect } from "react"
import { Link } from "react-router-dom"
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import DefaultLayout from "../../layouts/default-layout"
import { useEventsQuery } from "../../api/events.api"
import { useProgramGroupsQuery, useProgramsQuery } from "../../api/programs.api"
import { getPageContentItem, PageContentComponentType, useConfigurationQuery, usePageContentQuery } from "../../api/configuration.api"
import { useCartState } from "./functions/cart-state"
import CartItem from "./cart-item"
import CartSummary from "./cart-summary"
import CartItemProgramRegistration from "./cart-item-program-registration"
import { linkTitle } from "../../common/helpers"

const Cart = ({}) => {
	const { cart, isCartEmpty, validateCart } = useCartState();
	const { data: events } = useEventsQuery(true);
	const { data: programs } = useProgramsQuery();
	const { data: programGroups } = useProgramGroupsQuery();
	const { data: pageContent } = usePageContentQuery();
	const { data: configuration } = useConfigurationQuery();
	
	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | Cart`;
	const cartHeader = getPageContentItem(pageContent, "Cart", null, PageContentComponentType.HeaderImage);
	
	var headerLargeImageUrl = cartHeader.Content;
	var headerSmallImageUrl = cartHeader.Content;
	if ((cart.Reservations.length ?? 0) > 0) {
		let eventId = cart.Reservations[0].EventId;
		let event = events?.find(e => e.Id === eventId);
		if (event) {
			headerLargeImageUrl = event.ImageURL;
			headerSmallImageUrl = event.ImageURLSmall;
		}
	} else if ((cart.ProgramRegistrations?.length ?? 0) > 0) {
		let programId = cart.ProgramRegistrations[0].ProgramId;
		let program = programs?.find(p => p.Id === programId);
		if (program) {
			headerLargeImageUrl = program.ImageURL;
			headerSmallImageUrl = program.ImageURL;
		}
	}

	const showProgramActivityReminder = (cart.ProgramRegistrations?.length ?? 0) > 0 ||
		cart.Reservations.filter(r => r.Metadata?.eventTitle.toLowerCase().includes("summer") ?? false).length > 0;

	useEffect(() => {
		validateCart();
	}, [])

	return <>
		<DefaultLayout pageTitle="Your Reservations" subTitle="Please review your reservations before checkout" headerLargeImageUrl={headerLargeImageUrl} headerSmallImageUrl={headerSmallImageUrl}>

			<div className="row">
				<div className="col-12 col-md-8">
					<div className="card shadow mb-3 guest-information">
						<h5 className="card-header"><FontAwesomeIcon icon={faShoppingCart} className="text-muted mr-2" />Cart</h5>
						<div className="card-body">
							{cart.Reservations?.map((reservation, index) => {
								var last = index === (cart.Reservations.length ?? 0)-1;
								return <div key={index}>
									<CartItem reservation={reservation} />
									{!last && <hr />}
								</div>
							})}

							{(cart.Reservations?.length ?? 0) > 0 && (cart.ProgramRegistrations?.length ?? 0) > 0 && <hr />}

							{cart.ProgramRegistrations?.map((registration, index) => {
								var last = index === (cart.ProgramRegistrations.length ?? 0)-1;
								return <div key={index}>
									<CartItemProgramRegistration registration={registration} />
									{!last && <hr />}
								</div>
							})}

							{showProgramActivityReminder && <>
								<hr />
								
								<h5 className="font-weight-bold mb-3">You can also book the following programs or activities.</h5>
								{programGroups?.map((programGroup, index) => {
									return <div key={index} className="mb-2">
										<Link className="font-weight-bold" to={`/programs/${linkTitle(programGroup.Title)}`}>{programGroup.Title}</Link>
									</div>
								})}
							</>}

							{isCartEmpty() &&
								<>
									<p>There are no reservations in your cart.</p>
									<p>
										<Link to="/events">Explore Events</Link>
										<span> | </span>
										<Link to="/programs">Explore Programs &amp; Activities</Link>
									</p>
								</>
							}
						</div>
					</div>
				</div>

				<div className="col-12 col-md-4">
					<CartSummary />				
				</div>
			</div>

		</DefaultLayout>
	</>
}

export default Cart