import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import classNames from "classnames";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js/types';
import { faCalendarTimes, faCheck, faCircleInfo, faCircleNotch, faClipboardCheck, faCreditCard, faDollarSign, faLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useEventsQuery } from "../../api/events.api"
import { useProgramsQuery } from "../../api/programs.api";
import { getPageContentItem, PageContentComponentType, useConfigurationQuery, usePageContentQuery } from "../../api/configuration.api";
import { useCartState } from "./functions/cart-state"
import { useAuthState } from "../../state/auth.state";
import { checkout, createStripePaymentMethod, CreateStripePaymentMethodResult } from "./functions/cart-functions";
import { CartCheckoutResult, CartCheckoutResultErrorType, PaymentMethod } from "../../api/cart.models"
import { currency } from "../../common/helpers"
import DefaultLayout from "../../layouts/default-layout"
import CartSummary from "./cart-summary"
import CheckoutBilling from "./checkout-billing"
import "./checkout.css"

const Checkout = ({}) => {
	const history = useHistory();
	const stripe = useStripe();
	const elements = useElements();
	const queryClient = useQueryClient();
	const { userInfo } = useAuthState();
	const { cart, updateCart, resetCart, getCartCost, getCartDeposit, getFullPaymentRequired, getNoPaymentRequired, validateCart } = useCartState();
	const { data: events } = useEventsQuery(true);
	const { data: programs } = useProgramsQuery();
	const { data: configuration } = useConfigurationQuery();
	const { data: pageContent } = usePageContentQuery();

	const [cardError, setCardError] = useState("");
	const [cardComplete, setCardComplete] = useState(false);
	const [formValid, setFormValid] = useState(false);
	const [agreement, setAgreement] = useState(false);
	const [processingCheckout, setProcessingCheckout] = useState(false);
	const [cartCheckoutResult, setCartCheckoutResult] = useState<CartCheckoutResult>();

	let totalCost = getCartCost();
	let totalDeposit = getCartDeposit();
	let fullPaymentRequired = getFullPaymentRequired();
	let noPaymentRequired = getNoPaymentRequired();
	let programRegistrationsOnly = false;

	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | Checkout`;
	const checkoutHeader = getPageContentItem(pageContent, "Checkout", null, PageContentComponentType.HeaderImage);
	
	const agreementContent = getPageContentItem(pageContent, "Checkout", "Agreement", PageContentComponentType.HTML);
	const agreementHtml = () => { return { __html: agreementContent?.Content ?? "" }; };
	const showAgreement = agreementContent !== undefined && agreementContent.Content !== "";

	const cancellationMessage = getPageContentItem(pageContent, "Checkout", "Cancellation", PageContentComponentType.HTML);// pageContent?.find(c => c.Page === "Checkout" && c.Item === "Cancellation" && c.ComponentType === PageContentComponentType.HTML);
	const cancellationHtml = () => { return { __html: cancellationMessage?.Content ?? "" }; };

	var headerLargeImageUrl = checkoutHeader.Content;
	var headerSmallImageUrl = checkoutHeader.Content;
	if ((cart.Reservations.length ?? 0) > 0) {
		let eventId = cart.Reservations[0].EventId;
		let event = events?.find(e => e.Id === eventId);
		if (event) {
			headerLargeImageUrl = event.ImageURL;
			headerSmallImageUrl = event.ImageURLSmall;
		}
	} else if ((cart.ProgramRegistrations.length ?? 0) > 0) {
		let programId = cart.ProgramRegistrations[0].ProgramId;
		let program = programs?.find(p => p.Id === programId);
		if (program) {
			headerLargeImageUrl = program.ImageURL;
			headerSmallImageUrl = program.ImageURL;
		}

		if ((cart.Reservations?.length ?? 0) === 0) {
			programRegistrationsOnly = true;
		}
	}

	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(cart.PaymentMethod);

	useEffect(() => {
		if (cart.Reservations.length === 0 && cart.ProgramRegistrations.length === 0) {
			history.push({ pathname: '/cart' });
			return;
		}

		const validate = async () => {
			let validationErrors = await validateCart();
			if (validationErrors.length > 0) {
				history.push({ pathname: '/cart' });
				return;
			}
		}
		validate();

		if (noPaymentRequired === true || totalCost === 0) {
			cart.PaymentMethod = PaymentMethod.None;
			updateCart(cart);
		} else if (fullPaymentRequired === true) {
			setPaymentMethod(PaymentMethod.FullAmountOnline);
			cart.PaymentMethod = PaymentMethod.FullAmountOnline;
			updateCart(cart);
		}
	}, [])

	const handlePaymentMethodChanged = (value: PaymentMethod) => {
		setPaymentMethod(value);
		cart.PaymentMethod = value;
		updateCart(cart);
	}

	const handleStripeCardChange = (event: StripeCardElementChangeEvent) => {
		setCardError(event.error?.message ?? "");
		setCardComplete(event.complete);
	}

	const handleSubmit = async () => {
		if (stripe === undefined || stripe === null || elements === undefined || elements === null)
			return;

		if (processingCheckout === true)
			return;
			
		setProcessingCheckout(true);
		
		try {
			let validationErrors = await validateCart();
			if (validationErrors.length > 0) {
				history.push({ pathname: '/cart' });
				return;
			}
		} catch (error) {
			setProcessingCheckout(false);
			setCartCheckoutResult({ 
				Success: false, 
				ErrorType: CartCheckoutResultErrorType.API,
				ErrorMessage: "An unknown error occurred. Please reload the page and try again."
			});
			return;
		}

		let createPaymentMethodResult: CreateStripePaymentMethodResult = {
			Success: true,
			PaymentMethod: null
		};
		
		if (noPaymentRequired === false)
			createPaymentMethodResult = await createStripePaymentMethod(cart.BillingContact, stripe, elements);

		if (createPaymentMethodResult.Success === false) {
			setCartCheckoutResult({ 
				Success: false, 
				ErrorType: createPaymentMethodResult.ErrorType,
				ErrorMessage: createPaymentMethodResult.ErrorMessage
			});
			setProcessingCheckout(false);
			return;
		}

		let result = await checkout(cart, createPaymentMethodResult.PaymentMethod);
		
		setProcessingCheckout(false);
		setCartCheckoutResult(result);

		if (result.Success === true) {
			resetCart();
			queryClient.invalidateQueries(['user-people', userInfo?.email]);
			queryClient.invalidateQueries(['user-addresses', userInfo?.email]);

			var redirectParam = "";
			if (programRegistrationsOnly === true) 
				redirectParam = new URLSearchParams({ redirect:  'registrations' }).toString();

			history.push({ pathname: '/cart/checkout/success', search: redirectParam });
		}
	}

	return <>
		<DefaultLayout pageTitle="Checkout" subTitle="Complete your checkout" headerLargeImageUrl={headerLargeImageUrl} headerSmallImageUrl={headerSmallImageUrl}>

			<div className="row">
				<div className="col-12 col-md-8">
					<div className="card shadow mb-3 guest-information">
						<h5 className="card-header"><FontAwesomeIcon icon={noPaymentRequired ? faCircleInfo : faDollarSign} className="text-muted mr-2" />
							{noPaymentRequired && <>
							Contact
							</>}
							{!noPaymentRequired && <>
							Billing &amp; Payment
							</>}
						</h5>
						<div className="card-body">
							<CheckoutBilling setFormValid={setFormValid} noPaymentRequired={noPaymentRequired} />

							{!noPaymentRequired && <hr />}
					
							{fullPaymentRequired && !noPaymentRequired &&
							<p className="lead font-weight-bold mb-2">Payment Information</p>
							}
							{!fullPaymentRequired && !noPaymentRequired && <>
							<p className="lead font-weight-bold mb-2">Payment Options</p>
							<p>Select a payment option:</p>
							</>}

							{/* {noPaymentRequired && <div className="row">
								<div className="col-12 mt-2">
									<p className="mb-0">No payment is required to complete your checkout.</p>
								</div>
							</div>} */}

							{!noPaymentRequired &&
							<div className="row">
								<div className="col-12">
									{fullPaymentRequired && totalCost > 0 && <>
										<div className="col-12 mt-2 p-0">
											<p className="font-weight-bold mb-0">You will be charged: {currency.format(totalCost * 1.13)} via secure online payment.</p>
										</div>
									</>}

									{fullPaymentRequired && totalCost === 0 && <>
										<div className="col-12 mt-2 p-0">
											<p className="font-weight-bold mb-0">You will not be charged right now, but your payment details will be securely stored, per the registration policy that you have agreed to for one or more of the items in your cart.</p>
										</div>
									</>}

									{!fullPaymentRequired && 
										<p className="font-weight-bold mb-1"><FontAwesomeIcon icon={faLock} /> Secure Online Payment</p>
									}

									{!fullPaymentRequired && 
									<div className="row">
										<div className="col-12 col-sm-6">
											<div className="custom-control custom-radio">
												<input className="custom-control-input" type="radio" name="paymentMethod" id="paymentMethod1" value={PaymentMethod.DepositOnline} checked={paymentMethod === PaymentMethod.DepositOnline} onChange={(e) => { handlePaymentMethodChanged(parseInt(e.target.value))}} />
												<label className="custom-control-label" htmlFor="paymentMethod1">
													Pay {currency.format(totalDeposit)} deposit now
												</label>
											</div>
										</div>

										<div className="col-12 col-sm-6">
											<div className="custom-control custom-radio">
												<input className="custom-control-input" type="radio" name="paymentMethod" id="paymentMethod2" value={PaymentMethod.FullAmountOnline} checked={paymentMethod === PaymentMethod.FullAmountOnline} onChange={(e) => { handlePaymentMethodChanged(parseInt(e.target.value))}} />
												<label className="custom-control-label" htmlFor="paymentMethod2">
													Pay {currency.format(totalCost * 1.13)} full amount now
												</label>
											</div>
										</div>
									</div>
									}

									<div className="row">
										<div className="col-12">
											<hr className="mb-sm-0" />
										</div>

										<div className="col-12">
											<div className="mt-sm-4">
												<p className="font-weight-bold mb-3"><FontAwesomeIcon icon={faCreditCard} className="mr-2" />Credit Card Information</p>
												
												{/* <PaymentElement /> */}
												<CardElement className="StripeElement" onChange={handleStripeCardChange} options={{ disableLink: true }} />
												{cardError !== "" && 
												<div role="alert" className="text-danger font-weight-bold mb-2">{cardError}</div>
												}
												<label className="small"><FontAwesomeIcon icon={faLock} className="mr-2" />Credit card processing is powered by <a href="https://www.stripe.com" target="_blank" rel="noreferrer">Stripe</a></label>
											</div>
										</div>
									</div>
								</div>

							</div>
							}
						</div>
					</div>

					{cancellationMessage !== undefined && cancellationMessage.Content !== "" &&
					<div className="card shadow mb-3">
						<h5 className="card-header"><FontAwesomeIcon icon={faCalendarTimes} className="mr-2 text-muted" />Cancellation Policy</h5>
						<div className="card-body" dangerouslySetInnerHTML={cancellationHtml()}></div>
					</div>
					}

					<div className="card shadow mb-3">
						<h5 className="card-header"><FontAwesomeIcon icon={faClipboardCheck} className="mr-2 text-muted" />
							{showAgreement && <>Agreement &amp; </>}
							Complete Checkout
						</h5>
						<div className="card-body">
							{showAgreement && <>
							<div dangerouslySetInnerHTML={agreementHtml()}></div>							

							<div className="custom-control custom-checkbox custom-control-inline mr-2 mb-3">
								<input type="checkbox" checked={agreement}  onChange={(e) => { setAgreement(e.target.checked)}} className="custom-control-input" id="agree" />
								<label className="custom-control-label" htmlFor="agree">I confirm agreement to the above statements</label>
							</div>

							<hr className="mb-4" />
							</>}
							
							{(!formValid || !cardComplete) && !noPaymentRequired && <>
								<h5 className="text-danger font-weight-bold">Missing Information</h5>
							</>}
							{!formValid && 
							<div className="text-danger font-weight-bold mb-3">
								Please provide information for all required fields above in order to complete the checkout.
							</div>
							}
							{!cardComplete && !noPaymentRequired &&
							<div className="text-danger font-weight-bold mb-3">
								Please complete your payment information.
							</div>
							}
							
							<button type="submit" onClick={handleSubmit} disabled={!formValid || (!cardComplete && !noPaymentRequired) || (!agreement && showAgreement) || processingCheckout} className={classNames(["btn btn-primary font-weight-bold d-block", !formValid || (!cardComplete && !noPaymentRequired) || (!agreement && showAgreement) ? "btn-secondary" : "" ])}>
								{processingCheckout === false && 
								<span><FontAwesomeIcon icon={faCheck} className="mr-2" />Complete Checkout</span>
								}
								{processingCheckout === true && 
								<span><FontAwesomeIcon icon={faCircleNotch} spin className="mr-2" />Processing Checkout...</span>
								}
							</button>

							{cartCheckoutResult !== undefined && cartCheckoutResult.Success === false &&
							<div className="alert alert-danger mt-4 mb-0">
								<p className="m-0 mb-2 font-weight-bold">{cartCheckoutResult.ErrorMessage}</p>
								{cartCheckoutResult.ValidationErrors?.map((validationError, index) => {
									return <p key={index}>{validationError.Message}</p>
								})}
							</div>
							}
						</div>
					</div>
				</div>

				<div className="col-12 col-md-4">
					<CartSummary showCheckout={false} />
				</div>
			</div>

		</DefaultLayout>
	</>
}

export default Checkout