import * as Yup from 'yup';

export interface RegisterModel {
	name: string;
	email: string;
	password: string;
	recaptcha: string;
}

export const RegisterSchema = Yup.object().shape({
	name: Yup.string()
		.max(50, 'Too Long!')
		.required('Required'),
	email: Yup.string().email('Invalid email')
		.max(50, 'Too Long!')
		.required('Required'),
	password: Yup.string()
		.min(4, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required')
});

export interface LoginModel {
	username: string;
	password: string;
	recaptcha: string;
}

export const LoginSchema = Yup.object().shape({
	username: Yup.string().email('Invalid email')
		.max(50, 'Too Long!')
		.required('Required'),
	password: Yup.string()
		.min(4, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required')
});


export interface LoginResponseModel {
	name: string;
	email: string;
	expiry: number;
	// expires_at: number;
	// access_token: string;
}

export interface RegisterResponseModel {
	name: string;
	email: string;
	expiry: number;
	// expires_at: number;
	// access_token: string;
}

export const PasswordResetSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email')
		.max(50, 'Too Long!')
		.required('Required')
});

export interface PasswordResetModel {
	email: string;
	recaptcha: string;
}

export interface PasswordResetResponseModel {
	Success: boolean;
	Error?: string;
}

export interface NewPasswordResponse {
	Success: boolean;
	Error?: string;
}

export const UpdateEmailSchema = Yup.object().shape({
	email: Yup.string().email('The email address is not valid')
		.max(50, 'Too Long!')
		.required('Required')
});

export interface UpdateEmailModel {
	email: string;
}

export interface UpdateEmailResponse {
	Success: boolean;
	Error?: {
		statusCode: number,
		error: string,
    	message: string
	};
}

export const UpdatePasswordSchema = Yup.object().shape({
	currentPassword: Yup.string()
		.max(50, 'Too Long!')
		.when('newPassword', { is: undefined, otherwise: (schema) => schema.required('Required') }),
	newPassword: Yup.string()
		.max(50, 'Too Long!')
		.when('currentPassword', { is: undefined, otherwise: (schema) => schema.required('Required') })
}, [['currentPassword', 'newPassword']]);

export interface UpdatePasswordModel {
	currentPassword: string;
	newPassword: string;
}

export interface UpdatePasswordResponse {
	success: boolean;
	error?: string;
}

export interface AccountInfo {
	Name: string;
	Email: string;
}

export const NewPasswordSchema = Yup.object().shape({
	password: Yup.string()
		.max(50, 'Too Long!')
		.required('Required')
});

export interface NewPasswordModel {
	password: string;
	key: string;
	recaptcha: string;
}