import { Switch, Route, useRouteMatch } from "react-router-dom";
import ProgramDetails from "./program-details-new";
import ProgramRegistration from "./program-registration";
import Programs from "./programs";

const ProgramRoutes = () => {
	let { path } = useRouteMatch();

	return <>
		<Switch>
			<Route path={path} exact component={Programs} />
			<Route path={`${path}/:programGroup`} exact component={Programs} />
			<Route path={`${path}/:programGroup/:program`} exact component={ProgramDetails} />
			<Route path={`${path}/:programGroup/:program/registration`} exact component={ProgramRegistration} />
		</Switch>
	</>
}

export default ProgramRoutes