import { hookstate, State, useHookstate } from "@hookstate/core";

export interface AuthUIState {
	isLoginOpen: boolean,
	isLogoutOpen: boolean,
	isLoggedOutOpen: boolean,
	isRegisterOpen: boolean,
	isResetPasswordOpen: boolean
}

const AUTHUI_STATE = hookstate<AuthUIState>({ 
	isLoginOpen: false,
	isLogoutOpen: false,
	isLoggedOutOpen: false,
	isRegisterOpen: false,
	isResetPasswordOpen: false
});

export const useAuthUIState = () => {
	let state = useHookstate<AuthUIState>(AUTHUI_STATE)

	return { 
		isLoginOpen: state.isLoginOpen.value,
		showLogin: () => showLogin(state),
		hideLogin: () => showLogin(state, true),
		isLogoutOpen: state.isLogoutOpen.value,
		showLogout: () => showLogout(state),
		hideLogout: () => showLogout(state, true),
		isLoggedOutOpen: state.isLoggedOutOpen.value,
		showLoggedOut: () => showLoggedOut(state),
		hideLoggedOut: () => showLoggedOut(state, true),
		isRegisterOpen: state.isRegisterOpen.value,
		showRegister: () => showRegister(state),
		hideRegister: () => showRegister(state, true),
		isResetPasswordOpen: state.isResetPasswordOpen.value,
		showResetPassword: () => showResetPassword(state),
		hideResetPassword: () => showResetPassword(state, true)
	};
}

const reset = (state: State<AuthUIState>) => {
	state.set({
		isLoginOpen: false,
		isLogoutOpen: false,
		isLoggedOutOpen: false,
		isRegisterOpen: false,
		isResetPasswordOpen: false
	});
}

const showLogin = (state: State<AuthUIState>, hide: boolean = false) => {
	reset(state);
	state.isLoginOpen.set(!hide);
}

const showLogout = (state: State<AuthUIState>, hide: boolean = false) => {
	reset(state); 
	state.isLogoutOpen.set(!hide);
}

const showLoggedOut = (state: State<AuthUIState>, hide: boolean = false) => {
	reset(state);
	state.isLoggedOutOpen.set(!hide);
}

const showRegister = (state: State<AuthUIState>, hide: boolean = false) => {
	reset(state); 
	state.isRegisterOpen.set(!hide);
}

const showResetPassword = (state: State<AuthUIState>, hide: boolean = false) => {
	reset(state); 
	state.isResetPasswordOpen.set(!hide);
}