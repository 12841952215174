import { faCalendarCheck, faCheckCircle, faImages, faTasks, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { InventoryItem, InventoryProperty } from "../../../api/inventory.models";
import { useInventoryPropertiesQuery } from '../../../api/inventory.api';
import { getInventoryPropertyValue } from '../../events/functions/inventory-functions';
import MediaGallery from '../../../common/components/MediaGallery';
import "./explore-inventory.css"
import InventoryCalendar from './inventory-calendar';
import moment from 'moment-timezone';

interface ExploreInventoryItemProps {
	inventoryItem: InventoryItem;
	selectedDateRange?: { dateFrom: Date, dateTo: Date },
	onChangeDates?: () => void;
}

const ExploreInventoryItem = ({ inventoryItem, selectedDateRange, onChangeDates }: ExploreInventoryItemProps) => {
	const { data: inventoryProperties } = useInventoryPropertiesQuery(inventoryItem.InventoryTypeId);

	const isPropertyMuted = (inventoryProperty: InventoryProperty) => {
		return getInventoryPropertyValue(inventoryItem, inventoryProperty) === '' || 
			getInventoryPropertyValue(inventoryItem, inventoryProperty) === 'N/A'
	}

	const getAvailabilityMessage = (inventoryItem: InventoryItem, dateFrom: Date, dateTo: Date) : string => {
		if (inventoryItem === undefined)
			return "";

		if (inventoryItem.InventoryPolicy != null && inventoryItem.InventoryPolicy.DatesValid === false)
			return `${inventoryItem.Description} can't be reserved for ${moment(dateFrom).format("dddd, MMMM D")} - ${moment(dateTo).format("dddd, MMMM D, YYYY")}`;

		return `${inventoryItem.Description} is ${inventoryItem.Available ? 'available' : 'unavailable'} for ${moment(dateFrom).format("dddd, MMMM D")} - ${moment(dateTo).format("dddd, MMMM D, YYYY")}`;
	}

	return <div className="explore-inventory">
		<Tabs variant="pills" defaultActiveKey="availability">
			<Tab eventKey="availability" title={<><FontAwesomeIcon icon={faCalendarCheck} /> Availability</>} mountOnEnter={true}>
				<div className="mt-4 text-center">
					<h3>Availability for {inventoryItem.Metadata?.Title ?? inventoryItem.Description}</h3>

					{selectedDateRange &&
					<div className="mb-2 text-center">
						{inventoryItem.Available && <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-1" />}
						{!inventoryItem.Available && <FontAwesomeIcon icon={faTimesCircle} className="text-danger mr-1" />}
						<span className={classNames(["font-weight-bold", inventoryItem.Available ? "text-success":"", !inventoryItem.Available ? "text-danger":""])}>
							{getAvailabilityMessage(inventoryItem, selectedDateRange.dateFrom, selectedDateRange.dateTo)}
						</span>
						{/* <a onClick={() => {}} className="ml-3 text-primary">Change Dates</a> */}
						<button onClick={onChangeDates} className="btn btn-link p-0 pl-3">Change Dates</button>
						
						{inventoryItem.InventoryPolicy !== undefined && !inventoryItem.InventoryPolicy.DatesValid &&
						<div className="mt-1 font-weight-bold">
							{inventoryItem.InventoryPolicy.Message}
						</div>
						}
					</div>
					}

					<InventoryCalendar inventoryItem={inventoryItem} selectedRange={{ From: selectedDateRange?.dateFrom ?? moment().toDate(), To: selectedDateRange?.dateTo ?? moment().toDate() }} />
				</div>
			</Tab>
			<Tab eventKey="photos" title={<><FontAwesomeIcon icon={faImages} /> Photos</>} mountOnEnter={true}>
				<div className="mt-4 mb-4 text-center">
					<h3>Photos for {inventoryItem.Metadata?.Title ?? inventoryItem.Description}</h3>
				</div>
				<MediaGallery galleryId={inventoryItem.Metadata?.MediaGalleryId ?? 0} open={true} />
			</Tab>
			<Tab eventKey="amenities" title={<><FontAwesomeIcon icon={faTasks} /> Amenities</>} mountOnEnter={true}>
				<div className="mt-4 mb-4 text-center">
					<h3>Amenities for {inventoryItem.Metadata?.Title ?? inventoryItem.Description}</h3>
				</div>

				<div className="row mb-3">
					{inventoryProperties?.map((inventoryProperty, index) => {
						return <div className="col-12 col-sm-6" key={index}>
							<div className="row">
								<div className={classNames(["col-6 text-right", isPropertyMuted(inventoryProperty) ? "text-muted no-inventory-property-value":""])}>{inventoryProperty.Name}</div>
								<div className={classNames(["col-6", isPropertyMuted(inventoryProperty) ? "text-muted no-inventory-property-value":""])}>{getInventoryPropertyValue(inventoryItem, inventoryProperty)}</div>
							</div>
						</div>
					})}
				</div>
			</Tab>
		</Tabs>
		
	</div>
}

export default ExploreInventoryItem