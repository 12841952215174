import { ProgramRegistrationPageContent, ProgramRegistrationPageContentType } from "../../../api/program.registration.models";
import { useProgramRegistrationPageContentQuery } from "../../../api/programregistration.api";
import { useProgramState } from "../functions/program-state";

interface ProgramRegistrationPageContentProps {
	type: ProgramRegistrationPageContentType;
}

const RegistrationPageContent = ({ type }: ProgramRegistrationPageContentProps) => {
	const { program } = useProgramState();
	const { data: pageContent } = useProgramRegistrationPageContentQuery(program?.Id ?? 0);

	function createMarkup(content: ProgramRegistrationPageContent) { return { __html: content.Content }; };

	return <>
		{pageContent?.map((content, index) => {
			return content.ComponentType === type ? <div key={index} className="mb-3" dangerouslySetInnerHTML={createMarkup(content)}></div> : <div key={index}></div>
		})}
	</>
}

export default RegistrationPageContent