import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import styles from "./breadcrumb.module.css";
import classNames from "classnames";

function BreadcrumpStep({ title, active, complete }) {
	return <li className={classNames(styles.item, "breadcrumb-item", active ? '' : 'active')}>
		{active && <FontAwesomeIcon icon={faArrowCircleRight} className="mr-1 mt-1" />}
		{complete && <FontAwesomeIcon icon={faCheck} className="mr-1 mt-1" />}
		{title}
	</li>
}

function Breadcrumb({ step }) {
	return <>
		<div>
			<nav className={classNames(styles.nav, 'float-sm-right-x mb-3')} aria-label="breadcrumb">
				<ol className="breadcrumb bg-transparent p-0">
					<BreadcrumpStep title="Program" active={step === 1} complete={step > 1} />
					<BreadcrumpStep title="Selection" active={step === 2} complete={step > 2} />
					<BreadcrumpStep title="Registration" active={step === 3} complete={step > 3} />
					<BreadcrumpStep title="Checkout" active={step === 4} complete={step > 4} />
				</ol>
			</nav>
		</div>
	</>
}

Breadcrumb.defaultProps = { step: 1 }

export default Breadcrumb