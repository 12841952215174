import { Dispatch, SetStateAction, useState } from "react";
import moment from "moment-timezone";
import _ from "lodash";
import { Formik, ErrorMessage, Field, Form } from 'formik';
import classnames from "classnames";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useUserPeopleDeleteMutation, useUserPeopleSaveMutation, useUserPeopleQuery } from "../../api/user.api";
import { UserPerson } from "../../api/user.models";
import { DateInputField } from "../../common/components/DateInputField";
import { PhoneInputField } from "../../common/components/PhoneInputField";
import { createEmtpyAdult, createEmptyChild, PersonSchema } from "./functions/people-functions";
import { toastError, toastSuccess } from "../../common/components/toast";

const AccountPeople = () => {
	const { data: userPeople } = useUserPeopleQuery();
	const [editingPerson, setEditingPerson] = useState<UserPerson|undefined>();
	
	const adults = userPeople === undefined ? [] : userPeople.filter(p => p.Person.Birthdate === null);
	const children = userPeople === undefined ? [] : userPeople.filter(p => p.Person.Birthdate !== null);

	const getAge = (userPerson: UserPerson) : string|number|null => {
		if (userPerson.Person == null || userPerson.Person.Birthdate == null)
			return null;

		var age: string|number = moment().diff(moment(userPerson.Person.Birthdate), 'years');
		if (age < 2)
			return `${moment().diff(moment(userPerson.Person.Birthdate), 'months')} months`;
		return age < 19 ? age : null;
	}

	const addPerson = (adult: boolean) => {
		var userPerson: UserPerson = {
			Id: 0,
			DefaultBilling: false,
			Person: adult ? createEmtpyAdult() : createEmptyChild()
		}

		setEditingPerson(userPerson);
	}

	const editPerson = (person: UserPerson) => {
		setEditingPerson(_.cloneDeep(person));
	}

	const adultList = adults.map((adult, adultIndex) => {
		return <div key={adultIndex}>
			<a onClick={(e) => { editPerson(adult); e.preventDefault(); }} href="">{adult.Person.FirstName} {adult.Person.LastName}
				{getAge(adult) !== null && <span> ({getAge(adult)})</span>}
			</a>
			{adult.DefaultBilling && <span> (default billing)</span>}
		</div>
	})

	const childList = children.map((child, childIndex) => {
		return <div key={childIndex}>
			<a onClick={(e) => { editPerson(child); e.preventDefault(); }} href="">{child.Person.FirstName} {child.Person.LastName}
				{getAge(child) !== null && <span> ({getAge(child)})</span>}
			</a>
		</div>
	})

	return <>
		<h4 className="font-weight-bold">Saved People</h4>
		{(userPeople && userPeople.length > 0) ?
			<p className="text-muted">Click on an person to edit or remove them.</p> :
			<p className="text-muted">Click the Add Adult or Add Child button to enter a new adult or child.</p>
		}
		
		<div className="font-weight-bold">Adults</div>
		{adults.length > 0 ? adultList : <div className="text-muted">No recent adults</div>}

		<div className="font-weight-bold mt-3">Children</div>
		{children.length > 0 ? childList : <div className="text-muted">No recent children</div>}

		{editingPerson && <EditPerson person={editingPerson} setEditingPerson={setEditingPerson} />}

		{editingPerson === undefined &&
		<div className="form-row">
			<div className="col-12 col-sm-3">
				<button onClick={() => addPerson(true)} className="btn btn-primary font-weight-bold btn-block font-weight-bold mt-3">Add Adult</button>
			</div>
			<div className="col-12 col-sm-3">
				<button onClick={() => addPerson(false)} className="btn btn-primary font-weight-bold btn-block font-weight-bold mt-3">Add Child</button>
			</div>
		</div>
		}
	</>
}

export default AccountPeople

interface EditPersonProps {
	person: UserPerson;
	setEditingPerson: Dispatch<SetStateAction<UserPerson | undefined>>;
}

export const EditPerson = ({ person, setEditingPerson }: EditPersonProps) => {
	const { mutate: savePerson } = useUserPeopleSaveMutation();
	const { mutate: deletePerson } = useUserPeopleDeleteMutation();
	const [showRemovePopover, setShowRemovePopover] = useState(false);

	const getAge = (userPerson: UserPerson) => {
		if (userPerson.Person == null || userPerson.Person.Birthdate == null)
			return 19;

		var age = moment().diff(moment(userPerson.Person.Birthdate), 'years');
		return age;
	}

	const isAdult = person.Person.Birthdate === undefined || getAge(person) >= 19;
	const isChild = person.Person.Birthdate !== undefined && getAge(person) < 19;
	const salutations = ["", "Mr", "Mrs", "Ms", "Dr", "Rev"];

	const handleSave = (values: UserPerson) => {
		savePerson(values, { 
			onSuccess: () => {
				toastSuccess('Your changes have been saved.');
			},
			onError: (error) => {
				toastError(null, 'Your changes were not saved. Please reload the page and try again.');
			},
			onSettled: () => {
				setEditingPerson(undefined);
			}
		})
	}

	const handleCancel = () => {
		setEditingPerson(undefined);
	}

	const handleDelete = () => {
		setShowRemovePopover(false);
		deletePerson(person, {
			onSuccess: () => {
				setEditingPerson(undefined);
			}
		})
	}

	const removePopover = (
		<Popover id="popover-basic" className="shadow" arrowProps={{ className: 'arrow' }}>
			{/* <Popover.Header as="h3">Confirm</Popover.Header> */}
			<Popover.Body>
				<div className="mb-2">Are you sure you want to remove this person ?</div>
				<div className="row">
					<div className="col-6 pr-1">
						<button onClick={handleDelete} type="button" className="btn btn-danger btn-block font-weight-bold">Remove</button>
					</div>
					<div className="col-6 pl-1">
						<button onClick={() => setShowRemovePopover(false)} type="button" className="btn btn-light btn-block font-weight-bold mb-2">Cancel</button>
					</div>
				</div>
			</Popover.Body>
		</Popover>
	);

	return <>
		<hr />
		<h5 className="font-weight-bold">{person.Id === 0 ? "New" : "Edit"} {isAdult ? "Adult" : "Child"}</h5>

		<Formik
			validationSchema={PersonSchema}
			enableReinitialize={true}
			initialValues={person}
			onSubmit={(values, { setSubmitting }) => {
				handleSave(values);
			}}
			validateOnBlur={true}
		>
		{({ handleSubmit, isSubmitting, submitCount, isValid, values, errors, handleChange }) => 
			<>
			<Form noValidate onSubmit={handleSubmit}>
				<div className="form-row">
					{isAdult && 
					<div className={classnames(["col-6 col-sm-2", isChild ? 'pr-sm-0-x':''])}>
						<div className="form-label-group">
							<Field component="select" name="Person.Salutation" className={classnames(["custom-select", values.Person.Salutation !== '' ? "value-selected":""])}>
								{salutations.map((salutation, i) => {
									return <option key={i}>{salutation}</option>
								})}
							</Field>
							<label className="text-truncate">Salutation</label>
						</div>
					</div>
					}
					<div className={classnames(["col-12", isAdult ? "col-sm-4 pl-sm-0-x":"", isChild ? "col-sm-3 pr-sm-0-x":""])}>
						<div className="form-label-group">
							<Field type="text" name={`Person.FirstName`} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="off" />
							<label>First Name</label>
							<ErrorMessage name="Person.FirstName" component="div" className="error-message fw-bold small text-danger" />
						</div>
					</div>
					<div className={classnames(["col-12 col-sm-4", isAdult ? "pl-sm-0-x":"", isChild ? "pr-sm-0-x":""])}>
						<div className="form-label-group">
							<Field type="text" name={`Person.LastName`} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="off" />
							<label>Last Name</label>
							<ErrorMessage name="Person.LastName" component="div" className="error-message fw-bold small text-danger" />
						</div>
					</div>
					<div className={classnames(["col-6 col-sm-2", isAdult ? "pl-sm-0-x":"", isChild ? 'pr-sm-0-x':''])}>
						<div className="form-label-group">
							<Field component="select" name="Person.Gender" className={classnames(["custom-select", values.Person.Gender !== 0 ? "value-selected":""])}>
								<option value="0"></option>
								<option value="1">Male</option>
								<option value="2">Female</option>
							</Field>
							<label className="text-truncate">Gender</label>
						</div>
					</div>
					{isChild &&
						<div className={classnames(["col-12 col-sm-3", "pr-sm-0-x"])}>
							<div className="form-label-group">
								<DateInputField name={`Person.Birthdate`} minDate={moment().add(-100, "years").toDate()} maxDate={moment().toDate()} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="off" />
								<label>Date of Birth</label>
								<ErrorMessage name="Person.Birthdate" component="div" className="error-message fw-bold small text-danger" />
							</div>
						</div>
					}

				</div>

				{isAdult && <>
				<div className="form-row">
					<div className="col-12 col-sm-4">
						<div className="form-label-group">
							<Field type="text" name="Person.PersonalEmail" className={`form-control`} placeholder={true ? "Email (required)" : ""} autoComplete="off" required />
							<label>Email</label>
							<ErrorMessage name="Person.PersonalEmail" component="div" className="error-message fw-bold small text-danger" />
						</div>
					</div>
					<div className="col-12 col-sm-4">
						<div className="form-label-group">
							<PhoneInputField name="Person.HomePhone" className="form-control" placeholder="Required" autoComplete="off" required={values.Person.MobilePhone === "" || values.Person.MobilePhone == null} />
							<label>Home Phone</label>
							<ErrorMessage name="Person.HomePhone" component="div" className="error-message fw-bold small text-danger" />
						</div>
					</div>
					<div className="col-12 col-sm-4">
						<div className="form-label-group">
							<PhoneInputField name="Person.MobilePhone" className="form-control" placeholder={values.Person.MobilePhone === "" || values.Person.MobilePhone == null ? "required":"required"} autoComplete="off" required={values.Person.HomePhone === "" || values.Person.HomePhone == null}  />
							<label>Cell Phone</label>
							<ErrorMessage name="Person.MobilePhone" component="div" className="error-message fw-bold small text-danger" />
						</div>
					</div>
				</div>

				<div className="form-row mb-3">
					<div className="col-12 col-sm-6">
						<div className="custom-control custom-checkbox custom-control-inline">
							<Field type="checkbox" name="DefaultBilling" id={`DefaultBilling`} checked={values.DefaultBilling} className="custom-control-input" />
							<label htmlFor={`DefaultBilling`} className="custom-control-label">Default Billing Person</label>
						</div>
					</div>
				</div>
				</>}

				<div className="form-row">
					<div className="col-12 col-sm-3">
						<button type="submit" disabled={!isValid || isSubmitting} className="btn btn-success btn-block font-weight-bold mb-3 mb-sm-0">
							{isSubmitting && <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />}
							Save {person.Id === 0 ? "Person" : "Changes"}
						</button>
					</div>
					<div className="col-12 col-sm-3">
						<button type="button" onClick={handleCancel} disabled={isSubmitting} className="btn btn-light btn-block font-weight-bold mb-3 mb-sm-0">Cancel {person.Id === 0 ? "" : "Changes"}</button>
					</div>
					<div className="col-sm-3 d-none d-sm-block"></div>
					{person.Id > 0 &&
					<div className="col-12 col-sm-3">
						<OverlayTrigger show={showRemovePopover} trigger="click" placement="top" overlay={removePopover}>
							<button type="button" onClick={() => setShowRemovePopover(true)} disabled={isSubmitting} className="btn btn-danger btn-block float-right font-weight-bold">Remove Person</button>
						</OverlayTrigger>
					</div>
					}
				</div>
			</Form>
			</>}
		</Formik>

	</>
}