import { useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { hookstate, State, useHookstate } from "@hookstate/core";
import { devtools } from '@hookstate/devtools'
import moment from "moment-timezone";
import { useEventDatesQuery, useEventDepositsQuery, useEventFromTitleQuery } from "../../../api/events.api";
import { Event, EventDate, EventDeposit } from "../../../api/events.models";
import { findMatchingDates } from "./event-selection";

export interface EventState {
	datesSelected?: boolean,
	selectedEventDate?: EventDate|null;
	selectedDateRange?: string[];
	event?: Event;
	eventDates: EventDate[];
	eventDeposits: EventDeposit[];
	step?: number;
}

const EVENT_STATE = hookstate<EventState>(
	{ 
		datesSelected: false,
		selectedEventDate: null,
		selectedDateRange: [],
		event: undefined,
		eventDates: [],
		eventDeposits: [],
		step: 1
	},
	devtools({ key: "event" })
);

type EventUrlParams = {
	eventTitle: string
}

export const useEventState = () => {
	const history = useHistory();
	const search = useLocation().search;
	const { eventTitle } = useParams<EventUrlParams>();

	let state = useHookstate<EventState>(EVENT_STATE);

	const { data: event, isLoading: isLoadingEvent } = useEventFromTitleQuery(eventTitle ?? "");
	const { data: eventDates, isLoading: isLoadingEventDates } = useEventDatesQuery(event?.Id ?? 0);
	const { data: eventDeposits, isLoading: isLoadingEventDeposits } = useEventDepositsQuery(event?.Id ?? 0);

	// state.event.set(useEventFromTitleQuery(eventTitle));
	// state.eventDates.set(eventDates);
	useEffect(() => {
		state.event.set(event);
	}, [isLoadingEvent])

	useEffect(() => {
		state.eventDates.set(eventDates ?? []);
	}, [isLoadingEventDates])

	useEffect(() => {
		state.eventDeposits.set(eventDeposits ?? []);
	}, [isLoadingEventDeposits])

	useEffect(() => {
		return history.listen((location) => { 
			// console.log(`You changed the page to: ${location.pathname}${location.search}`) 
			if (isLoadingEvent || isLoadingEventDates)
				return;
			updateDateRange(state, location.search, history);
			updateEventDate(state, location.search, history);
		 }) 
	}, [history]);

	useEffect(() => {
		if (isLoadingEvent || isLoadingEventDates || isLoadingEventDeposits)
			return;
		state.event.set(event);
		state.eventDates.set(eventDates ?? []);
		state.eventDeposits.set(eventDeposits ?? []);
	}, [eventTitle])

	useEffect(() => {
		// console.log(search)
		if (isLoadingEvent || isLoadingEventDates)
			return;
		updateDateRange(state, search, history);
		updateEventDate(state, search, history);
	}, [isLoadingEvent, isLoadingEventDates]);

	// Automatically update the selectedDateRange and event page URL when the selectedEventDate changes
	// useEffect(() => {
	// 	if (state.selectedEventDate.value != null) {
	// 		state.selectedDateRange.set([moment(state.selectedEventDate.value.DateFrom).toISOString(), moment(state.selectedEventDate.value.DateTo).toISOString()]);
	// 		updateUrl(state.selectedEventDate.value.DateFrom, state.selectedEventDate.value.DateTo);
	// 	}
	// }, [state.selectedEventDate.value?.Id ?? 0]);


	return { 
		event: state.event.get({ noproxy: true }) as Event,
		eventDates: state.eventDates.get({ noproxy: true }) as EventDate[],
		eventDeposits: state.eventDeposits.get({ noproxy: true }) as EventDeposit[],
		selectedDateRange: state.selectedDateRange.get({ noproxy: true }) as string[],
		selectedEventDate: state.selectedEventDate.get({ noproxy: true }) ?? undefined,
		datesSelected: state.datesSelected.get({ noproxy: true }),
		step: state.step.get({ noproxy: true }),

		setCurrentStep: (step: number) => { state.step.set(step); },
		
		isLoadingEvent: isLoadingEvent,
		isLoadingEventDates: isLoadingEventDates,

		updateSelectedEventDate: (eventDateId: number) => updateSelectedEventDate(state, eventDateId, history, search),
		updateSelectedDateRange: (dateRange: string[]) => updateSelectedDateRange(state, dateRange, history, search)
	}
}

const updateSelectedEventDate = (state: State<EventState>, eventDateId: number, history: any, search: string) => {
	let selectedEventDate = state.eventDates.get({ noproxy: true }).find(e => e.Id === eventDateId);
	if(selectedEventDate) {
		state.selectedEventDate.set(selectedEventDate);
		state.selectedDateRange.set([moment(selectedEventDate.DateFrom).toISOString(), moment(selectedEventDate.DateTo).toISOString()]);
		updateUrl(history, search, selectedEventDate.DateFrom, selectedEventDate.DateTo);
	}
}

const updateSelectedDateRange = (state: State<EventState>, dateRange: string[], history: any, search: string) => {
	let dateFrom = moment(dateRange[0]).startOf("day").toISOString();
	let dateTo = moment(dateRange[1]).startOf("day").toISOString();
	state.selectedDateRange.set([dateFrom, dateTo]);
	updateUrl(history, search, dateFrom, dateTo);
}

const updateUrl = (history: any, search: string, dateFrom: string, dateTo: string) => {
	const params = new URLSearchParams(search);
	params.set("dateFrom", moment(dateFrom).toISOString());
	params.set("dateTo", moment(dateTo).toISOString());
// console.log('push')
	history.replace({ search: params.toString() });
}

const updateDateRange = (state: State<EventState>, query: string, history: any) => {
	const params = new URLSearchParams(query);
	if (params.has("dateFrom")) {
		// console.log('loading date range', params.get("dateFrom"));
		state.selectedDateRange.set([params.get("dateFrom") ?? new Date().toISOString(), params.get("dateTo") ?? new Date().toISOString()]);
		// findMatchingDates(state, moment(params.get("dateFrom")), moment(params.get("dateTo")), true, history);
		state.datesSelected.set(true);
	} else {
		state.selectedDateRange.set([]);
		state.selectedEventDate.set(null);
		state.datesSelected.set(false);
	}
}

const updateEventDate = (state: State<EventState>, query: string, history: any) => {
	const params = new URLSearchParams(query);
	if (params.has("dateFrom")) {
		// console.log('findMatchingDates')
		findMatchingDates(state, moment(params.get("dateFrom")), moment(params.get("dateTo")), false, history);
		// console.log(url)
		// console.log('loading date range', params.get("dateFrom"));
		// state.selectedDateRange.set([params.get("dateFrom"), params.get("dateTo")]);
		// state.datesSelected.set(true);
	} else {
		state.selectedDateRange.set([]);
		state.datesSelected.set(false);
	}
}