import classNames from "classnames";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getDefaultHeaderImage, useConfigurationQuery, usePageContentQuery } from "../../api/configuration.api"
import { useInventoryCategoriesQuery, useInventoryItemsQuery, useInventoryTypesQuery } from "../../api/inventory.api";
import { InventoryCategory, InventoryItem, InventoryType } from "../../api/inventory.models";
import Card from "../../common/components/Card";
import MediaGallery from "../../common/components/MediaGallery";
import DefaultLayout from "../../layouts/default-layout"
import FeaturedTypes from "../home/components/featured-types";
import ExploreInventoryItem from "./components/explore-inventory";
import { linkTitle } from "../../common/helpers";
import "./explore.css"

interface ExploreProps {
	inventoryType?: string;
}

type ExploreUrlParams = {
	inventoryType: string;
	inventoryCategory: string;
}

const Explore = ({ }: ExploreProps) => {
	const { inventoryType: inventoryTypeParam, inventoryCategory: inventoryCategoryParam } = useParams<ExploreUrlParams>();
	const { data: configuration } = useConfigurationQuery();
	const { data: inventoryTypes } = useInventoryTypesQuery(true);
	const { data: inventoryCategories } = useInventoryCategoriesQuery(true);
	const { data: pageContent } = usePageContentQuery();

	let pageTitle = `Explore ${configuration?.OrganizationShortform ?? ""}`;
	var pageTitleLink: string|undefined = undefined;
	let subTitle: string|undefined = undefined;
	var inventoryType: InventoryType|undefined = undefined;
	var inventoryCategory: InventoryCategory|undefined = undefined;
	var headerLargeImageUrl: string|undefined = undefined;
	var headerSmallImageUrl: string|undefined = undefined;

	const defaultHeaderImage = getDefaultHeaderImage(pageContent);

	if (inventoryTypeParam) {
		inventoryType = inventoryTypes?.find(t => linkTitle(t.Metadata?.Title ?? "") === inventoryTypeParam);
		if (inventoryType) {
			pageTitle = inventoryType.Metadata!.Title;
			document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | ${pageTitle}`;
			// subTitle = inventoryType.Metadata!.Content;
			if (inventoryType?.Metadata?.ImageURL) {
				headerLargeImageUrl = inventoryType?.Metadata?.ImageURL;
				headerSmallImageUrl = inventoryType?.Metadata?.ImageURL;
			}
		}
	}

	if (inventoryCategoryParam) {
		inventoryCategory = inventoryCategories?.find(c => linkTitle(c.Metadata?.Title ?? c.Description) === inventoryCategoryParam);
		if (inventoryCategory) {
			// pageTitle = inventoryCategory.Metadata!.Title;
			document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | ${inventoryCategory.Metadata?.Title ?? ""}`;
			subTitle = "";
			if (inventoryCategory?.Metadata?.ImageURL) {
				headerLargeImageUrl = inventoryCategory?.Metadata?.ImageURL;
				headerSmallImageUrl = inventoryCategory?.Metadata?.ImageURL;
			}
			pageTitleLink = `/explore/${linkTitle(inventoryType?.Metadata?.Title ?? inventoryType?.Description ?? "")}`;
		}
	}

	function createDescriptionMarkup() { return { __html: inventoryType?.Metadata?.Content ?? "" }; };

	return <DefaultLayout pageTitle={pageTitle} pageTitleLink={pageTitleLink} subTitle={subTitle} headerLargeImageUrl={headerLargeImageUrl ?? defaultHeaderImage.Content} headerSmallImageUrl={headerSmallImageUrl ?? defaultHeaderImage.Content} customClasses={inventoryType !== undefined && inventoryCategory === undefined ? "mb-0":"mb-4"}>
		<>
			{!inventoryType && <FeaturedTypes />}
			{inventoryType && !inventoryCategory && 
				<>
					<p dangerouslySetInnerHTML={createDescriptionMarkup()} className="mb-3"></p>
					<ExploreInventoryCategories inventoryType={inventoryType} />
				</>
			}
			{inventoryCategory && <ExploreInventoryCategory inventoryCategory={inventoryCategory} />}
		</>
	</DefaultLayout>
}

interface ExploreInventoryCategoriesProps {
	inventoryType: InventoryType;
}

const ExploreInventoryCategories = ({ inventoryType }: ExploreInventoryCategoriesProps) => {
	const { data: inventoryCategories } = useInventoryCategoriesQuery(true);
	return <div className="row inventory-categories">
		{inventoryCategories?.filter(c => c.InventoryTypeId === inventoryType.Id).map((inventoryCategory, index) => {
			return <div className="col-sm-4 mb-4" key={index}>
				<Card title={inventoryCategory.Metadata?.Title ?? inventoryCategory.Description} description={inventoryCategory.Metadata?.Description ?? ""} imageUrl={inventoryCategory.Metadata?.ImageURL ?? ""} link={`/explore/${linkTitle(inventoryType.Metadata?.Title ?? inventoryType.Description)}/${linkTitle(inventoryCategory.Metadata?.Title ?? inventoryCategory.Description)}`} />
			</div>
		})}
	</div>
}

interface ExploreInventoryCategoryProps {
	inventoryCategory: InventoryCategory;
}

const ExploreInventoryCategory = ({ inventoryCategory }: ExploreInventoryCategoryProps) => {
	const { data: inventoryItems } = useInventoryItemsQuery(true, true, inventoryCategory.Id);
	const [selectedInventoryItem, setSelectedInventoryItem] = useState<InventoryItem>();

	function createDescriptionMarkup() { return { __html: inventoryCategory.Metadata?.Content ?? "" }; };

	const handleInventoryItemSelected = (inventoryItem: InventoryItem) => {
		setSelectedInventoryItem(inventoryItem);
	}

	useEffect(() => {
		if (selectedInventoryItem === undefined && inventoryItems !== undefined && inventoryItems.length > 0)
			setSelectedInventoryItem(inventoryItems[0]);
	}, [inventoryItems])

	return <div>
		<hr />
		<h3>{inventoryCategory.Metadata?.Title}</h3>
		<p dangerouslySetInnerHTML={createDescriptionMarkup()}></p>	
		<MediaGallery galleryId={inventoryCategory.Metadata?.MediaGalleryId ?? 0} open={true} showNoPhotos={false} />

		<div className="row mb-3">
			<div className="col-12 text-center">
				Click on a room to view availability, photos and amenities.
			</div>
		</div>

		<div className="row mb-3">
			{inventoryItems?.map((inventoryItem, index) => {
				return <div key={index} className="col-4 col-sm-2 text-center">
					<span onClick={() => handleInventoryItemSelected(inventoryItem)} className={classNames(["inventory-item-title", selectedInventoryItem === inventoryItem ? "selected":""])}>{inventoryItem.Metadata?.Title ?? inventoryItem.Description}</span>
				</div>
			})}
		</div>

		{selectedInventoryItem !== undefined && 
		<div className="row mb-3">
			<div className="col-12">
				<ExploreInventoryItem inventoryItem={selectedInventoryItem} />
			</div>
		</div>
		}
	</div>
}


export default Explore