import React, { useState } from "react";
import { hookstate, State, useHookstate } from "@hookstate/core";
import { localstored, StoreEngine } from '@hookstate/localstored';
import { createEvent, Emitter } from "react-event-hook";
import { AxiosError } from "axios";
import moment from "moment-timezone";
import { useAuthUIState } from "./authui.state";
import { login, newPassword, register, resetPassword } from '../api/auth.api';
import { LoginModel, LoginResponseModel, RegisterModel, PasswordResetModel, NewPasswordModel } from "../api/auth.models";
import { ApiError } from "../api/api";

export interface AuthState {
	isAuthenticated: boolean,
	userInfo?: LoginResponseModel
}

const AUTH_STATE = hookstate<AuthState>(
	{ 
		isAuthenticated: false,
	}, 
	localstored({
		key: 'authorization',
		engine: localStorage as StoreEngine,
		initializer: () => new Promise<AuthState>((resolve, reject) => { 
			resolve({ 
				isAuthenticated: false,
				userInfo: undefined
			})
		})
	})
);

const { useLoginListener, emitLogin } = createEvent("login")();

export const useAuthState = () => {
	let state = useHookstate<AuthState>(AUTH_STATE);
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const [isRegistering, setIsRegistering] = useState(false);
	const [isTimedOut, setIsTimedOut] = useState(false);
	const [isResettingPassword, setIsResettingPassword] = useState(false);
	const { showLogin, showLogout, showLoggedOut, showRegister } = useAuthUIState();

	return { 
		userInfo: state.userInfo.value,
		isAuthenticated: () => { return authIsExpired(state) ? false : state.isAuthenticated.value },
		getIsAuthenticated: () => authGetAuthenticated(state),
		isAuthenticationExpired: () => authIsExpired(state),
		showLogin: showLogin,
		login: async (model: LoginModel) => authLogin(state, model, setIsLoggingIn, emitLogin),
		isLoggingIn: isLoggingIn,
		isTimedOut: isTimedOut,
		getIsTimedOut: () => isTimedOut,
		setIsTimedOut: setIsTimedOut,
		showLogout: showLogout,
		showLoggedOut: () => { setIsTimedOut(true); showLoggedOut() },
		logout: () => authLogout(state),
		register: async (model: RegisterModel) => doRegister(state, model, setIsRegistering),
		isRegistering: isRegistering,
		showRegister: showRegister,
		resetPassword: async (model: PasswordResetModel) => doResetPassword(state, model, setIsResettingPassword),
		isResettingPassword: isResettingPassword,
		newPassword: async (model: NewPasswordModel) => doNewPassword(state, model, setIsResettingPassword),

		useLoginListener
	};
}

export interface AuthLoginResult {
	success: boolean,
	error?: string
}

const authGetAuthenticated = (state: State<AuthState>) => {
	if (authIsExpired(state))
		return false;
	return state.isAuthenticated.value;
}

const authIsExpired = (state: State<AuthState>) : boolean => {
	if (state.userInfo.value === undefined)
		return false;

	if (state.isAuthenticated.value === undefined || state.isAuthenticated.value === false)
		return false;

	const expiry = moment.unix(state.userInfo.value.expiry);
	return moment().isAfter(expiry);
}

const authLogin = async (state: State<AuthState>, model: LoginModel, setIsLoggingIn: React.Dispatch<React.SetStateAction<boolean>>, emitLogin: Emitter<void>) : Promise<AuthLoginResult> => {
	setIsLoggingIn(true)
	try {
		let loginResult = await login(model);
		if (loginResult) {
			state.userInfo.set(loginResult);
			state.isAuthenticated.set(true);
			emitLogin();
		}
		return { success: true };
	} catch (error) {
		let message = (error as AxiosError<ApiError>).response?.data.message ?? "";
		return { success: false, error: message };
	} finally {
		setIsLoggingIn(false)
	}
}

const authLogout = (state: State<AuthState>) => {
	// TODO: Clear cookie
	state.userInfo.set(undefined);
	state.isAuthenticated.set(false);
}

const doRegister = async (state: State<AuthState>, model: RegisterModel, setIsRegistering: React.Dispatch<React.SetStateAction<boolean>>) => {
	setIsRegistering(true);
	try {
		let registerResult = await register(model);
		if (registerResult) {
			state.userInfo.set(registerResult);
			state.isAuthenticated.set(true);
		}
		return true;
	} catch {
		return false;
	} finally {
		setIsRegistering(false);
	}
}


const doResetPassword = async (state: State<AuthState>, model: PasswordResetModel, setIsResettingPassword: React.Dispatch<React.SetStateAction<boolean>>) => {
	setIsResettingPassword(true);
	try {
		await resetPassword(model);
		return { success: true, error: null };
	} catch (error) {
		let message = (error as AxiosError<ApiError>).response?.data.message ?? "";
		return { success: false, error: message };
	} finally {
		setIsResettingPassword(false);
	}
}

const doNewPassword = async (state: State<AuthState>, model: NewPasswordModel, setIsResettingPassword: React.Dispatch<React.SetStateAction<boolean>>) => {
	setIsResettingPassword(true);
	try {
		await newPassword(model);
		return { success: true, error: null };
	} catch (error) {
		let message = (error as AxiosError<ApiError>).response?.data.message ?? "";
		return { success: false, error: message };
	} finally {
		setIsResettingPassword(false);
	}
}