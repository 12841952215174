import _ from "lodash";
import { getSelectedInventoryItemsForCategory } from "./inventory-selection";
import { InventoryCategory, InventoryItem, InventoryType, InventoryTypeBase } from "../../../api/inventory.models";

export const getInventoryType = (inventoryTypes: InventoryType[], inventoryTypeId: number) => {
	return inventoryTypes.find(t => t.Id === inventoryTypeId);
}

export const getInventoryCategory = (inventoryTypes: InventoryType[], inventoryTypeId: number, inventoryCategoryId: number) => {
	return inventoryTypes?.find(t => t.Id === inventoryTypeId)?.InventoryCategories.find(c => c.Id === inventoryCategoryId);
}

export const getInventoryItem = (inventoryTypes: InventoryType[], inventoryTypeId: number, inventoryCategoryId: number, inventoryItemId: number) => {
	return inventoryTypes.find(t => t.Id === inventoryTypeId)?.InventoryCategories.find(c => c.Id === inventoryCategoryId)?.InventoryItems.find(i => i.Id === inventoryItemId);
}

export const getInventoryItemById = (inventoryTypes: InventoryType[], inventoryItemId: number) => {
	for (let inventoryType of inventoryTypes) {
		for (let inventoryCategory of inventoryType.InventoryCategories) {
			let inventoryItem = inventoryCategory.InventoryItems.find((i) => i.Id === inventoryItemId);
			if (inventoryItem != null)
				return inventoryItem;
		}
	}
	return null;
}

export const isAccommodation = (type: InventoryType) => {
	if (type == null)
		return false;

	return type.Type === InventoryTypeBase.Accommodation;
}

export const isCampsite = (type: InventoryType) => {
	if (type == null)
		return false;
		
	return type.Type === InventoryTypeBase.Campsite;
}

export const getAvailableInventoryItemsForCategory = (inventoryCategory: InventoryCategory, filters: any[] = [], excludeFiltered: boolean = true) : any[] => {
	if (filters.length > 0 && excludeFiltered)
		return inventoryCategory.InventoryItems.filter((i: any) => i.Filtered === false && i.Available === true);

	return inventoryCategory.InventoryItems.filter((i: any) => i.Available === true); //Math.max(inventoryCategory.InventoryItems.length, 1);
}

export const getInventoryTitle = (inventoryTypes: InventoryType[], inventoryItem: InventoryItem, includeCategory: boolean = false) => {
	if (!includeCategory || inventoryTypes == null)
		return inventoryItem.Metadata?.Title ?? inventoryItem.Description;

	let inventoryCategory = getInventoryCategory(inventoryTypes ?? [], inventoryItem.InventoryTypeId, inventoryItem.InventoryCategoryId);

	if (inventoryCategory)
		return `${inventoryCategory.Metadata?.Title ?? inventoryCategory.Description} - ${inventoryItem.Metadata?.Title ?? inventoryItem.Description}`;
}

export const getPerNightCostForCategory = (category: InventoryCategory) => {
	if (category.InventoryItems[0].EventInventoryItems != null &&
		category.InventoryItems[0].EventInventoryItems.length > 0) {

		var highPrice = 0;
		for (var i = 0; i < category.InventoryItems.length; i++) {
			if ((category.InventoryItems[i].Available || category.InventoryItems[i].InventoryPolicy != null) && category.InventoryItems[i].EventInventoryItems[0].Price*1 > highPrice)
				highPrice = category.InventoryItems[i].EventInventoryItems[0].Price*1;
		}
		var lowPrice = highPrice;
		for (i = 0; i < category.InventoryItems.length; i++) {
			if ((category.InventoryItems[i].Available || category.InventoryItems[i].InventoryPolicy != null) && category.InventoryItems[i].EventInventoryItems[0].Price*1 < lowPrice)
				lowPrice = category.InventoryItems[i].EventInventoryItems[0].Price*1;
		}
		return lowPrice;//category.InventoryItems[0].EventInventoryItems[0].Price*1;
	}
		
	return category.EventInventory![0].Price*1;
}

export const getTotalPerNightForCategory = (selectedInventory: InventoryItem[], category: InventoryCategory) => {
	var total = 0;
	let selectedItems = getSelectedInventoryItemsForCategory(selectedInventory, category);

	const isSelected = (item: any) => {
		return _.some(selectedItems, (i: any) => i.Id === item.Id);
	}

	category.InventoryItems.forEach((item: any) => {
		if (item.EventInventoryItems.length > 0)
			total += isSelected(item) ? item.EventInventoryItems[0].Price*1 : 0;
		else
			total += isSelected(item) ? category.EventInventory![0].Price*1 : 0;
	});

	return total;
}

export const getPriceDescriptionForCategory = (category: InventoryCategory) => {
	if (category.InventoryItems[0].EventInventoryItems != null &&
		category.InventoryItems[0].EventInventoryItems.length > 0)
		return category.InventoryItems[0].EventInventoryItems[0].PriceDescription;
	
	return category.EventInventory![0].PriceDescription;
}

export const getPerNightCostForInventoryItem = (item: InventoryItem) => {
	if (item.EventInventoryItems != null && 
		item.EventInventoryItems.length > 0)
		return item.EventInventoryItems[0].Price*1;

	return 0;
}

export const getPriceDescriptionForInventoryItem = (item: InventoryItem) => {
	if (item.EventInventoryItems != null && 
		item.EventInventoryItems.length > 0)
		return item.EventInventoryItems[0].PriceDescription;

	return 0;
}

export const getInventoryCapacity = (inventoryItem: InventoryItem, inventoryCategory: InventoryCategory) : number => {
	if (inventoryItem == null)
		return 0;
	if (inventoryItem.Metadata != null && inventoryItem.Metadata.Capacity != null)
		return inventoryItem.Metadata.Capacity;
	return inventoryCategory?.Metadata?.Capacity ?? 0;
}