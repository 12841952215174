import { useEffect, useState } from "react";
import { ModalProps, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";

import { useProgramRegistrationHistoryQuery } from "../../api/programregistration.api";
import { currency, getLocalDate } from "../../common/helpers";
import { ProgramRegistration, RegistrationHistory } from "../../api/program.registration.models";
import { useUserRegistrationCancelMutation, useUserRegistrationQuery, useUserRegistrationUpdateMutation } from "../../api/user.api";
import { toastError, toastSuccess } from "../../common/components/toast";
import { useProgramActivitiesQuery, useProgramActivitySessionsQuery, useProgramGroupsQuery, useProgramSessionsByIdQuery } from "../../api/programs.api";
import { ProgramActivitySession, ProgramSession, SessionSelectionType } from "../../api/programs.models";
import _ from "lodash";
import { useConfigurationQuery } from "../../api/configuration.api";
import { ActivitySelectionType } from "../../api/programs.models";
import { getActivities } from "../programs/functions/program-functions";

const formatDateRange = (dateFrom: string, dateTo: string, includeTime: boolean) => {
	if (includeTime) {
		if (moment(dateFrom).isSame(dateTo, "day")) {
			return `${moment(dateFrom).format("dddd, LL")}: ${moment(dateFrom).format("LT")} - ${moment(dateTo).format("LT")}`;
		}
		return `${moment(dateFrom).format("LLLL")} - ${moment(dateTo).format("LLLL")}`;
	} else {
		if (moment(dateFrom).isSame(dateTo, "day")) {
			return `${moment(dateFrom).format("dddd, LL")}`;
		}
		return `${moment(dateFrom).format("dddd, LL")} - ${moment(dateTo).format("dddd, LL")}`;
	}
}

const AccountProgramRegistrations = () => {
	const { data: registrations, isLoading } = useProgramRegistrationHistoryQuery();

	const upcomingRegistrations = () => {
		if (registrations === undefined)
			return [];
		return registrations.filter(r => moment(r.DateTo) > moment() && r.Deleted !== true).sort((a, b) => { return moment(a.DateFrom).isAfter(moment(b.DateFrom)) ? 1 : -1});
	}

	const pastRegistrations = () => {
		if (registrations === undefined)
			return [];
		return registrations.filter(r => moment(r.DateTo) < moment() && r.Deleted !== true).sort((a, b) => { return moment(a.DateFrom).isBefore(moment(b.DateFrom)) ? 1 : -1});
	}

	const deletedRegistrations = () => {
		if (registrations === undefined)
			return [];
		return registrations.filter(r => r.Deleted === true).sort((a, b) => { return moment(a.DateFrom).isBefore(moment(b.DateFrom)) ? 1 : -1});
	}

	const noReservations = upcomingRegistrations().length === 0 && pastRegistrations().length === 0 && deletedRegistrations().length === 0;

	if (isLoading)
		return <>
			<h4 className="font-weight-bold mb-4">Loading...</h4>
		</>
		
	return <>
		{noReservations && 
			<>
				<h4 className="font-weight-bold mb-4">Registrations</h4>
				<p className="text-muted">You don't have any upcoming or past registrations.</p>
			</>
		}

		{upcomingRegistrations().length > 0 &&
			<>
				<h4 className="font-weight-bold mb-4">Upcoming Registrations</h4>
				
				{upcomingRegistrations()?.map((registration, index) => {
					return <div key={`upcoming${index}`}>
						<AccountRegistration registration={registration} />
						{index !== upcomingRegistrations().length-1 &&
						<hr className="mt-4 mb-4" />
						}
					</div>
				})}	
			</>
		}

		{pastRegistrations().length > 0 &&
			<>
				<hr className="mt-4 mb-4" />
				<h4 className="font-weight-bold mb-4">Past Registrations</h4>
				{pastRegistrations()?.map((registration, index) => {
					return <div key={`past${index}`}>
						<AccountRegistration registration={registration} />
						{index !== pastRegistrations().length-1 &&
						<hr className="mt-4 mb-4" />
						}
				</div>
				})}	
			</>
		}

		{deletedRegistrations().length > 0 &&
			<>
				<hr className="mt-4 mb-4" />
				<h4 className="font-weight-bold mb-4">Cancelled Registrations</h4>
				{deletedRegistrations()?.map((registration, index) => {
					return <div key={`past${index}`}>
						<AccountRegistration registration={registration} />
						{index !== deletedRegistrations().length-1 &&
						<hr className="mt-4 mb-4" />
						}
				</div>
				})}	
			</>
		}
	</>
}

export default AccountProgramRegistrations;

interface AccountRegistrationProps {
	registration: RegistrationHistory;
}

const AccountRegistration = ({ registration }: AccountRegistrationProps) => {
	const { data: programGroups } = useProgramGroupsQuery();
	const programGroup = programGroups?.find(g => g.Id === registration.ProgramGroupId);

	const [showModifySessionsModal, setShowModifySessionsModal] = useState(false);
	const [showModifyActivitiesModal, setShowModifyActivitiesModal] = useState(false);
	const [showCancelRegistrationModal, setShowCancelRegistrationModal] = useState(false);

	const canModify = moment(registration.DateTo).startOf("day") > moment().startOf("day") && (programGroup?.SessionSelectionType === SessionSelectionType.Custom ?? false) && registration.Deleted === false;
	const canDelete = moment(registration.DateFrom).startOf("day") > moment().startOf("day") && registration.Deleted === false;

	return <>
		<EditSessionsModal registration={registration} show={showModifySessionsModal} onHide={() => setShowModifySessionsModal(false)} />
		<EditActivitiesModal registration={registration} show={showModifyActivitiesModal} onHide={() => setShowModifyActivitiesModal(false)} />
		<DeleteRegistrationModal registration={registration} show={showCancelRegistrationModal} onHide={() => setShowCancelRegistrationModal(false)} />

		<div className="row">
			<div className={!canModify && !canDelete ? "col-sm-12" : "col-sm-8"}>
				<h5 className="font-weight-bold">{registration.ProgramGroup}: {registration.Program}</h5>
				<div className="font-weight-bold mb-2">{formatDateRange(registration.DateFrom, registration.DateTo, false)}</div>
			</div>
			<div className="col-sm-4">
				<div className="row">
					{canDelete &&
					<div className="col-sm-12">
						<button onClick={() => setShowCancelRegistrationModal(true)} className="btn btn-danger btn-block font-weight-bold font-weight-bold mb-3" style={{ lineHeight: '1.3em' }}>Cancel Registration...</button>
					</div>
					}
				</div>
			</div>
		</div>

		<div className="row">
			<div className="col-sm-4 mb-3">
				<span className="small text-muted">Online Registration #</span>
				<br />
				{registration.RegistrationNumber}
			</div>
			<div className="col-sm-6 mb-3">
				<span className="small text-muted">Date Submitted</span>
				<br />
				<span>{moment(getLocalDate(registration.DateSubmitted)).format("dddd, LL")}</span>
			</div>
		</div>

		<div className="row">
			<div className="col-sm-4 mb-3">
				<div className="small text-muted">Participants</div>
				{registration.Participants.map((participant, index) => {
					return <span key={index}>
						{participant}
						{index !== registration.Participants.length && <br />}
					</span>
				})}
			</div>
			{registration.ParentGuardians.length > 0 &&
			<div className="col-sm-8 mb-3">
				<div className="small text-muted">Parents/Guardians</div>
				{registration.ParentGuardians.map((parentGuardian, index) => {
					return <span key={index}>
						{parentGuardian.Name}
						{index < registration.ParentGuardians.length - 1 && <br />}
						{index === registration.ParentGuardians.length - 1 && <>
							<br />
							<small>
							Location: {registration.ParentGuardians[0].Location}, 
							Emergency Contact: {registration.ParentGuardians[0].EmergencyContact}
							</small>
						</>}
					</span>
				})}
			</div>
			}
		</div>
		<div className="row">
			<div className="col-sm-8">
				<div className="small text-muted">Sessions</div>
				<div className="row">
				{registration.Sessions.map((session, index, arr) => {
					return <div className="col-sm-12 mb-3" key={index}>
						{registration.Participants.length > 1 && 
						<span className="font-weight-bold">{session.Participant}:</span>
						}
						{session.Sessions.sort((a, b) => { return moment(a.DateFrom).isSameOrAfter(moment(b.DateFrom)) ? 1 : -1 }).map((s, index2, arr2) => {
							return <div className="small" key={index2}>
								{formatDateRange(s.DateFrom, s.DateTo, true)}
								{s.WaitList === true && ' (on wait list)'}
								{index2 === arr2.length - 1 && <br />}
								{/* <br /> */}
							</div>
						})}
						{/* {index < arr.length - 1 && <br />} */}
					</div>
				})}
				</div>
			</div>
			<div className="col-sm-4 mb-2">
				{canModify && <>
					<button onClick={() => setShowModifySessionsModal(true)} className="btn btn-primary btn-block font-weight-bold font-weight-bold mb-2" style={{ lineHeight: '1.3em' }}>Modify Sessions...</button>
				</>}
			</div>
		</div>

		{registration.Activities.length > 0 &&
		<div className="row">
			<div className="col-sm-8 mb-2">
				<div className="small text-muted">Skills</div>
				{registration.Activities.map((activity, index, arr) => {
					return <div key={index}>
						{registration.Participants.length > 1 && 
						<span>{activity.Participant}: </span>
						}
						{activity.Activities.map((a, index2, arr2) => {
							return <span key={index2} className="">
								{a}
								{/* {a.WaitList === true && ' (on wait list)'} */}
								{index2 < arr2.length - 1 && ', '}
							</span>
						})}
					</div>
				})}
			</div>
			<div className="col-sm-4 mb-2">
				{canModify && <>
					<button onClick={() => setShowModifyActivitiesModal(true)} className="btn btn-primary btn-block font-weight-bold font-weight-bold mb-2" style={{ lineHeight: '1.3em' }}>Modify Skills...</button>
				</>}
			</div>
		</div>
		}

		<div className="row">
			<div className="col-sm-4 mb-3 mb-sm-0">
				<div className="small text-muted">Total Cost (including taxes)</div>
				{registration.Cost > 0 ? currency.format(registration.Cost) : "No charge"}
			</div>
			{registration.Cost > 0 &&
			<div className="col-sm-4 mb-3 mb-sm-0">
				<div className="small text-muted">Amount Paid</div>
				{currency.format(registration.Cost)}
				{registration.ReceiptURL &&
				<span> <a href={registration.ReceiptURL} target="_blank" rel="noreferrer">View Receipt</a></span>
				}
			</div>
			}
		</div>
	</>
}

interface EditSessionsModalProps {
	registration: RegistrationHistory
}

const EditSessionsModal = ({ registration, ...props }: EditSessionsModalProps & ModalProps) => {
	const { data: configuration } = useConfigurationQuery();
	const { mutate: updateRegistration, isLoading: isUpdating } = useUserRegistrationUpdateMutation();
	const { data: programRegistrationData } = useUserRegistrationQuery(props.show ? registration?.RegistrationId : 0);
	const [programRegistration, setProgramRegistration] = useState<ProgramRegistration|undefined>();
	const { data: programGroups } = useProgramGroupsQuery();
	const { data: programSessions } = useProgramSessionsByIdQuery(programRegistration?.ProgramId ?? 0);


	useEffect(() => {
		setProgramRegistration(_.cloneDeep(programRegistrationData));
	}, [programRegistrationData, props.show]);

	const programSessionsForReservation = programSessions?.filter(s => 
		moment(s.StartDate).isSameOrAfter(moment(registration.DateFrom)) && 
		moment(s.EndDate).isSameOrBefore(moment(registration.DateTo)));

	const handleUpdate = () => {
		updateRegistration(programRegistration!, {
			onSuccess: () => {
				handleClose();
				toastSuccess('The registration has been updated.');
			},
			onError: () => {
				toastError(null, 'The registration was not updated. Please reload the page and try again.');
			},
		})
	}

	const handleClose = () => {
		if (props.onHide)
			props.onHide();
	}

	const programSessionChanged = (programSession: ProgramSession, participantId: string, selected: boolean) => {
		if (!selected) {
			let index = programRegistration?.Data.ProgramSessions.findIndex(s => s.ProgramSessionId === programSession.Id) ?? -1;
			if (index >= 0) {
				let participantIndex = programRegistration?.Data.ProgramSessions[index].Participants.findIndex(p => p.ParticipantId === participantId) ?? -1;
				if (participantIndex >= 0)
					programRegistration?.Data.ProgramSessions[index].Participants.splice(participantIndex, 1);

				if (programRegistration?.Data.ProgramSessions[index].Participants.length === 0)
					programRegistration?.Data.ProgramSessions.splice(index, 1);

				setProgramRegistration(_.cloneDeep(programRegistration));
			}
		} else {
			let index = programRegistration?.Data.ProgramSessions.findIndex(s => s.ProgramSessionId === programSession.Id) ?? -1;
			if (index >= 0) {
				programRegistration?.Data.ProgramSessions[index].Participants.push({
					ParticipantId: participantId,
					ActivitySessionIds: []
				});
			} else {
				programRegistration?.Data.ProgramSessions.push({
					ProgramSessionId: programSession.Id,
					Participants: [{
						ParticipantId: participantId,
						ActivitySessionIds: []
					}]
				});
			}

			// TODO: Make an setting for this
			let sameDaySession = programSessionsForReservation?.find(s => s.Id !== programSession.Id && moment(s.StartDate).isSame(moment(programSession.StartDate), "date"));
			if (sameDaySession) {
				let index = programRegistration?.Data.ProgramSessions.findIndex(s => s.ProgramSessionId === sameDaySession?.Id) ?? -1;
				if (index >= 0) {
					programRegistration?.Data.ProgramSessions.splice(index, 1);
				}
			}

			setProgramRegistration(_.cloneDeep(programRegistration));
		}
	}

	return <>
		<Modal centered {...props} enforceFocus={false} backdrop={true}>
			<Modal.Body className="p-4 shadow-lg">
				<button type="button" onClick={props.onHide} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3>Modify Sessions</h3>
				
				<p>You can change the session enrollments for each participant. For any other changes, please contact the {configuration?.OrganizationTitle} office at {configuration?.OrganizationPhone} or {configuration?.OrganizationEmail}.</p>
				<div className="alert alert-info">
					<div className="font-weight-bold">Online Registration #{registration.RegistrationNumber}</div>
					<div className="">{registration.ProgramGroup}: {registration.Program}</div>
					<div className="mb-3">{formatDateRange(registration.DateFrom, registration.DateTo, false)}</div>
					<div className="row">
					{programRegistration?.Data.Participants.map((participant, index) => {
						return <div key={index} className="col-sm-12">
							<div className="font-weight-bold mb-2">{participant.FirstName} {participant.LastName}</div>

							{programSessionsForReservation?.map((programSession, index) => {
								let registrationProgramSession = programRegistration?.Data.ProgramSessions.find(s => s.ProgramSessionId === programSession.Id);
								let selected = registrationProgramSession !== undefined && registrationProgramSession?.Participants.find(p => p.ParticipantId === participant.Id) !== undefined;
								// let program = programs?.find(p => p.Id === registration.ProgramId);
								let programGroup = programGroups?.find(g => g.Id === registration.ProgramGroupId);
								let sessionParticipant = registrationProgramSession?.Participants?.find(p => p.ParticipantId === participant.Id);
								let multipleSameDay = programSessionsForReservation.length > index + 1 && moment(programSession.StartDate).isSame(moment(programSessionsForReservation[index+1].StartDate), "date") && moment(programSession.StartDate).day() == 0;

								return <div key={index}>
										{multipleSameDay && <>
											<div className="small text-muted">Select one choice for {moment(programSession.StartDate).format("dddd")}</div>
										</>}
										<div className="custom-control custom-checkbox">					
											<input type="checkbox" id={`ps${index}_${participant.Id}`} className="custom-control-input" checked={selected} onChange={(e) => programSessionChanged(programSession, participant.Id, e.target.checked)} disabled={programGroup?.AllowWaitlist === false && !selected && programSession.Enrollment > programSession.Limit} />
											<label htmlFor={`ps${index}_${participant.Id}`} className="custom-control-label small">
												{moment(programSession.StartDate).format("dddd, LL")}, {moment(programSession.StartDate).format("h:mm A")} - {moment(programSession.EndDate).format("h:mm A")}
												{/* - ({programSession.Enrollment + getProgramSessionQuantity(programSession)}/{programSession.Limit}) */}
												{/* {isProgramSessionSpaceAvailable(programSession) && <>
												<span className="small text-black-50"> ({programSession.Limit - programSession.Enrollment - getProgramSessionQuantity(programSession)} spaces available)</span>
												</>} */}
												{/* {programGroup?.AllowWaitlist === true && !isProgramSessionSpaceAvailable(programSession) && <>
												<span className="small text-black-50"> (join wait list)</span>
												</>} */}
											</label>
											{selected && programGroup?.AllowWaitlist === true && sessionParticipant === undefined && programSession.Enrollment >= programSession.Limit && <>
												<div className="alert alert-warning d-inline-block p-1 pl-2 pr-2 mb-0 small">This session is over capacity. Participant will be placed on wait list.</div>
											</>}
										</div>	
								
									{/* {selected &&
									<ProgramRegistrationActivitySelection program={program} programGroup={programGroup} programSession={getProgramSession(registrationProgramSession?.ProgramSessionId ?? 0)} participantId={participant.Id} activitySelectionMap={activitySelectionMap} />
									} */}
								</div>
							})}

							{index < (programRegistration?.Data.Participants.length - 1 ?? 0) && <hr />}
						</div>
					})}
					</div>
					{/* {registration.Cost > 0 &&
					<div className="mt-2 font-weight-bold">Refund Amount: {currency.format(registration.Cost)}</div>
					} */}
				</div>
				
				<hr />
				<div className="row">
					{/* <div className="col-sm-5"></div> */}
					<div className="col-sm-6">
						<Button onClick={handleUpdate} disabled={isUpdating} className="btn-block btn-success mb-2 mb-sm-0">{isUpdating && <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />}{!isUpdating ? "Update Registration" : "Updating..."}</Button>
					</div>
					<div className="col-sm-6">
						<Button onClick={handleClose} className="btn-block btn-light">Cancel</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	</>
}

interface EditActivitiesModalProps {
	registration: RegistrationHistory
}

const EditActivitiesModal = ({ registration, ...props }: EditActivitiesModalProps & ModalProps) => {
	const { data: configuration } = useConfigurationQuery();
	const { mutate: updateRegistration, isLoading: isUpdating } = useUserRegistrationUpdateMutation();
	const { data: programRegistrationData } = useUserRegistrationQuery(props.show ? registration?.RegistrationId : 0);
	const [programRegistration, setProgramRegistration] = useState<ProgramRegistration|undefined>();
	const { data: programGroups } = useProgramGroupsQuery();
	const { data: programSessions } = useProgramSessionsByIdQuery(programRegistration?.ProgramId ?? 0);
	const { data: programActivities } = useProgramActivitiesQuery();
	// const { data: programActivitySessions } = useProgramActivitySessionsForProgramQuery(programRegistrationData?.Data.ProgramId ?? 0, programRegistrationData?.Data.DateFrom ?? new Date().toISOString(), programRegistrationData?.Data.DateTo ?? new Date().toISOString());
	const { data: programActivitySessionsUngrouped } = useProgramActivitySessionsQuery();

	const programSessionsForReservation = programSessions?.filter(s => 
		moment(s.StartDate).isSameOrAfter(moment(registration.DateFrom)) && 
		moment(s.EndDate).isSameOrBefore(moment(registration.DateTo)));

	const getProgramActivity = (id: number) => programActivities?.find(a => a.Id === id);
	const getProgramActivitySession = (id: number) => programActivitySessionsUngrouped?.find(a => a.Id === id);

	const programGroup = programGroups?.find(g => g.Id === registration.ProgramGroupId);

	useEffect(() => {
		setProgramRegistration(_.cloneDeep(programRegistrationData));
	}, [programRegistrationData, props.show]);

	const getProgramActivitySessions = (programSession: ProgramSession) => {
		let results = programActivitySessionsUngrouped?.filter(p => { 
			return p.ProgramIds.includes(programRegistration?.ProgramId ?? 0) &&
				moment(p.StartDate).isSameOrAfter(moment(programSession?.StartDate)) &&
				moment(p.EndDate).isSameOrBefore(moment(programSession?.EndDate));
		}) ?? [];

		results = results.sort((a, b) => { return moment(a.StartDate).isBefore(moment(b.StartDate)) ? -1 : 1 });

		let sessionsGroupedByTime: ProgramActivitySession[][] = [];
		let times = _.uniq(results.map(r => moment(r.StartDate).toISOString()));

		for (let time of times) {
			let classesForTime = results.filter(r => moment(r.StartDate).toISOString() === time);
			sessionsGroupedByTime.push(classesForTime);
		}
		
		return sessionsGroupedByTime;
	}

	const showActivityPrice = (activityId: number) => {
		console.log(getProgramActivity(activityId)?.Title)
		return (getProgramActivity(activityId)?.Price ?? 0) > 0;
	}

	const getActivityPriceDescription = (activityId: number) => {
		let programActivity = getProgramActivity(activityId);
		if (programActivity === undefined)
			return "n/a";

		let price = currency.format(programActivity.Price);
		return `${price} ${programActivity.PriceDescription}`;
	}

	const getParticipantActivities = (programSessionId: number, participantId: string) => {
		return programRegistration?.Data.ProgramSessions.find(p => p.ProgramSessionId === programSessionId)?.Participants.find(a => a.ParticipantId === participantId);
	}

	const updateActivitySelection = (programSessionId: number, participantId: string, index: number, activitySessionId: number) => {
		let rps = programRegistration?.Data.ProgramSessions.find(s => s.ProgramSessionId === programSessionId);
		if (!rps)
			return;

		let participant = rps.Participants.find(p => p.ParticipantId === participantId);
		if (!participant)
			return;

		participant.ActivitySessionIds[index] = activitySessionId;

		if (programGroup?.ActivitySelectionType === ActivitySelectionType.Single) {
			let programSession = programSessions?.find(s => s.Id == programSessionId);
			let startOfWeek = moment(programSession?.StartDate).startOf("week");
			let endOfWeek = moment(programSession?.EndDate).endOf("week");

			let rpsToUpdate = programRegistration?.Data.ProgramSessions.filter(s => s.ProgramSessionId !== programSessionId &&
				moment(programSessions!.find(p => p.Id == s.ProgramSessionId)!.StartDate).isSameOrAfter(startOfWeek) &&
				moment(programSessions!.find(p => p.Id == s.ProgramSessionId)!.StartDate).isSameOrBefore(endOfWeek)) ?? [];

			for (let rps of rpsToUpdate) {
				let participant = rps.Participants.find(p => p.ParticipantId === participantId);
				if (!participant)
					continue;
				// for (let selection of selections) {
					// let index = selections.findIndex(s => s === selection);
					let pas = programActivitySessionsUngrouped?.find(s => s.Id === activitySessionId);
					let pa = programActivities?.find(a => a.Id === pas?.ActivityId);
					let ps = programSessions?.find(s => s.Id === rps.ProgramSessionId);
					if (ps === undefined)
						continue;
					let pass = programActivitySessionsUngrouped?.filter(s => moment(s.StartDate).isSameOrAfter(moment(ps!.StartDate)) && moment(s.EndDate).isSameOrBefore(moment(ps!.EndDate)));
					
					let match = pass?.find(s => s.ActivityId === pa?.Id && 
						moment.duration(moment(s.StartDate).diff(moment(s.StartDate).startOf("day"))).asMinutes() === 
						moment.duration(moment(pas?.StartDate).diff(moment(pas?.StartDate).startOf("day"))).asMinutes() );

					if (participant.ActivitySessionIds.length >= index - 1 && match !== undefined)
						participant.ActivitySessionIds[index] = match?.Id ?? 0;
				// }
			}
		}

		if (programRegistration && programRegistration.Data.Metadata)
			programRegistration.Data.Metadata.activities = getActivities(programRegistration.Data, programActivities, programActivitySessionsUngrouped);

		setProgramRegistration(_.cloneDeep(programRegistration));
	}

	const handleUpdate = () => {
		updateRegistration(programRegistration!, {
			onSuccess: () => {
				handleClose();
				toastSuccess('The registration has been updated.');
			},
			onError: () => {
				toastError(null, 'The registration was not updated. Please reload the page and try again.');
			},
		})
	}

	const handleClose = () => {
		if (props.onHide)
			props.onHide();
	}

	// const programSessionChanged = (programSession: ProgramSession, participantId: string, selected: boolean) => {
	// 	if (!selected) {
	// 		let index = programRegistration?.Data.ProgramSessions.findIndex(s => s.ProgramSessionId === programSession.Id) ?? -1;
	// 		if (index >= 0) {
	// 			let participantIndex = programRegistration?.Data.ProgramSessions[index].Participants.findIndex(p => p.ParticipantId === participantId) ?? -1;
	// 			if (participantIndex >= 0)
	// 				programRegistration?.Data.ProgramSessions[index].Participants.splice(participantIndex, 1);

	// 			if (programRegistration?.Data.ProgramSessions[index].Participants.length === 0)
	// 				programRegistration?.Data.ProgramSessions.splice(index, 1);

	// 			setProgramRegistration(_.cloneDeep(programRegistration));
	// 		}
	// 	} else {
	// 		let index = programRegistration?.Data.ProgramSessions.findIndex(s => s.ProgramSessionId === programSession.Id) ?? -1;
	// 		if (index >= 0) {
	// 			programRegistration?.Data.ProgramSessions[index].Participants.push({
	// 				ParticipantId: participantId,
	// 				ActivitySessionIds: []
	// 			});
	// 		} else {
	// 			programRegistration?.Data.ProgramSessions.push({
	// 				ProgramSessionId: programSession.Id,
	// 				Participants: [{
	// 					ParticipantId: participantId,
	// 					ActivitySessionIds: []
	// 				}]
	// 			});
	// 		}

	// 		setProgramRegistration(_.cloneDeep(programRegistration));
	// 	}
	// }

	return <>
		<Modal centered {...props} enforceFocus={false} backdrop={true} size="lg">
			<Modal.Body className="p-4 shadow-lg">
				<button type="button" onClick={props.onHide} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3>Modify Skills</h3>
				
				<p>You can change the skill selections for each participant. For any other changes, please contact the {configuration?.OrganizationTitle} office at {configuration?.OrganizationPhone} or {configuration?.OrganizationEmail}.</p>
				<div className="alert alert-info pb-4">
					<div className="font-weight-bold">Online Registration #{registration.RegistrationNumber}</div>
					<div className="">{registration.ProgramGroup}: {registration.Program}</div>
					<div className="mb-3">{formatDateRange(registration.DateFrom, registration.DateTo, false)}</div>
					<div className="row pr-3">
					{programRegistration?.Data.Participants.map((participant, index) => {
						return <div key={index} className="col-sm-12">
							<div className="font-weight-bold mb-2">{participant.FirstName} {participant.LastName}</div>
							{programSessionsForReservation?.map((programSession, index) => {
								return <div className="row">
									<div className="col-sm-3 mt-2">
									{moment(programSession.StartDate).format("LL")}
									</div>
									{getProgramActivitySessions(programSession)?.map((sessionsForTime, index) => {
										let programActivityListItems = sessionsForTime?.map(s => { return { label: `${getProgramActivity(s.ActivityId)?.Title ?? ""}`, value: s.Id.toString() }}).sort((a, b) => a.label < b.label ? -1 : 1) ?? [];
										let selections = getParticipantActivities(programSession.Id, participant.Id);
										let selectedActivityId = getProgramActivitySession(selections?.ActivitySessionIds[index] ?? 0)?.ActivityId ?? 0;
										return <div className="col-sm-3 pr-0" key={index}>
											<label className="small mb-0">{moment(sessionsForTime[0].StartDate).format("h:mm")}-{moment(sessionsForTime[0].EndDate).format("h:mm A")}</label>
											<select value={selections ? selections.ActivitySessionIds[index] : 0} onChange={(e) => updateActivitySelection(programSession.Id, participant.Id, index, Number(e.target.value))} className="custom-select custom-select-sm d-block w-100 value-selected">
												{programActivityListItems.map((item, index2) => {
													return <option key={index2} value={item.value}>{item.label}</option>
												})}
											</select>
											{showActivityPrice(selectedActivityId) &&
											<div style={{ fontSize: 'smaller', marginBottom: '-22px' }}>{getActivityPriceDescription(selectedActivityId)}</div>
											}
										</div>
									})}
								</div>
							})}
							{index < (programRegistration?.Data.Participants.length - 1 ?? 0) && <hr />}
						</div>
					})}
					</div>
					{/* {registration.Cost > 0 &&
					<div className="mt-2 font-weight-bold">Refund Amount: {currency.format(registration.Cost)}</div>
					} */}
				</div>
				
				<hr />
				<div className="row">
					{/* <div className="col-sm-5"></div> */}
					<div className="col-sm-6">
						<Button onClick={handleUpdate} disabled={isUpdating} className="btn-block btn-success mb-2 mb-sm-0">{isUpdating && <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />}{!isUpdating ? "Update Registration" : "Updating..."}</Button>
					</div>
					<div className="col-sm-6">
						<Button onClick={handleClose} className="btn-block btn-light">Cancel</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	</>
}

interface DeleteRegistrationModalProps {
	registration: RegistrationHistory
}

const DeleteRegistrationModal = ({ registration, ...props }: DeleteRegistrationModalProps & ModalProps) => {
	const { mutate: cancelRegistration, isLoading } = useUserRegistrationCancelMutation();

	const handleCancel = () => {
		cancelRegistration(registration.RegistrationId, {
			onSuccess: () => {
				handleClose();
				toastSuccess('The registration has been cancelled.');
			},
			onError: () => {
				toastError(null, 'The registration was not cancelled. Please reload the page and try again.');
			},
		})
	}

	const handleClose = () => {
		if (props.onHide)
			props.onHide();
	}

	return <>
		<Modal centered {...props} enforceFocus={false} backdrop={true}>
			<Modal.Body className="p-4 shadow-lg">
				<button type="button" onClick={props.onHide} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3>Cancel Registration</h3>
				
				<p>Please confirm that you would like to cancel this registration.</p>
				<div className="alert alert-info">
					<div className="font-weight-bold">Online Registration #{registration.RegistrationNumber}</div>
					<div className="">{registration.ProgramGroup}: {registration.Program}</div>
					<div className="">{formatDateRange(registration.DateFrom, registration.DateTo, false)}</div>
					{registration.Participants.map((participant, index) => {
						return <span key={index}>
							{participant}
							{index < registration.Participants.length -1 && ", "}
						</span>
					})}
					{registration.Cost > 0 &&
					<div className="mt-2 font-weight-bold">Refund Amount: {currency.format(registration.Cost)}</div>
					}
				</div>
				<div>Cancellation is permanent, and the sessions booked for the participants of this registration will be released.</div>
				<hr />
				<div className="row">
					<div className="col-sm-6">
						<Button onClick={handleCancel} disabled={isLoading} className="btn-block btn-danger mb-2 mb-sm-0">{isLoading && <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />}{!isLoading ? "Yes, Cancel Registration" : "Cancelling..."}</Button>
					</div>
					<div className="col-sm-6">
						<Button onClick={handleClose} className="btn-block btn-light">No, Don't Cancel</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	</>
}