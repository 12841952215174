import { State } from "@hookstate/core";
import moment from "moment-timezone";
import { linkTitle } from "../../../common/helpers";
import { EventState } from "./event-state";

export const findMatchingDates = (eventState: State<EventState>, dateFrom: moment.Moment, dateTo: moment.Moment, updateURL: boolean, history: History) : string => {
	if (dateFrom == null || dateTo == null) {
		if (eventState.eventDates && eventState.eventDates.length > 0)
			eventState.selectedEventDate.set(eventState.eventDates.value[0]);
		return "";
	}

	if (!eventState.event.value?.FixedDates ?? false) {
		if (process.env.NODE_ENV === "development")
			console.log("DF3 " + dateTo.toISOString());

		if (eventState.eventDates.length === 0) {
			eventState.datesSelected.set(false);
			eventState.selectedDateRange.set([]);
			eventState.selectedEventDate.set(null);
			// dateFrom = null;
			// dateTo = null;
			if (updateURL && eventState.event.value) {
				return `/events/${linkTitle(eventState.event.value.Title)}/inventory?`;//, search: params.toString() });
				// this.router.navigate(["/events", Helper.linkTitle(this.event.Title)], { 
				// 	replaceUrl: true
				// });
			}
		}

		var eventDates = eventState.eventDates.get({ noproxy: true });
		var containingEventDate = eventDates[0];
		for (let eventDate of eventDates) {
			if (moment(dateFrom).isBetween(moment(eventDate.DateFrom), moment(eventDate.DateTo), "date", "[]"))
				containingEventDate = eventDate;
		}

		if (containingEventDate != null)
			eventState.selectedEventDate.set(containingEventDate);

		if (dateFrom.startOf("day").isBefore(moment())) {
			dateFrom = moment().add(1, "day").startOf("day");//new Date();
		}
		
		// need to limit date range to min or max dates
		// if (this.dateFrom.getTime() < new Date(this.selectedEventDate.DateFrom).getTime()) {
		if (moment(dateFrom).isBefore(moment(eventState.selectedEventDate.value?.DateFrom), 'date')) {
			dateFrom = moment(eventState.selectedEventDate.value?.DateFrom).startOf('day');
		}

		if (dateFrom.isAfter(moment(eventState.selectedEventDate.value?.DateTo)))
			dateFrom = moment(eventState.selectedEventDate.value?.DateTo).add(-1, "days");
		
		if (dateTo.isAfter(moment(eventState.selectedEventDate.value?.DateTo)))
			dateTo = moment(eventState.selectedEventDate.value?.DateTo);

		if (dateTo.diff(dateFrom, "days") < 0) {
			dateTo = moment(dateFrom).add(1, "days");
		}

		if (dateTo.diff(dateFrom, "days") > 31) {
			dateTo = moment(dateFrom).add(31, "days");
		}

		// adjustMinDateForCutOffTime();

		eventState.selectedDateRange.set([dateFrom.toISOString(), dateTo.toISOString()]);

		if (updateURL === true)
			updateUrl(dateFrom, dateTo, history);
		return "";
	}

	var found = false;
	var foundDateFrom: moment.Moment = moment();
	var foundDateTo: moment.Moment = moment();

	eventDates = eventState.eventDates.get({ noproxy: true });

	for (let eventDate of eventDates) {
		// if (found)
		// 	continue;

		var eddateFrom = moment(eventDate.DateFrom);
		var eddateTo = moment(eventDate.DateTo);

		if (dateFrom.isSame(eddateFrom) && //.get('milliseconds') === dateFrom.get('milliseconds') && 
			dateTo.isSame(eddateTo)) {//.get('milliseconds') === dateTo.get('milliseconds')) {
			found = true;
			eventState.datesSelected.set(true);

			eventState.selectedEventDate.set(eventDate);
			// this.dateFrom = dateFrom;
			// this.dateTo = dateTo;
			foundDateFrom = dateFrom;
			foundDateTo = dateTo;

			if (process.env.NODE_ENV === "development")
				console.log("DF5 " + dateTo.toISOString());

			if (updateURL && eventState.event.value) {
				return `/events/${linkTitle(eventState.event.value.Title)}`
				// this.router.navigate(["/events", Helper.linkTitle(this.event.Title)], { 
				// 	queryParams: {
				// 		from: this.dateFrom.toISOString(),
				// 		to: this.dateTo.toISOString()
				// 	},
				// 	queryParamsHandling: "merge",
				// 	replaceUrl: true
				// });
			}
		} else if (dateFrom.isSameOrBefore(eddateFrom) && dateTo.isSameOrAfter(eddateTo)) {
			
			found = true;
			eventState.datesSelected.set(true);
			eventState.selectedEventDate.set(eventDate);
			// this.dateFrom = dateFrom;
			// this.dateTo = dateTo;
			foundDateFrom = dateFrom;
			foundDateTo = dateTo;

			if (process.env.NODE_ENV === "development")
				console.log("DF8 " + dateTo.toISOString());

			if (updateURL && eventState.event.value) {
				return `/events/${linkTitle(eventState.event.value.Title)}`
				// this.router.navigate(["/events", Helper.linkTitle(this.event.Title)], { 
				// 	queryParams: {
				// 		from: this.dateFrom.toISOString(),
				// 		to: this.dateTo.toISOString()
				// 	},
				// 	queryParamsHandling: "merge",
				// 	replaceUrl: true
				// });
			}
		}
	};

	if (found) {
		dateFrom = foundDateFrom;
		dateTo = foundDateTo;
	}

	if (!found) {

		eventDates = eventState.eventDates.get({ noproxy: true });
		if (eventDates.length > 0) {
			eventState.selectedEventDate.set(eventDates[0]);
			dateFrom = moment(eventState.eventDates.value[0].DateFrom);
			dateTo = moment(eventState.eventDates.value[0].DateTo);
			if (process.env.NODE_ENV === "development")
				console.log("DF4");
			if (updateURL && eventState.event.value) {
				return `/events/${linkTitle(eventState.event.value.Title)}`
				// this.router.navigate(["/events", Helper.linkTitle(this.event.Title)], { 
				// 	queryParams: {
				// 		from: this.dateFrom.toISOString(),
				// 		to: this.dateTo.toISOString()
				// 	},
				// 	queryParamsHandling: "merge",
				// 	replaceUrl: true
				// });
			}
		} else {
			eventState.datesSelected.set(false);
			eventState.selectedDateRange.set([]);
			eventState.selectedEventDate.set(null);
			// dateFrom = null;
			// dateTo = null;
			if (updateURL) {
				return `/events/${linkTitle(eventState.event.value.Title)}`
				// this.router.navigate(["/events", Helper.linkTitle(this.event.Title)], { 
				// 	replaceUrl: true
				// });
			}
		}
	}

	return "";
}

const updateUrl = (dateFrom: moment.Moment, dateTo: moment.Moment, history: any) => {
	const params = new URLSearchParams();
	params.append("dateFrom", moment(dateFrom).toISOString());
	params.append("dateTo", moment(dateTo).toISOString());

	history.replace({ search: params.toString() });
}