import { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { getLocalDate } from '../helpers';

function DateRangeInput({ dateRange, minDate, maxDate, onDateRangeSelected, isInvalidDate }) {
	const [selectedDateRange, setSelectedDateRange] = useState(dateRange ?? []);
	const [_minDate, setMinDate] = useState(minDate ?? moment().toDate());
	const [_maxDate, setMaxDate] = useState(maxDate ?? moment().add(1, 'year').toDate());
	// const [canSearch, setCanSearch] = useState(false);
	const keyRef = useRef(Date.now());

	useEffect(() => {
		keyRef.current = Date.now();
	}, []);

	useEffect(() => {
		setSelectedDateRange(dateRange ?? []);
		keyRef.current = Date.now();
	}, [dateRange]);

	useEffect(() => {
		setMinDate(minDate);
		setMaxDate(maxDate);
		keyRef.current = Date.now();
	}, [minDate, maxDate]);

	function handleCallback(start, end, label) {
		// console.log(moment(end).add(-1, 'days').toISOString())
		// // console.log('dates selected: ', start, end, label);
		// setSelectedDateRange([start.toDate(),end.toDate()]);
		// // onDateRangeSelected([start.toDate(),end.toDate()]);
		// onDateRangeSelected([moment(start).toISOString(),moment(end).toISOString()]);
		// // setCanSearch(true);
	}

	function handleApply(e, picker) {
		setSelectedDateRange([picker.startDate.toDate(),picker.endDate.startOf("day").toDate()]);
		// onDateRangeSelected([picker.startDate.toDate(),picker.endDate.toDate()]);
		onDateRangeSelected([moment(picker.startDate).toISOString(),moment(picker.endDate).startOf("day").toISOString()]);
	}

	function showDatePicker(e, picker) {
		keyRef.current = Date.now();
	}

	function getDisplayDateRange() {
		return selectedDateRange.length > 0 ? `${moment(getLocalDate(selectedDateRange[0])).format('MMM D, YYYY')} - ${moment(getLocalDate(selectedDateRange[1])).format('MMM D, YYYY')}` : '';
	}

	return <>
		<DateRangePicker key={keyRef.current} onCallback={handleCallback} onApply={handleApply} onShow={showDatePicker} initialSettings={{autoApply: true, opens: 'center', class: 'shadow', showDropdowns: true, autoUpdateInput: false, startDate: moment(selectedDateRange[0]), endDate: moment(selectedDateRange[1]), minDate: moment(_minDate), maxDate: moment(_maxDate), isInvalidDate: isInvalidDate }}>
			<input type="text" className="form-control" readOnly={true} value={getDisplayDateRange()} placeholder="Arrival and Departure" style={{backgroundColor: 'white'}} />
		</DateRangePicker>
	</>
}

export default DateRangeInput;