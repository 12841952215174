import { useQuery } from 'react-query';
import { useAuthState } from '../state/auth.state';
import { api } from './api';
import { ValidationError } from './cart.models';
import { Reservation, ReservationCustomFieldGroup, ReservationHistory, ReservationInventoryPolicy, ReservationPageContent, ReservationPolicy } from './reservation.models';

export const validate = async (reservation: Reservation) => {
	let response = await api.post<ValidationError[]>(`/api/reservations/validate`, reservation);
	return response.data;
}

export const useReservationHistoryQuery = () => { 
	const { isAuthenticated, userInfo } = useAuthState();

	return useQuery(['reservation-history', userInfo?.email], async () => {
		let res = await api.get<ReservationHistory[]>(`/api/reservations/history`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: isAuthenticated() });
}

export const useReservationPageContentQuery = (eventId: number, enabled: boolean = true) => {
	return useQuery(['reservation-page-content', eventId], async () => {
		let res = await api.get<ReservationPageContent[]>(`/api/reservations/pagecontent/${eventId}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60 * 60000, enabled: enabled && eventId > 0 });
}

export const useReservationCustomFieldsQuery = (eventId: number, enabled: boolean = true) => {
	return useQuery(['reservation-custom-fields', eventId], async () => {
		let res = await api.get<ReservationCustomFieldGroup[]>(`/api/reservations/customfields/${eventId}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: enabled && eventId > 0 });
}

export const useReservationPoliciesQuery = (eventId: number) => {
	return useQuery(['reservation-policies', eventId], async () => {
		let res = await api.get<ReservationPolicy[]>(`/api/reservations/policies/${eventId}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: eventId > 0 });
}

export const useReservationInventoryPoliciesQuery = (eventId: number) => {
	return useQuery(['reservation-inventory-policies', eventId], async () => {
		let res = await api.get<ReservationInventoryPolicy[]>(`/api/reservations/inventorypolicies/${eventId}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: eventId > 0 });
}