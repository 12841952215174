import { useField } from "formik";
import { templateParser, templateFormatter, parseDigit } from 'input-format';
import ReactInput from 'input-format/react';

interface PhoneInputFieldProps {
	name: string
	className?: string
	required?: boolean,
	autoComplete?: "on"|"off"|string,
	placeholder?: string;
}

export const PhoneInputField = (props: PhoneInputFieldProps) => {
	const [field, meta, helpers] = useField(props.name);
	const TEMPLATE = '(xxx) xxx-xxxx';
	const parse = templateParser(TEMPLATE, parseDigit);
	const format = templateFormatter(TEMPLATE);

	let value = meta.value as string;
	if (value !== undefined && value.startsWith("+1"))
		value = value.substring(2, value.length);

	return <ReactInput {...field} {...props} value={value} onChange={helpers.setValue} parse={parse} format={format} />
}