import _ from "lodash";
import moment from "moment-timezone";
import { Event, EventCharge, EventChargeItem, EventChargeItemQuantityType, EventDate, EventDeposit, EventDepositAmountType, EventDepositDurationType, EventDepositQuantityType, EventDepositType, EventMeal, EventMealDiscount, EventMealDiscountSpecificationQuantityType, EventMealDiscountType, EventMealPackage, EventMealRequiredType, EventMealRequirementOverride } from "../../../api/events.models";
import { InventoryCategory, InventoryItem, InventoryType } from "../../../api/inventory.models";
import { MealAgeGroup } from "../../../api/meals.models";
import { PromotionCodeDiscountType } from "../../../api/promotions.models";
import { Address, AdultGuest, ChildGuest, GuestBase, Reservation, ReservationCharge, ReservationCustomField, ReservationCustomFieldType, ReservationInventoryItem, ReservationInventoryPolicy, ReservationInventoryPolicyResult, ReservationItemBase, ReservationMeal, ReservationPolicy, ReservationPolicyResult, ReservationType } from "../../../api/reservation.models"
import { getInventoryCategory, getInventoryItem, getInventoryCapacity, getPerNightCostForInventoryItem } from "./inventory-data";

export const createReservation = (event: Event, eventDate: EventDate, type: ReservationType, inventoryTypes: any[], inventoryItems: any[], dates: { dateFrom: moment.Moment, dateTo: moment.Moment }) => {
	let reservation: Reservation = {
		Id: _.random(1000000, 9999999),
		EventId: event.Id,
		EventDateId: eventDate.Id,
		Type: type,
		DateFrom: moment(dates.dateFrom).toISOString(),
		DateTo: moment(dates.dateTo).toISOString(),
		Requests: "",
		Referral: "",
		PromotionCode: undefined,
		PromotionCodeId: 0,
		DepositType: event.DepositType,
		DepositAmount: parseFloat(event.DepositAmount),
		Items: [],
		Meals: [],
		MealPackageId: null,
		MealDiscountId: 0,
		MealDiscount: 0,
		Charges: [],
		Custom: []
	};

	
	if (inventoryItems.length > 0) {
		for (let inventoryItem of inventoryItems) {
			let inventoryType = inventoryTypes.find(t => t.Id === inventoryItem.InventoryTypeId);
			let inventoryCategory = inventoryType.InventoryCategories.find((c: any) => c.Id === inventoryItem.InventoryCategoryId);
			
			let reservationInventoryItem = {
				Type: ReservationType.Onsite,
				EventInventoryId: inventoryCategory.EventInventory[0].Id,
				EventInventoryItemId: inventoryItem.EventInventoryItems[0].Id,
				InventoryTypeId: inventoryCategory.InventoryTypeId,
				InventoryCategoryId: inventoryCategory.Id,
				InventoryItemId: inventoryItem.Id,
				Adults: 1,
				Children: 0,
				AdultGuests: [createEmtpyAdult()],
				ChildGuests: []
			} as ReservationInventoryItem;

			reservation.Items?.push(reservationInventoryItem);
		}
	}

	if (type === ReservationType.Offsite) {
		reservation.Items.push({
			Type: ReservationType.Offsite,
			Adults: 1, 
			Children: 0, 
			AdultGuests: [createEmtpyAdult()], 
			ChildGuests: []
		});
	}

	if (type === ReservationType.Virtual) {
		reservation.Items.push({
			Type: ReservationType.Virtual,
			Adults: 1, 
			Children: 0, 
			AdultGuests: [createEmtpyAdult()], 
			ChildGuests: []
		});
	}

	// TODO: Create meals

	// TODO: Create charges

	return reservation;
}

export const updateReservation = (reservation: Reservation, type: ReservationType, inventoryTypes: any[], inventoryItems: any[], dates: { dateFrom: moment.Moment, dateTo: moment.Moment }) => {
	reservation.Type = type;
	reservation.DateFrom = moment(dates.dateFrom).toISOString();
	reservation.DateTo = moment(dates.dateTo).toISOString();

	var savedGuests: ReservationItemBase[] = [];
	var newItems: ReservationInventoryItem[] = [];
	var removeItems: ReservationItemBase[] = [];

	for (let reservationItem of reservation.Items) {
		let inventoryReservationItem = reservationItem as ReservationInventoryItem;

		if (reservationItem.Type === ReservationType.Onsite && !_.some(inventoryItems, (i) => i.Id === inventoryReservationItem.InventoryItemId)) {
			removeItems.push(reservationItem);
			savedGuests.push(reservationItem);
		}
	}

	for (let reservationItem of removeItems)
	{
		reservation.Items.splice(reservation.Items.indexOf(reservationItem), 1);
	}

	for (let inventoryItem of inventoryItems) {
		var reservationItem = reservation.Items.find((item) => (item as ReservationInventoryItem).InventoryItemId === inventoryItem.Id);
			
		if (reservationItem === undefined) {
			let inventoryType = inventoryTypes.find(t => t.Id === inventoryItem.InventoryTypeId);
			let inventoryCategory = inventoryType.InventoryCategories.find((c: any) => c.Id === inventoryItem.InventoryCategoryId);

			let newItem: ReservationInventoryItem = {
				Type: ReservationType.Onsite,
				EventInventoryId: inventoryCategory.EventInventory[0].Id,
				EventInventoryItemId: inventoryItem.EventInventoryItems[0].Id,
				InventoryTypeId: inventoryCategory.InventoryTypeId,
				InventoryCategoryId: inventoryCategory.Id,
				InventoryItemId: inventoryItem.Id,
				Adults: 1,
				Children: 0,
				AdultGuests: [createEmtpyAdult()],
				ChildGuests: []
			};
			reservation.Items.push(newItem);
			newItems.push(newItem);
		} 

	}

	if (savedGuests.length > 0) {
		var saved = savedGuests.pop();
		var newItem = newItems.pop();
		while (saved != null && newItem != null) {
			newItem.AdultGuests = saved.AdultGuests;
			newItem.Adults = saved.Adults;
			newItem.ChildGuests = saved.ChildGuests;
			newItem.Children = saved.Children;

			saved = savedGuests.pop();
			newItem = newItems.pop();
		}
	}

	return reservation;
}

export const removeReservationItem = (reservation: Reservation, itemIndex: number) => {
	reservation.Items.splice(itemIndex, 1);
}

export const createEmtpyAdult = () : AdultGuest => {
	let adult: AdultGuest = {
		
		Type: "adult",
		Id: 0,
		FirstName: "",
		LastName: "",
		Gender: 0,
	
		Address: createEmtpyAddress(),
		SameAddress: true,
	
		Allergies: "",
		Dietary: "",

		Salutation: "",
		Email: "",
		HomePhone: "",
		MobilePhone: ""
	};

	return adult;
}

export const createEmtpyChild = () : ChildGuest => {
	let child: ChildGuest = {
		
		Type: "child",
		Id: 0,
		FirstName: "",
		LastName: "",
		Gender: 0,
	
		Address: createEmtpyAddress(),
		SameAddress: true,
	
		Allergies: "",
		Dietary: "",

		Birthdate: new Date()
	};

	return child;
}

export const createEmtpyAddress = () : Address => {
	let address: Address = {
		Id: 0,
		Street1: "",
		Street2: "",
		// Street3?: string;
		City: "",
		Province: "Ontario",
		PostalCode: "",
		Country: "Canada"
	}

	return address;
}

export const getTotalNights = (reservation: Reservation) : number => {
	return Math.abs(moment(reservation.DateFrom).diff(moment(reservation.DateTo), 'days'));
}

export const getTotalRooms = (reservation: Reservation) : number => {
	if (reservation.Items == null)
		return 0;

	return reservation.Items.filter(item => item.Type === ReservationType.Onsite).length;
}

export const getTotalGuests = (reservation: Reservation) : number => {
	if (reservation.Items == null)
		return 0;

	return _.sumBy(reservation.Items, item => item.Adults + item.Children);
}

export const getTotalAdults = (reservation: Reservation) : number => {
	if (reservation.Items == null)
		return 0;

	return _.sumBy(reservation.Items, item => item.Adults);
}

export const getTotalChildren = (reservation: Reservation) : number => {
	if (reservation.Items == null)
		return 0;

	return _.sumBy(reservation.Items, item => item.Children);
}

export const getInventoryItemTotal = (inventoryItem: InventoryItem, reservation: Reservation) : number => {
	let costPerNight = getPerNightCostForInventoryItem(inventoryItem);
	return costPerNight * getTotalNights(reservation);
}


export const getReservationCost = (reservation: Reservation, inventoryTypes: InventoryType[], eventMeals: EventMeal[], eventCharges: EventCharge[], includeDiscount: boolean = false) => {
	var total = 0;
	
	var inventoryTotal = getInventoryItemsTotal(reservation, inventoryTypes);
	var mealsTotal = getMealsTotal(reservation, eventMeals) - getMealsDiscount(reservation);
	var chargesTotal = getChargesTotal(reservation, eventCharges);

	total = inventoryTotal + mealsTotal + chargesTotal;
	
	if (includeDiscount) {
		if (reservation.PromotionCode != null && includeDiscount) {
			var discountAmount = reservation.PromotionCode.DiscountAmount;
			if (reservation.PromotionCode.DiscountType === PromotionCodeDiscountType.Percent)
				discountAmount = total * discountAmount / 100;
			else if (reservation.PromotionCode.DiscountType === PromotionCodeDiscountType.AccommodationPercent)
				discountAmount = inventoryTotal * discountAmount / 100;
			
			total -= discountAmount;
		}
	}

	return total;
}

export const getInventoryItemsTotal = (reservation: Reservation, inventoryTypes: InventoryType[]) => {
	var inventoryTotal = 0;

	for (let reservationItem of reservation.Items) {
		if (reservationItem.Type !== ReservationType.Onsite)
			continue;
		let reservationInventoryItem = reservationItem as ReservationInventoryItem;

		let inventoryItem = getInventoryItem(inventoryTypes ?? [], reservationInventoryItem.InventoryTypeId, reservationInventoryItem.InventoryCategoryId, reservationInventoryItem.InventoryItemId);

		if (inventoryItem)
			inventoryTotal += getInventoryItemTotal(inventoryItem, reservation);
	}

	return inventoryTotal;
}

export const getSummaryDescription = (reservation: Reservation) : string => {
	if (reservation == null)
		return "";

	var totalNights = getTotalNights(reservation);
	var totalRooms = getTotalRooms(reservation)
	var totalGuests = getTotalGuests(reservation);

	if (totalRooms > 0)
		return `${totalNights} nights, ${totalRooms} room${totalRooms === 1 ? '' : 's'}, ${totalGuests} guest${totalGuests === 1 ? '' : 's'}`;

	return `${totalNights+1} days, ${totalGuests} guest${totalGuests === 1 ? '' : 's'}`;
}

export function updateAdults(reservation: Reservation, index: number, adults: number) {
	if (reservation !== undefined && reservation.Items !== undefined) {	
		let item = reservation.Items[index];
		item.Adults = adults;
		if (item.Adults > item.AdultGuests.length) {
			var add = item.Adults - item.AdultGuests.length;
			for (var i = 0; i < add; i++) {
				var adult = createEmtpyAdult();
				adult.SameAddress = true;
				item.AdultGuests.push(adult);
			}
		} else {
			var remove = item.AdultGuests.length - item.Adults;
			for (i = 0; i < remove; i++) {
				item.AdultGuests.pop();
			}
		}
	}
}

export const updateChildren = (reservation: Reservation, index: number, children: number) => {
	if (reservation !== undefined && reservation.Items !== undefined) {	
		let item = reservation.Items[index];
		item.Children = children;
		if (item.Children > item.ChildGuests.length) {
			var add = item.Children - item.ChildGuests.length;
			for (var i = 0; i < add; i++) {
				var child = createEmtpyChild();
				child.SameAddress = true;
				item.ChildGuests.push(child);
			}
		} else {
			var remove = item.ChildGuests.length - item.Children;
			for (i = 0; i < remove; i++) {
				item.ChildGuests.pop();
			}
		}
	}
}

export const getAdults = (reservation: Reservation) : string[] => {
	var adults: string[] = [];

	for (let reservationItem of reservation.Items) {
		for (let adult of reservationItem.AdultGuests) {				
			adults.push(`${adult.FirstName} ${adult.LastName}`);
		};
	};

	return adults;
}

export const getChildren = (reservation: Reservation) : string[] => {
	var children: string[] = [];

	for (let reservationItem of reservation.Items) {
		for (let child of reservationItem.ChildGuests) {	
			var age = moment().diff(moment(child.Birthdate), "years");	
			var ageInMonths = moment().diff(moment(child.Birthdate), "months");
			children.push(`${child.FirstName} ${child.LastName} (${age >= 2 ? age : ageInMonths + ' months'})`);
		};
	};

	return children;
}

export const getInventoryType = (reservation: Reservation, inventoryTypes: InventoryType[]) => {
	var description = "";

	var inventoryCategories: InventoryCategory[] = [];
	var inventoryItems: InventoryItem[] = [];

	for (let type of inventoryTypes) {
		inventoryCategories.push(...type.InventoryCategories);
		for (let category of type.InventoryCategories) {
			inventoryItems.push(...category.InventoryItems);
		}
	}

	for (let [index, item] of reservation.Items.filter(i => ReservationType.Onsite).entries()) {
		var reservationItem = item as ReservationInventoryItem;
		var inventoryItem = inventoryItems.find(i => i.Id === reservationItem.InventoryItemId);
		if (inventoryItem != null) {
			var category = inventoryCategories.find(c => c.Id === reservationItem.InventoryCategoryId);
			if (index === 0) {
				var plural = reservation.Items.length === 1 ? "" : "s";
				description = `${category?.Metadata?.UnitDescription}${plural}: `;
			}
			// description += `${category.Title} ${inventoryItem.Description}`;
		}
		// if (index < reservation.Items.length - 1)
			// description += ", ";
	}

	return description;
}

export const getInventoryDescriptions = (reservation: Reservation, inventoryTypes: InventoryType[]) => {
	var descriptions: string[] = [];

	var inventoryCategories: InventoryCategory[] = [];
	var inventoryItems: InventoryItem[] = [];

	for (let type of inventoryTypes) {
		inventoryCategories.push(...type.InventoryCategories);
		for (let category of type.InventoryCategories) {
			inventoryItems.push(...category.InventoryItems);
		}
	}

	for (let item of reservation.Items.filter(i => i.Type === ReservationType.Onsite)) {
		var reservationItem = item as ReservationInventoryItem;
		var inventoryItem = inventoryItems.find(i => i.Id === reservationItem.InventoryItemId);
		if (inventoryItem != null) {
			var category = inventoryCategories.find(c => c.Id === reservationItem.InventoryCategoryId);
			descriptions.push(`${category?.Metadata?.Title} ${inventoryItem.Description}`);
		}
	}

	return descriptions;
}

const isReservationInventoryItemOverCapacity = (item: ReservationInventoryItem, inventoryItem: InventoryItem, inventoryCategory: InventoryCategory) : boolean => {
	return item.Adults + item.Children > getInventoryCapacity(inventoryItem, inventoryCategory);
}

export const isReservationInventoryValid = (reservation: Reservation, inventoryTypes: InventoryType[]) => {
	var valid = true;
	reservation.Items.forEach(item => {
		if (item.Type === ReservationType.Onsite) {
			var reservationInventoryItem = item as ReservationInventoryItem;
			let inventoryItem = getInventoryItem(inventoryTypes, reservationInventoryItem.InventoryTypeId, reservationInventoryItem.InventoryCategoryId, reservationInventoryItem.InventoryItemId);
			let inventoryCategory = getInventoryCategory(inventoryTypes, reservationInventoryItem.InventoryTypeId, reservationInventoryItem.InventoryCategoryId);

			if (inventoryItem !== undefined && inventoryCategory !== undefined && isReservationInventoryItemOverCapacity(reservationInventoryItem, inventoryItem, inventoryCategory)) {
				valid = false;
			}
		}
	})
	return valid;
}

export const isReservationGuestsValid = (reservation: Reservation) => {
	var valid = true;

	let findMatchingCount = (guest: GuestBase) => {
		var count = 0;
		for (var item of reservation.Items) {
			for (var adultGuest of item.AdultGuests) {
				if (adultGuest.FirstName === guest.FirstName &&
					adultGuest.LastName === guest.LastName)
					count++;
			}
			for (var childGuest of item.ChildGuests) {
				if (childGuest.FirstName === guest.FirstName &&
					childGuest.LastName === guest.LastName)
					count++;
			}
		}
		return count;
	}

	for (var item of reservation.Items) {
		for (var adultGuest of item.AdultGuests) {
			if (findMatchingCount(adultGuest) > 1)
				valid = false;
		}
		for (var childGuest of item.ChildGuests) {
			if (findMatchingCount(childGuest) > 1)
				valid = false;
		}
	}
	return valid;
}

export const getFilteredMealPackagesForReservationType = (mealPackages: EventMealPackage[]|undefined, reservationType: ReservationType) => {
	if (reservationType === ReservationType.Onsite)
		return mealPackages?.filter(m => m.AllowOnsite === true) ?? [];
	if (reservationType === ReservationType.Offsite)
		return mealPackages?.filter(m => m.AllowOffsite === true) ?? [];
	if (reservationType === ReservationType.Virtual)
		return mealPackages?.filter(m => m.AllowVirtual === true) ?? [];
	
	return [];
}

export const loadMealsByDate = (event: Event, eventMeals: EventMeal[], mealPackages: EventMealPackage[], reservation: Reservation) => {
	var availableFrom: moment.Moment;
	if (mealPackages != null && mealPackages.length > 0 && mealPackages[0].AvailableFrom != null)
		availableFrom = moment(mealPackages[0].AvailableFrom);
	var availableTo: moment.Moment;
	if (mealPackages != null && mealPackages.length > 0 && mealPackages[0].AvailableTo != null)
		availableTo = moment(mealPackages[0].AvailableTo);

	if (mealPackages != null && mealPackages.length === 0) {
		return [];
	}

	var days: moment.Moment[] = [];
	if (reservation.Type !== ReservationType.Virtual) {
		var start = moment.utc(reservation.DateFrom);
		var end = moment.utc(reservation.DateTo);
		while (start <= end) {
			days.push(moment(start));
			start.add(1, "days");
		}
	}
		
	var parsedCheckInTime = moment.utc(event.CheckInTime, 'HH:mm'); // TODO: Make sure this works with UTC event dates
	var checkInTime = moment.utc(reservation.DateFrom);
	checkInTime.hour(parsedCheckInTime.hour());//moment.utc(this.reservationService.Reservation.DateFrom).startOf('day').hour(parsedCheckInTime.hour()).minute(parsedCheckInTime.minute());

	var parsedCheckOutTime = moment.utc(event.CheckOutTime, 'HH:mm');
	var checkOutTime = moment.utc(reservation.DateTo);//.startOf('day').hour(parsedCheckOutTime.hour()).minute(parsedCheckOutTime.minute());
	checkOutTime.hour(parsedCheckOutTime.hour());

	var fromDayIndex = moment(reservation.DateFrom).day();
	var toDayIndex = moment(reservation.DateTo).day();

	var mealsByDate: EventMeal[][] = [];
	days.forEach((day, index) => {
		var dayIndex = day.get("weekday");
		// var dayKey = day.toISOString();
		mealsByDate[index] = [];
		var filteredEventMeals = eventMeals.filter(eventMeal => eventMeal.MealDefinitionItem.DayOfWeek === dayIndex || eventMeal.MealDefinitionItem.DayOfWeek === 7 /* Any */).sort((a, b) => a.MealDefinitionItem.DayOfWeek < b.MealDefinitionItem.DayOfWeek ? -1 : 1);

		if (filteredEventMeals.length > 0) {
			filteredEventMeals.forEach(eventMeal => {
				if (mealsByDate[index].some(ev => ev.MealId === eventMeal.MealId))
					return;
				var parsedMealTime = moment.utc(eventMeal.Meal.Time, 'HH:mm');
				var mealTime = moment.utc(day);//.hour(parsedMealTime.hour()).minute(parsedMealTime.minute());
				mealTime.hour(parsedMealTime.hour());
				if (parsedMealTime.hour() <= (0 - (moment().utcOffset() / 60)))
					mealTime.add(1, 'day');

				var outsideRange = ((index === 0 && dayIndex === fromDayIndex && mealTime.isBefore(checkInTime)) ||
					(index === days.length - 1 && dayIndex === toDayIndex && mealTime.isAfter(checkOutTime)) ||
					(availableFrom != null && mealTime.isBefore(availableFrom)) || 
					(availableTo != null && mealTime.isAfter(availableTo)));

				if (!outsideRange)
					mealsByDate[index].push(eventMeal);
			});
		}

		mealsByDate[index] = mealsByDate[index].sort((a,b) => { return a.MealId - b.MealId; });
	})

	return mealsByDate;
}

export const addMeals = (reservation: Reservation, mealsByDate: EventMeal[][]) => {
	if (reservation.Type === ReservationType.Virtual)
		return;

	var days: Date[] = [];
	var start = new Date(reservation.DateFrom);
	var end = new Date(reservation.DateTo);
	var current = new Date(start);
	while (current <= end) {
		days.push(new Date(current));
		current = new Date(current.setDate(current.getDate() + 1));
	}

	var validMeals: ReservationMeal[] = [];
	reservation.Meals.forEach(meal => {
		var mealDate = new Date(meal.Date);
		if (mealDate >= start && mealDate <= end) {
			validMeals.push(meal);
		}
	});

	reservation.Meals = validMeals;

	if (reservation.Meals.length === 0)
		reservation.MealPackageId = null;
	
	days.forEach((day, index) => {
		// var dayKey = day.toISOString();
		var meals = mealsByDate[index];
		if (meals == null)
			return;

		meals.forEach(meal => {
			if (meal.Disabled === true)
				return;
				
			var existing = reservation.Meals.find(m => m.Date === day.toISOString() && m.EventMealId === meal.Id);
			if (existing != null)
				return;

			var reservationMeal: ReservationMeal = {
				EventMealId: meal.Id,
				Date: day.toISOString(),
				Quantities: []
			}

			meal.MealDefinitionItem.MealDefinitionItemAgeGroups.forEach(ageGroup => {
				reservationMeal.Quantities.push({
					MealAgeGroupId: ageGroup.MealAgeGroupId,
					Quantity: 0
				});
			});

			reservation.Meals.push(reservationMeal);
		});
	});

	return reservation;
}

export const updateMeals = (reservation: Reservation, event: Event, eventMeals: EventMeal[], eventMealRequirementOverrides: EventMealRequirementOverride[], mealPackages: EventMealPackage[]) => {
	if (reservation.Type === ReservationType.Virtual)
		return;
		
	reservation.Meals.forEach(reservationMeal => {
		var eventMeal = eventMeals.find(e => e.Id === reservationMeal.EventMealId);
		if (eventMeal === undefined)
			return;
		reservationMeal.Quantities.forEach(reservationMealQuantity => {
			var mealDefinitionItemAgeGroup = eventMeal?.MealDefinitionItem.MealDefinitionItemAgeGroups.find(a => a.MealAgeGroupId === reservationMealQuantity.MealAgeGroupId);
			if (mealDefinitionItemAgeGroup != null) {
				var ageGroup = mealDefinitionItemAgeGroup.MealAgeGroup;
				var minimum = getEventMealMinimumQuantity(reservation, eventMeal!, eventMealRequirementOverrides, ageGroup);
				var maximum = getEventMealMaximumQuantity(reservation, ageGroup);

				var eventMealPackage = mealPackages?.find(p => p.Id === reservation.MealPackageId);
				if (eventMealPackage !== undefined) {
					var include = eventMealPackage.EventMealPackageItems.find(i => i.EventMealId === eventMeal?.Id);
					reservationMealQuantity.Quantity = include ? maximum : minimum;
				}

				if (reservationMealQuantity.Quantity < minimum)
					reservationMealQuantity.Quantity = minimum;
				else if (reservationMealQuantity.Quantity > maximum)
					reservationMealQuantity.Quantity = maximum;
			}
		});
	});

	// this.updateDiscount(reservation);

	return reservation;
}

export const getMealAgeGroups = (dateIndex: number|null, days: Date[], mealsByDate: EventMeal[][]) : MealAgeGroup[] => {
	if (mealsByDate === undefined || mealsByDate.length === 0)
		return [];

	if (dateIndex !== null && mealsByDate[dateIndex] !== null && mealsByDate[dateIndex].filter(eventMeal => eventMeal.Disabled === false).length === 0)
		return [];

	if (dateIndex == null) {
		var result: MealAgeGroup[]|undefined = undefined;
		days.forEach((day, index) => {
			if (mealsByDate[index] !== undefined && mealsByDate[index].length > 0) {
				result = mealsByDate[index][0].MealDefinitionItem.MealDefinitionItemAgeGroups.map(mdiag => mdiag.MealAgeGroup);
			}
		})
		if (result !== undefined)
			return result;
	}

	// var dayKey = date.toISOString();
	if (mealsByDate[dateIndex!] === undefined || mealsByDate[dateIndex!].length === 0)
		return [];

	return mealsByDate[dateIndex!][0].MealDefinitionItem.MealDefinitionItemAgeGroups.map(mdiag => mdiag.MealAgeGroup);
}

export const getEventMealMinimumQuantity = (reservation: Reservation, eventMeal: EventMeal, eventMealRequirementOverrides: EventMealRequirementOverride[]|undefined, ageGroup: MealAgeGroup) : number => {
	if (eventMeal.RequiredType === EventMealRequiredType.NotRequired) {
		// TODO: Check meal requirement overrides
		return 0;
	}

	if (eventMeal.RequiredType === EventMealRequiredType.NotRequiredIfOtherSelected)
		return 0;

	// var cachedEventMealRequirementOverrides = this.getCachedEventMealRequirementOverrides(reservation.EventId, reservation.EventDateId);

	if (eventMeal.RequiredType === EventMealRequiredType.Required) {
		if (ageGroup.MaxAge > 50) {
			return _.sumBy(reservation.Items, (item) => {
					var inventoryItem = item as ReservationInventoryItem;
					if (item.Type === ReservationType.Onsite && eventMealRequirementOverrides) {
						var override = eventMealRequirementOverrides.find(e => e.EventMealId === eventMeal.Id && e.EventInventoryItemId === inventoryItem.EventInventoryItemId);
						if (override != null && override.Required === false)
							return 0;
					}
					return item.Adults;
				}) + 
				_.sumBy(reservation.Items, (item) => {
					var inventoryItem = item as ReservationInventoryItem;
					if (item.Type === ReservationType.Onsite && eventMealRequirementOverrides) {
						var override = eventMealRequirementOverrides.find(e => e.EventMealId === eventMeal.Id && e.EventInventoryItemId === inventoryItem.EventInventoryItemId);
						if (override != null && override.Required === false)
							return 0;
					}
					return _.filter(item.ChildGuests, (child) => { 
						var age = moment().diff(moment(child.Birthdate), "years");
						return age >= ageGroup.MinAge && age <= ageGroup.MaxAge 
					}).length
				});
				
		} else {
			return _.sumBy(reservation.Items, (item) => _.filter(item.ChildGuests, (child) => { 
					var inventoryItem = item as ReservationInventoryItem;
					if (item.Type === ReservationType.Onsite && eventMealRequirementOverrides) {
						var override = eventMealRequirementOverrides.find(e => e.EventMealId === eventMeal.Id && e.EventInventoryItemId === inventoryItem.EventInventoryItemId);
						if (override != null && override.Required === false)
							return 0;
					}
					var age = moment().diff(moment(child.Birthdate), "years");
					return age >= ageGroup.MinAge && age <= ageGroup.MaxAge 
				}).length);
		}
	}

	return 0;
}

export const getEventMealMaximumQuantity = (reservation: Reservation, ageGroup: MealAgeGroup) : number => {
	if (ageGroup.MaxAge > 50) {
		return _.sumBy(reservation.Items, (item) => item.Adults) + 
			_.sumBy(reservation.Items, (item) => _.filter(item.ChildGuests, (child) => { 
				var age = moment().diff(moment(child.Birthdate), "years");
				return age >= ageGroup.MinAge && age <= ageGroup.MaxAge 
			}).length);
	} else {
		return _.sumBy(reservation.Items, (item) => _.filter(item.ChildGuests, (child) => { 
				var age = moment().diff(moment(child.Birthdate), "years");
				return age >= ageGroup.MinAge && age <= ageGroup.MaxAge 
			}).length);
		// return _.sumBy(this.reservation.Items, (item) => item.Children);
	}
}

export const getEventMealOverride = (reservation: Reservation, eventMeal: EventMeal, eventMealRequirementOverrides: EventMealRequirementOverride[]|undefined) => {
	let eventInventoryItemIds = reservation.Items.filter(i => i.Type === ReservationType.Onsite).map(i => (i as ReservationInventoryItem).EventInventoryItemId);
	
	return eventMealRequirementOverrides?.find(o => _.some(eventInventoryItemIds, t => t === o.EventInventoryItemId) && o.EventMealId === eventMeal.Id);
}

export const getMealPrice = (eventMeal: EventMeal, ageGroupIndex: number) => {
	return eventMeal.MealDefinitionItem.MealDefinitionItemAgeGroups[ageGroupIndex].Price;
}

export const getMealDiscount = (reservation: Reservation, eventMeal: EventMeal, ageGroupIndex: number, date: Date, appliedDiscount: EventMealDiscount|undefined) => {
	var totalDiscount = 0;

	totalDiscount += getMealPrice(eventMeal, ageGroupIndex) * getDefaultMealDiscount(eventMeal, ageGroupIndex) / 100;

	var quantity = getAgeGroupQuantityForMeal(reservation, eventMeal, ageGroupIndex, date);

	if (appliedDiscount === undefined)
		return totalDiscount * quantity;
	
	if (appliedDiscount.Type === EventMealDiscountType.Percent) {
		for (let mealDiscountSpecification of appliedDiscount.Specification.Meals) {
			if (appliedDiscount.Amount > getDefaultMealDiscount(eventMeal, ageGroupIndex) && 
				mealDiscountSpecification.MealId === eventMeal.MealId) {
					totalDiscount = getMealPrice(eventMeal, ageGroupIndex) * appliedDiscount.Amount / 100;
			}
		}
	}

	// TODO: How to handle a discount amount that is not a percent?

	return totalDiscount * quantity;
}

export const getDefaultMealDiscount = (eventMeal: EventMeal, ageGroupIndex: number) => {
	return eventMeal.MealDefinitionItem.MealDefinitionItemAgeGroups[ageGroupIndex].DefaultDiscount;
}

export const getAgeGroupQuantityForMeal = (reservation: Reservation, eventMeal: EventMeal, ageGroupIndex: number, date: Date) => {
	let meal = reservation.Meals.find(m => m.EventMealId === eventMeal.Id && m.Date === date.toISOString());
	if (meal === undefined)
		return 0;
	
	return meal.Quantities[ageGroupIndex].Quantity;
}

export const getMealsQuantity = (reservation: Reservation, eventMeals: EventMeal[]) => {
	return _.sumBy(reservation.Meals, (reservationMeal) => {
		var eventMeal = eventMeals?.find(m => m.Id === reservationMeal.EventMealId);
		if (eventMeal == null)
			return 0;
		return _.sumBy(reservationMeal.Quantities, (quantity) => {
			return quantity.Quantity;
		});
	})
}

export const getMealsDiscount = (reservation: Reservation) => {
	return reservation.MealDiscount;
}

export const getApplicableDiscount = (reservation: Reservation, eventMeals: EventMeal[], eventMealDiscounts: EventMealDiscount[]) : EventMealDiscount|undefined => {

	var appliedDiscount: EventMealDiscount|undefined = undefined;
	
	eventMealDiscounts.forEach(discount => {
		if (isDiscountValid(reservation, discount, eventMeals)) {
			appliedDiscount = discount;
		}
	});

	return appliedDiscount;
}

export const isDiscountValid = (reservation: Reservation, discount: EventMealDiscount, eventMeals: EventMeal[]) : boolean => {
	var valid = true;

	if (discount == null || discount.Specification == null || 
		discount.Specification.Meals == null || discount.Specification.Meals.length === 0)
		valid = false;

	for (var i = 0; i < discount.Specification.Meals.length; i++) {
		var mealDiscountSpec = discount.Specification.Meals[i];

		if (mealDiscountSpec.QuantityType === EventMealDiscountSpecificationQuantityType.Sequential) {
			var count = 0;

			var previousDay:string|undefined = undefined;
			var sequential = true;
			reservation.Meals.forEach(reservationMeal => {
				var eventMeal = eventMeals?.find(m => m.Id === reservationMeal.EventMealId);
				if (previousDay != null && eventMeal != null && eventMeal.MealId === mealDiscountSpec.MealId) {
					var previousDate = new Date(previousDay);
					if (!moment(previousDate).isSame(moment(reservationMeal.Date).add(-1, 'days'))) {
						sequential = false;
					}
				}
				if (eventMeal != null && eventMeal.MealId === mealDiscountSpec.MealId && _.sumBy(reservationMeal.Quantities, (quantity) => quantity.Quantity) >= 1) {
					previousDay = reservationMeal.Date;
					if (sequential)
						count++;
				}
			});

			if (count < mealDiscountSpec.Quantity)
				valid = false;
			
		} else if (mealDiscountSpec.QuantityType === EventMealDiscountSpecificationQuantityType.Cumulative) {
			count = 0;

			reservation.Meals.forEach(reservationMeal => {
				var eventMeal = eventMeals?.find(m => m.Id === reservationMeal.EventMealId);
				if (eventMeal != null && eventMeal.MealId === mealDiscountSpec.MealId && _.sumBy(reservationMeal.Quantities, (quantity) => quantity.Quantity) >= 1) {
					count+= _.sumBy(reservationMeal.Quantities, (quantity) => quantity.Quantity);
				}
			});

			if (count < mealDiscountSpec.Quantity)
				valid = false;
		}
	}

	return valid;
};

export const updateDiscount = (reservation: Reservation, eventMeals: EventMeal[], eventMealDiscounts: EventMealDiscount[]) => {
	var defaultDiscount = _.sumBy(reservation.Meals, (reservationMeal) => {
		var eventMeal = eventMeals?.find(m => m.Id === reservationMeal.EventMealId);
		if (eventMeal == null)
			return 0;
		return _.sumBy(reservationMeal.Quantities, (quantity) => {
			var ageGroup = eventMeal?.MealDefinitionItem.MealDefinitionItemAgeGroups.find(g => g.MealAgeGroupId === quantity.MealAgeGroupId);
			if (ageGroup == null)
				return 0;
			return quantity.Quantity * (ageGroup.Price * ageGroup.DefaultDiscount / 100);
		});
	});

	var discountAmount = defaultDiscount;

	var appliedDiscount = getApplicableDiscount(reservation, eventMeals, eventMealDiscounts);

	if (appliedDiscount != null) {
		if (appliedDiscount.Type === EventMealDiscountType.Amount) {
			discountAmount += appliedDiscount.Amount;
		} else if (appliedDiscount.Type === EventMealDiscountType.Percent) {
			// TODO: Should the discount be calculated from the total cost of all meals, 
			// or just the meals in the discount specification?
			for (let mealDiscountSpecification of appliedDiscount.Specification.Meals) {
				discountAmount += getMealsTotalForMeal(eventMeals, reservation, mealDiscountSpecification.MealId) * appliedDiscount.Amount / 100;
			}
		}

		reservation.MealDiscountId = appliedDiscount.Id;
		reservation.MealDiscount = discountAmount;
	} else {
		reservation.MealDiscountId = 0;
		reservation.MealDiscount = defaultDiscount;
	}
	return appliedDiscount;
}

export const getMealsTotalForMeal = (eventMeals: EventMeal[], reservation: Reservation, mealId: number) => {
	return _.sumBy(reservation.Meals, (reservationMeal) => {
		var eventMeal = eventMeals?.find(m => m.Id === reservationMeal.EventMealId && m.MealId === mealId);
		if (eventMeal === undefined)
			return 0;
		return _.sumBy(reservationMeal.Quantities, (quantity) => {
			var ageGroup = eventMeal?.MealDefinitionItem.MealDefinitionItemAgeGroups.find(g => g.MealAgeGroupId === quantity.MealAgeGroupId);
			if (ageGroup === undefined)
				return 0;
			return quantity.Quantity * (ageGroup.Price);
		});
	})
}

export const getMealsTotalForAgeGroup = (mealAgeGroup: MealAgeGroup, eventMeals: EventMeal[], reservation: Reservation) => {
	return _.sumBy(reservation.Meals, (reservationMeal) => {
		var eventMeal = eventMeals.find(m => m.Id === reservationMeal.EventMealId);
		if (eventMeal == null)
			return 0;
		return _.sumBy(reservationMeal.Quantities, (quantity) => {
			var ageGroup = eventMeal?.MealDefinitionItem.MealDefinitionItemAgeGroups.find(g => g.MealAgeGroupId === quantity.MealAgeGroupId);
			if (ageGroup == null || ageGroup.MealAgeGroupId !== mealAgeGroup.Id)
				return 0;
			return quantity.Quantity * ageGroup.Price; //(ageGroup.Price - ageGroup.Price * ageGroup.DefaultDiscount / 100);
		});
	})
}

export const getMealsDiscountTotalForAgeGroup = (mealAgeGroup: MealAgeGroup, eventMeals: EventMeal[], appliedDiscount: EventMealDiscount|undefined, reservation: Reservation) => {
	return _.sumBy(reservation.Meals, (reservationMeal) => {
		var eventMeal = eventMeals.find(m => m.Id === reservationMeal.EventMealId);
		if (eventMeal == null)
			return 0;
		return _.sumBy(reservationMeal.Quantities, (quantity) => {
			var ageGroup = eventMeal?.MealDefinitionItem.MealDefinitionItemAgeGroups.find(g => g.MealAgeGroupId === quantity.MealAgeGroupId);
			if (ageGroup == null || ageGroup.MealAgeGroupId !== mealAgeGroup.Id)
				return 0;
			var amount = quantity.Quantity * (ageGroup.Price * ageGroup.DefaultDiscount / 100);

			if (appliedDiscount !== undefined && appliedDiscount.Amount > ageGroup.DefaultDiscount) {
				amount = 0;
				for (let mealDiscountSpecification of appliedDiscount.Specification.Meals) {
					if (mealDiscountSpecification.MealId === eventMeal?.MealId)
						amount += quantity.Quantity * ageGroup.Price * appliedDiscount.Amount / 100;
				}
			}
			return amount;
		});
	})
}

export const getMealsTotal = (reservation: Reservation, eventMeals: EventMeal[]) => {
	if (eventMeals === undefined)
		return 0;

	return _.sumBy(reservation.Meals, (reservationMeal) => {
		var eventMeal = eventMeals?.find(m => m.Id === reservationMeal.EventMealId);
		if (eventMeal === undefined)
			return 0;
		return _.sumBy(reservationMeal.Quantities, (quantity) => {
			var ageGroup = eventMeal?.MealDefinitionItem.MealDefinitionItemAgeGroups.find(g => g.MealAgeGroupId === quantity.MealAgeGroupId);
			if (ageGroup == null)
				return 0;
			return quantity.Quantity * (ageGroup.Price);
		});
	})
}

export const getCustomFieldSize = (field: ReservationCustomField) => {
	switch (field.Type) {
		case ReservationCustomFieldType.TextSmall:
		case ReservationCustomFieldType.CommentsSmall:
		case ReservationCustomFieldType.DropdownListSmall:
		case ReservationCustomFieldType.RadioButtonListSmall:
		case ReservationCustomFieldType.CheckBoxListSmall:
		case ReservationCustomFieldType.CheckBoxSmall:
			return "col-4";
		case ReservationCustomFieldType.TextMedium:
		case ReservationCustomFieldType.CommentsMedium:
		case ReservationCustomFieldType.DropdownListMedium:
		case ReservationCustomFieldType.RadioButtonListMedium:
		case ReservationCustomFieldType.CheckBoxListMedium:
		case ReservationCustomFieldType.CheckBoxMedium:
			return "col-6";
		case ReservationCustomFieldType.TextLarge:
		case ReservationCustomFieldType.CommentsLarge:
		case ReservationCustomFieldType.DropdownListLarge:
		case ReservationCustomFieldType.RadioButtonListLarge:
		case ReservationCustomFieldType.CheckBoxListLarge:
		case ReservationCustomFieldType.CheckBoxLarge:
			return "col-12";
		default:
			return "";
	}
}

export const isInputField = (field: ReservationCustomField) => {
	switch (field.Type) {
		case ReservationCustomFieldType.TextSmall:
		case ReservationCustomFieldType.TextMedium:
		case ReservationCustomFieldType.TextLarge:
			return true;
		default:
			return false;
	}
}

export const isTextAreaField = (field: ReservationCustomField) => {
	switch (field.Type) {
		case ReservationCustomFieldType.CommentsSmall:
		case ReservationCustomFieldType.CommentsMedium:
		case ReservationCustomFieldType.CommentsLarge:
			return true;
		default:
			return false;
	}
}

export const isDropdownList = (field: ReservationCustomField) => {
	switch (field.Type) {
		case ReservationCustomFieldType.DropdownListSmall:
		case ReservationCustomFieldType.DropdownListMedium:
		case ReservationCustomFieldType.DropdownListLarge:
			return true;
		default:
			return false;
	}
}

export const isCheckBox = (field: ReservationCustomField) => {
	switch (field.Type) {
		case ReservationCustomFieldType.CheckBoxSmall:
		case ReservationCustomFieldType.CheckBoxMedium:
		case ReservationCustomFieldType.CheckBoxLarge:
			return true;
		default:
			return false;
	}
}

export const isMultifield = (field: ReservationCustomField) => {
	switch (field.Type) {
		// case ReservationCustomFieldType.RadioButtonListSmall:
		// case ReservationCustomFieldType.RadioButtonListMedium:
		// case ReservationCustomFieldType.RadioButtonListLarge:
		case ReservationCustomFieldType.CheckBoxListSmall:
		case ReservationCustomFieldType.CheckBoxListMedium:
		case ReservationCustomFieldType.CheckBoxListLarge:
			return true;
		default:
			return false;
	}
}

export const isRadioButtonList = (field: ReservationCustomField) => {
	switch (field.Type) {
		case ReservationCustomFieldType.RadioButtonListSmall:
		case ReservationCustomFieldType.RadioButtonListMedium:
		case ReservationCustomFieldType.RadioButtonListLarge:
			return true;
		default:
			return false;
	}
}

export const isCheckBoxList = (field: ReservationCustomField) => {
	switch (field.Type) {
		case ReservationCustomFieldType.CheckBoxListSmall:
		case ReservationCustomFieldType.CheckBoxListMedium:
		case ReservationCustomFieldType.CheckBoxListLarge:
			return true;
		default:
			return false;
	}
}

export const addCharges = (reservation: Reservation, charges: EventCharge[]) => {
	charges.forEach(charge => {
		var existing = reservation.Charges.find(c => c.EventChargeId === charge.Id);
		if (existing != null)
			return;

		var reservationCharge: ReservationCharge = { 
			EventChargeId: charge.Id,
			Items: [] 
		};

		charge.Items.forEach(item => {
			reservationCharge.Items.push({
				EventChargeItemId: item.Id,
				Quantity: getEventChargeMinimumQuantity(item, reservation)
			});
		});

		reservation.Charges.push(reservationCharge);
	});

	// Filter out any invalid charges from the reservation (i.e. charges that have been removed from the event)
	reservation.Charges = reservation.Charges.filter(r => _.some(charges, c => c.Id === r.EventChargeId));

	return reservation;
}

export const updateCharges = (reservation: Reservation, eventCharges: EventCharge[]) => {
	reservation.Charges.forEach(reservationCharge => {
		var charge = eventCharges.find(c => c.Id === reservationCharge.EventChargeId);
		reservationCharge.Items.forEach(reservationChargeItem => {
			var item = charge?.Items.find(i => i.Id === reservationChargeItem.EventChargeItemId);
			if (item !== undefined) {
				var minimum = getEventChargeMinimumQuantity(item, reservation);
				var maximum = getEventChargeMaximumQuantity(item, reservation);
				if (reservationChargeItem.Quantity < minimum)
					reservationChargeItem.Quantity = minimum;
				else if (reservationChargeItem.Quantity > maximum)
					reservationChargeItem.Quantity = maximum;
			}
		});
	});

	return reservation;
}

export const getEventChargeItemQuantity = (reservation: Reservation, eventChargeIndex: number, eventChargeItemIndex: number) => {
	if (reservation === undefined || reservation.Charges === undefined || 
		reservation.Charges.length < eventChargeIndex - 1 ||
		reservation.Charges[eventChargeIndex] === undefined ||
		reservation.Charges[eventChargeIndex].Items === undefined ||
		reservation.Charges[eventChargeIndex].Items.length < eventChargeItemIndex - 1)
		return 0;
	return reservation.Charges[eventChargeIndex].Items[eventChargeItemIndex].Quantity ?? 0;
}

export const updateEventChargeItemQuantity = (reservation: Reservation, eventChargeIndex: number, eventChargeItemIndex: number, quantity: number) => {
	if (reservation.Charges.length > eventChargeIndex &&
		reservation.Charges[eventChargeIndex].Items.length > eventChargeItemIndex) {
		reservation.Charges[eventChargeIndex].Items[eventChargeItemIndex].Quantity = quantity;
	}
	return reservation;
}

export const getEventChargeMinimumQuantity = (item: EventChargeItem, reservation: Reservation) => {
	var itemMin = item.MinimumQuantity;

	if (item.MinQuantityType === EventChargeItemQuantityType.PerInventoryItem || item.MinQuantityType == EventChargeItemQuantityType.PerInventoryItemNightly)
		itemMin = item.MinimumQuantity * getTotalRooms(reservation);
	else if (item.MinQuantityType === EventChargeItemQuantityType.PerPerson || item.MinQuantityType == EventChargeItemQuantityType.PerPersonNightly)
		itemMin = item.MinimumQuantity * getTotalGuests(reservation);
	else if (item.MinQuantityType === EventChargeItemQuantityType.PerAdult || item.MinQuantityType == EventChargeItemQuantityType.PerAdultNightly)
		itemMin = item.MinimumQuantity * getTotalAdults(reservation);
	else if (item.MinQuantityType === EventChargeItemQuantityType.PerChild || item.MinQuantityType == EventChargeItemQuantityType.PerChildNightly)
		itemMin = item.MinimumQuantity * getTotalChildren(reservation);

	return itemMin;
}

export const getEventChargeMaximumQuantity = (item: EventChargeItem, reservation: Reservation) => {
	var itemMax = item.MaximumQuantity;

	if (item.MaxQuantityType === EventChargeItemQuantityType.PerInventoryItem || item.MinQuantityType == EventChargeItemQuantityType.PerInventoryItemNightly)
		itemMax = Math.max(1, item.MaximumQuantity * getTotalRooms(reservation));
	else if (item.MaxQuantityType === EventChargeItemQuantityType.PerPerson || item.MinQuantityType == EventChargeItemQuantityType.PerPersonNightly)
		itemMax = item.MaximumQuantity * getTotalGuests(reservation);
	else if (item.MaxQuantityType === EventChargeItemQuantityType.PerAdult || item.MinQuantityType == EventChargeItemQuantityType.PerAdultNightly)
		itemMax = item.MaximumQuantity * getTotalAdults(reservation);
	else if (item.MaxQuantityType === EventChargeItemQuantityType.PerChild || item.MinQuantityType == EventChargeItemQuantityType.PerChildNightly)
		itemMax = item.MaximumQuantity * getTotalChildren(reservation);

	return itemMax;
}

export const getFilteredChargesForReservationType = (eventCharges: EventCharge[]|undefined, reservationType: ReservationType) => {
	if (reservationType === ReservationType.Onsite)
		return eventCharges?.filter(c => c.AllowOnsite === true) ?? [];
	if (reservationType === ReservationType.Offsite)
		return eventCharges?.filter(c => c.AllowOffsite === true) ?? [];
	if (reservationType === ReservationType.Virtual)
		return eventCharges?.filter(c => c.AllowVirtual === true) ?? [];
	
	return [];
}

export const getChargeTotal = (charge: EventCharge, reservation: Reservation): number => {
	if (charge == null)
		return 0;

	if (reservation != null) {
		if (reservation.Type === ReservationType.Onsite && !charge.AllowOnsite)
			return 0;
		if (reservation.Type === ReservationType.Offsite && !charge.AllowOffsite)
			return 0;
		if (reservation.Type === ReservationType.Virtual && !charge.AllowVirtual)
			return 0;
	}

	if (charge.Items.length === 0)
		return 0;
		// return charge.Price;
		
	return _.sumBy(charge.Items, (item) => {
		var reservationCharge = reservation.Charges.find(rc => rc.EventChargeId === charge.Id);
		if (reservationCharge == null)
			return 0;
		var reservationChargeItem = reservationCharge.Items.find(i => i.EventChargeItemId === item.Id);
		if (reservationChargeItem == null)
			return 0;
		var total = item.Price * (reservationChargeItem?.Quantity ?? 0);

		if (item.MinQuantityType === EventChargeItemQuantityType.PerPersonNightly || 
			item.MinQuantityType === EventChargeItemQuantityType.PerAdultNightly ||
			item.MinQuantityType === EventChargeItemQuantityType.PerChildNightly ||
			item.MinQuantityType === EventChargeItemQuantityType.PerInventoryItemNightly || 
			item.MaxQuantityType === EventChargeItemQuantityType.PerPersonNightly || 
			item.MaxQuantityType === EventChargeItemQuantityType.PerAdultNightly ||
			item.MaxQuantityType === EventChargeItemQuantityType.PerChildNightly ||
			item.MaxQuantityType === EventChargeItemQuantityType.PerInventoryItemNightly) 
		{
			total = total * getTotalNights(reservation);
		}

		return total;
	});
}

export const getChargesTotal = (reservation: Reservation, eventCharges: EventCharge[]) => {
	var chargesTotal = 0;

	for (let reservationCharge of reservation.Charges) {
		var eventCharge = eventCharges.find(c => c.Id === reservationCharge.EventChargeId);
		if (eventCharge !== undefined)
			chargesTotal += getChargeTotal(eventCharge, reservation);
	}

	return chargesTotal;
}

const getApplicableEventDeposit = (reservation: Reservation, eventDeposits: EventDeposit[]) => {
	var foundEventDeposit: EventDeposit|null = null;

	for (var eventDeposit of eventDeposits) {
		switch (eventDeposit.DurationType) {
			case EventDepositDurationType.Any:
				foundEventDeposit = eventDeposit;
				break;
			case EventDepositDurationType.LessThanOrEqual:
				if (getTotalNights(reservation) <= eventDeposit.Duration)
					foundEventDeposit = eventDeposit;
				break;
			case EventDepositDurationType.MoreThanOrEqual:
				if (getTotalNights(reservation) >= eventDeposit.Duration)
					foundEventDeposit = eventDeposit;
				break;
		}
	}

	return foundEventDeposit;	
}

const getEventDepositAmount = (reservation: Reservation, totalCost: number, eventDeposit: EventDeposit) => {
	if (eventDeposit === null)
		return 0;

	switch(eventDeposit.AmountType) {
		case EventDepositAmountType.Amount:
			switch(eventDeposit.QuantityType) {
				case EventDepositQuantityType.PerReservation:
					return eventDeposit.Amount;
				case EventDepositQuantityType.PerInventoryItem:
					return eventDeposit.Amount * reservation.Items.length;
			}
			break;
		case EventDepositAmountType.Percent:
			return _.round(eventDeposit.Amount / 100 * totalCost * 1.13 + 0.00001, 2);
	}

	return 0;
}

export const getDepositType = (reservation: Reservation, event: Event|undefined, eventDeposits: EventDeposit[]) => {
	if (eventDeposits === null || eventDeposits.length === 0)
		return event?.DepositType ?? EventDepositType.None;

	let applicableDeposit = getApplicableEventDeposit(reservation, eventDeposits);

	return applicableDeposit?.AmountType ?? EventDepositType.None;
}

export const getDepositPercent = (reservation: Reservation, event: Event|undefined, eventDeposits: EventDeposit[]) => {
	if (eventDeposits === null || eventDeposits.length === 0)
		return parseFloat(event?.DepositAmount ?? "0") ?? 0;

	let applicableDeposit = getApplicableEventDeposit(reservation, eventDeposits);

	if (applicableDeposit?.AmountType === EventDepositAmountType.Percent)
		return applicableDeposit.Amount;

	return 0;
}

export const getDepositAmountFromTotal = (reservation: Reservation, totalCost: number, event: Event|undefined, eventDeposits: EventDeposit[]) : number => {
	if (eventDeposits.length > 0) {
		let applicableDeposit = getApplicableEventDeposit(reservation, eventDeposits);

		if (applicableDeposit !== null)
			return getEventDepositAmount(reservation, totalCost, applicableDeposit);

		return 0;
	}

	if (event === undefined)
		return 0;

	if (event.DepositType === EventDepositType.Amount)
		return parseFloat(event.DepositAmount);
	else if (event.DepositType === EventDepositType.Percent)
		return _.round(parseFloat(event.DepositAmount) / 100 * totalCost * 1.13 + 0.00001, 2);
	else
		return 0;
}

export const getReservationPolicyResult = (eventId: number, dateFrom: Date, dateTo: Date, reservationPolicies: ReservationPolicy[]) : ReservationPolicyResult|null => {
	if (eventId == null || dateFrom == null || dateTo == null)
		return null;
			
	var applicablePolicies = reservationPolicies.filter(p => ((p.PolicyTo != null && moment(p.PolicyTo) > moment()) || p.PolicyTo == null) && 
			moment(p.DateFrom) < moment(dateTo).utc() && moment(p.DateTo) >= moment(dateTo).utc()
		).sort((a,b) => { return a.Priority < b.Priority ? 1 : -1; });
		
	if (applicablePolicies.length === 0) {
		return { DatesValid: true }
	}

	var defaultPolicy = applicablePolicies.find(p => p.Default === true);
	var length = moment(dateTo).diff(moment(dateFrom), 'day');
	var policyResult: ReservationPolicyResult = { DatesValid: true };
	
	applicablePolicies.forEach(policy => {					
		if (policyResult.Policy == null && policy.Default === false &&
			(policy.PolicyFrom == null || moment() >= moment(policy.PolicyFrom)) &&
			(policy.PolicyTo == null || moment() <= moment(policy.PolicyTo))) {

			if (length < policy.MinimumNights)
			{
				// console.log('Apply1: ' + policy.Title);
				policyResult = {
					Title: `Minimum stay is ${policy.MinimumNights} nights`,
					Message: policy.Description,
					MinimumStayValid: false,
					MaximumStayValid: true,
					DatesValid: false,
					Policy: policy
				}
			} else if (length > policy.MaximumNights) {
				// console.log('Apply2: ' + policy.Title);
				policyResult = {
					Title: `Maximum stay is ${policy.MaximumNights} nights`,
					Message: policy.Description,
					MinimumStayValid: true,
					MaximumStayValid: false,
					DatesValid: false,
					Policy: policy
				}
			}

			if (policyResult.Policy == null && 
				((policy.StartDayOfWeek !== null && moment(dateFrom).day() !== policy.StartDayOfWeek) ||
				(policy.EndDayOfWeek !== null && moment(dateTo).day() !== policy.EndDayOfWeek))) {
				policyResult = {
					Title: `Date range must begin on a ${moment().day(policy.StartDayOfWeek!).format('dddd')} and end on a ${moment().day(policy.EndDayOfWeek!).format('dddd')}`,
					Message: policy.Description,
					MinimumStayValid: true,
					MaximumStayValid: true,
					DatesValid: false,
					Policy: policy
				}
			}
		}
	});

	if (policyResult.Policy == null) {
		if (length < defaultPolicy!.MinimumNights)
		{
			// console.log('Apply3: ' + defaultPolicy.Title);
			policyResult = {
				Title: `Minimum stay is ${defaultPolicy!.MinimumNights} night${ defaultPolicy!.MinimumNights !== 1 ? 's' : '' }`,
				Message: defaultPolicy!.Description,
				MinimumStayValid: false,
				MaximumStayValid: true,
				DatesValid: false,
				Policy: defaultPolicy
			}
		} else if (length > defaultPolicy!.MaximumNights) {
			// console.log('Apply4: ' + defaultPolicy.Title);
			policyResult = {
				Title: `Maximum stay is ${defaultPolicy!.MaximumNights} night${ defaultPolicy!.MaximumNights !== 1 ? 's' : '' }`,
				Message: defaultPolicy!.Description,
				MinimumStayValid: true,
				MaximumStayValid: false,
				DatesValid: false,
				Policy: defaultPolicy
			}
		}
	}

	return policyResult;
}

export const getReservationInventoryPolicyResult = (eventId: number, dateFrom: moment.MomentInput, dateTo: moment.MomentInput, reservationInventoryPolicies: ReservationInventoryPolicy[]) : ReservationInventoryPolicyResult|null => {
	if (eventId == null || dateFrom == null || dateTo == null)
		return null;
			
	var applicablePolicies = reservationInventoryPolicies.filter(p => ((p.PolicyTo != null && moment(p.PolicyTo) > moment()) || p.PolicyTo == null) && 
			moment(p.DateFrom) < moment(dateFrom).utc() && moment(p.DateTo) >= moment(dateTo).utc()
		);
		
	if (applicablePolicies.length === 0) {
		return { DatesValid: true }
	}

	var length = moment(dateTo).diff(moment(dateFrom), 'day');
	var policyResult: ReservationInventoryPolicyResult = { DatesValid: true };
	
	applicablePolicies.forEach(policy => {					
		if (policyResult.Policy == null &&
			(policy.PolicyFrom == null || moment() >= moment(policy.PolicyFrom)) &&
			(policy.PolicyTo == null || moment() <= moment(policy.PolicyTo))) {

			if (length < policy.MinimumNights)
			{
				policyResult = {
					Title: `Minimum stay is ${policy.MinimumNights} nights`,
					Message: policy.Description,
					MinimumStayValid: false,
					MaximumStayValid: true,
					DatesValid: false,
					Policy: policy
				}
			} else if (length > policy.MaximumNights) {
				policyResult = {
					Title: `Maximum stay is ${policy.MaximumNights} nights`,
					Message: policy.Description,
					MinimumStayValid: true,
					MaximumStayValid: false,
					DatesValid: false,
					Policy: policy
				}
			}
		}
	});

	return policyResult;
}