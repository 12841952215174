import { ProgramPromotionCode } from "./promotions.models";

export interface Registration {
	Id: number;
	ProgramId: number;
	ProgramGroupId: number;
	DateFrom: string;
	DateTo: string;
	Requests: string;
	PromotionCodeId: number|undefined;
	ParentGuardians: ParentGuardian[];
	Participants: ParticipantBase[];
	ProgramSessions: RegistrationProgramSession[];	
	Custom: ProgramRegistrationCustomFieldValue[];
	Metadata?: RegistrationMetadata|undefined;
	PromotionCode: ProgramPromotionCode|undefined;
}

export interface RegistrationMetadata {
	program: string;
	programGroup: string;
	participants: string[];
	activities: { name: string, activities: string[] }[];
	cost: number;
	total: number;
	discount: number;
	savePaymentMethod: boolean;
}

export interface RegistrationProgramSession {
	ProgramSessionId: number;
	Participants: { 
		ParticipantId: string;
		WaitList?: boolean;
		ActivitySessionIds: number[];
	}[];
}

export interface ParticipantBase {
	Type: "adult"|"child";
	Id: string;
	FirstName?: string;
	LastName?: string;
	Gender?: number;

	Address?: Address;
	SameAddress?: boolean;

	Allergies?: string;
	Dietary?: string;
}

export interface ParentGuardian extends ParticipantBase {
	Salutation?: string;
	Email?: string;
	HomePhone?: string;
	MobilePhone?: string;

	Location?: string;
	EmergencyContact?: string;
}

export interface AdultParticipant extends ParticipantBase {
	Salutation?: string;
	Email?: string;
	HomePhone?: string;
	MobilePhone?: string;

	Location?: string;
	EmergencyContact?: string;
}

export interface ChildParticipant extends ParticipantBase {
	Birthdate: Date|null;
	HealthConcerns?: string;
	Allergies?: string;
	SpecialNeeds?: string;
	AgeOverrideReason?: string;
	ChangeDiaper?: boolean;
}

export interface Address {
	Id?: number;
	Street1?: string;
	Street2?: string;
	City?: string;
	Province?: string;
	Region?: string;
	PostalCode?: string;
	Country?: string;
}

export interface ProgramRegistration {
	Id: number;
	TransactionId: number;
	UserId: number;
	DateSubmitted: Date;
	DateFrom: Date;
	DateTo: Date;
	ProgramId: number;
	ProgramGroupId: number;
	Data: Registration;
	Total: number;
	Metadata?: ProgramRegistrationMetadata;
	Deleted: boolean;
}

export class ProgramRegistrationMetadata {
	Program?: string;
	ProgramGroup?: string;
	Participants?: string[];
}

export interface RegistrationHistory {
	RegistrationId: number;
	RegistrationNumber: number;
	ProgramId: number;
	ProgramGroupId: number;
	DateSubmitted: string;
	DateFrom: string;
	DateTo: string;
	Requests: string;
	Program: string;
	ProgramGroup: string;
	Participants: string[];
	ParentGuardians: { Name: string, Location: string, EmergencyContact: string }[];
	Sessions: { Participant: string, Sessions: { DateFrom: string, DateTo: string, WaitList: boolean }[] }[];
	Activities: { Participant: string, Activities: string[] }[];
	Cost: number;
	ReceiptURL: string;
	Deleted: boolean;
}

export interface ProgramRegistrationPageContent {
	ComponentType: ProgramRegistrationPageContentType;
	Content: string
}

export enum ProgramRegistrationPageContentType {
	Participant = 1,
	Program = 2,
	Additional = 3
}

export interface ProgramRegistrationCustomFieldGroup {
	Id: number;
	Title: string;
	Subtitle: string;
	CustomFields: ProgramRegistrationCustomField[];
}

export interface ProgramRegistrationCustomField {
	Id: number;
	Type: ProgramRegistrationCustomFieldType;
	Required: boolean;
	Title: string;
	Items: string[];

	Value?: string;
}

export interface ProgramRegistrationCustomFieldValue {
	CustomFieldId: number;
	CustomFieldGroupId: number;
	Value: string|string[]|boolean;
}

export enum ProgramRegistrationCustomFieldType {
	TextSmall				= 1,
	TextMedium				= 2,
	TextLarge				= 3,
	CommentsSmall			= 11,
	CommentsMedium			= 12,
	CommentsLarge			= 13,
	CheckBoxSmall			= 21,
	CheckBoxMedium			= 22,
	CheckBoxLarge			= 23,
	CheckBoxListSmall		= 25,
	CheckBoxListMedium		= 26,
	CheckBoxListLarge		= 27,
	RadioButtonListSmall 	= 31,
	RadioButtonListMedium	= 32,
	RadioButtonListLarge 	= 33,
	DropdownListSmall		= 41,
	DropdownListMedium		= 42,
	DropdownListLarge		= 43
}