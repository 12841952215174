import axios from 'axios';

export const api = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	withCredentials: true
});

export interface ApiError {
	statusCode: number;
	error: string;
	message: string;
}