import moment from "moment-timezone";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getPageContentItem, PageContentComponentType, useConfigurationQuery, usePageContentQuery } from "../../api/configuration.api";
import { useEventsSearchQuery } from "../../api/events.api"
import { useProgramGroupFromTitleQuery, useProgramGroupsQuery, useProgramsQuery } from "../../api/programs.api";
import { ProgramGroup } from "../../api/programs.models";
import DefaultLayout from "../../layouts/default-layout";
import ProgramCard from "./components/program-card";
import ProgramGroupCard from "./components/program-group-card";

type ProgramUrlParams = {
	programGroup: string;
	program: string;
}

const Programs = () => {
	const { data: configuration } = useConfigurationQuery();

	const { programGroup: programGroupParam } = useParams<ProgramUrlParams>();
	const { data: programGroup } = useProgramGroupFromTitleQuery(programGroupParam ?? "");

	if (programGroup?.Title) {
		return <ProgramGroupPrograms programGroup={programGroup} />
	}

	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | Explore All Programs`;

	return <ProgramGroups /> //allEvents ? <AllEvents /> : <SearchEvents />
}

export default Programs

const ProgramGroups = () => {
	const history = useHistory();
	const { data: programGroups, isLoading } = useProgramGroupsQuery();
	const { data: pageContent } = usePageContentQuery();

	if (programGroups === undefined && !isLoading) {
		history.push({ pathname: "/programs" });
		return <></>;
	}

	const programsHeader = getPageContentItem(pageContent, "Programs", null, PageContentComponentType.HeaderImage);
	const headerImageUrl = programsHeader.Content;

	return <DefaultLayout pageTitle="All Programs & Activities" headerLargeImageUrl={headerImageUrl} headerSmallImageUrl={headerImageUrl}>
		<div className="row">
			{!isLoading && programGroups?.map((programGroup, index) => {
				return <div key={index} className="col-sm-4 mb-4">
					<ProgramGroupCard key={index} programGroup={programGroup} />
				</div>
			})}
		</div>
	</DefaultLayout>
}

interface ProgramGroupProgramsProps {
	programGroup: ProgramGroup;
}

const ProgramGroupPrograms = ({ programGroup }: ProgramGroupProgramsProps) =>  {
	const { data: configuration } = useConfigurationQuery();
	const { data: programs, isLoading } = useProgramsQuery();
	// const { data: pageContent } = usePageContentQuery();

	// const { data: programGroup } = useProgramGroupFromTitleQuery(programGroupTitle);
	const programsForGroup = programs?.filter(p => p.ProgramGroupId === programGroup?.Id ?? 0);
	
	// const programsHeader = getPageContentItem(pageContent, "Programs", null, PageContentComponentType.HeaderImage);
	const headerImageUrl = programGroup?.ImageURL ?? "";//programsHeader.Content;
	
	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | ${programGroup.Title}`;

	function createDescriptionMarkup() { return { __html: programGroup?.Description ?? "" }; };

	return <DefaultLayout pageTitle={`${programGroup?.Title}`} headerLargeImageUrl={headerImageUrl} headerSmallImageUrl={headerImageUrl}>
		<div className="row mb-3">
			<div className="col-sm-12">
				<div dangerouslySetInnerHTML={createDescriptionMarkup()}></div>
			</div>
		</div>
		<div className="row">
			{!isLoading && programsForGroup?.map((program, index) => {
				return <div key={index} className="col-sm-4 mb-4">
					<ProgramCard key={index} programGroup={programGroup!} program={program} />
				</div>
			})}
		</div>
	</DefaultLayout>
}


const SearchEvents = () => {
	const search = useLocation().search;
	const params = new URLSearchParams(search);
	const { data: pageContent } = usePageContentQuery();
	
	const programsHeader = getPageContentItem(pageContent, "Programs", null, PageContentComponentType.HeaderImage);
	const headerImageUrl = programsHeader.Content;

	var selectedDateRange: Date[] = [];
	
	if (params.get("dateFrom") && params.get("dateTo"))
		selectedDateRange = [moment(params.get("dateFrom")).toDate(), moment(params.get("dateTo")).toDate()];

	const { data: results, isLoading } = useEventsSearchQuery(selectedDateRange);

	const formatDateRange = () => {
		if (selectedDateRange.length !== 2)
			return '';
		return `Dates: ${moment(selectedDateRange[0]).format('MMM D, YYYY')} - ${moment(selectedDateRange[1]).format('MMM D, YYYY')}`;
	}

	return <DefaultLayout pageTitle="Explore All Events" subTitle={formatDateRange()} headerLargeImageUrl={headerImageUrl} headerSmallImageUrl={headerImageUrl}>
		{!isLoading && results?.Events.length === 0 &&
		<h5 className="font-weight-bold">There are no events currently scheduled for the searched date range.</h5>
		}

		{!isLoading && (results?.Events.length ?? 0) > 0 && <>
			<h5 className="font-weight-bold">{results?.Events.length} event{results?.Events.length === 1 ? '':'s'} found</h5>
			<div className="row mt-4">
				{results?.Events.map((event, index) => {
					return <div key={index} className="col-sm-4">
						{/* <Event key={index} event={event} /> */}
					</div>
				})}
			</div>
		</>
		}

		{!isLoading && results?.Upcoming && (results?.Upcoming.length ?? 0) > 0 &&
		<div className="border-top mt-4 pt-4">
			<h3>Upcoming Events</h3>

			<div className="row mt-4">
				{!isLoading && results.Upcoming.map((event, index) => {
					return <div key={index} className="col-sm-4">
						{/* <Event key={index} event={event} /> */}
					</div>
				})}
			</div>
		</div>
		}
	</DefaultLayout>
}