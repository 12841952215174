import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun as fasSun, faMoon as fasMoon } from '@fortawesome/free-solid-svg-icons'
import { faSun as farSun, faMoon as farMoon } from '@fortawesome/free-regular-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useThemeState, ThemeMode } from "../../pages/events/functions/theme-state";

const ThemeToggle = () => {
	const { setTheme, currentTheme } = useThemeState();

	const setLightTheme = () => {
		setTheme(ThemeMode.Light);
	}

	const setDarkTheme = () => {
		setTheme(ThemeMode.Dark);
	}

	const isLightTheme = currentTheme === ThemeMode.Light;

	const sunIcon = isLightTheme ? 
		<FontAwesomeIcon icon={fasSun} /> : 
		<FontAwesomeIcon icon={farSun} />;
		
	const moonIcon = isLightTheme ? 
		<FontAwesomeIcon icon={farMoon} /> : 
		<FontAwesomeIcon icon={fasMoon} />;
		
	return <>
		<div className="m-0">
			<OverlayTrigger	placement="top"	overlay={<Popover body placement="top">Use light theme</Popover>}>
				<a onClick={() => setLightTheme()} style={{marginRight: '15px', cursor: 'pointer' }}>
					{sunIcon}
				</a>
			</OverlayTrigger>
			<OverlayTrigger	placement="top"	overlay={<Popover body placement="top">Use dark theme</Popover>}>
				<a onClick={() => setDarkTheme()} style={{cursor: 'pointer' }}>
					{moonIcon}
				</a>
			</OverlayTrigger>
		</div>
	</>
}

export default ThemeToggle