import { Switch, Route, useRouteMatch } from "react-router-dom";
import Protected from "../../common/components/auth/ProtectedRoute";
import Account from "./account";
import AccountPasswordReset from "./account-password-reset";

const AccountRoutes = () => {
	let { path } = useRouteMatch();
	return <>
		<Switch>
			<Route path={`${path}/password-reset`} exact>
				<AccountPasswordReset />
			</Route>
			<Protected>
				<Route path={`${path}/`} exact>
					<Account />
				</Route>
				<Route path={`${path}/reservations`} exact>
					<Account defaultTab="reservations" />
				</Route>
				<Route path={`${path}/registrations`} exact>
					<Account defaultTab="registrations" />
				</Route>
			</Protected>
		</Switch>
	</>
}

export default AccountRoutes