import React from 'react'
import { PageContentComponentType, useConfigurationQuery, usePageContentQuery } from '../../api/configuration.api'
import HomeLayout from '../../layouts/home-layout'
import FeaturedEvents from './components/featured-events'
import FeaturedTypes from './components/featured-types'
import './home.css'

const Home = () => {
	const { data: pageContent, isLoading, isError } = usePageContentQuery();
	const { data: configuration } = useConfigurationQuery();

	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation`;

	if (isLoading && !isError)
		return <HomeLayout>
			<p>Loading...</p>
		</HomeLayout>

	if (isError)
		return <HomeLayout>
			<p>Unable to load at this time.</p>
		</HomeLayout>

	const homepageContent = pageContent?.filter(c => c.Page === "Index") ?? [];

	return <>
		<HomeLayout>
			{homepageContent.map((p, i) => {
				switch (p.ComponentType) {
					case PageContentComponentType.HeaderText:
						return <PageContentHeader key={i} content={p.Content} />
					case PageContentComponentType.IndexFeaturedEvents:
						return <FeaturedEvents key={i} />
					case PageContentComponentType.IndexFeaturedInventoryTypes:
						return <FeaturedTypes key={i} />
					default:
						return <React.Fragment key={i}></React.Fragment>
				}
			})}
		</HomeLayout>
	</>
}

export default Home

interface PageContentHeaderProps {
	content: string|null
}

const PageContentHeader = ({ content }: PageContentHeaderProps) => {
	return <>
		<div className="home-header">
			<div className="row mt-5">
				<h2 className="mt-0 mx-auto pl-3 pr-3 text-center">{content}</h2>
			</div>
			<hr className="mb-5" style={PageContentHeaderSeparatorStyle} />
		</div>
	</>
}

const PageContentHeaderSeparatorStyle = {
	height: '1px',
	width: '80px', 
	margin: '1rem auto',
	backgroundColor: '#428627'
}