import { useInventoryTypesQuery } from "../../../api/inventory.api"
import { InventoryType } from "../../../api/inventory.models";
import Card from '../../../common/components/Card';
import { linkTitle } from "../../../common/helpers";
import './featured-types.css'

const FeaturedTypes = () => {
	const { data: inventoryTypes, isLoading, isError } = useInventoryTypesQuery(true);

	if (isLoading) // TODO: Replace with skeleton
		return <>Loading...</>

	if (isError) // TODO: Replace with proper error component
		return <>Unable to load content at this time.</>

	return <>
		<div className="row">
			{!isLoading && inventoryTypes && inventoryTypes.filter(type => type.Metadata!.Featured === true).map((type, index) => {
				return <div key={index} className="col-sm-6">
					<FeaturedType inventoryType={type} />
				</div>
			})}
		</div>
	</>
}

interface FeaturedTypeProps {
	inventoryType: InventoryType;
}

const FeaturedType = ({ inventoryType }: FeaturedTypeProps) => {
	return <div className="featured-type">
		<Card title={inventoryType.Metadata!.Title} description={inventoryType.Metadata!.ShortContent} imageUrl={inventoryType.Metadata!.ImageURL} link={`/explore/${linkTitle(inventoryType.Metadata!.Title)}`} />
	</div>
}

export default FeaturedTypes