import { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import moment from "moment-timezone";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faCampground, faCircleNotch, faExclamationCircle, faLaptopHouse, faUsers, faWalking } from "@fortawesome/free-solid-svg-icons";
import { useConfigurationQuery } from "../../api/configuration.api";
import { useEventChargesQuery, useEventInventoryQuery, useEventMealDiscountsQuery, useEventMealPackagesQuery, useEventMealRequirementOverridesQuery, useEventMealsQuery } from "../../api/events.api";
import { ReservationInventoryItem, ReservationOffsiteItem, ReservationPageContentType, ReservationType } from "../../api/reservation.models";
import { useEventState } from "./functions/event-state";
import { useReservationState } from "./functions/reservation-state";
import { addCharges, addMeals, getFilteredChargesForReservationType, getTotalRooms, loadMealsByDate, removeReservationItem, updateAdults, updateChildren, updateDiscount, updateMeals } from "./functions/reservation-functions";
import { getInventoryCapacity, getInventoryCategory, getInventoryItem, getInventoryType, getPerNightCostForInventoryItem, getPriceDescriptionForInventoryItem, isAccommodation, isCampsite } from "./functions/inventory-data";
import { currency, linkTitle } from "../../common/helpers";
import EventReservationSummary from "./components/event-reservation-summary";
import EventReservationMeals from "./components/event-reservation-meals";
import EventsLayout from "../../layouts/events-layout";
import EventReservationGuest from "./components/event-reservation-guest";
import EventReservationCharges from "./components/event-reservation-charges";
import EventReservationAdditionalInformation from "./components/event-reservation-additional-information";
import EventReservationDeposit from "./components/event-reservation-deposit";
import EventReservationPromotionCode from "./components/event-reservation-promotioncode";
import EventReservationPageContent from "./components/event-reservation-pagecontent";
import EventReservationCustomFields from "./components/event-reservation-custom-fields";
import { InventoryCategory, InventoryItem } from "../../api/inventory.models";
import { getInventoryItems } from "./functions/inventory-functions";
import { getInventoryValidationErrors } from "../cart/functions/cart-functions";

interface EventReservationProps {

}

const EventReservation = ({}: EventReservationProps) => {
	const history = useHistory();
	const search = useLocation().search;
	const { data: configuration } = useConfigurationQuery();
	const { event, selectedEventDate, selectedDateRange, setCurrentStep } = useEventState();
	const { reservation, updateReservation, updateInventorySelections, emitReservationGuests, validationErrors } = useReservationState();
	const { data: inventoryTypes } = useEventInventoryQuery(selectedEventDate, selectedDateRange);

	const { data: eventCharges } = useEventChargesQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);
	const { data: eventMeals } = useEventMealsQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);
	const { data: mealPackages } = useEventMealPackagesQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);
	const { data: eventMealDiscounts } = useEventMealDiscountsQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);
	const { data: eventMealRequirementOverrides } = useEventMealRequirementOverridesQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);

	const [showRemovePopover, setShowRemovePopover] = useState<number|undefined>();

	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | Reservation`;

	useEffect(() => setCurrentStep(3), []);

	useEffect(() => {
		if (reservation !== null)
			updateInventorySelections(getInventoryItems(inventoryTypes ?? []));
	}, [reservation?.Id ?? 0, inventoryTypes]);


	useEffect(() => {
		if (reservation !== null && reservation.Id !== undefined && event !== undefined && eventCharges !== undefined && eventMeals !== undefined && eventMealRequirementOverrides !== undefined && mealPackages !== undefined && eventMealDiscounts !== undefined) {
			var charges = getFilteredChargesForReservationType(eventCharges, reservation.Type);

			if (charges.length > 0 && reservation.Charges.length === 0) {
				addCharges(reservation, charges);
			}

			var mbd = loadMealsByDate(event, eventMeals, mealPackages, reservation);
			addMeals(reservation, mbd);

			if (reservation.Meals.length > 0) {
				if (reservation.MealPackageId === null && mealPackages.length > 0) {
					reservation.MealPackageId = mealPackages[0].Id;
				}
				if (reservation.MealPackageId !== null && reservation.MealPackageId > 0) {
					updateMeals(reservation, event, eventMeals, eventMealRequirementOverrides, mealPackages);		
				}
				updateDiscount(reservation, eventMeals ?? [], eventMealDiscounts ?? []);
			}

			updateReservation(reservation);
		}
	}, [event, eventCharges, eventMeals, eventMealRequirementOverrides, mealPackages, eventMealDiscounts]);

	const getGuestCountRange = (reservationInventoryItem: ReservationInventoryItem, startCount: number) => {
		// var inventoryItem = getInventoryItem(item);
		let inventoryItem = getInventoryItem(inventoryTypes ?? [], reservationInventoryItem.InventoryTypeId, reservationInventoryItem.InventoryCategoryId, reservationInventoryItem.InventoryItemId);
		
		if (inventoryItem != null && inventoryItem.Metadata != null && inventoryItem.Metadata.Capacity != null)
		return _.range(startCount, inventoryItem.Metadata.Capacity + startCount);
		
		// var inventoryCategory = getInventoryCategory(item);
		let inventoryCategory = getInventoryCategory(inventoryTypes ?? [], reservationInventoryItem.InventoryTypeId, reservationInventoryItem.InventoryCategoryId);
		
		return _.range(startCount, (inventoryCategory?.Metadata?.Capacity ?? 0) + startCount, 1);
	}

	const getOffsetGuestCountRange = (startCount: number) => {
		return _.range(startCount, 10 + 1, 1);
	}

	const canRemoveInventory = () => {
		return getTotalRooms(reservation) > 1;
	}

	const handleEditInventory = () => {
		const params = new URLSearchParams(search);

		if (event!.FixedDates) {
			params.set("dateFrom", moment(selectedEventDate!.DateFrom).toISOString());
			params.set("dateTo", moment(selectedEventDate!.DateTo).toISOString());
		} else {
			params.set("dateFrom", moment(selectedDateRange![0]).toISOString());
			params.set("dateTo", moment(selectedDateRange![1]).toISOString());
		}

		params.set("edit", "0")

		history.push({ pathname: `/events/${linkTitle(event!.Title)}/inventory`, search: params.toString() })
	}

	const handleRemoveInventory = (itemIndex: number) => {
		setShowRemovePopover(undefined);
		removeReservationItem(reservation, itemIndex);
		updateReservation(reservation);
		updateInventorySelections(getInventoryItems(inventoryTypes ?? []));
		setTimeout(() => {
			emitReservationGuests(reservation);
		}, 10);
	}

	const handleUpdateAdults = (itemIndex: number, adults: number) => {
		updateAdults(reservation, itemIndex, adults);
		updateReservation(reservation);
		setTimeout(() => {
			emitReservationGuests(reservation);
		}, 10);
	}

	const handleUpdateChildren = (itemIndex: number, children: number) => {
		updateChildren(reservation, itemIndex, children);
		updateReservation(reservation);
		setTimeout(() => {
			emitReservationGuests(reservation);
		}, 10);
	}

	const isOverCapacity = (item: ReservationInventoryItem, inventoryItem: InventoryItem, inventoryCategory: InventoryCategory) : boolean => {
		return item.Adults + item.Children > getInventoryCapacity(inventoryItem, inventoryCategory);
	}

	const removePopover = (itemIndex: number) => {
		return <Popover id="popover-basic" className="shadow" arrowProps={{ className: 'arrow' }}>
			{/* <Popover.Header as="h3">Confirm</Popover.Header> */}
			<Popover.Body>
				<div className="mb-2">Are you sure you want to remove this reservation from your cart ?</div>
				<div className="row">
					<div className="col-6 pr-1">
						<button onClick={() => handleRemoveInventory(itemIndex)} type="button" className="btn btn-danger btn-block font-weight-bold">Remove</button>
					</div>
					<div className="col-6 pl-1">
						<button onClick={() => setShowRemovePopover(undefined)} type="button" className="btn btn-light btn-block font-weight-bold mb-2">Cancel</button>
					</div>
				</div>
			</Popover.Body>
		</Popover>
	}

	if (event === undefined || inventoryTypes === undefined || reservation === null)
		return <EventsLayout showEditDates={true}>
			<>
				<h4 className="mb-4">
					<FontAwesomeIcon icon={faCircleNotch} spin /> 
					<span> Loading Reservation...</span>
				</h4>
			</>
		</EventsLayout>

	return <EventsLayout showEditDates={true}>
		<>
		<h4 className="mb-4">Complete Reservation</h4>

		<div className="row">
			<div className="col-12 col-md-8">

				{false && // getOtherValidationErrors
				<div className="card shadow mb-3 guest-information">
					<h5 className="card-header"><FontAwesomeIcon icon={faExclamationCircle} className="text-muted mr-2" />Problems with Reservation</h5>

					<div className="card-body">
						<div className="text-danger font-weight-bold mb-2" style={{ fontSize: 'initial', marginBottom: '10px' }}>
							<FontAwesomeIcon icon={faExclamationCircle} /> Error message
						</div>
					</div>					
				</div>
				}

				<div className="card shadow mb-3 guest-information">
					<h5 className="card-header"><FontAwesomeIcon icon={faUsers} className="text-muted mr-2" />Guest Information</h5>

					<div className="card-body">
						<EventReservationPageContent type={ReservationPageContentType.GuestInformation} />

						{reservation.Items?.map((item, itemIndex) => {
							if (item.Type === ReservationType.Onsite) {
								let reservationInventoryItem = item as ReservationInventoryItem;
								let inventoryType = getInventoryType(inventoryTypes ?? [], reservationInventoryItem.InventoryTypeId);
								let inventoryCategory = getInventoryCategory(inventoryTypes ?? [], reservationInventoryItem.InventoryTypeId, reservationInventoryItem.InventoryCategoryId);
								let inventoryItem = getInventoryItem(inventoryTypes ?? [], reservationInventoryItem.InventoryTypeId, reservationInventoryItem.InventoryCategoryId, reservationInventoryItem.InventoryItemId);
								return <Fragment key={itemIndex}>
									{itemIndex > 0 &&
									<hr className="mt-3 mb-3" />
									}
									<div className="float-right text-right font-weight-bold">
										{inventoryItem && 
											<>
												<span>{currency.format(getPerNightCostForInventoryItem(inventoryItem))}</span>
												<div className="small text-right">{getPriceDescriptionForInventoryItem(inventoryItem)}</div>
											</>
										}
									</div>
									<h4 className="card-title">

										{inventoryType && isAccommodation(inventoryType) &&
											<FontAwesomeIcon icon={faBed} className="mr-2" />
										}
										{inventoryType && isCampsite(inventoryType) &&
											<FontAwesomeIcon icon={faCampground} className="mr-2" />
										}
						
										{inventoryCategory &&
											<span>{inventoryCategory.Metadata?.UnitDescription } {itemIndex + 1}: {inventoryCategory.Metadata?.Title ?? inventoryCategory?.Description} </span>
										}
										{inventoryItem && 
											<span>({inventoryItem.Description})</span>
										}
										{getInventoryValidationErrors(validationErrors, reservation).filter(e => e.ReservationId === reservation.Id).length > 0 &&
										<div className="text-danger font-weight-bold mb-2" style={{ fontSize: 'initial', marginTop: '10px' }}>
											<FontAwesomeIcon icon={faExclamationCircle} /> {getInventoryValidationErrors(validationErrors, reservation).filter(e => e.ReservationId === reservation.Id)[0].Message}
										</div>
										}
										{inventoryCategory &&
										<div>
											<button onClick={handleEditInventory} type="button" className="btn btn-link btn-sm p-0 mr-3">Edit {(inventoryCategory.Metadata?.UnitDescription ?? 'room').toLowerCase()} selection(s)</button>
											{canRemoveInventory() && <>
												<OverlayTrigger show={showRemovePopover === itemIndex} trigger="click" placement="top" overlay={removePopover(itemIndex)}>
													<button onClick={() => setShowRemovePopover(itemIndex)} type="button" className="btn btn-link btn-sm p-0">Remove this {(inventoryCategory.Metadata?.UnitDescription ?? 'room').toLowerCase()}</button>
												</OverlayTrigger>
											</>
											}
										</div>
										}
									</h4>

									<p className="mb-2">How many guests will be staying in this {(inventoryCategory?.Metadata?.UnitDescription ?? "").toLowerCase()}?</p>

									<div className="form-row">
										<div className="col-6 col-lg-4">
											<div className="form-label-group">
												<select value={`${item.Adults}`} onChange={(e) => handleUpdateAdults(itemIndex, parseInt(e.target.value))} className="custom-select value-selected w-100">
													{getGuestCountRange(reservationInventoryItem, 1).map((val: number, index: number) => {
														return <option value={val} key={index}>{val} Adult{val === 1 ? '': 's'}</option>
													})}
												</select>
												<label className="text-truncate">Adults</label>
												{/* <select [(ngModel)]="item.Adults" name="adults{{itemIndex}}" id="adults{{itemIndex}}" *ngIf="event.AdultGuests" (change)="guestCountChanged(item)" class="custom-select value-selected w-100">
													<option *ngFor="let number of getGuestCountRange(item, 1)" [ngValue]="number">{{number}} Adult{{number == 1 ? "" : "s"}}</option>
												</select>
												<label for="adults{{itemIndex}}" class="text-truncate">Adults</label> */}
											</div>
										</div>
										{event?.ChildGuests && 
										<div className="col-6 col-lg-4">
											<div className="form-label-group">
												<select value={`${item.Children}`} onChange={(e) => handleUpdateChildren(itemIndex, parseInt(e.target.value))} className="custom-select value-selected w-100">
													{getGuestCountRange(reservationInventoryItem, 0).map((val: number, index: number) => {
														return <option value={val} key={index}>{val} Child{val === 1 ? '': 'ren'}</option>
													})}
												</select>
												<label className="text-truncate">Children (under 19)</label>
												{/* <select [(ngModel)]="item.Children" name="children{{itemIndex}}" id="children{{itemIndex}}" (change)="guestCountChanged(item)" class="custom-select value-selected w-100">
													<option *ngFor="let number of getGuestCountRange(item, 0)" [ngValue]="number">{{number}} Child{{number == 1 ? "" : "ren"}}</option>
												</select>
												<label for="children{{itemIndex}}" class="text-truncate">Children (under 19)</label> */}
											</div>
										</div>
										}
									</div>

									{inventoryItem && inventoryCategory && isOverCapacity(reservationInventoryItem, inventoryItem, inventoryCategory) &&
									<div className="alert alert-danger pb-2 pl-3 pr-3 pt-2">
										This {inventoryCategory?.Metadata?.UnitDescription} has a maximum capacity of {getInventoryCapacity(inventoryItem!, inventoryCategory!)} guests.
									</div>	
									}

									{reservationInventoryItem.AdultGuests.map((adultGuest, guestIndex) => {
										// return <div key={index}>Adult Guest</div>
										return <EventReservationGuest key={guestIndex} ItemIndex={itemIndex} Item={reservationInventoryItem} GuestIndex={guestIndex} GuestType="adult" Guest={adultGuest} GuestNumber={guestIndex+1} UseAddressFrom={reservationInventoryItem.AdultGuests[0]} />
									})}

									{reservationInventoryItem.ChildGuests.map((childGuest, guestIndex) => {
										// return <div key={index}>Adult Guest</div>
										return <EventReservationGuest key={guestIndex} ItemIndex={itemIndex} Item={reservationInventoryItem} GuestIndex={guestIndex} GuestType="child" Guest={childGuest} GuestNumber={guestIndex+1} UseAddressFrom={reservationInventoryItem.AdultGuests[0]} />
									})}

								</Fragment>
							}
							if (item.Type === ReservationType.Offsite || item.Type === ReservationType.Virtual) {
								let reservationOffsiteItem = item as ReservationOffsiteItem;
								
								return <Fragment key={itemIndex}>
									{itemIndex > 0 &&
									<hr className="mt-3 mb-3" />
									}
									<h4 className="card-title">
										{item.Type === ReservationType.Offsite && <>
											<FontAwesomeIcon icon={faWalking} className="mr-2" />
											Offsite Attendance
										</>}
										{item.Type === ReservationType.Virtual && <>
											<FontAwesomeIcon icon={faLaptopHouse} className="mr-2" />
											Virtual Attendance
										</>}
									</h4>

									{item.Type === ReservationType.Offsite &&
										<p className="mb-2">How many off-site guests are you registering?</p>
									}
									{item.Type === ReservationType.Virtual && 
									<p className="mb-2">How many guests are you registering for virtual attendance?</p>
									}

									<div className="form-row">
										<div className="col-6 col-lg-4">
											<div className="form-label-group">
												<select value={`${item.Adults}`} onChange={(e) => handleUpdateAdults(itemIndex, parseInt(e.target.value))} className="custom-select value-selected w-100">
													{getOffsetGuestCountRange(1).map((val: number, index: number) => {
														return <option value={val} key={index}>{val} Adult{val === 1 ? '': 's'}</option>
													})}
												</select>
												<label className="text-truncate">Adults</label>
											</div>
										</div>
										{event?.ChildGuests && 
										<div className="col-6 col-lg-4">
											<div className="form-label-group">
												<select value={`${item.Children}`} onChange={(e) => handleUpdateChildren(itemIndex, parseInt(e.target.value))} className="custom-select value-selected w-100">
													{getOffsetGuestCountRange(0).map((val: number, index: number) => {
														return <option value={val} key={index}>{val} Child{val === 1 ? '': 'ren'}</option>
													})}
												</select>
												<label className="text-truncate">Children (under 19)</label>
												{/* <select [(ngModel)]="item.Children" name="children{{itemIndex}}" id="children{{itemIndex}}" (change)="guestCountChanged(item)" class="custom-select value-selected w-100">
													<option *ngFor="let number of getGuestCountRange(item, 0)" [ngValue]="number">{{number}} Child{{number == 1 ? "" : "ren"}}</option>
												</select>
												<label for="children{{itemIndex}}" class="text-truncate">Children (under 19)</label> */}
											</div>
										</div>
										}
									</div>

									{reservationOffsiteItem.AdultGuests.map((adultGuest, guestIndex) => {
										// return <div key={index}>Adult Guest</div>
										return <EventReservationGuest key={guestIndex} ItemIndex={itemIndex} Item={reservationOffsiteItem} GuestIndex={guestIndex} GuestType="adult" Guest={adultGuest} GuestNumber={guestIndex+1} UseAddressFrom={reservationOffsiteItem.AdultGuests[0]} />
									})}

									{reservationOffsiteItem.ChildGuests.map((childGuest, guestIndex) => {
										// return <div key={index}>Adult Guest</div>
										return <EventReservationGuest key={guestIndex} ItemIndex={itemIndex} Item={reservationOffsiteItem} GuestIndex={guestIndex} GuestType="child" Guest={childGuest} GuestNumber={guestIndex+1} UseAddressFrom={reservationOffsiteItem.AdultGuests[0]} />
									})}

								</Fragment>
							}
							return <></>
						})}
					</div>
				</div>

				<EventReservationMeals />

				<EventReservationCustomFields />

				<EventReservationCharges />

				<EventReservationAdditionalInformation />

				<EventReservationPromotionCode />

				<EventReservationDeposit />

			</div>

			<div className="col-12 col-md-4">
				<EventReservationSummary />

				
			</div>

		</div>

		{import.meta.env.MODE == 'development' && <>
			<pre>{JSON.stringify(reservation, null, 2)}</pre>
		</>}

		</>
	</EventsLayout>
}

export default EventReservation