import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faAddressCard, faSignInAlt, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { useAuthState } from '../../state/auth.state';
import { useCartState } from '../../pages/cart/functions/cart-state';

const HeaderUserMenu = () => {

	const { isAuthenticated, showLogin, showLogout, showRegister } = useAuthState();
	const { getCartSize } = useCartState();

	const handleSignUp = () => {
		showRegister();
	}

	const handleLogin = () => {
		showLogin();
	}

	const handleLogout = () => {
		showLogout();		
	}

	return <>
		{!isAuthenticated() && 
		<>
			{getCartSize() > 0 &&
			<li className="nav-item">
				<NavLink to="/cart" activeClassName="active" className="nav-link ml-sm-4"> <FontAwesomeIcon icon={faShoppingCart} className="mr-2" />Cart ({getCartSize()})</NavLink>
			</li>
			}
			<li className="nav-item">
				<a onClick={handleSignUp} className="nav-link ml-sm-4"> <FontAwesomeIcon icon={faAddressCard} className="mr-2" />Sign Up</a>
			</li>

			<li className="nav-item">
				<a onClick={handleLogin} className="nav-link ml-sm-4"> <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />Login</a>
			</li>
		</>

		}
		{isAuthenticated() && 
		<>
			{getCartSize() > 0 &&
			<li className="nav-item">
				<NavLink to="/cart" activeClassName="active" className="nav-link ml-sm-4"> <FontAwesomeIcon icon={faShoppingCart} className="mr-2" />Cart ({getCartSize()})</NavLink>
			</li>
			}
			<li className="nav-item">
				<NavLink to="/account" activeClassName="active" className="nav-link ml-sm-4"> <FontAwesomeIcon icon={faUser} className="mr-2" />My Account</NavLink>
			</li>
			<li className="nav-item">
				<a onClick={handleLogout} className="nav-link ml-sm-4"> <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />Logout</a>
			</li>
		</>
		}
	</>
}

export default HeaderUserMenu