import { Collapse } from "react-bootstrap";
import MediaGallery from "../../../common/components/MediaGallery";

export interface EventInventoryDetailsProps {
	inventoryCategory: any;
	open: boolean;
}

const EventInventoryDetails = ({ inventoryCategory, open }: EventInventoryDetailsProps) => {
	function createDescriptionMarkup() { return { __html: inventoryCategory.Metadata != null ? inventoryCategory.Metadata.Content : null }; };

	return <>
		<Collapse in={open}>
			<div className="row">
				<div className="col-sm-12 mt-3">
					<div className="card-body pt-0 pb-0">
						<h5 className="card-title">{inventoryCategory.Metadata.Title} Details</h5>
						<p className="content" dangerouslySetInnerHTML={createDescriptionMarkup()}></p>
						
						<MediaGallery galleryId={inventoryCategory.Metadata.MediaGalleryId} open={open} showNoPhotos={false} />

						<hr className="mt-0 mb-0" />
					</div>
				</div>
			</div>											
		</Collapse>
	</>
}

export default EventInventoryDetails