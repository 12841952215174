import { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import _ from "lodash";
import { faCartPlus, faCircleNotch, faClipboardList, faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useCartState } from "../../cart/functions/cart-state";
import { useEventState } from "../functions/event-state";
import { useReservationState } from "../functions/reservation-state";
import { ReservationInventoryItem, ReservationMetadata, ReservationType } from "../../../api/reservation.models";
import { getReservationCost, getInventoryItemTotal, getTotalNights, getSummaryDescription, getInventoryType, getInventoryDescriptions, getAdults, getChildren, getMealsTotal, getMealsQuantity, getChargeTotal, getDepositAmountFromTotal, isReservationInventoryValid, isReservationGuestsValid, getFilteredChargesForReservationType } from "../functions/reservation-functions";
import { getInventoryCategory, getInventoryItem } from "../functions/inventory-data";
import { useEventChargesQuery, useEventInventoryQuery, useEventMealsQuery } from "../../../api/events.api";
import { toastSuccess } from "../../../common/components/toast";
import { currency } from "../../../common/helpers";
import "./event-reservation-summary.css";

const EventReservationSummary = ({}) => {
	const history = useHistory();
	const { reservation, validateReservation, validationErrors } = useReservationState();
	const { addToCart, isInCart } = useCartState();

	const showCheckInOutSummary = reservation.Type === ReservationType.Onsite || reservation.Type === ReservationType.Offsite;
	const showItemSummary = reservation.Type === ReservationType.Onsite;
	const showMealsSummary = reservation.Type === ReservationType.Onsite || reservation.Type === ReservationType.Offsite;
	const showChargesSummary = reservation.Type === ReservationType.Onsite || reservation.Type === ReservationType.Offsite || reservation.Type === ReservationType.Virtual;

	const { event, eventDeposits, selectedEventDate, selectedDateRange } = useEventState();
	const { data: inventoryTypes } = useEventInventoryQuery(selectedEventDate, selectedDateRange);
	const { data: eventMeals } = useEventMealsQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);
	const { data: eventCharges } = useEventChargesQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);

	let totalCost = getReservationCost(reservation, inventoryTypes ?? [], eventMeals ?? [], eventCharges ?? [], true);
	let totalCostExcludingDiscount = getReservationCost(reservation, inventoryTypes ?? [], eventMeals ?? [], eventCharges ?? [], false);
	let deposit = getDepositAmountFromTotal(reservation, totalCost, event, eventDeposits);

	const reservationType = getInventoryType(reservation, inventoryTypes ?? []);
	const inventoryDescriptions = getInventoryDescriptions(reservation, inventoryTypes ?? []);
	const adults = getAdults(reservation);
	const children = getChildren(reservation);

	const [isValidating, setIsValidating] = useState(false);

	const isInventoryValid = isReservationInventoryValid(reservation, inventoryTypes ?? []);
	const isGuestsValid = isReservationGuestsValid(reservation);

	const handleAddToCart = async () => {
		if (!isInventoryValid || !isGuestsValid)
			return;

		setIsValidating(true);
		let errors = await validateReservation();
		setIsValidating(false);
		
		if (errors.length > 0) {
			return;
		}

		const reservationMetadata: ReservationMetadata = {
			eventTitle: event?.Title ?? "",
			inventoryType: reservationType,
			inventoryDescriptions: inventoryDescriptions,
			adults: adults,
			children: children,
			cost: _.round(totalCost, 2),
			deposit: _.round(deposit, 2)
		}

		
		reservation.Metadata = reservationMetadata;

		if (isInCart(reservation))
			toastSuccess("Your reservation has been updated")
		else
			toastSuccess("Your reservation has been added to the cart")

		addToCart(reservation);
		// clearReservation();
		history.push({ pathname: `/cart` });
	}
	
	return <>
		<div className="card shadow mb-3 position-sticky reservation-details-card">
			<h5 className="card-header"><FontAwesomeIcon icon={faClipboardList} className="text-muted mr-2" /> Reservation Summary</h5>
			<div className="card-body">
			
				{showCheckInOutSummary && <CheckInOutSummary />}

				{showItemSummary && <ItemSummary />}

				{showMealsSummary && <MealsSummary />}

				{showChargesSummary && <ChargesSummary />}

				<PromotionCodeSummary totalCost={totalCost} totalCostExcludingDiscount={totalCostExcludingDiscount} />
			
				<CostSummary totalCost={totalCost} />	

				{validationErrors && validationErrors.length > 0 &&
				<div className="text-danger font-weight-bold mb-3">
					Please check the reservation for any missing information or invalid choices.
				</div>
				}

				{!isGuestsValid && 
				<div className="text-danger font-weight-bold mb-3">
					One or more guests have the same name. All guests must have unique names in order to update the reservation.
				</div>
				}

				<button type="button" onClick={handleAddToCart} disabled={isValidating || !isInventoryValid || !isGuestsValid} className="btn btn-primary font-weight-bold btn-block">
					{isValidating ? <FontAwesomeIcon icon={faCircleNotch} spin className="mr-2" /> : <FontAwesomeIcon icon={faCartPlus} className="mr-2" />}
					{isInCart(reservation) ? "Update Reservation" : "Add Reservation to Cart" }
				</button>
			</div>
		</div>
	</>
}

export default EventReservationSummary

interface CheckInOutSummaryProps {
	
}

const CheckInOutSummary = ({  }: CheckInOutSummaryProps) => { 
	const { reservation } = useReservationState();

	return <div>
		<div className="row">
			<div className="col-6 text-center">
				<div><FontAwesomeIcon icon={faSignInAlt} /> <span className="font-weight-bold"> Check In</span></div>
				{moment(reservation.DateFrom).format('MMM D, YYYY')}
			</div>
			<div className="col-6 text-center">
				<div><FontAwesomeIcon icon={faSignOutAlt} /> <span className="font-weight-bold"> Check Out</span></div>
				{moment(reservation.DateTo).format('MMM D, YYYY')}
			</div>
		</div>
		<div className="badge badge-light text-black-50 mt-2 p-2 d-block">{getSummaryDescription(reservation)}</div>
		<hr className="mb-2" />
	</div>
}

interface ItemSummaryProps {
	// inventory: InventoryItem[];
}

const ItemSummary = ({ }: ItemSummaryProps) => {
	const { reservation } = useReservationState();

	return <>
		<div className="mb-2">
			{reservation && reservation.Items?.filter(i => i.Type === ReservationType.Onsite).length === 0 && 
				<div>No rooms selected</div>
			}
			{reservation && reservation.Items?.map((item, index) => {
				if (item.Type === ReservationType.Onsite)
					return <InventoryItemSummary reservationInventoryItem={item as ReservationInventoryItem} key={index} />
				else
					return <></>
			})}
		</div>
	</>
}


interface InventoryItemSummaryProps {
	reservationInventoryItem: ReservationInventoryItem;
}

const InventoryItemSummary = ({ reservationInventoryItem }: InventoryItemSummaryProps) => {
	const { reservation } = useReservationState();
	const { selectedEventDate, selectedDateRange } = useEventState();
	const { data: inventoryTypes, isLoading: isLoadingInventory } = useEventInventoryQuery(selectedEventDate, selectedDateRange);

	let inventoryCategory = getInventoryCategory(inventoryTypes ?? [], reservationInventoryItem.InventoryTypeId, reservationInventoryItem.InventoryCategoryId);
	let inventoryItem = getInventoryItem(inventoryTypes ?? [], reservationInventoryItem.InventoryTypeId, reservationInventoryItem.InventoryCategoryId, reservationInventoryItem.InventoryItemId);

	if (reservation.Items?.length === 0)
		return <></>

	if (isLoadingInventory)
		return <>
			<div className="font-weight-bold">
				{inventoryCategory?.Metadata?.Title ?? inventoryCategory?.Description} ({inventoryItem?.Description})
			</div>
			<div>Loading...</div>
		</>

	return <>
		<div className="font-weight-bold">
			{inventoryCategory?.Metadata?.Title ?? inventoryCategory?.Description} ({inventoryItem?.Description})
		</div>
		<div className="row">
			<div className="col-6">{getTotalNights(reservation)} nights</div>
			<div className="col-6 text-right">{inventoryItem && currency.format(getInventoryItemTotal(inventoryItem, reservation))}</div>
		</div>
	</>
}

interface MealsSummaryProps {
	
}

const MealsSummary = ({  }: MealsSummaryProps) => {
	const { reservation } = useReservationState();
	const { event, selectedEventDate } = useEventState();
	const { data: eventMeals, isLoading } = useEventMealsQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);

	if (reservation.Meals?.length === 0)
		return <></>

	if (isLoading)
		return <>
			<div className="font-weight-bold">
				Meals
			</div>
			<div>Loading...</div>
		</>

	var quantity = getMealsQuantity(reservation, eventMeals ?? []);
	var total = getMealsTotal(reservation, eventMeals ?? []);

	return <div className="mb-2">
		<div className="font-weight-bold">
			Meals
		</div>
		<div className="row">
			{quantity === 0 && <div className="col-6">No meals</div>}
			{quantity > 0 && <div className="col-6">{quantity} meal{quantity === 1 ? '':'s'}</div>}
			<div className="col-6 text-right">{currency.format(total)}</div>
		</div>
		{reservation.MealDiscount !== undefined && reservation.MealDiscount > 0 &&
		<div className="row">
			<div className="col-6">Discount</div>
			<div className="col-6 pl-0 text-right">{currency.format(-reservation.MealDiscount)}</div>
		</div>
		}
	</div>
}

const ChargesSummary = () => {
	const { reservation } = useReservationState();
	const { event, selectedEventDate } = useEventState();
	const { data: eventCharges, isLoading } = useEventChargesQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);

	var filteredCharges = getFilteredChargesForReservationType(eventCharges, reservation.Type);

	if (reservation.Charges?.length === 0)
		return <></>

	if (isLoading)
		return <>
			<div className="font-weight-bold">
				Charges
			</div>
			<div>Loading...</div>
		</>

	return <div className="mb-2">
		<div className="font-weight-bold">
			Charges
		</div>
		{filteredCharges?.map((eventCharge, index) => {
			return <div className="row" key={index}>
				<div className="col-8">{eventCharge.Title}</div>
				<div className="col-4 pl-0 text-right">{currency.format(getChargeTotal(eventCharge, reservation))}</div>
			</div>
		})}
	</div>
}

interface PromotionCodeSummaryProps {
	totalCost: number;
	totalCostExcludingDiscount: number;
}

const PromotionCodeSummary = ({ totalCost, totalCostExcludingDiscount }: PromotionCodeSummaryProps) => {
	const { reservation } = useReservationState();
	
	if (reservation.PromotionCodeId === 0 || reservation.PromotionCode === undefined)
		return <></>
		
	return <>
		<div className="font-weight-bold mt-2">Discounts</div>
		<div className="row">
			<div className="col-8">{reservation.PromotionCode.Code}</div>
			<div className="col-4 pl-0 text-right">{currency.format(totalCost - totalCostExcludingDiscount)}</div>
		</div>
	</>
}

interface CostSummaryProps {
	totalCost: number;
}

const CostSummary = ({ totalCost }: CostSummaryProps) => {
	return <>
		<hr className="mt-2 mb-2" />
		<div className="row">
			<div className="col-8">Subtotal</div>
			<div className="col-4 pl-0 text-right">{currency.format(totalCost)}</div>
		</div>
		<div className="row">
			<div className="col-8">Taxes <small>(13% HST)</small></div>
			<div className="col-4 pl-0 text-right">{currency.format(totalCost * 0.13)}</div>
		</div>
		<div className="row totals">
			<div className="col-6">Total Cost</div>
			<div className="col-6 pl-0 text-right">{currency.format(totalCost * 1.13)}</div>
		</div>

		<hr />
	</>
}