import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import { getPageContentItem, PageContentComponentType, useConfigurationQuery, usePageContentQuery } from '../../api/configuration.api';
import DefaultLayout from "../../layouts/default-layout"
import AccountAddresses from './account-addresses';
import AccountInformation from './account-information';
import AccountPeople from './account-people';
import AccountReservations from './account-reservations';
import AccountUpdateEmail from './account-updateemail';
import AccountUpdatePassword from './account-updatepassword';

import "./account.css";
import AccountProgramRegistrations from './account-programregistrations';

interface AccountProps {
	defaultTab?: "account"|"people"|"reservations"|"registrations";
}

const Account = ({ defaultTab }: AccountProps) => {
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState("account");
	const { data: configuration } = useConfigurationQuery();
	const { data: pageContent } = usePageContentQuery();

	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | My Account`;
	const accountHeader = getPageContentItem(pageContent, "Account", null, PageContentComponentType.HeaderImage);
	const headerImageUrl = accountHeader.Content;

	useEffect(() => {
		setCurrentTab(defaultTab ?? "account");
	}, [history.location]);

	return <>
		<DefaultLayout pageTitle="My Account" subTitle="Manage your contact information and view your reservations" size="sm" headerLargeImageUrl={headerImageUrl} headerSmallImageUrl={headerImageUrl}>
			
			<Tab.Container activeKey={currentTab} onSelect={key => setCurrentTab(key ?? "account")} defaultActiveKey={defaultTab ?? "account"}>
				<Row>
					<Col sm={3} className="border-right">
					<Nav variant="pills" className="flex-column mb-5">
						<Nav.Item>
							<Nav.Link eventKey="account">Account</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="people">People &amp; Addresses</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="reservations">Event Reservations</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="registrations">Program Registrations</Nav.Link>
						</Nav.Item>
					</Nav>
					</Col>
					<Col sm={9}>
					<Tab.Content>
						<Tab.Pane eventKey="account" className="mb-4">
							<AccountInformation />
							<hr />
							<AccountUpdateEmail />
							<hr />
							<AccountUpdatePassword />
						</Tab.Pane>
						<Tab.Pane eventKey="people">
							<AccountPeople />
							<hr />
							<AccountAddresses />
						</Tab.Pane>
						<Tab.Pane eventKey="reservations">
							<AccountReservations />
						</Tab.Pane>
						<Tab.Pane eventKey="registrations">
							<AccountProgramRegistrations />
						</Tab.Pane>
					</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>

		</DefaultLayout>			
	</>
}

export default Account