import { useFormik } from 'formik';
import moment from "moment-timezone"
import classNames from 'classnames';
import _ from 'lodash';
import { useProgramActivitiesQuery, useProgramActivitySessionsQuery } from "../../../api/programs.api"
import { ActivitySelectionType, Program, ProgramActivity, ProgramActivitySession, ProgramGroup, ProgramSession } from '../../../api/programs.models';
import { useRegistrationState } from "../functions/registration-state";
import { currency } from '../../../common/helpers';
import { useEffect, useState } from 'react';

interface ProgramRegistrationActivitySelectionProps {
	program: Program|undefined,
	programGroup: ProgramGroup|undefined,
	programSession: ProgramSession|undefined,
	participantId: string,
	activitySelectionMap: Map<string,ProgramActivity>
	// dates: string[]
}

const ProgramRegistrationActivitySelection = ({ program, programGroup, programSession, participantId, activitySelectionMap }: ProgramRegistrationActivitySelectionProps) => {
	const getProgramActivity = (programActivityId: number) => programActivities?.find(p => p.Id === programActivityId);
	// const getProgramSession = (programSessionId: number) => programSessions?.find(c => c.Id === programSessionId);
	
	const { registration, updateRegistration, useRegistrationValidationListener } = useRegistrationState();

	const { data: programActivities } = useProgramActivitiesQuery();
	// const { data: programSessions } = useProgramSessionsByIdQuery(program?.Id ?? 0);
	const { data: programActivitySessionsUngrouped, isLoading, refetch } = useProgramActivitySessionsQuery(); //useProgramActivitySessionsForProgramQuery(program?.Id ?? 0, programSession?.StartDate ?? "", programSession?.EndDate ?? "");

	// let programActivitySessionPeriods = [];
	// for (let programActivitySession of programActivitySessions) {

	// }

	useEffect(() => {
		// refetch();
	}, []);

	useRegistrationValidationListener(() => {
		refetch();
	});

	const getProgramActivitySessions = () => {
		let results = programActivitySessionsUngrouped?.filter(p => { 
			return p.ProgramIds.includes(program?.Id ?? 0) &&
				moment(p.StartDate).isSameOrAfter(moment(programSession?.StartDate)) &&
				moment(p.EndDate).isSameOrBefore(moment(programSession?.EndDate));
				// moment(p.StartDate).isSameOrAfter(moment(programSession?.StartDate).startOf("day")) &&
				// moment(p.EndDate).isSameOrBefore(moment(programSession?.EndDate).endOf("day"));
		}) ?? [];

		results = results.sort((a, b) => { return moment(a.StartDate).isBefore(moment(b.StartDate)) ? -1 : 1 });

		let sessionsGroupedByTime: ProgramActivitySession[][] = [];
		let times = _.uniq(results.map(r => moment(r.StartDate).toISOString()));

		for (let time of times) {
			let classesForTime = results.filter(r => moment(r.StartDate).toISOString() === time);
			sessionsGroupedByTime.push(classesForTime);
		}
		
		return sessionsGroupedByTime;
	}

	const [programActivitySessions, setProgramActivitySessions] = useState<ProgramActivitySession[][]>(getProgramActivitySessions());

	useEffect(() => {
		if (programActivitySessionsUngrouped && programActivitySessionsUngrouped.length > 0)
			setProgramActivitySessions(getProgramActivitySessions());
	}, [programActivitySessionsUngrouped, isLoading])

	const getFilteredProgramActivitySessions = () => {
		if (programActivitySessions === undefined)
			return undefined;

		if (programGroup !== undefined && programGroup.ActivitySelectionType === ActivitySelectionType.Multi)
			return programActivitySessions;

		const getKey = (as: ProgramActivitySession) => {			
			return `${moment(as.StartDate).startOf('week').toISOString() ?? ""}-${moment(as.StartDate).format("h:mm a") ?? ""}-${moment(as.EndDate).format("h:mm a") ?? ""}`;
		}

		let programActivitySessionsCopy = _.cloneDeep(programActivitySessions);

		for (let i = 0; i < programActivitySessionsCopy.length; i++) {
			let sessionsForTime = programActivitySessionsCopy[i];

			// if (activitySelectionMap.size > 0) {
			sessionsForTime = sessionsForTime.filter(s => {
				let selection = activitySelectionMap.get(getKey(s));
				if (selection === undefined)
					return true;

				return s.ActivityId === selection.Id;
			});

			sessionsForTime.sort((a, b) => {
				return (programActivities?.findIndex(p => p.Id === a.ActivityId) ?? 0) < (programActivities?.findIndex(p => p.Id === b.ActivityId) ?? 0) ? -1 : 1;
			});
			// }
			
			programActivitySessionsCopy[i] = sessionsForTime;
			// let rps = registration.ProgramSessions.find(s => s.Participants[s.Participants.findIndex(p => p.ParticipantId === participantId)])
		}

		return programActivitySessionsCopy;
	}

	// const handleSelection = (programActivitySessionId: number) => {
	// 	let registrationProgramSession = registration.ProgramSessions.find(p => p.ProgramSessionId === programSessionId);
	// 	if (registrationProgramSession === null)
	// 		return;

	// 	let participantActivities = registrationProgramSession?.Participants?.find(a => a.ParticipantId === participantId);
	// 	if (participantActivities === undefined)
	// 		return;

	// 	if (!participantActivities.ActivitySessionIds.includes(programActivitySessionId))
	// 		participantActivities.ActivitySessionIds.push(programActivitySessionId);

	// 	updateRegistration(registration);
	// }

	const updateSelections = (programActivitySessionIds: number[]) => {
		let registrationProgramSession = registration.ProgramSessions.find(p => p.ProgramSessionId === programSession?.Id);
		if (registrationProgramSession === null)
			return;

		// for (let registrationProgramSession of registrationProgramSessions) {
		let participantActivities = registrationProgramSession?.Participants?.find(a => a.ParticipantId === participantId);
		if (participantActivities === undefined)
			return;

		participantActivities.ActivitySessionIds = programActivitySessionIds;
		// }

		updateRegistration(registration);
	}

	const getSelections = () => {
		let registrationProgramSession = registration.ProgramSessions.find(p => p.ProgramSessionId === programSession?.Id);
		if (registrationProgramSession === null)
			return;
		// if (registrationProgramSessions.length === 0)
		// 	return;

		// let registrationProgramSession = registrationProgramSessions[0];

		let participantActivities = registrationProgramSession?.Participants?.find(a => a.ParticipantId === participantId);
		if (participantActivities === undefined)
			return;

		let programActivitySessions = getFilteredProgramActivitySessions();

		if (participantActivities.ActivitySessionIds.length === 0 && programActivitySessions !== undefined && programActivitySessions.length > 0) {
			for (let sessionsForTime of programActivitySessions) {
				if (sessionsForTime.length === 1) {
					participantActivities.ActivitySessionIds.push(sessionsForTime[0].Id);
					// updateRegistration(registration);
				} else {
					participantActivities.ActivitySessionIds.push(0);
				}
			}
		}

		return participantActivities.ActivitySessionIds;
	}

	const showActivityPrice = (activityId: number) => {
		return (getProgramActivity(activityId)?.Price ?? 0) > 0;
	}

	const getActivityPriceDescription = (activityId: number) => {
		let programActivity = getProgramActivity(activityId);
		if (programActivity === undefined)
			return "";

		let price = currency.format(programActivity.Price);
		return `${price} ${programActivity.PriceDescription}`;
	}

	const isFull = (programActivitySession: ProgramActivitySession) => {
		return programActivitySession != undefined && programActivitySession.Enrollment >= programActivitySession.Limit;
	}

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			ProgramActivitySessionIds: getSelections()?.map(v => v.toString()) ?? []
		},
		onSubmit: values => {
		//   console.log(JSON.stringify(values, null, 2));
		},
		validate: values => {
			updateSelections(values.ProgramActivitySessionIds.map(p => Number(p)).filter(n => !isNaN(n)));
		},
		validateOnBlur: true,
		validateOnChange: true,
		validateOnMount: false
	  });

	// useEffect(() => {
	// 	formik.va
	// }, [])

	return <>
		<form onSubmit={formik.handleSubmit}>
		{/* <button type="button" className="btn btn-link btn-sm p-0" onClick={(e) => copySelection()}>Copy to other days</button> */}
			<div className={classNames(["row pr-3", (getFilteredProgramActivitySessions()?.length ?? 0) > 0 ? "mb-2":""])}>
				{getFilteredProgramActivitySessions()?.map((sessionsForTime, index2) => {
					return <div className="col-sm-4 pr-0" key={index2}>
							<div className="form-label-group mb-0">
								<select name={`ProgramActivitySessionIds[${index2}]`} className={classNames(["custom-select custom-select-sm d-block w-100 value-selected"], (formik.values.ProgramActivitySessionIds[index2] === undefined || formik.values.ProgramActivitySessionIds[index2] === "0" || isFull(sessionsForTime[0])) ? "is-invalid" : "is-valid")} onChange={formik.handleChange} value={formik.values.ProgramActivitySessionIds[index2]}>
									<option value={0}>{formik.values.ProgramActivitySessionIds[index2] === undefined ? "Make a selection..." : "Change selection..."}</option>
									{sessionsForTime.map((pac, index) => {
										return <option key={index} value={pac.Id}>
										{getProgramActivity(pac.ActivityId)?.Title}
										 {/* ({pac.Id}) */}
										{/* ({pac.Enrollment}/{pac.Limit})				 */}
									</option>
									})}
								</select>
								{sessionsForTime.length > 0 &&
								<label>{moment(sessionsForTime[0].StartDate).format("h:mm A")} - {moment(sessionsForTime[0].EndDate).format("h:mm A")}</label>
								}
							</div>
							{isFull(sessionsForTime[0]) && <>
							<small className="text-danger">This skill is at full capacity</small>
							</>}
							{sessionsForTime.length > 0 && showActivityPrice(sessionsForTime[0].ActivityId) &&
							<small>{getActivityPriceDescription(sessionsForTime[0].ActivityId)}</small>
							}
						</div>
				})}
			</div>
		</form>
		{/* {programActivitySessions && programActivitySessions.length > 0 &&
		<hr />
		} */}
	</>
}

export default ProgramRegistrationActivitySelection