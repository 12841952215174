import { Meal, MealDefinitionItem } from "./meals.models";

export interface Event {
	Id: number;
	Title: string;
	ShortDescription: string;
	Description: string;
	ImageURL: string;
	ImageURLSmall: string;
	FixedDates: boolean;
	CheckInTime: string;
	CheckOutTime: string;
	DepositType: EventDepositType;
	DepositAmount: string;
	AllowSharedAddress: boolean;
	AllowMultipleInventory: boolean;
	AllowCustomizeMeals: boolean;
	AdultGuests: boolean;
	ChildGuests: boolean;
	SortIndex: number;
	Hidden: boolean;
	Disabled: boolean;
	Deleted: boolean;
	Dates: EventDate[];
}


export enum EventDepositType {
	None = 0,
	Amount = 1,
	Percent = 2
}

export interface EventDate {
	Id: number;
	EventId: number;
	DateFrom: string;
	DateTo: string;
	Description: string;
	AllowOnsite: boolean;
	AllowOffsite: boolean;
	AllowVirtual: boolean;
	AttendanceLimit?: number;
	SoldOut: boolean;
}

export interface EventDeposit {
	Id: number;
	EventId: number;
	Description: string;
	QuantityType: EventDepositQuantityType;
	AmountType: EventDepositAmountType;
	Amount: number;
	DurationType: EventDepositDurationType;
	Duration: number;
}

export enum EventDepositQuantityType {
	PerReservation = 1,
	PerInventoryItem = 2
}

export enum EventDepositAmountType {
	Amount = 1,
	Percent = 2
}

export enum EventDepositDurationType {
	Any = 0,
	LessThanOrEqual = 1,
	MoreThanOrEqual = 2
}

export interface EventInventory	{
	Id: number;
	EventId: number;
	InventoryTypeId: number;
	InventoryCategoryId: number;
	Price: number;
	PriceDescription: string;
	SelectionType: EventInventorySelectionType;
}

export enum EventInventorySelectionType {
	Quantity = 0,
	Specific = 1
}

export interface EventInventoryItem {
	Id: number;
	Price: number;
	PriceDescription: string;
}

export interface EventMeal {
	Id: number;
	EventId: number;
	EventDateId: number;
	MealId: number;
	MealDefinitionId: number;
	MealDefinitionItemId: number;
	RequiredType: EventMealRequiredType;
	RequiredId?: number;
	Disabled: boolean;

	Meal: Meal;
	MealDefinitionItem: MealDefinitionItem;
}

export enum EventMealRequiredType {
	NotRequired = 0,
	Required = 1,
	NotRequiredIfOtherSelected = 2,
	RequiredIfOtherSelected = 3,
}

export interface EventMealPackage {
	Id: number;
	EventId: number;
	EventDateId: number;
	Title: string;
	Description: string;
	AvailableFrom?: string;
	AvailableTo?: string;
	AllowOnsite: boolean;
	AllowOffsite: boolean;
	AllowVirtual: boolean;

	EventMealPackageItems: EventMealPackageItem[];
}

export interface EventMealPackageItem {
	Id: number;
	MealPackageId: number;
	EventMealId: number;
}

export interface EventMealDiscount {
	Id: number;
	EventId: number;
	EventDateId: number;
	Title: string;
	Amount: number;
	Type: EventMealDiscountType;
	Specification: {
		Meals: { 
			MealId: number;
			Quantity: number;
			QuantityType: EventMealDiscountSpecificationQuantityType;
		}[];
	}
}

export enum EventMealDiscountType {
	Amount = 0,
	Percent = 1
}

export enum EventMealDiscountSpecificationQuantityType {
	Sequential = 1,
	Cumulative = 2
}

export interface EventMealRequirementOverride {
	Id: number;
	EventMealId: number;
	EventInventoryItemId: number;
	Required: boolean;
}

export interface EventCharge {
	Id: number;
	EventId: number;
	EventDateId: number;
	Title: string;
	Description: string;
	Required: boolean;
	// Price: number;
	// PriceDescription: string;
	AllowOnsite: boolean;
	AllowOffsite: boolean;
	AllowVirtual: boolean;
	SortIndex: number;
	Items: EventChargeItem[];
}

export interface EventChargeItem {
	Id: number;
	EventChargeId: number;
	Title: string;
	Price: number;
	PriceDescription: string;
	MinQuantityType: EventChargeItemQuantityType;
	MinimumQuantity: number;
	MaxQuantityType: EventChargeItemQuantityType;
	MaximumQuantity: number;
	SortIndex: number;
}

export enum EventChargeItemQuantityType {
	Number = 0,
	PerPerson = 1,
	PerAdult = 2,
	PerChild = 3,
	PerInventoryItem = 4,
	PerPersonNightly = 5,
	PerAdultNightly = 6,
	PerChildNightly = 7,
	PerInventoryItemNightly = 8
}