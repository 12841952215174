import { api } from './api';
import { LoginModel, LoginResponseModel, RegisterModel, RegisterResponseModel, PasswordResetModel, PasswordResetResponseModel, NewPasswordModel } from './auth.models';

export const login = async (loginModel: LoginModel) => {
	let response = await api.post<LoginResponseModel>(`/api/auth/login`, loginModel);
	return response.data;
}

export const logout = async () => {
	let response = await api.post(`/api/auth/logout`);
	return response.data;
}

export const register = async (registerModel: RegisterModel) => {
	let response = await api.post<RegisterResponseModel>(`/api/auth/register`, registerModel);
	return response.data;
}

export const resetPassword = async (resetPasswordModel: PasswordResetModel) => {
	let response = await api.post<PasswordResetResponseModel>(`/api/auth/reset-password`, resetPasswordModel);
	return response.data;
}

export const newPassword = async (newPasswordModel: NewPasswordModel) => {
	let response = await api.post<PasswordResetResponseModel>(`/api/auth/new-password`, newPasswordModel);
	return response.status === 200;
}