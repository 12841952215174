import { useFeaturedEventsQuery } from "../../../api/events.api";
import Event, { dateFormats } from "../../events/components/event"

const FeaturedEvents = () => {
	const { data: events, isLoading, isError } = useFeaturedEventsQuery();

	return <>
		<div className="row">
			{!isLoading && events?.map((event, index) => {
				return <div key={index} className="col-sm-6 mb-4">
					<Event event={event} dateFormat={dateFormats.long} />
				</div>	
			})}

			{isError && 
				<div>Sorry, but featured getaways can't be loaded at this time. Please try again later</div> 
			}
		</div>
	</>
}

export default FeaturedEvents