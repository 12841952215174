import { useEffect } from "react";
import * as Sentry from "@sentry/react";

const SetupSentry = () => {
	useEffect(() => {
		if (import.meta.env.VITE_SENTRY_ENABLED === "true") {
			Sentry.init({
				dsn: import.meta.env.VITE_SENTRY_DSN,
				environment: import.meta.env.VITE_SENTRY_ENV,
				// integrations: [new BrowserTracing()],
			  
				// Set tracesSampleRate to 1.0 to capture 100%
				// of transactions for performance monitoring.
				// We recommend adjusting this value in production
				// tracesSampleRate: 0.1,
			});
		}
	}, []);

	return <>
	</>
}

export default SetupSentry