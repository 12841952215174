import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from "moment-timezone";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEventState } from "./functions/event-state"
import { useInventoryState } from "./functions/inventory-state";
import { useReservationState } from "./functions/reservation-state";
import { useConfigurationQuery } from '../../api/configuration.api';
import { useReservationPoliciesQuery } from '../../api/reservation.api';
import { getReservationPolicyResult } from './functions/reservation-functions';
import EventDates from "./components/event-dates";
import EventsLayout from "../../layouts/events-layout";
import { linkTitle } from "../../common/helpers";

const EventDetails = () => {
	const history = useHistory();
	const location = useLocation();
	const {	event, eventDates, selectedEventDate, selectedDateRange, setCurrentStep } = useEventState();
	const { clearReservation } = useReservationState();
	const { clearSelectedInventory } = useInventoryState();
	const { data: configuration } = useConfigurationQuery();
	const { data: reservationPolicies } = useReservationPoliciesQuery(event?.Id ?? 0);

	if (configuration && event)
		document.title = `${configuration.OrganizationShortform} Online Reservation | ${event.Title}`;

	let reservationPolicyResult = getReservationPolicyResult(event?.Id ?? 0, selectedDateRange[0], selectedDateRange[1], reservationPolicies ?? []);

	let unavailable = event && event.Dates.length === 0;
	let soldOut = selectedEventDate?.SoldOut ?? false;

	useEffect(() => setCurrentStep(1), []);

	function createDescriptionMarkup() { return { __html: event.Description }; };

	const canCheckAvailability = () => {
		if (reservationPolicyResult != null && reservationPolicyResult.DatesValid === false)
			return false;

		return selectedEventDate != null && selectedDateRange != null;
	}

	const checkAvailability = () => {
		const params = new URLSearchParams();

		if (event.FixedDates) {
			params.append("dateFrom", moment(selectedEventDate.DateFrom).toISOString());
			params.append("dateTo", moment(selectedEventDate.DateTo).toISOString());
		} else {
			params.append("dateFrom", moment(selectedDateRange[0]).toISOString());
			params.append("dateTo", moment(selectedDateRange[1]).toISOString());
		}

		var currentParams = new URLSearchParams(location.search);
		if (!currentParams.has("edit")) {
			clearSelectedInventory();
			clearReservation();
		}
		history.push({ pathname: `/events/${linkTitle(event.Title)}/inventory`, search: params.toString() })
	}

	return <>
		<EventsLayout>
			{event &&
				<>
					{eventDates?.length > 0 &&
						<div className="row mb-3">
							<div className="col-sm-4 order-1 order-sm-0 mt-3 mt-sm-0">
								<h4>Event Details</h4>
							</div>
							<div className="col-12 col-sm-5 mb-2 mb-sm-0">
								<EventDates />
							</div>
							<div className="col-12 col-sm-3">
								<button className="btn btn-primary font-weight-bold btn-block" onClick={checkAvailability} disabled={!canCheckAvailability()}><FontAwesomeIcon icon={faArrowCircleRight} className="mr-2" />{event.Dates[0].AllowOffsite ? "Reservation Options" : "Check Availability"}</button>
							</div>
						</div>
					}

					{reservationPolicyResult && !reservationPolicyResult.DatesValid &&
						<div className="alert alert-warning mt-3 p-2 pl-3 pr-3">
							<h5 className="font-weight-bold">{reservationPolicyResult.Title}</h5>
							{reservationPolicyResult.Message}
						</div>
					}						

					{unavailable &&
						<div className="alert alert-danger p-2 pl-3 pr-3">
							<h5 className="font-weight-bold">Not currently available for online reservation</h5> 
							Online reservation for {event.Title} is currently unavailable. Please call the {configuration?.OrganizationShortform ?? ""} Office at {configuration?.OrganizationPhone ?? ""} to reserve. 
						</div>
					}

					{soldOut && !unavailable &&
						<div className="alert alert-danger p-2 pl-3 pr-3">
							<h5 className="font-weight-bold">Sold Out</h5>
							{event.Title} is currently sold out. 
							Please call the {configuration?.OrganizationShortform ?? ""} Office at {configuration?.OrganizationPhone ?? ""} to inquire about a waitlist.
						</div>
					}

					{(soldOut || unavailable) &&
						<h4>Event Details</h4>
					}
					<div dangerouslySetInnerHTML={createDescriptionMarkup()}></div>
				</>
			}

			
		</EventsLayout>
	</>
}

export default EventDetails