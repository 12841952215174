import { useQuery } from 'react-query';
import { linkTitle } from '../common/helpers';
import { api } from './api';
import { ProgramSession, Program, ProgramGroup, ProgramActivitySession, ProgramActivity } from './programs.models';


export const useProgramGroupsQuery = () => { 
	return useQuery('program-groups', async () => {
		let res = await api.get<ProgramGroup[]>(`/api/programs/groups`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useProgramGroupFromTitleQuery = (title: string) => { 
	return useQuery(['program-groups-by-title', title], async () => {
		let res = await api.get<ProgramGroup[]>(`/api/programs/groups`);
		return res.data?.find(programGroup => linkTitle(programGroup.Title) === title);
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useProgramsQuery = () => { 
	return useQuery('programs', async () => {
		let res = await api.get<Program[]>(`/api/programs`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useProgramFromTitleQuery = (title: string) => { 
	return useQuery(['programs-by-title', title], async () => {
		let res = await api.get<Program[]>('/api/programs');
		return res.data?.find(program => linkTitle(program.Title) === title);
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useProgramSessionsByIdQuery = (programId: number) => {
	return useQuery(['program-sessions', programId], async () => {
		let res = await api.get<ProgramSession[]>(`/api/programs/${programId}/sessions`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: programId > 0 });
}

export const useProgramSessionAccessCodeQuery = (programSessionId: number, accessCode: string) => {
	return useQuery(['program-session-access-code', programSessionId, accessCode], async () => {
		let res = await api.get<boolean>(`/api/programs/sessions/${programSessionId}/accesscode/${accessCode}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 0, enabled: programSessionId > 0 && accessCode !== "" });
}

export const useProgramActivitiesQuery = () => { 
	return useQuery('program-activities', async () => {
		let res = await api.get<ProgramActivity[]>(`/api/programs/activities`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useProgramActivitySessionsQuery = () => {
	return useQuery(['program-activity-sessions'], async () => {
		let res = await api.get<ProgramActivitySession[]>(`/api/programs/activities/sessions/all`);//
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useProgramActivitySessionsForProgramQuery = (programId: number, dateFrom: string, dateTo: string) => {
	return useQuery(['program-activity-sessions', programId, dateFrom, dateTo], async () => {
		let res = await api.get<ProgramActivitySession[][]>(`/api/programs/activities/sessions/program/${programId}`, { params: { dateFrom, dateTo } });
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: programId > 0 });
}