import * as stripeJs from "@stripe/stripe-js";
import { AxiosError } from "axios";
import { submitCart } from "../../../api/cart.api";
import { Cart, BillingContact, CartCheckoutResult, CartCheckoutResultErrorType, ValidationError } from "../../../api/cart.models";
import { Registration } from "../../../api/program.registration.models";
import { Reservation } from "../../../api/reservation.models";
import { getCountryCode } from "../../../common/address";
import { createEmtpyAddress } from "../../events/functions/reservation-functions";

export const createEmtpyBillingContact = () : BillingContact => {
	let billingContact: BillingContact = {
		
		Type: "adult",
		Id: 0,
		FirstName: "",
		LastName: "",
		Gender: 0,
	
		Address: createEmtpyAddress(),

		Salutation: "",
		Email: "",
		HomePhone: "",
		MobilePhone: ""
	};

	return billingContact;
}

export const getValidationErrors = (validationErrors: ValidationError[], reservation: Reservation) => {
	return validationErrors?.filter(e => e.ReservationId === reservation.Id) ?? [];
}

export const getEventValidationErrors = (validationErrors: ValidationError[], reservation: Reservation) => {
	return validationErrors?.filter(e => e.Type === "event" && e.ReservationId === reservation.Id) ?? [];
}

export const getReservationValidationErrors = (validationErrors: ValidationError[], reservation: Reservation) => {
	return validationErrors?.filter(e => e.Type === "reservation" && e.ReservationId === reservation.Id) ?? [];
}

export const getInventoryValidationErrors = (validationErrors: ValidationError[], reservation: Reservation) => {
	return validationErrors?.filter(e => e.Type === "inventory" && e.ReservationId === reservation.Id) ?? [];
}

export const getOtherValidationErrors = (validationErrors: ValidationError[], reservation: Reservation) => {
	return validationErrors?.filter(e => e.Type !== "inventory" && e.Type !== "event" && e.ReservationId === reservation.Id) ?? [];
}

export const isEventInvalid = (validationErrors: ValidationError[], reservation: Reservation) => {
	return (validationErrors?.filter(e => e.Code === "ERR_INVALID_EVENT" && e.ReservationId === reservation.Id) ?? []).length > 0;
}

export const getProgramValidationErrors = (validationErrors: ValidationError[], registration: Registration) => {
	return validationErrors?.filter(e => e.Type === "program" && e.RegistrationId === registration.Id) ?? [];
}

export const getRegistrationValidationErrors = (validationErrors: ValidationError[], registration: Registration) => {
	return validationErrors?.filter(e => e.Type === "registration" && e.RegistrationId === registration.Id) ?? [];
}

export const getRegistrationOtherValidationErrors = (validationErrors: ValidationError[], registration: Registration) => {
	return validationErrors?.filter(e => e.Type !== "registration" && e.Type !== "program" && e.RegistrationId === registration.Id) ?? [];
}

export const isProgramInvalid = (validationErrors: ValidationError[], registration: Registration) => {
	return (validationErrors?.filter(e => e.Code === "ERR_INVALID_PROGRAM" && e.RegistrationId === registration.Id) ?? []).length > 0;
}

export interface CreateStripePaymentMethodResult {
	Success: boolean;
	PaymentMethod: stripeJs.PaymentMethod|null;
	ErrorType?: CartCheckoutResultErrorType;
	ErrorMessage?: string;
}

export const createStripePaymentMethod = async (billingContact: BillingContact, stripe: stripeJs.Stripe, elements: stripeJs.StripeElements) : Promise<CreateStripePaymentMethodResult> => {
	let cardElement = elements?.getElement("card");

	if (cardElement === null || cardElement === undefined)
		return { 
		Success: false,
		PaymentMethod: null,
		ErrorType: CartCheckoutResultErrorType.PaymentProvider,
		ErrorMessage: "Stripe card element not loaded"
	};

	let paymentMethodResult = await stripe?.createPaymentMethod({
		card: cardElement,
		type: "card",
		billing_details: {
			name: billingContact.FirstName + ' ' + billingContact.LastName,
			email: billingContact.Email,
			phone: billingContact.HomePhone || billingContact.MobilePhone,
			address: {
				line1: billingContact.Address?.Street1,
				line2: billingContact.Address?.Street2,
				city: billingContact.Address?.City,
				state: billingContact.Address?.Province ?? billingContact.Address?.Region,
				postal_code: billingContact.Address?.PostalCode,

				country: getCountryCode(billingContact.Address?.Country ?? "")
			}
		}
	});

	if (paymentMethodResult?.error) {
		return { 
			Success: false,
			PaymentMethod: null,
			ErrorType: CartCheckoutResultErrorType.PaymentProvider,
			ErrorMessage: paymentMethodResult.error.message
		}
	}

	return {
		Success: true,
		PaymentMethod: paymentMethodResult?.paymentMethod
	}
}

export const checkout = async (cart: Cart, paymentMethod: stripeJs.PaymentMethod|null): Promise<CartCheckoutResult> => {
	cart.PaymentToken = paymentMethod ?? null;

	// const cartCopy = _.cloneDeep(cart);
	
	// for (let reservation of cartCopy.Reservations) {
	// 	reservation.Metadata = undefined;
	// }

	// for (let programRegistration of cartCopy.ProgramRegistrations) {
	// 	programRegistration.Metadata = undefined;
	// }

	try {
		let validationErrors = await submitCart(cart);

		if (validationErrors.length > 0) {
			return {
				Success: false,
				ErrorMessage: "There were errors processing the checkout",
				ValidationErrors: validationErrors
			}
		}

		return { 
			Success: true,
			ValidationErrors: []
		}
	} catch (error) {
		return {
			Success: false,
			ErrorType: CartCheckoutResultErrorType.API,
			ErrorMessage: (error as AxiosError<{ message: string }>).response?.data?.message ?? ""
		}
	}
}