import { useMutation, useQuery } from 'react-query';
import { api } from './api';
import { PromotionCodeResult, ProgramPromotionCodeResult } from './promotions.models';

export const usePromotionCodeQuery = (eventId: number, code: string) => { 
	return useQuery(['promotion-code', eventId, code], async () => {
		let res = await api.get<PromotionCodeResult[]>(`/api/common/promotions/event/${eventId}/code/${code}`);
		return res.data;
	}, { staleTime: 0, cacheTime: 0, enabled: eventId > 0 && code !== "" && code !== undefined });
}

export const useGetPromotionCodeMutation = () => {
	return useMutation((model: { eventId: number, code: string }) => api.get<PromotionCodeResult>(`/api/common/promotions/event/${model.eventId}/code/${model.code}`), {
		onSuccess: () => {
			
		}
	})
}

export const useGetPromotionCodeForEventDateMutation = () => {
	return useMutation((model: { eventDateId: number, code: string }) => api.get<PromotionCodeResult>(`/api/common/promotions/eventdate/${model.eventDateId}/code/${model.code}`), {
		onSuccess: () => {
			
		}
	})
}

export const useEventHasPromotionCodesQuery = (eventId: number, enabled: boolean = true) => { 
	return useQuery(['promotion-code', eventId], async () => {
		let res = await api.get<boolean>(`/api/common/promotions/event/${eventId}`);
		return res.data === true
	}, { staleTime: Infinity, cacheTime: 60000, enabled: enabled });
}

export const useProgramPromotionCodeMutation = () => {
	return useMutation((model: { programSessionId: number, code: string }) => api.get<ProgramPromotionCodeResult>(`/api/common/promotions/programsession/${model.programSessionId}/code/${model.code}`), {
		onSuccess: () => {
			
		}
	})
}

export const useHasProgramGroupPromotionCodesQuery = (programGroupId: number) => { 
	return useQuery(['has-program-group-promotion-codes', programGroupId], async () => {
		let res = await api.get<boolean>(`/api/common/promotions/programgroup/${programGroupId}`);
		return res.data === true
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useHasProgramSessionPromotionCodesQuery = (programSessionId: number, enabled: boolean = true) => { 
	return useQuery(['has-program-session-promotion-code', programSessionId], async () => {
		let res = await api.get<boolean>(`/api/common/promotions/programsession/${programSessionId}`);
		return res.data === true
	}, { staleTime: Infinity, cacheTime: 60000, enabled: enabled });
}