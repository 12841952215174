import { PaymentMethod as StripePaymentMethod } from "@stripe/stripe-js";
import { Registration } from "./program.registration.models";
import { GuestBase, Reservation } from "./reservation.models";

export interface Cart {
	Reservations: Reservation[];
	ProgramRegistrations: Registration[];
	BillingContact: BillingContact;
	PaymentMethod: PaymentMethod;
	PaymentToken: StripePaymentMethod|null;
	PaymentRequired?: boolean;
}

export interface CartCheckoutResult {
	Success: boolean;
	ErrorType?: CartCheckoutResultErrorType;
	ValidationErrors?: ValidationError[];
	ErrorMessage?: string;
}

export enum CartCheckoutResultErrorType {
	PaymentProvider,
	API
}

export interface ValidationError {
	Code: string;
	Message: string;
	Type?: string;
	ReservationId?: number;
	RegistrationId?: number;
}

export enum PaymentMethod {
	None = 0,
	DepositOnline = 1,
	FullAmountOnline = 2,
	PhoneFollowUp = 3
}

export interface BillingContact extends GuestBase {
	Salutation?: string;
	Email?: string;
	HomePhone?: string;
	MobilePhone?: string;
}