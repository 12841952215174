import { useField } from "formik";
import DateInput from "./DateInput";

interface DateInputFieldProps {
	name: string
	className?: string
	required?: boolean,
	disabled?: boolean,
	autoComplete?: "on"|"off",
	placeholder?: string;
	// date: Date;
	minDate: Date|undefined;
	maxDate: Date|undefined;
}

export const DateInputField = (props: DateInputFieldProps) => {
	const [field, meta, helpers] = useField(props.name);
	return <DateInput {...field} {...props} date={meta.value} onDateSelected={(value: string) => helpers.setValue(value)} />
}