import { LightgalleryItem } from "react-lightgallery";
import { useMediaGalleryQuery } from '../../api/media.api'
import "lightgallery.js/dist/css/lightgallery.css";

const MediaGallery = ({ galleryId, open, showNoPhotos = true }) => {
	const { data: mediaGallery, isLoading } = useMediaGalleryQuery(galleryId, true);

	const smallItemStyles = {
	    cursor: 'pointer',
		maxHeight: '184px',
		width: '100%',
		objectFit: 'cover',			
		borderRadius: '5px',
		boxShadow: '0px 12px 18px -6px rgb(0 0 0 / 30%)'
	}

	return <div className="row">
		{!isLoading && mediaGallery?.Images?.map((image, index) => {
			return <div className="col-sm-4 mb-4" key={index}>
				<LightgalleryItem group={`mediagallery-${galleryId}`} src={image.ImageURL}>
					<img src={image.ImageURL} alt="" style={smallItemStyles} />
				</LightgalleryItem>
			</div>
		})}
		{!isLoading && (mediaGallery?.Images?.length ?? 0) === 0 && showNoPhotos === true &&
			<div className="col-12 text-center">No photos available</div>
		}
	</div>
}

export default MediaGallery