import { Formik, ErrorMessage, Field, useFormikContext } from 'formik';
import { faAddressCard } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Yup from 'yup';
import _, { debounce } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import { AdultParticipant, ChildParticipant, ParentGuardian, ParticipantBase, Registration } from '../../../api/program.registration.models';
import { DateInputField } from '../../../common/components/DateInputField';
import { PhoneInputField } from '../../../common/components/PhoneInputField';
import { getCountries, getProvincesStates, getProvinceLabel, getPostalCodeLabel } from '../../../common/address';
import { useEffect, useMemo, useState } from 'react';
import { useUserAddressQuery, useUserPeopleQuery } from '../../../api/user.api';
import { useAuthState } from '../../../state/auth.state';
import { useRegistrationState } from '../functions/registration-state';
import { Address, Person } from '../../../api/user.models';
import { useProgramState } from '../functions/program-state';
import { ProgramParticipantType } from '../../../api/programs.models';
import { useCartState } from '../../cart/functions/cart-state';

const AdultSchema = Yup.object().shape({
	Adult: Yup.object().shape({
		FirstName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Required'),
		LastName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Required'),
		Email: Yup.string().email('Invalid email').required('Required'),
		HomePhone: Yup.string().min(10, 'Not a valid phone number').when('MobilePhone', { is: undefined, then: (schema) => schema.required('Required') }),
		MobilePhone: Yup.string().min(10, 'Not a valid phone number').when('HomePhone', { is: undefined, then: (schema) => schema.required('Required') }),
		SameAddress: Yup.boolean(),
		Address: Yup.object().shape({
			Street1: Yup.string().required('Required'),
			Street2: Yup.string(),
			City: Yup.string().required('Required'),
			Country: Yup.string().required('Required'),
			Province: Yup.string(),
			PostalCode: Yup.string().required('Required'),
		}),
		Location: Yup.string().required('Required'),
		EmergencyContact: Yup.string().required('Required')
	}, [['HomePhone', 'MobilePhone']])
});

interface ProgramRegistrationAdultProps {
	participant: AdultParticipant;
	index: number;
	onRemove: (index: number) => void;
}

const ProgramRegistrationAdult = ({ participant, index, onRemove }: ProgramRegistrationAdultProps) => {
	const { programGroup } = useProgramState();

	const isAdult = true;
	const isChild = false;
	const salutations = ["", "Mr", "Mrs", "Ms", "Dr", "Rev"];
	const emergencyContactRequired = programGroup?.ParticipantType === ProgramParticipantType.ChildrenPreSelected ||
		programGroup?.ParticipantType === ProgramParticipantType.Children;
	
	const handleCountryChanged = (country: string, values: { Adult: ParticipantBase }) => {
		if (country === "Canada") {
			values.Adult.Address!.Province = "Ontario";
			values.Adult.Address!.Region = "";
		}
		else if (country === "United States") {
			values.Adult.Address!.Province = "Alabama";
			values.Adult.Address!.Region = "";
		}
		else
			values.Adult.Address!.Province = "";
	}

	// const showRemove = registration.Participants.length > 1;

	const showUseSameAddress = index > 0;
	
	return <>
		<p className="lead font-weight-bold mb-2">
			<FontAwesomeIcon icon={faAddressCard} className="mr-2" />
			Please provide information for Adult Participant #{index+1}
		</p>

		<Formik
			validationSchema={AdultSchema}
			enableReinitialize={true}
			initialValues={{
				Adult: _.cloneDeep(participant),
				GuestType: "adult"
			}}
			onSubmit={(values, { setSubmitting }) => {

			}}
			validateOnBlur={true}
		>
		{({ isSubmitting, submitCount, isValid, values, errors, handleChange }) => <>

			<AutoSaveAdultState ItemIndex={0} GuestIndex={index} GuestType={"adult"} />
			<div className="form-row">
				<div className="col-12 col-sm-6">
					<AutofillPerson />
				</div>
				{/* {showRemove &&
				<div className="col-12 col-sm-6 text-right">
					<button type="button" onClick={() => onRemove(index)} className="btn btn-link mt-1 p-0"><FontAwesomeIcon icon={faTimesCircle} className="mr-2" />Remove this Parent/Guardian</button>
				</div>
				} */}
			</div>
			<div className="form-row">
				{isAdult && 
				<div className={classNames(["col-6 col-sm-2", isChild ? 'pr-sm-0-x':''])}>
					<div className="form-label-group">
						<Field component="select" name="Adult.Salutation" className={classNames(["custom-select", values.Adult.Salutation !== '' ? "value-selected":""])}>
							{salutations.map((salutation, i) => {
								return <option key={i}>{salutation}</option>
							})}
						</Field>
						<label className="text-truncate">Salutation</label>
					</div>
				</div>
				}
				<div className={classNames(["col-12", isAdult ? "col-sm-4 pl-sm-0-x":"", isChild ? "col-sm-3 pr-sm-0-x":""])}>
					<div className="form-label-group">
						<Field type="text" name={`Adult.FirstName`} className={classNames([`form-control`, errors.Adult?.FirstName ? 'is-invalid':''])} placeholder={true ? "Required" : ""} autoComplete="off" />
						<label htmlFor={`firstName_${index}`}>First Name</label>
						<ErrorMessage name="Adult.FirstName" component="div" className="error-message fw-bold small text-danger" />
						{/* {!isUnique(values) &&
						<div className="error-message fw-bold small text-danger">Name is duplicated</div>
						} */}
					</div>
				</div>
				<div className={classNames(["col-12 col-sm-4", isAdult ? "pl-sm-0-x":"", isChild ? "pr-sm-0-x":""])}>
					<div className="form-label-group">
						<Field type="text" name={`Adult.LastName`} className={classNames([`form-control`, errors.Adult?.LastName ? 'is-invalid':''])} placeholder={true ? "Required" : ""} autoComplete="off" />
						<label>Last Name</label>
						<ErrorMessage name="Adult.LastName" component="div" className="error-message fw-bold small text-danger" />
						{/* {!isUnique(values) &&
						<div className="error-message fw-bold small text-danger">Name is duplicated</div>
						} */}
					</div>
				</div>
				<div className={classNames(["col-6 col-sm-2", isAdult ? "pl-sm-0-x":"", isChild ? 'pr-sm-0-x':''])}>
					<div className="form-label-group">
						<Field component="select" name="Adult.Gender" className={classNames(["custom-select", values.Adult.Gender !== 0 ? "value-selected":""])}>
							<option value="0"></option>
							<option value="1">Male</option>
							<option value="2">Female</option>
						</Field>
						<label className="text-truncate">Gender</label>
					</div>
				</div>
				{isChild &&
					<div className={classNames(["col-12 col-sm-3", "pr-sm-0-x"])}>
						<div className="form-label-group">
							<DateInputField name={`Adult.Birthdate`} minDate={moment().add(-100, "years").toDate()} maxDate={moment().toDate()} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="off" />
							<label>Date of Birth</label>
							<ErrorMessage name="Adult.Birthdate" component="div" className="error-message fw-bold small text-danger" />
						</div>
					</div>
				}

			</div>

			{isAdult && 
			<div className="form-row">
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Adult.Email`} className={classNames([`form-control`, errors.Adult?.Email ? 'is-invalid':''])} placeholder={true ? "Email (required)" : ""} autoComplete="off" required={false} />
						<label>Email</label>
						<ErrorMessage name="Adult.Email" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<PhoneInputField name="Adult.HomePhone" className={classNames([`form-control`, errors.Adult?.HomePhone ? 'is-invalid':''])} placeholder="Required" autoComplete="off" required={values.Adult.MobilePhone === "" || values.Adult.MobilePhone == null} />
						<label>Home Phone</label>
						<ErrorMessage name="Adult.HomePhone" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<PhoneInputField name="Adult.MobilePhone" className={classNames([`form-control`, errors.Adult?.MobilePhone ? 'is-invalid':''])} placeholder={values.Adult.MobilePhone === "" || values.Adult.MobilePhone == null ? "required":"required"} autoComplete="off" required={values.Adult.HomePhone === "" || values.Adult.HomePhone == null}  />
						<label>Cell Phone</label>
						<ErrorMessage name="Adult.MobilePhone" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
			</div>
			}


			{showUseSameAddress && <>
			<div className="form-row">
				<div className="col-12">
					<div className="custom-control custom-checkbox mb-2">					
						<Field type="checkbox" name="Adult.SameAddress" id={`Adult.SameAddress${index}`} checked={values.Adult.SameAddress} className="custom-control-input" />
						<label htmlFor={`Adult.SameAddress${index}`} className="custom-control-label">Use same address as Adult Participant #1</label>
					</div>		
				</div>
			</div>

			{values.Adult.SameAddress &&
			<div className="form-row">
				<div className="col-12 mb-2">
					<span>Address: </span>
					{values.Adult.Address?.Street1}{values.Adult.Address?.Street1 ? ", " : ""}
					{values.Adult.Address?.Street2}{values.Adult.Address?.Street2 ? ", " : ""}
					{values.Adult.Address?.City}{values.Adult.Address?.City ? ", " : ""}
					{values.Adult.Address?.Province}{values.Adult.Address?.Province ? ", " : ""}
					{values.Adult.Address?.PostalCode}{values.Adult.Address?.PostalCode ? ", " : ""}
					{values.Adult.Address?.Country}
				</div>
			</div>
			}
			</>}
			
			{(showUseSameAddress === false || values.Adult.SameAddress === false) && 
			<>
			<div className="form-row">
				<div className="col-12 col-sm-6">
					<AutofillAddress />
				</div>
			</div>

			<div className="form-row">
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Adult.Address.Street1`} className={classNames([`form-control`, _.get(errors.Adult, "Address.Street1") ? 'is-invalid':''])} placeholder={true ? "required" : ""} autoComplete="off" required={false} />
						<label>Street Address</label>
						<ErrorMessage name="Adult.Address.Street1" component="div" className="fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Adult.Address.Street2`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
						<label>Apt / Unit Number</label>
						<ErrorMessage name="Adult.Address.Street2" component="div" className="fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Adult.Address.City`} className={classNames([`form-control`, _.get(errors.Adult, "Address.City") ? 'is-invalid':''])} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
						<label>City</label>
						<ErrorMessage name="Adult.Address.City" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				<div className={classNames(["col-6 col-sm-4"])}>
					<div className="form-label-group">
						<Field component="select" name="Adult.Address.Country" onChange={(e:any) => { handleCountryChanged(e.target.value, values); handleChange(e); }} className={classNames(["custom-select", values.Adult.Address?.Country !== "" ? "value-selected":""])}>
							{getCountries().map((country, index) => {
								return <option key={index} value={country}>{country}</option>
							})}
						</Field>
						<label className="text-truncate">Country</label>
					</div>
				</div>
				{(values.Adult.Address?.Country === "Canada" || values.Adult.Address?.Country === "United States") &&
				<div className={classNames(["col-6 col-sm-4"])}>
					<div className="form-label-group">
						<Field component="select" name="Adult.Address.Province" className={classNames(["custom-select", values.Adult.Address?.Province !== "" ? "value-selected":""])}>
							{getProvincesStates(values.Adult.Address?.Country ?? "").map((province, index) => {
								return <option key={index} value={province}>{province}</option>
							})}
						</Field>
						<label className="text-truncate">{getProvinceLabel(values.Adult.Address?.Country ?? "")}</label>
					</div>
				</div>
				}
				{(values.Adult.Address?.Country !== "Canada" && values.Adult.Address?.Country !== "United States") &&
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Adult.Address.Region`} className={`form-control`} placeholder={" "} autoComplete="off" required={false} />
						<label>Region</label>
						<ErrorMessage name="Adult.Address.Region" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				}
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Adult.Address.PostalCode`} className={classNames([`form-control`, _.get(errors.Adult, "Address.PostalCode") ? 'is-invalid':''])} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
						<label className="text-truncate">{getPostalCodeLabel(values.Adult.Address?.Country ?? "")}</label>
						<ErrorMessage name="Adult.Address.PostalCode" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
			</div>

			</>}

			{emergencyContactRequired &&
				<div className="form-row">
					<div className="col-12">
						<p className="form-text font-weight-bold mb-2">Emergency Contact Information</p>
					</div>	
					<div className="col-12 col-sm-6">
						<div className="form-label-group mb-sm-0">
							<Field component="textarea" rows="3" maxLength="100" name={`Adult.Location`} className={classNames([`form-control`, errors.Adult?.Location ? 'is-invalid':''])} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
							<label>Room # / Site # / Address</label>
							<span className="text-muted small">({100-(values.Adult.Location != null ? values.Adult.Location.length : 0)}/100 characters remaining)</span>
						</div>
						<ErrorMessage name="Adult.Location" component="div" className="fw-bold small text-danger" />
					</div>
					<div className="col-12 col-sm-6">
						<div className="form-label-group mb-0">
							<Field component="textarea" rows="3" maxLength="100" name={`Adult.EmergencyContact`} className={classNames([`form-control`, errors.Adult?.EmergencyContact ? 'is-invalid':''])} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
							<label>Emergency Contact #</label>
							<span className="text-muted small">({100-(values.Adult.EmergencyContact != null ? values.Adult.EmergencyContact.length : 0)}/100 characters remaining)</span>
						</div>
						<ErrorMessage name="Adult.EmergencyContact" component="div" className="fw-bold small text-danger" />
					</div>
				</div>
			}
		</>}

		</Formik>
	</>
}

export default ProgramRegistrationAdult


interface AutoSaveAdultStateProps {
	ItemIndex: number,
	GuestIndex: number,
	GuestType: "adult"|"child"
}

const AutoSaveAdultState = ({ ItemIndex, GuestIndex, GuestType }: AutoSaveAdultStateProps) => {
	// const { reservation, updateReservation, emitReservationGuests, useReservationGuestsListener, useReservationValidationListener } = useReservationState();
	const { registration, updateRegistration, emitRegistrationParticipants, useRegistrationParticipantsListener, useRegistrationValidationListener } = useRegistrationState();
	const { values, submitForm, isValidating } = useFormikContext<{ Adult: AdultParticipant|ChildParticipant }>();

	useRegistrationValidationListener(() => {
		submitForm();
	});

	const update = useMemo(() => debounce((registration: Registration, values: { Adult: AdultParticipant|ChildParticipant }) => {
		if (values.Adult) {		
			if (GuestType === "adult") { 
				registration.Participants[GuestIndex] = values.Adult as AdultParticipant;
			}
			if (GuestType === "child") {
				registration.Participants[GuestIndex] = values.Adult as ChildParticipant;
			}
			
	
			updateRegistration(registration);
			emitRegistrationParticipants(registration);
				
		}
	}, 100), []);

	useEffect(() => {
		update(registration, values);
	}, [isValidating])

	const updateSameAddress = useMemo(() => debounce((res: Registration ,values: { Adult: ParentGuardian|AdultParticipant|ChildParticipant }) => {
		if (values.Adult.SameAddress) {
			values.Adult.Address = res.Participants[0].Address;
		}
		updateRegistration(res);
	}, 1000), []);

	useRegistrationParticipantsListener((reg: Registration) => {
		updateSameAddress(reg, values);
	});

	useEffect(() => {
		if (GuestIndex > 0 || GuestType === "child") {
			updateSameAddress(registration, values);
		}
	}, [values.Adult.SameAddress])
	
	return <></>
}


interface AutofillPersonProps {
	// ItemIndex: number
	// GuestIndex: number
	// GuestType: "adult"|"child"
}

const AutofillPerson = ({ }: AutofillPersonProps) => {
	const { values, setValues, validateForm } = useFormikContext<{ Adult: AdultParticipant|ChildParticipant, GuestType: string }>();
	// const { validateReservation } = useRegistrationState();
	const { isAuthenticated } = useAuthState();
	const { getCartAutofillAdultParticipants, getCartAutofillParentGuardians } = useCartState();
	const { data: userPeople } = useUserPeopleQuery();

	const getAge = (person: Person) : number|null => {
		if (person.Birthdate == null)
			return null;

		return moment().diff(moment(person.Birthdate), "years");
	}
	
	// const autofillAdults = userPeople?.map(p => p.Person)?.filter(p => { return p != null && (p.Birthdate == null || (getAge(p) != null && getAge(p)! > 18 )) }) ?? [];
	// const autofillChildren = userPeople?.map(p => p.Person)?.filter(p => { return p != null && (p.Birthdate != null && (getAge(p) != null && getAge(p)! <= 18 )) }) ?? [];

	const [autofillPerson, setAutofillPerson] = useState("");
	let autofillPeople = userPeople?.map(p => p.Person).filter(p => ((values.GuestType === "adult" && p.Birthdate === null) || (values.GuestType === "child" && p.Birthdate != null && getAge(p) != null && getAge(p)! <= 18))).map(p => p) ?? [];
	let autofillCartPeople = getCartAutofillAdultParticipants();
	autofillCartPeople = autofillCartPeople.concat(getCartAutofillParentGuardians());

	const handleAutofillPerson = async (value: string) => {
		let autofillPerson = autofillPeople.find(a => a.Id === parseInt(value));
		if (autofillPerson === undefined)
			return handleAutofillParticipant(value);

		if (values.GuestType === "adult") {
			let guest = values.Adult as AdultParticipant;
			guest.Salutation = autofillPerson.Salutation;
			guest.FirstName = autofillPerson.FirstName;
			guest.LastName = autofillPerson.LastName;
			guest.Gender = autofillPerson.Gender;
			guest.Email = autofillPerson.PersonalEmail;
			guest.HomePhone = autofillPerson.HomePhone;
			guest.MobilePhone = autofillPerson.MobilePhone;

			setValues(values);
		}
		if (values.GuestType === "child") {
			let guest = values.Adult as ChildParticipant;
			guest.FirstName = autofillPerson.FirstName;
			guest.LastName = autofillPerson.LastName;
			guest.Gender = autofillPerson.Gender;
			guest.Birthdate = autofillPerson.Birthdate!;

			setValues(values);
		}

		setAutofillPerson("");

		await validateForm();
		await validateForm();

		// await validateReservation();
	}

	const handleAutofillParticipant = async (value: string) => {
		let autofillParticipant = autofillCartPeople.find(a => a.Id === value);
		if (autofillParticipant === undefined)
			return;

		if (values.GuestType === "adult") {
			let guest = values.Adult as AdultParticipant;
			guest.Salutation = autofillParticipant.Salutation;
			guest.FirstName = autofillParticipant.FirstName;
			guest.LastName = autofillParticipant.LastName;
			guest.Gender = autofillParticipant.Gender;
			guest.Email = autofillParticipant.Email;
			guest.HomePhone = autofillParticipant.HomePhone;
			guest.MobilePhone = autofillParticipant.MobilePhone;
			guest.EmergencyContact = autofillParticipant.EmergencyContact;
			guest.Location = autofillParticipant.Location;

			setValues(values);
		}
		// if (values.GuestType === "child") {
		// 	let guest = values.Child as ChildParticipant;
		// 	guest.FirstName = autofillParticipant.FirstName;
		// 	guest.LastName = autofillParticipant.LastName;
		// 	guest.Gender = autofillParticipant.Gender;
		// 	guest.Birthdate = autofillParticipant.Birthdate!;

		// 	setValues(values);
		// }

		setAutofillPerson("");

		await validateForm();
		await validateForm();
	}

	return !isAuthenticated() ? <></> : <>
		<select value={autofillPerson} onChange={(e) => handleAutofillPerson(e.target.value)} className="custom-select custom-select-sm w-100 mb-3 text-info font-weight-bold">
			<option>Autofill with person...</option>
			{autofillPeople?.map((person, i) => {
				return values.GuestType === "adult" ? 
					<option key={i} value={person.Id}>{person.FirstName} {person.LastName}, {person.PersonalEmail}, {person.HomePhone}</option> : 
					<option key={i} value={person.Id}>{person.FirstName} {person.LastName}</option>
			})}
			{autofillCartPeople.map((participant, i) => {
				return <option key={i} value={participant.Id}>{participant.FirstName} {participant.LastName}</option>
			})}
		</select>
	</>
}

interface AutofillAddressProps {
	// ItemIndex: number
	// GuestIndex: number
	// GuestType: "adult"|"child"
}

const AutofillAddress = ({ }: AutofillAddressProps) => {
	const { values, setValues, validateForm } = useFormikContext<{ Adult: AdultParticipant|ChildParticipant }>();
	// const { validateReservation } = useRegistrationState();
	const { isAuthenticated } = useAuthState();
	const { getCartAutofillAddresses } = useCartState();
	const { data: userAddresses } = useUserAddressQuery();

	const [autofillAddress, setAutofillAddress] = useState("");
	let autofillAddresses = userAddresses?.map(a => a.Address) ?? [];
	let autofillCartAddresses = getCartAutofillAddresses();

	const handleAutofillAddress = async (value: string) => {
		let autofillAddress = autofillAddresses.find(a => a.Street1 === value);
		if (autofillAddress === undefined) {
			autofillAddress = autofillCartAddresses.find(a => a.Street1 === value) as Address|undefined;
		}
		if (autofillAddress === undefined)
			return;

		values.Adult.Address = autofillAddress;
		setValues(values);

		setAutofillAddress("");

		await validateForm();
		await validateForm();

		// await validateReservation();
	}

	return !isAuthenticated() ? <></> : <>
		<select value={autofillAddress} onChange={(e) => handleAutofillAddress(e.target.value)} className="custom-select custom-select-sm w-100 mb-3 text-info font-weight-bold">
			<option>Autofill with address...</option>
			{autofillAddresses?.map((address, i) => {
				return <option key={i} value={address.Street1}>{address.Street1}, {address.City}, {address.Province}, {address.PostalCode}, {address.Country}</option>
			})}
			{autofillCartAddresses?.map((address, i) => {
				return <option key={i} value={address.Street1}>{address.Street1}, {address.City}, {address.Province}, {address.PostalCode}, {address.Country}</option>
			})}
		</select>
	</>
}