import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLightgallery } from "react-lightgallery";
import { faInfoCircle, faBed, faListAlt, faTasks, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import _ from "lodash";
import EventInventoryCategoryPrice from "./event-inventory-category-price";
import EventInventoryCategoryPicker from "./event-inventory-category-picker";
import EventInventorySelections from "./event-inventory-selections";
import { useInventoryCategoryState, useInventoryState } from "../functions/inventory-state";
import EventInventoryDetails from "./event-inventory-details";
import { useEventState } from "../functions/event-state";
import { linkTitle } from "../../../common/helpers";
import { useReservationState } from "../functions/reservation-state";
import { createReservation, getReservationInventoryPolicyResult, updateReservation } from "../functions/reservation-functions";
import { ReservationType } from "../../../api/reservation.models";
import { useEventInventoryQuery } from "../../../api/events.api";
import { useReservationInventoryPoliciesQuery } from "../../../api/reservation.api";
import { useAuthState } from "../../../state/auth.state";
import { useAuthUIState } from "../../../state/authui.state";
import './event-inventory-category.css';

function EventInventoryCategory ({ inventoryCategory }) {
	const history = useHistory();
	const location = useLocation();
	const { openGallery } = useLightgallery();

	const { getIsAuthenticated, useLoginListener } = useAuthState();
	const { showLogin } = useAuthUIState();

	const { isAvailable, isSelected, availableCount } = useInventoryCategoryState(inventoryCategory);
	const { event, selectedEventDate, selectedDateRange } = useEventState();
	const { getSelectedInventory, deselectAllInventoryItems } = useInventoryState();
	const { reservation, setCurrentReservation, inventorySelections } = useReservationState();
	const { data: inventoryTypes } = useEventInventoryQuery(selectedEventDate, selectedDateRange);

	const { data: reservationInventoryPolicies } = useReservationInventoryPoliciesQuery(event.Id ?? 0);
	let reservationInventoryPolicyResult = getReservationInventoryPolicyResult(event?.Id ?? 0, selectedDateRange[0], selectedDateRange[1], reservationInventoryPolicies ?? []);

	const isInventoryPolicyApplicable = reservationInventoryPolicyResult != null && reservationInventoryPolicyResult.DatesValid == false &&
		_.findIndex(reservationInventoryPolicyResult?.Policy?.InventoryCategories, category => category == inventoryCategory.Id) >= 0;

	const [selectionOpen, setSelectionOpen] = useState(inventorySelections.filter(s => s.InventoryCategoryId === inventoryCategory.Id).length > 0);
	const [detailsOpen, setDetailsOpen] = useState(false);
	const [bookInitiated, setBookInitiated] = useState(false);

	const isReservationUpdate = reservation !== null && reservation.Id !== undefined && reservation.Id > 0;
	const hasMediaGallery = inventoryCategory.Metadata.MediaGalleryId > 0;

	useEffect(() => {
		if (isInventoryPolicyApplicable) {
			deselectAllInventoryItems(inventoryCategory);
			console.log('deselectAllInventoryItems')
		}
	}, [isInventoryPolicyApplicable]);

	useLoginListener(() => {
		if (bookInitiated === true) {
			completeBook();
		}
	})

	const book = () => {
		if (!getIsAuthenticated()) {
			setBookInitiated(true);
			showLogin();
			return;
		}
		// const params = new URLSearchParams();

		completeBook();
	}

	const completeBook = () => {
		let selections = getSelectedInventory();

		if (isReservationUpdate && reservation.Type !== ReservationType.Offsite && reservation.Type !== ReservationType.Virtual) {
			reservation.Type = ReservationType.Onsite;
			updateReservation(reservation, ReservationType.Onsite, inventoryTypes, selections, { dateFrom: selectedDateRange[0], dateTo: selectedDateRange[1] });
			setCurrentReservation(reservation);
		} else {
			let newReservation = createReservation(event, selectedEventDate, ReservationType.Onsite, inventoryTypes, selections, { dateFrom: selectedDateRange[0], dateTo: selectedDateRange[1] });
			setCurrentReservation(newReservation);
		}		

		history.push({ pathname: `/events/${linkTitle(event.Title)}/reservation`, search: location.search })
	}

	return <div className="card mb-3 border-0 shadow">
		<div className="row no-gutters">
			<div className="col-sm-3">
				<img onClick={() => hasMediaGallery && openGallery(`mediagallery-${inventoryCategory.Metadata.MediaGalleryId}`)} src={inventoryCategory.Metadata.ImageURL} alt={inventoryCategory.Metadata.Title} className={classNames(["category-image rounded-top d-sm-none", !isAvailable ? 'image-muted':'', hasMediaGallery ? 'has-media-gallery':''])} />
				<img onClick={() => hasMediaGallery && openGallery(`mediagallery-${inventoryCategory.Metadata.MediaGalleryId}`)} src={inventoryCategory.Metadata.ImageURL} alt={inventoryCategory.Metadata.Title} className={classNames(["category-image rounded-left d-none d-sm-block", !isAvailable ? 'image-muted':'', hasMediaGallery ? 'has-media-gallery':''])} />
			</div>
			<div className="col-sm-6">
				<div className={classNames(["card-body mb-0 pt-3 pt-sm-2", !isAvailable ? 'text-muted':''])}>
					<h3 className="card-title">{inventoryCategory.Metadata.Title ?? inventoryCategory.Description}</h3>
					<p className="card-text m-0">
						<FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
						{inventoryCategory.Metadata.Description}
						<br />
						<FontAwesomeIcon icon={faBed} className="mr-2" />
						{inventoryCategory.Metadata.Sleeps}
					</p>
					<div className="cart-text m-0 mt-1">
						<div className="no-wrap">
							<FontAwesomeIcon icon={faListAlt} className="mr-2" />
							<button className="btn btn-link p-0 border-0 mr-3" style={{	marginTop: "-4px"}} onClick={() => setDetailsOpen(!detailsOpen)}>{inventoryCategory.Metadata.Title} Details</button>
						</div>
						<div className="no-wrap">
							<FontAwesomeIcon icon={faTasks} className="mr-2" />
							<button className="btn btn-link p-0 border-0 mr-3" style={{	marginTop: "-4px"}} onClick={() => setSelectionOpen(!selectionOpen)}>{inventoryCategory.Metadata.UnitDescription} Selection  ({availableCount} available)</button>
						</div>
					</div>
				</div>
			</div>
			<div className="col-sm-3 pl-4 pl-sm-0 pr-4 pt-sm-4 pb-3">
				
				<EventInventoryCategoryPicker inventoryCategory={inventoryCategory} selectionOpen={selectionOpen} toggleSelectionOpen={setSelectionOpen} />

				<EventInventoryCategoryPrice inventoryCategory={inventoryCategory} />

				{isInventoryPolicyApplicable &&
				<div className="alert alert-warning px-2 py-1 mt-1 mb-0" style={{ fontSize: '10pt' }}>
					{reservationInventoryPolicyResult.Message}
				</div>
				}

				{isSelected &&
				<>
				<button onClick={book} hidden={isInventoryPolicyApplicable} className="btn btn-primary font-weight-bold btn-sm btn-block mt-2 mb-3"><FontAwesomeIcon icon={faArrowCircleRight} className="mr-2" />
					{(!isReservationUpdate) ? <span>Book Now</span> : <span>Update Reservation</span>}
				</button>
				</>
				}
			</div>	
		</div>

		<EventInventoryDetails inventoryCategory={inventoryCategory} open={detailsOpen} />

		<EventInventorySelections inventoryCategory={inventoryCategory} open={selectionOpen} />
	</div>
}

export default EventInventoryCategory;