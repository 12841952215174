import { Link } from 'react-router-dom';
import { useEventsQuery } from '../../api/events.api';
import { useInventoryCategoriesQuery, useInventoryTypesQuery } from '../../api/inventory.api';
import { linkTitle } from '../helpers';

const ExploreProgramMenu = () => {

	const { data: events, isLoading: eventsLoading } = useEventsQuery();
	const { data: inventoryTypes, isLoading: isLoadingTypes } = useInventoryTypesQuery(true);
	const { data: inventoryCategories, isLoading: isLoadingCategories } = useInventoryCategoriesQuery(true);

	const getInventoryCategoriesForType = (inventoryTypeId: number) => {
		if (isLoadingCategories)
			return [];

		return inventoryCategories?.filter(c => c.InventoryTypeId === inventoryTypeId) ?? [];
	}
	
	const eventLinks = !eventsLoading && events?.map((event, index) => {
		return <li key={index} className="nav-item"><Link to={`/events/${linkTitle(event.Title)}`} className="nav-link text-small d-inline-block pb-0">{event.Title}</Link></li>
	})

	return <>
		<li className="nav-item dropdown megamenu d-none d-sm-block"><a id="megamenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Events</a>
			<div aria-labelledby="megamenu" className="dropdown-menu border-0 p-0 m-0">
				<div className="container">
					<div className="row rounded-bottom m-0 shadow megamenu-bg w-100 {{megaMenuThemeClass}}">
						<div className="col-lg-12 col-xl-8">
							<div className="p-4">
								<div className="row">

									<div className="col-sm-6 col-md-12 col-lg-6">
										<h6 className="font-weight-bold text-uppercase"><Link to="/events" className="nav-link text-small d-inline-block pb-0">EVENTS</Link></h6>
										<ul className="list-unstyled">
											{eventLinks}
										</ul>
									</div>

									{!isLoadingTypes && inventoryTypes?.map((inventoryType, index) => {
										return <div key={index} className="col-sm-3 col-md-6 col-lg-3">
											<h6 className="font-weight-bold text-uppercase"><Link to={`/explore/${linkTitle(inventoryType.Metadata?.Title ?? "")}`} className="nav-link text-small d-inline-block pb-0">{inventoryType.Metadata?.Title}</Link></h6>
											<ul className="list-unstyled">
												{getInventoryCategoriesForType(inventoryType.Id).map((inventoryCategory, index2) => {
													return <li key={index2} className="nav-item"><Link to={`/explore/${linkTitle(inventoryType.Metadata?.Title ?? "")}/${linkTitle(inventoryCategory.Metadata?.Title ?? "")}`} className="nav-link text-small d-inline-block pb-0">{inventoryCategory.Metadata?.Title}</Link></li>
												})}
											</ul>
										</div>
									})}

								</div>
							</div>
						</div>
						{/* <div className="col-lg-5 col-xl-4 px-0 d-none d-lg-block" style="background: center center url(https://res.cloudinary.com/mhmd/image/upload/v1556990826/mega_bmtcdb.png)no-repeat; background-size: cover;"></div> */}
					</div>
				</div>
			</div>
		</li>

		<li className="nav-item dropdown megamenu d-block d-sm-none">
			<div className="dropdown-divider"></div>
			<div className="small text-uppercase font-weight-bold">Events &amp; Where to Stay</div>
			<Link to="/events" className="nav-link text-small d-inline-block pb-0 mb-1">Events</Link>

			{!isLoadingTypes && inventoryTypes?.map((inventoryType, index) => {
				return <div key={index}>
						<Link to={`/explore/${linkTitle(inventoryType.Metadata?.Title ?? "")}`} className="nav-link text-small d-inline-block pb-0 mb-1">{inventoryType.Metadata?.Title}</Link>
				</div>
			})}

			<div className="dropdown-divider"></div>
		</li>
	</>
}

export default ExploreProgramMenu