import { useEffect } from "react";
import ReactGA from 'react-ga';

const SetupGoogleAnalytics = () => {
	useEffect(() => {
		if (import.meta.env.VITE_GA_ENABLED === "true") {
			ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_CODE);
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}, []);

	return <>
	</>
}

export default SetupGoogleAnalytics