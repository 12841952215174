import { State } from "@hookstate/core";
import _ from "lodash";
import { InventoryCategory, InventoryItem } from "../../../api/inventory.models";
import { InventoryState } from "./inventory-state";

export const selectInventoryItem = (inventoryState: State<InventoryState>, item: InventoryItem, allowMultipleSelection: boolean) => {
	let selectedInventoryItems = inventoryState.selectedInventoryItems.get({ noproxy: true }) as InventoryItem[];

	if (allowMultipleSelection === false)
		selectedInventoryItems = [];

	if (_.some(selectedInventoryItems, (i) => i.Id === item.Id) === false) {
		selectedInventoryItems.push(item);
	}
	inventoryState.selectedInventoryItems.set(selectedInventoryItems);
}

export const addInventoryItems = (inventoryState: State<InventoryState>, category: InventoryCategory, items: InventoryItem[], clear: boolean, allowMultipleSelection: boolean) => {
	let selectedInventoryItems = inventoryState.selectedInventoryItems.get({ noproxy: true }) as InventoryItem[];
	if (clear && selectedInventoryItems.length > 0) {
		selectedInventoryItems = selectedInventoryItems.filter((i) => i.InventoryCategoryId !== category.Id);
		inventoryState.selectedInventoryItems.set(selectedInventoryItems);
	}

	if (allowMultipleSelection === false)
		selectedInventoryItems = [];

	for (var item of items) {
		if (_.some(selectedInventoryItems, (i) => i !== null && i.Id === item.Id) === false) {
			selectedInventoryItems.push(item);
		}
	}
	inventoryState.selectedInventoryItems.set(selectedInventoryItems);
}

export const deselectInventoryItem = (inventoryState: State<InventoryState>, item: InventoryItem) => {
	let selectedInventoryItems = inventoryState.selectedInventoryItems.get({ noproxy: true }) as InventoryItem[];
	if (_.some(selectedInventoryItems, (i) => i.Id === item.Id) === true) {
		_.remove(selectedInventoryItems, (i) => i.Id === item.Id);
	}
	inventoryState.selectedInventoryItems.set(selectedInventoryItems);
}

export const selectAllInventoryItems = (inventoryState: State<InventoryState>, category: InventoryCategory) => {
	let selectedInventoryItems = inventoryState.selectedInventoryItems.get({ noproxy: true }) as InventoryItem[];
	for (var item of category.InventoryItems) {
		if (_.some(selectedInventoryItems, (i) => i !== null && i.Id === item.Id) === false &&
			item.Available === true) {
			selectedInventoryItems.push(item);
		}
	}
	inventoryState.selectedInventoryItems.set(selectedInventoryItems);
}

export const deselectAllInventoryItems = (inventoryState: State<InventoryState>, category: InventoryCategory) => {
	let selectedInventoryItems = inventoryState.selectedInventoryItems.get({ noproxy: true }) as InventoryItem[];
	if (selectedInventoryItems.length > 0) {
		selectedInventoryItems = selectedInventoryItems.filter((i) => i.InventoryCategoryId !== category.Id);
		inventoryState.selectedInventoryItems.set(selectedInventoryItems);
	}
}

export const getSelectedInventoryItemsForCategory = (selectedInventory: InventoryItem[], category: InventoryCategory) => {
	return selectedInventory.filter(i => i.InventoryCategoryId === category.Id) ?? [];
}

export const isInventoryItemSelected = (selectedInventory: InventoryItem[], inventoryItem: InventoryItem) => {
	return selectedInventory.filter(i => i.Id === inventoryItem.Id).length > 0;
}