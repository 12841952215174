import _ from "lodash";
import moment from "moment-timezone";
import { v4 as uuidv4 } from 'uuid';
import { AdultParticipant, ChildParticipant, Address, Registration, ParticipantBase, ParentGuardian } from "../../../api/program.registration.models";
import { Program, ProgramSessionSelection, ProgramGroup, ProgramParticipantType, PriceType, ProgramActivity, ProgramActivitySession, ProgramActivityPriceType } from "../../../api/programs.models";
import { ProgramPromotionCodeDiscountType } from "../../../api/promotions.models";
import { Person } from "../../../api/user.models";

export const createRegistration = (program: Program, programGroup: ProgramGroup, parentGuardian: ParentGuardian|null, selectedParticipants: ParticipantBase[], selectedProgramSessions: ProgramSessionSelection[], dateFrom: moment.Moment, dateTo: moment.Moment) : Registration => {
	let parentGuardians: ParentGuardian[] = [];

	if (programGroup.ParticipantType === ProgramParticipantType.ChildrenPreSelected) {
		parentGuardians.push(parentGuardian ?? createEmptyParentGuardian());
	}

	if (programGroup.ParticipantType === ProgramParticipantType.AdultsAndChildren || 
		programGroup.ParticipantType === ProgramParticipantType.Children) {

		if (!selectedParticipants.some(p => p.Type === "adult")) {
			parentGuardians.push(createEmptyParentGuardian());
		}
	}

	let registration: Registration = {
		Id: _.random(1000000, 9999999),
		ProgramId: program.Id,
		ProgramGroupId: programGroup.Id,
		DateFrom: dateFrom.toISOString(),
		DateTo: dateTo.toISOString(),
		Requests: "",
		PromotionCode: undefined,
		PromotionCodeId: undefined,
		ParentGuardians: parentGuardians,
		Participants: selectedParticipants,
		ProgramSessions: selectedProgramSessions
			.sort((a, b) => {
				return moment(a.ProgramSession.StartDate).isBefore(moment(b.ProgramSession.StartDate)) ? -1 : 1
			})
			.filter(a => {
				return moment(a.ProgramSession.StartDate).isSameOrAfter(moment(dateFrom)) &&
					moment(a.ProgramSession.EndDate).isSameOrBefore(moment(dateTo));
			})
			.map(s => { 
				return { 
					ProgramSessionId: s.ProgramSession.Id,
					Participants: selectedParticipants.map(participant => {
						return {
							ParticipantId: participant.Id,
							ActivitySessionIds: []
						}
					})
				} 
		}),
		Custom: []
		// Items: []
	}

	if (selectedParticipants.length > 0) {
		for (let participant of selectedParticipants) {
			if (parentGuardian != null) {
				participant.Address = parentGuardian.Address;
				participant.SameAddress = true;
			}

			// let registrationItem : RegistratonItemBase = {
			// 	Participant: participant,
			// 	ProgramClasses: programClassSelections.map(s => s.ProgramClass.Id)
			// }

			// registration.Items.push(registrationItem);
		}
	}

	// if (programClassSelections.length > 0) {
	// 	let registrationItem : RegistratonItemBase = {
	// 		AdultParticipants: selectedParticipants.filter(p => p.Type === "adult") as AdultParticipant[],
	// 		Adults: selectedParticipants.filter(p => p.Type === "adult").length,
	// 		ChildParticipants: selectedParticipants.filter(p => p.Type === "child") as ChildParticipant[],
	// 		Children: selectedParticipants.filter(p => p.Type === "child").length,
	// 		ProgramClasses: programClassSelections.map(s => s.ProgramClass)
	// 	}

	// 	if (registrationItem.AdultParticipants.length === 0) {
	// 		registrationItem.AdultParticipants.push(createEmptyAdult());
	// 		registrationItem.Adults = 1;
	// 	}

	// 	registration.Items.push(registrationItem);
	// }

	return registration;
}

export const createEmptyParentGuardian = () : ParentGuardian => {
	let adult: ParentGuardian = {
		Type: "adult",
		Id: uuidv4(),
		Salutation: "",
		FirstName: "",
		LastName: "",
		Gender: 0,
		Email: "",
		HomePhone: "",
		MobilePhone: "",
		Location: "",
		EmergencyContact: "",
		Address: createEmtpyAddress()
	};

	return adult;
}

export const createParentGuardian = (person: Person, address: Address) : ParentGuardian => {
	let adult: ParentGuardian = {
		Type: "adult",
		Id: uuidv4(),
		Salutation: person.Salutation,
		FirstName: person.FirstName,
		LastName: person.LastName,
		Gender: person.Gender,
		Email: person.PersonalEmail,
		HomePhone: person.HomePhone,
		MobilePhone: person.MobilePhone,
		Location: "",
		EmergencyContact: "",
		Address: _.cloneDeep(address)
	};

	return adult;
}

export const createEmptyAdult = () : AdultParticipant => {
	let adult: AdultParticipant = {
		Type: "adult",
		Id: uuidv4(),
		Salutation: "",
		FirstName: "",
		LastName: "",
		Gender: 0,
		Email: "",
		HomePhone: "",
		MobilePhone: "",
		SameAddress: true,
		Address: createEmtpyAddress()
	};

	return adult;
}

export const createEmptyChild = () : ChildParticipant => {
	let child: ChildParticipant = {
		Type: "child",
		Id: uuidv4(),
		FirstName: "",
		LastName: "",
		Gender: 0,
		Birthdate: new Date(),
		SameAddress: true,
		Address: createEmtpyAddress()
	};

	return child;
}

export const createParticipant = (person: Person) : ParticipantBase => {
	if (person.Birthdate !== null && person.Birthdate !== undefined)
		return createChildParticipant(person);
	
	return createAdultParticipant(person);
}

export const createAdultParticipant = (person: Person) : AdultParticipant => {
	let child: ChildParticipant = {
		Type: "child",
		Id: uuidv4(),
		FirstName: person.FirstName,
		LastName: person.LastName,
		Gender: person.Gender,

		Birthdate: null,

		SameAddress: false,
		Address: createEmtpyAddress()
	};

	return child;
}

export const createChildParticipant = (person: Person) : ChildParticipant => {
	let child: ChildParticipant = {
		Type: "child",
		Id:uuidv4(),
		FirstName: person.FirstName,
		LastName: person.LastName,
		Gender: person.Gender,

		Birthdate: person.Birthdate,

		SameAddress: false,
		Address: createEmtpyAddress()
	};

	return child;
}

export const createEmtpyAddress = () : Address => {
	let address: Address = {
		Id: 0,
		Street1: "",
		Street2: "",
		// Street3?: string;
		City: "",
		Province: "Ontario",
		PostalCode: "",
		Country: "Canada"
	}

	return address;
}

export const getParticipants = (registration: Registration) : string[] => {
	let adults = getAdults(registration);
	let children = getChildren(registration);
	return [...adults, ...children];
}

export const getParticipant = (registration: Registration, participantId: string) => {
	let participant = registration.Participants.find(p => p.Id === participantId);
	return participant;
}

export const getParticipantName = (registration: Registration, participantId: string) => {
	let participant = getParticipant(registration, participantId);
	if (participant === undefined)
		return "";
	return `${participant.FirstName} ${participant.LastName}`;
}

export const getActivities = (registration: Registration, programActivities: ProgramActivity[]|undefined, programActivitySessions: ProgramActivitySession[]|undefined) : { name: string, activities: string[] }[] => {
	let participantActivityMap = new Map<string,string[]>();

	for (let programSession of registration.ProgramSessions) {
		for (let participant of programSession.Participants) {
			for (let activitySessionId of participant.ActivitySessionIds) {
				let activitySession = programActivitySessions?.find(s => s.Id === activitySessionId);
				if (activitySession === undefined)
					continue;

				let activity = programActivities?.find(a => a.Id === activitySession!.ActivityId);
				if (activity === undefined)
					continue;

				if (!participantActivityMap.has(participant.ParticipantId)) {
					participantActivityMap.set(participant.ParticipantId, []);
				}

				var pa = participantActivityMap.get(participant.ParticipantId);
				if (pa && pa.findIndex(p => p.startsWith(activity!.Title)) < 0) {
					pa.push(`${activity.Title}${activitySession.Enrollment > activitySession.Limit ? ' (on wait list)':''}`);
					participantActivityMap.set(participant.ParticipantId, pa);
				}

			}
		}
	}

	var result: any[] = [];

	participantActivityMap.forEach((activities, participantId) => {
		let participant = getParticipant(registration, participantId);
		result = result.concat({ name: participant?.FirstName ?? "", activities: activities });
	})

	return result;
}

export const getAdults = (registration: Registration) : string[] => {
	var adults: string[] = [];

	for (let participant of registration.Participants) {	
		if (participant.Type !== "adult")
			continue;
		let adult = participant as AdultParticipant;
		
		adults.push(`${adult.FirstName} ${adult.LastName}`);
	};
	
	return adults;
}

export const getChildren = (registration: Registration) : string[] => {
	var children: string[] = [];

	for (let participant of registration.Participants) {	
		if (participant.Type !== "child")
			continue;
		let child = participant as ChildParticipant;
		var age = moment().diff(moment(child.Birthdate), "years");	
		var ageInMonths = moment().diff(moment(child.Birthdate), "months");
		children.push(`${child.FirstName} ${child.LastName} (${age >= 2 ? age : ageInMonths + ' months'})`);
	};
	
	return children;
}

export const getRegistrationCost = (registration: Registration, program: Program|undefined, programActivities: ProgramActivity[]|undefined, programActivitySessions: ProgramActivitySession[]|undefined, includeDiscount: boolean = false) => {
	var total = 0;

	total += getProgramTotal(registration, program);

	total += getProgramActivityTotal(registration, programActivities, programActivitySessions);

	if (includeDiscount) {
		if (registration.PromotionCode != null && includeDiscount) {
			var discountAmount = registration.PromotionCode.DiscountAmount;
			if (registration.PromotionCode.DiscountType === ProgramPromotionCodeDiscountType.Percent)
				discountAmount = total * discountAmount / 100;
			total -= discountAmount;
		}
	}

	return total;
}

export const getProgramTotal = (registration: Registration, program: Program|undefined) => {
	var programTotal = 0;
	if (program === undefined)
		return 0;

	for (let programSession of registration.ProgramSessions) {
		if (program?.PriceType === PriceType.PerGroup)
			programTotal += program.Price * 1;
		else
			programTotal += (programSession.Participants.length * program.Price);
	}

	return programTotal;
}

export const getProgramActivityTotal = (registration: Registration, programActivities: ProgramActivity[]|undefined, programActivitySessions: ProgramActivitySession[]|undefined) => {
	var programActivityTotal = 0;

	let participantActivityMap = new Map<string,number[]>();

	for (let programSession of registration.ProgramSessions) {
		for (let participant of programSession.Participants) {
			for (let activitySessionId of participant.ActivitySessionIds) {
				let activitySession = programActivitySessions?.find(s => s.Id === activitySessionId);
				if (activitySession === undefined)
					continue;

				let activity = programActivities?.find(a => a.Id === activitySession!.ActivityId);
				if (activity === undefined)
					continue;

				let key = `${participant.ParticipantId}-${moment(activitySession.StartDate).format("h:mm a") ?? ""}-${moment(activitySession.EndDate).format("h:mm a") ?? ""}`;

				if (!participantActivityMap.has(key)) {
					participantActivityMap.set(key, []);
				}

				if (activity.PriceType === ProgramActivityPriceType.PerPerson) {
					var pa = participantActivityMap.get(key);
					if (pa && pa.includes(activity.Id))
						continue;

					pa?.push(activity.Id);
					participantActivityMap.set(key, pa ?? []);
					programActivityTotal += Number(activity.Price);
				} else if (activity.PriceType === ProgramActivityPriceType.PerSession) {
					programActivityTotal += Number(activity.Price);
				}

			}
		}
		// if (program?.PriceType === PriceType.PerGroup)
		// programActivityTotal += program?.Price ?? 0;
		// else
		// programActivityTotal += (programSession.Participants.length * (program?.Price ?? 0));
	}

	return programActivityTotal;
}

export const isRegistrationParticipantsValid = (registration: Registration) => {
	var valid = true;

	let findMatchingCount = (participant: ParticipantBase) => {
		var count = 0;
		for (var p of registration.Participants) {
			if (p.FirstName === participant.FirstName &&
				p.LastName === participant.LastName)
				count++;
		}
		return count;
	}

	for (var participant of registration.Participants) {
		if (findMatchingCount(participant) > 1)
			valid = false;
	}
	return valid;
}