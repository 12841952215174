import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuthState } from '../state/auth.state';
import { api } from './api';
import { UserAddress, UserPerson } from './user.models';
import { ProgramRegistration } from './program.registration.models';

export const useUserPeopleQuery = () => { 
	const { isAuthenticated, userInfo } = useAuthState();

	return useQuery(['user-people', userInfo?.email], async () => {
		let res = await api.get<UserPerson[]>(`/api/user/people`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: isAuthenticated() });
}

export const useUserPeopleSaveMutation = () => {
	const queryClient = useQueryClient();
	const { userInfo } = useAuthState();

	return useMutation((userPerson: UserPerson) => api.post<UserPerson>(`/api/user/people`, userPerson), {
		onSuccess: () => {
			queryClient.invalidateQueries(['user-people', userInfo?.email]);
		}
	})
}

export const useUserPeopleDeleteMutation = () => {
	const queryClient = useQueryClient();
	const { userInfo } = useAuthState();

	return useMutation((userPerson: UserPerson) => api.delete(`/api/user/people/${userPerson.Id}`), {
		onSuccess: () => {
			queryClient.invalidateQueries(['user-people', userInfo?.email]);
		}
	})
}

export const useUserAddressQuery = () => { 
	const { isAuthenticated, userInfo } = useAuthState();

	return useQuery(['user-addresses', userInfo?.email], async () => {
		let res = await api.get<UserAddress[]>(`/api/user/addresses`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: isAuthenticated() });
}

export const useUserAddressSaveMutation = () => {
	const queryClient = useQueryClient();
	const { userInfo } = useAuthState();

	return useMutation((userAddress: UserAddress) => api.post<UserAddress>(`/api/user/addresses`, userAddress), {
		onSuccess: () => {
			queryClient.invalidateQueries(['user-addresses', userInfo?.email]);
		}
	})
}

export const useUserAddressDeleteMutation = () => {
	const queryClient = useQueryClient();
	const { userInfo } = useAuthState();

	return useMutation((userAddress: UserAddress) => api.delete(`/api/user/addresses/${userAddress.Id}`), {
		onSuccess: () => {
			queryClient.invalidateQueries(['user-addresses', userInfo?.email]);
		}
	})
}

export const useUserRegistrationQuery = (registrationId: number) => { 
	const { isAuthenticated, userInfo } = useAuthState();

	return useQuery(['user-registration', registrationId, userInfo?.email], async () => {
		let res = await api.get<ProgramRegistration>(`/api/user/registrations/${registrationId}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: isAuthenticated() && registrationId > 0 });
}

export const useUserRegistrationUpdateMutation = () => {
	const queryClient = useQueryClient();
	const { userInfo } = useAuthState();

	return useMutation((programRegistration: ProgramRegistration) => api.put(`/api/user/registrations/${programRegistration.Id}`, programRegistration), {
		onSuccess: (result, programRegistration) => {
			queryClient.invalidateQueries(['user-registration', programRegistration.Id, userInfo?.email]);
			queryClient.invalidateQueries(['registration-history', userInfo?.email]);
		}
	})
}

export const useUserRegistrationCancelMutation = () => {
	const queryClient = useQueryClient();
	const { userInfo } = useAuthState();

	return useMutation((programRegistrationId: number) => api.delete(`/api/user/registrations/${programRegistrationId}`), {
		onSuccess: () => {
			queryClient.invalidateQueries(['registration-history', userInfo?.email]);
		}
	})
}