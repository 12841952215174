import { useQuery } from 'react-query';
import { api } from './api';
import { InventoryAvailabilityResult, InventoryCategory, InventoryItem, InventoryProperty, InventoryType } from './inventory.models';

export const useInventoryTypesQuery = (includeMetadata = false) => { 
	return useQuery(['inventory-types', includeMetadata], async () => {
		let res = await api.get<InventoryType[]>(`/api/inventory/types${includeMetadata ? '/metadata' : ''}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useInventoryCategoriesQuery = (includeMetadata = false) => { 
	return useQuery(['inventory-categories', includeMetadata], async () => {
		let res = await api.get<InventoryCategory[]>(`/api/inventory/categories${includeMetadata ? '/metadata' : ''}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useInventoryItemsQuery = (includeMetadata = false, includeProperties: boolean = false, inventoryCategoryId: number = 0) => { 
	return useQuery(['inventory-items', includeMetadata, includeProperties, inventoryCategoryId], async () => {
		let res = await api.get<InventoryItem[]>(`/api/inventory/items${includeMetadata ? '/metadata' : ''}${includeProperties ? '/properties' : ''}${inventoryCategoryId !== 0 ? `?category=${inventoryCategoryId}` : ''}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useInventoryPropertiesQuery = (inventoryTypeId: number) => { 
	return useQuery(['inventory-properties', inventoryTypeId], async () => {
		let res = await api.get<InventoryProperty[]>(`/api/inventory/properties${inventoryTypeId !== 0 ? `?type=${inventoryTypeId}` : ''}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000 });
}

export const useInventoryAvailabilityQuery = (inventoryItemId: number, dateFrom: Date, dateTo: Date) => { 
	return useQuery(['inventory-availability', inventoryItemId, dateFrom, dateTo], async () => {
		let res = await api.get<InventoryAvailabilityResult>(`/api/inventory/items/${inventoryItemId}/availability?dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000 });
}