import { hookstate, useHookstate } from "@hookstate/core";
import { devtools } from '@hookstate/devtools'
import { InventoryCategory, InventoryItem } from "../../../api/inventory.models";
import { getAvailableInventoryItemsForCategory } from "./inventory-data";
import { addInventoryItems, deselectAllInventoryItems, deselectInventoryItem, getSelectedInventoryItemsForCategory, isInventoryItemSelected, selectAllInventoryItems, selectInventoryItem } from "./inventory-selection";

export interface InventoryState {
	selectedInventoryItems: InventoryItem[];
}

const INVENTORY_STATE = hookstate<InventoryState>(
	{ 
		selectedInventoryItems: []
	}, 
	devtools({ key: 'inventory' })
);

export const useInventoryState = () => {
	let state = useHookstate<InventoryState>(INVENTORY_STATE)

	return { 
		getSelectedInventory: () => state.selectedInventoryItems.get({ noproxy: true }) as InventoryItem[],
		setSelectedInventory: (inventoryItems: InventoryItem[]) => { state.selectedInventoryItems.set(inventoryItems) },
		clearSelectedInventory: () => { state.selectedInventoryItems.set([]) },
		addInventoryItems: (category: InventoryCategory, items: InventoryItem[], clear: boolean, allowMultipleSelection: boolean) => addInventoryItems(state, category, items, clear, allowMultipleSelection),
		deselectInventoryItem: (item: InventoryItem) => deselectInventoryItem(state, item),
		selectAllInventoryItems: (category: InventoryCategory) => selectAllInventoryItems(state, category),
		deselectAllInventoryItems: (category: InventoryCategory) => deselectAllInventoryItems(state, category),
		toggleInventoryItem: (inventoryItem: InventoryItem, allowMultipleSelection: boolean) => {
			if (isInventoryItemSelected(state.selectedInventoryItems.get({ noproxy: true }) as InventoryItem[], inventoryItem)) {
				deselectInventoryItem(state, inventoryItem);
			} else {
				selectInventoryItem(state, inventoryItem, allowMultipleSelection);
			}
		}
	};
}

export const useInventoryCategoryState = (inventoryCategory: InventoryCategory) => {
	let { getSelectedInventory } = useInventoryState();
	let selectedInventory = getSelectedInventory();

	return {
		isAvailable: getAvailableInventoryItemsForCategory(inventoryCategory).length > 0,
		isSelected: getSelectedInventoryItemsForCategory(selectedInventory, inventoryCategory).length > 0,
		availableCount: getAvailableInventoryItemsForCategory(inventoryCategory).length
	}
}