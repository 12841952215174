import Modal from 'react-bootstrap/Modal';
import { useAuthState } from '../../../state/auth.state';
import { useAuthUIState } from '../../../state/authui.state';
import { toastSuccess } from '../toast';

interface LogoutDialogProps {

}

const LogoutDialog = ({ }: LogoutDialogProps) => {
	const { logout } = useAuthState();
	const { isLogoutOpen, hideLogout } = useAuthUIState();

	const handleLogout = () => {
		logout();
		hideLogout();
		toastSuccess('You are now signed out');
	}

	const handleClose = () => {
		hideLogout();
	}

	return <>
		<Modal size="sm" show={isLogoutOpen} onHide={handleClose} centered>
			<Modal.Body className="pb-4 text-center">
				<p className="font-weight-bold">Do you want to logout?</p>
				<button onClick={handleLogout} type="button" className="btn btn-outline-primary mr-3 pl-4 pr-4">Logout</button>
				<button onClick={handleClose} type="button" className="btn btn-outline-secondary pl-4 pr-4">Cancel</button>
			</Modal.Body>
      </Modal>
	</>
}

export default LogoutDialog