import { useEffect, useState } from "react";
import { faCirclePlus, faSpinner, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Modal, ModalProps } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import moment from "moment-timezone";
import classNames from "classnames";

import { useUserPeopleQuery, useUserPeopleSaveMutation } from "../../../api/user.api";
import { Person, UserPerson } from "../../../api/user.models";
import { useAuthState } from "../../../state/auth.state";
import { useProgramState } from "../functions/program-state";
import { PersonSchema, createEmptyChild } from "../../account/functions/people-functions";
import { DateInputField } from "../../../common/components/DateInputField";
import { toastSuccess, toastError } from "../../../common/components/toast";
import { Program } from "../../../api/programs.models";

interface ChildParticipantPreSelectionProps {
	program: Program;
	date: moment.MomentInput|undefined;
}

const DISALLOW_UNDER_AGE_REGISTRATION = import.meta.env.VITE_FEATURE_FLAG_DISALLOW_UNDER_AGE_REGISTRATION === 'true';

const ChildParticipantPreSelection = ({ program, date }: ChildParticipantPreSelectionProps) => {
	const { getIsAuthenticated, showLogin } = useAuthState();
	const { data: people } = useUserPeopleQuery();
	const { selectedParticipants, setSelectedParticipants, addParticipant, removeParticipant } = useProgramState();
	
	const [showAddChildModal, setShowAddChildModal] = useState(false);
	const [ageOverrideChild, setAgeOverrideChild] = useState<Person|null>(null);
	const [underAgeChild, setUnderAgeChild] = useState<Person|null>(null);
	const [currentDate, setCurrentDate] = useState<moment.MomentInput|undefined>();

	useEffect(() => {
		if (currentDate === undefined || moment(currentDate).isSame(moment(date), "year") === false) {
			setCurrentDate(date);
			setSelectedParticipants([]);
		}
	}, [date]);

	const children = people?.filter(p => p.Person.Birthdate != null);//.map(p => p.Person as ChildParticipant);
	const isSelected = (child: Person) => selectedParticipants.find(p => p.FirstName === child.FirstName && p.LastName === child.LastName) != null;
	const toggleChild = (child: Person) => {
		if (isSelected(child)) 
			removeParticipant(child);
		else {
			if (isChildAgeValid(child)) {
				addParticipant(child);
				return;
			}
			if (DISALLOW_UNDER_AGE_REGISTRATION && isChildUnderProgramAge(child)) {
				setUnderAgeChild(child);
				return;
			}

			setAgeOverrideChild(child);
		}
	}

	const ageAsOfDate = date !== undefined ? moment(date).format("LL") : "today"; //sessions && sessions.length > 0 ? moment(sessions[0].StartDate).format("LL") : "today";
	const relativeDate = date !== undefined ? moment(date) : moment();

	const getAge = (child: Person) => { 
		// let relativeDate = date !== undefined ? moment(date) : moment(); //sessions && sessions.length > 0 ? moment(sessions[0].StartDate) : moment();

		var age: string|number = moment(relativeDate).diff(moment(child.Birthdate), 'years');
		if (age < 2)
			return `${moment(relativeDate).diff(moment(child.Birthdate), 'months')} months`;
		return age < 19 ? `Age ${age}` : null;
	}

	const isChildAgeValid = (child: Person) => {
		if (!program)// || !sessions)
			return false;

		// let relativeDate = date !== undefined ? moment(date) : moment(); //sessions.length > 0 ? moment(sessions[0].StartDate) : moment();

		let minAge = moment.duration(program.MinimumAgeMonths ?? 0, "month").add(program.MinimumAgeYears ?? 0, "year");
		let minAgeDate = moment(relativeDate).subtract(minAge);
		let maxAge = moment.duration(program.MaximumAgeMonths ?? 0, "month").add(program.MaximumAgeYears ?? 0, "year");
		let maxAgeDate = moment(relativeDate).subtract(maxAge);

		return moment(child.Birthdate).isBefore(minAgeDate) && moment(child.Birthdate).isAfter(maxAgeDate);		
	}

	const isChildUnderProgramAge = (child: Person) => {
		if (!program)
			return false;

		let minAge = moment.duration(program.MinimumAgeMonths ?? 0, "month").add(program.MinimumAgeYears ?? 0, "year");
		let minAgeDate = moment(relativeDate).subtract(minAge);

		return moment(child.Birthdate).isAfter(minAgeDate);
	}

	const addChild = () => {
		setShowAddChildModal(true);
	}

	if (!getIsAuthenticated())
		return <>
			<button type="button" onClick={showLogin} className="btn btn-primary font-weight-bold">
				Please log in to add children
			</button>
		</>

	return <>
		<AgeOverrideModal show={ageOverrideChild != null} program={program} child={ageOverrideChild} relativeDate={relativeDate} onHide={() => setAgeOverrideChild(null)} />
		<UnderAgeModal show={underAgeChild != null} program={program} child={underAgeChild} relativeDate={relativeDate} onHide={() => setUnderAgeChild(null)} />
		<AddChildModal show={showAddChildModal} onHide={() => setShowAddChildModal(false)} />

		<div className="row mt-3 mb-0">
			{children?.map((child, index) => {
				return <div className="col-12 col-sm-3 mb-3" key={index}>
					{/* <div className="pl-4">
					<FontAwesomeIcon size="3x" icon={faCheckCircle} className={classNames([isSelected(child.Person) ? "text-success" : "text-black-50"])} />
					</div> */}
					<div className="custom-control custom-checkbox custom-control-inline">
						<input type="checkbox" className={classNames(["custom-control-input"])} checked={isSelected(child.Person)} onChange={() => toggleChild(child.Person)} id={`child${child.Person.Id}`} />
						<label className={classNames(["custom-control-label", isSelected(child.Person) && "font-weight-bold"])} htmlFor={`child${child.Person.Id}`}>
							{child.Person.FirstName} {child.Person.LastName}<br />
						</label>
					</div>
					<div><small>({getAge(child.Person)} as of {ageAsOfDate})</small></div>
				</div>
			})}

			<div className="col col-sm-3">
				<button type="button" onClick={addChild} className="btn btn-primary btn-block font-weight-bold"><FontAwesomeIcon icon={faCirclePlus} className="mr-2" />Add {(children?.length ?? 0) > 0 ? "another" : "a"} child...</button>
			</div>
		</div>
	</>
}

export default ChildParticipantPreSelection

interface AgeOverrideModalProps {
	program: Program;
	child: Person|null;
	relativeDate: moment.MomentInput|undefined;
}

const AgeOverrideModal = ({ program, child, relativeDate, ...props }: AgeOverrideModalProps & ModalProps) => {
	const { addParticipant } = useProgramState();
	// const { data: sessions } = useProgramSessionsByIdQuery(program?.Id ?? 0);
	const [reason, setReason] = useState("");
	
	const getAcceptableRange = () => {
		if (program === undefined)
			return "";

		// let relativeDate = sessions && sessions.length > 0 ? moment(sessions[0].StartDate).startOf("day").add(-1, "days") : moment();
		var adjustedRelativeDate = moment(relativeDate).endOf("month");
		if (adjustedRelativeDate.diff(relativeDate, "month") != 0)
			adjustedRelativeDate = moment(relativeDate).startOf("month");

		return `${moment(adjustedRelativeDate).subtract(moment.duration(program.MaximumAgeMonths, "months")).format("LL")} and ${moment(adjustedRelativeDate).subtract(moment.duration(program.MinimumAgeMonths, "months")).format("LL")}.`;
	}

	const handleAddChild = () => {
		if (child) {
			addParticipant(child, reason);
			handleCancel();
		}
	}

	const handleCancel = () => {
		setReason("");
		if (props.onHide)
			props.onHide();
	}

	return <>
		<Modal centered {...props} enforceFocus={false} backdrop={true}>
			<Modal.Body className="p-4 shadow-lg">
				<button type="button" onClick={props.onHide} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3>Override Age Restriction</h3>
				<p><strong>{child?.FirstName} {child?.LastName}'s</strong> age does not fall within the expected range for this program. This could be for one of several reasons: the wrong age group was selected, your child’s date of birth is incorrectly entered, or you wish to have your child in age group outside our expected groupings.</p>
				<p><strong>Expected range:</strong> Born between {getAcceptableRange()}</p>
				<p><strong>If you wish to continue to add {child?.FirstName} to this program, please explain why below, so that we can support your family well.</strong></p>
				<textarea className="form-control" rows={4} value={reason} placeholder="Explanation for overriding age restriction" onChange={(e) => setReason(e.target.value)}></textarea>
				
				<hr />
				<div className="form-row">
					<div className="col-12 text-right">
						<button type="button" onClick={handleAddChild} disabled={reason.length === 0} className="btn btn-success font-weight-bold mb-3 mb-sm-0 mr-3">
							Add Child to Program
						</button>
						<button type="button" onClick={handleCancel} className="btn btn-light font-weight-bold mb-3 mb-sm-0">Cancel</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	</>
}


interface UnderAgeModalProps {
	program: Program;
	child: Person|null;
	relativeDate: moment.MomentInput|undefined;
}

const UnderAgeModal = ({ program, child, relativeDate, ...props }: UnderAgeModalProps & ModalProps) => {
	const getAcceptableRange = () => {
		if (program === undefined)
			return "";

		var adjustedRelativeDate = moment(relativeDate).endOf("month");
		if (adjustedRelativeDate.diff(relativeDate, "month") != 0)
			adjustedRelativeDate = moment(relativeDate).startOf("month");

		return `${moment(adjustedRelativeDate).subtract(moment.duration(program.MaximumAgeMonths, "months")).format("LL")} and ${moment(adjustedRelativeDate).subtract(moment.duration(program.MinimumAgeMonths, "months")).format("LL")}.`;
	}

	const handleCancel = () => {
		if (props.onHide)
			props.onHide();
	}

	return <>
		<Modal centered {...props} enforceFocus={false} backdrop={true}>
			<Modal.Body className="p-4 shadow-lg">
				<button type="button" onClick={props.onHide} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3>Under Minimum Age</h3>
				<p><strong>{child?.FirstName} {child?.LastName}'s</strong> age is below the minimum for this program. This could be for one of several reasons: the wrong age group was selected, your child’s date of birth is incorrectly entered, or you wish to have your child in a program outside our expected groupings.</p>
				<p><strong>Expected range:</strong> Born between {getAcceptableRange()}</p>
				<p><strong>If you wish to continue to add {child?.FirstName} to this program, please add them to the program that is appropriate for their age, and explain in the registration comments why you want them in a different program, so that we can follow up with you.</strong></p>
				
				<hr />
				<div className="form-row">
					<div className="col-12 text-right">
						<button type="button" onClick={handleCancel} className="btn btn-light font-weight-bold mb-3 mb-sm-0">Close</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	</>
}

interface AddChildModalProps {

}

const AddChildModal = ({ programSession, ...props }: AddChildModalProps & ModalProps) => {
	// const [editingPerson, setEditingPerson] = useState<UserPerson|undefined>();
	// const addPerson = () => {
	// 	var userPerson: UserPerson = {
	// 		Id: 0,
	// 		DefaultBilling: false,
	// 		Person: createEmptyChild()
	// 	}

	// 	setEditingPerson(userPerson);
	// }

	// useEffect(() => {
	// 	addPerson();
	// }, []);

	return <>
		<Modal centered {...props} enforceFocus={false} backdrop={true}>
			<Modal.Body className="p-4 shadow-lg">
				<button type="button" onClick={props.onHide} className="close pull-right" style={{ outlineStyle: "none"}} aria-label="Close">
					<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<h3>Add a Child</h3>
				<AddChildEditor onClose={props.onHide!} />
				{/* <hr />
				<Button onClick={props.onHide}>Cancel</Button> */}
			</Modal.Body>
		</Modal>
	</>
}


interface AddChildEditorProps {
	// person: UserPerson;
	// setEditingPerson: Dispatch<SetStateAction<UserPerson | undefined>>;
	onClose: () => void;
}

export const AddChildEditor = ({ onClose }: AddChildEditorProps) => {
	const { mutate: savePerson } = useUserPeopleSaveMutation();
	// const { mutate: deletePerson } = useUserPeopleDeleteMutation();
	// const [showRemovePopover, setShowRemovePopover] = useState(false);

	// const [person] = useState<UserPerson>({ 
	// 	Id: 0,
	// 	DefaultBilling: false,
	// 	Person: createEmptyChild()
	// });

	// const getAge = (userPerson: UserPerson) => {
	// 	if (userPerson.Person == null || userPerson.Person.Birthdate == null)
	// 		return 19;

	// 	var age = moment().diff(moment(userPerson.Person.Birthdate), 'years');
	// 	return age;
	// }

	// const isAdult = person.Person.Birthdate === undefined || getAge(person) >= 19;
	const isChild = true; // person.Person.Birthdate !== undefined && getAge(person) < 19;

	const handleSave = (values: UserPerson) => {
		savePerson(values, { 
			onSuccess: () => {
				toastSuccess('Your changes have been saved.');
			},
			onError: (error) => {
				toastError(null, 'Your changes were not saved. Please reload the page and try again.');
			},
			onSettled: () => {
				// setEditingPerson(undefined);
				onClose();

			}
		})
	}

	const handleCancel = () => {
		onClose();
	}

	return <>
		{/* <hr />
		<h5 className="font-weight-bold">{person.Id === 0 ? "New" : "Edit"} {isAdult ? "Adult" : "Child"}</h5> */}

		<Formik
			validationSchema={PersonSchema}
			enableReinitialize={true}
			initialValues={{ 
				Id: 0,
				DefaultBilling: false,
				Person: createEmptyChild()
			}}
			onSubmit={(values, { setSubmitting }) => {
				handleSave(values);
			}}
			validateOnBlur={true}
		>
		{({ handleSubmit, isSubmitting, submitCount, isValid, values, errors, handleChange }) => 
			<>
			<Form noValidate onSubmit={handleSubmit}>
				<div className="form-row mt-4">
					<div className={classNames(["col-5", isChild ? "col-sm-6 pr-sm-0-x":""])}>
						<div className="form-label-group">
							<Field type="text" name={`Person.FirstName`} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="off" />
							<label>First Name</label>
							<ErrorMessage name="Person.FirstName" component="div" className="error-message fw-bold small text-danger" />
						</div>
					</div>
					<div className={classNames(["col-7 col-sm-6", isChild ? "pr-sm-0-x":""])}>
						<div className="form-label-group">
							<Field type="text" name={`Person.LastName`} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="off" />
							<label>Last Name</label>
							<ErrorMessage name="Person.LastName" component="div" className="error-message fw-bold small text-danger" />
						</div>
					</div>
				</div>
				<div className="form-row">
					<div className={classNames(["col-6 col-sm-4", isChild ? 'pr-sm-0-x':''])}>
						<div className="form-label-group">
							<Field component="select" name="Person.Gender" className={classNames(["custom-select", values.Person.Gender !== 0 ? "value-selected":""])}>
								<option value="0"></option>
								<option value="1">Male</option>
								<option value="2">Female</option>
							</Field>
							<label className="text-truncate">Gender</label>
						</div>
					</div>
					{isChild &&
						<div className={classNames(["col-6 col-sm-4", "pr-sm-0-x"])}>
							<div className="form-label-group">
								<DateInputField name={`Person.Birthdate`} minDate={moment().add(-100, "years").toDate()} maxDate={moment().toDate()} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="off" />
								<label>Date of Birth</label>
								<ErrorMessage name="Person.Birthdate" component="div" className="error-message fw-bold small text-danger" />
							</div>
						</div>
					}

				</div>

				<hr />
				<div className="form-row">
					<div className="col-12 text-right">
						<button type="submit" disabled={!isValid || isSubmitting} className="btn btn-success font-weight-bold mb-3 mb-sm-0 mr-3">
							{isSubmitting && <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />}
							Save Child
						</button>
					{/* </div> */}
					{/* <div className="col-12 col-sm-3"> */}
						<button type="button" onClick={handleCancel} disabled={isSubmitting} className="btn btn-light font-weight-bold mb-3 mb-sm-0">Cancel</button>
					</div>
					{/* <div className="col-sm-3 d-none d-sm-block"></div>
					{person.Id > 0 &&
					<div className="col-12 col-sm-3">
						<OverlayTrigger show={showRemovePopover} trigger="click" placement="top" overlay={removePopover}>
							<button type="button" onClick={() => setShowRemovePopover(true)} disabled={isSubmitting} className="btn btn-danger btn-block float-right font-weight-bold">Remove Person</button>
						</OverlayTrigger>
					</div>
					} */}
				</div>
			</Form>
			</>}
		</Formik>

	</>
}