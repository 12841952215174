import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Protected from "../../common/components/auth/ProtectedRoute";
import Cart from "./cart";
import Checkout from "./checkout";
import CheckoutSuccess from "./checkout-success";

const CartRoutes = () => {
	let { path } = useRouteMatch();

	const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

	return <>
		<Switch>
			<Route path={path} exact>
				<Cart />
			</Route>
			<Route path={`${path}/checkout`} exact>
				<Protected>
					<Elements stripe={stripePromise}>
						<Checkout />
					</Elements>
				</Protected>
			</Route>
			<Route path={`${path}/checkout/success`} exact>
				<Protected>
					<CheckoutSuccess />
				</Protected>
			</Route>
		</Switch>
	</>
}

export default CartRoutes