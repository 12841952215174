import { faAsterisk, faCircleArrowRight, faListCheck, faUserGroup, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useEffect } from "react";
import { AdultParticipant, ChildParticipant, ProgramRegistrationPageContentType } from "../../api/program.registration.models";
import { useProgramSessionsByIdQuery } from "../../api/programs.api";
import { SessionSelectionType } from "../../api/programs.models";
import DefaultLayout from "../../layouts/default-layout"
import Breadcrumb from "./components/breadcrumb";
import ProgramRegistrationChild from "./components/program-registration-child";
import ProgramRegistrationAdult from "./components/program-registration-adult";
import ProgramRegistrationParentGuardian from "./components/program-registration-parent-guardian";
import ProgramRegistrationParticipantSessions from "./components/program-registration-participant-sessions";
import ProgramRegistrationSummary from "./components/program-registration-summary";
import { createEmptyParentGuardian } from "./functions/program-functions";
import { useProgramState } from "./functions/program-state";
import { useRegistrationState } from "./functions/registration-state";
import ProgramRegistrationRequests from "./components/program-registration-requests";
import ProgramRegistrationPromotionCode from "./components/program-registration-promotioncode";
import RegistrationPageContent from "./components/program-registration-pagecontent";
import ProgramRegistrationCustomFields from "./components/program-registration-custom-fields";
import { useConfigurationQuery } from "../../api/configuration.api";

// type ProgramRegistrationUrlParams = {
// 	programGroup: string;
// 	program: string;
// }

const ProgramRegistration = () => {
	const { data: configuration } = useConfigurationQuery();
	document.title = `${configuration?.OrganizationShortform ?? ""} Online Reservation | Registration`;

	// const { data: programs, isLoading: isLoadingPrograms } = useProgramsQuery();
	
	// const getProgram = (programId: number) => programs?.find(p => p.Id === programId);
	
	const { program, programGroup, setProgramSessionQuantity } = useProgramState();
	const { registration, updateRegistration } = useRegistrationState();
	
	const { data: programSessions } = useProgramSessionsByIdQuery(program?.Id ?? 0);
	// const { data: programActivitySessions } = useProgramActivitySessionsQuery();

	const showPolicy = (programGroup?.Policies ?? "") !== "";
	function createPolicyMarkup() { return { __html: programGroup?.Policies ?? "" }; };

	useEffect(() => {
		if (registration !== undefined && programSessions !== undefined) {
			// setSelectedParticipants(registration.Participants);
			for (let rps of registration.ProgramSessions) {
				let session = programSessions.find(s => s.Id === rps.ProgramSessionId);
				if (session !== undefined) {
					setProgramSessionQuantity(session, rps.Participants.length);
				}
			}
		}
	}, [programSessions]);

	// const getProgramSession = (programSessionId: number) => programSessions?.find(c => c.Id === programSessionId);
	// const getProgramForProgramSession = (programSessionId: number) => getProgram(getProgramSession(programSessionId)?.ProgramId ?? 0);
	// const programGroup = programGroups?.find(g => linkTitle(g.Title) === programGroupParam);
	// const program = programs?.find(p => linkTitle(p.Title) === programParam);
	const headerImageUrl = program?.ImageURL ?? programGroup?.ImageURL ?? "";

	const showParentGuardian = registration.ParentGuardians.length > 0;
	const canAddParentGuardian = registration.ParentGuardians.length < 2;

	const addParentGuardian = () => {
		let pg = createEmptyParentGuardian();
		pg.SameAddress = true;
		registration.ParentGuardians.push(pg);
		updateRegistration(registration);
	}

	const handleRemove = (index: number) => {
		registration.ParentGuardians.splice(index, 1);
		updateRegistration(registration);
	}

	const formatDateRange = () => {
		if (moment(registration.DateFrom).isSame(moment(registration.DateTo), "date")) {
			return `Date: ${moment(registration.DateFrom).format('MMM D, YYYY')} - ${moment(registration.DateFrom).format('h:mm A')} to ${moment(registration.DateTo).format('h:mm A')}`;	
		}
		
		// let nights = moment(eventDate.DateTo).diff(moment(eventDate.DateFrom), 'days');
		return `Dates: ${moment(registration.DateFrom).format('MMM D, YYYY')} - ${moment(registration.DateTo).format('MMM D, YYYY')}`;
	}

	// const copySelection = (program: Program|undefined, programSession: ProgramSession|undefined, registrationProgramSession: RegistrationProgramSession, participant: ParticipantBase) => {
	// 	console.log(program?.Id, programSession?.Id, participant.Id);
	// 	copyProgramSessionSelections(program!, programSession!, participant, programSessions!, programActivitySessions!);
	// }
	
	return <DefaultLayout pageTitle={`${programGroup?.Title ?? ""}: ${program?.Title ?? "Loading..."}`} subTitle={formatDateRange()} headerLargeImageUrl={headerImageUrl} headerSmallImageUrl={headerImageUrl}>
		<Breadcrumb step={3} />
		<h4 className="mb-4">Complete Registration</h4>
		
		<div className="row">
			<div className="col-12 col-md-8">

				{showPolicy &&
				<div className="card shadow mb-3 guest-information">
					<h5 className="card-header"><FontAwesomeIcon icon={faAsterisk} className="text-muted mr-2" />{programGroup?.Title} Registration Policy</h5>

					<div className="card-body">
						<p className="card-text" dangerouslySetInnerHTML={createPolicyMarkup()}></p>	
					</div>
				</div>
				}

				{showParentGuardian && 
				<div className="card shadow mb-3 guest-information">
					<h5 className="card-header"><FontAwesomeIcon icon={faUserGroup} className="text-muted mr-2" />Parent/Guardian Information</h5>

					<div className="card-body">
						{/* <h4 className="card-title">{programGroup.Title}: {program.Title}</h4> */}
						{registration.ParentGuardians.map((participant, index) => {
							return <ProgramRegistrationParentGuardian key={index} participant={participant} index={index} onRemove={handleRemove} />
						})}	

						{canAddParentGuardian &&
						<button type="button" onClick={addParentGuardian} className="btn btn-link p-0"><FontAwesomeIcon icon={faCircleArrowRight} className="mr-2" />Add another Parent/Guardian (optional)</button>
						}
					</div>
				</div>
				}

		
				<div className="card shadow mb-3 guest-information">
					<h5 className="card-header"><FontAwesomeIcon icon={faUsers} className="text-muted mr-2" />Participant Information</h5>

					<div className="card-body">
						<RegistrationPageContent type={ProgramRegistrationPageContentType.Participant} />

						{registration.Participants.map((participant, index) => {
							return <div key={index}>
								{participant.Type === "adult" && 
								<ProgramRegistrationAdult participant={participant as AdultParticipant} index={index} onRemove={handleRemove} />
								}
							
								{participant.Type === "child" &&
								<ProgramRegistrationChild participant={participant as ChildParticipant} index={index} onRemove={handleRemove} />
								}

								{index < registration.Participants.length - 1 && <hr />}
							</div>
						})}
					</div>
				</div>
		
				{programGroup?.SessionSelectionType === SessionSelectionType.Custom && 
				<div className="card shadow mb-3 guest-information">
					<h5 className="card-header"><FontAwesomeIcon icon={faListCheck} className="text-muted mr-2" />Program Information</h5>

					<div className="card-body">
						<RegistrationPageContent type={ProgramRegistrationPageContentType.Program} />

						<h4 className="card-title">{programGroup?.Title}: {program?.Title}</h4>

						{registration.Participants.map((participant, index) => {
							return <div key={index}>
								<ProgramRegistrationParticipantSessions participant={participant} />
								
								{index < registration.Participants.length - 1 && <hr />}
							</div>
						})}
					</div>
				</div>
				}

				<ProgramRegistrationCustomFields />

				<ProgramRegistrationRequests />

				<ProgramRegistrationPromotionCode />
		
			</div>

			<div className="col-12 col-md-4">
				<ProgramRegistrationSummary />
			</div>
		</div>

		{/* <pre>{JSON.stringify(registration, null, 2)}</pre> */}
		
		{/* <div>Items: {registration.Items.length}</div> */}
		{/* <div>Classes: {registration.Items[0].ProgramSessiones.length}</div> */}
	</DefaultLayout>
}

export default ProgramRegistration