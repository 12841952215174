import moment from "moment-timezone";
import { faAngleRight, faClipboardList, faLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useCartState } from "./functions/cart-state";
import { currency } from "../../common/helpers";
import { Reservation } from "../../api/reservation.models";
import "./cart-summary.css";
import { useHistory } from "react-router-dom";
import { useAuthState } from "../../state/auth.state";
import { useState } from "react";
import { Registration } from "../../api/program.registration.models";
import _ from "lodash";

interface CartSummaryProps {
	showCheckout?: boolean
}

const CartSummary = ({ showCheckout = true }: CartSummaryProps) => {
	const history = useHistory();
	const { isCartEmpty, validationErrors } = useCartState();
	const { getIsAuthenticated, showLogin, useLoginListener } = useAuthState();
	const [checkoutInitiated, setCheckoutInitiated] = useState(false);

	useLoginListener(() => {
		if (checkoutInitiated === true) {
			completeCheckout();
		}
	})

	const handleCheckout = () => {	
		if (!getIsAuthenticated()) {
			setCheckoutInitiated(true);
			showLogin();
			return;
		}
		completeCheckout();
	}

	const completeCheckout = () => {
		history.push({ pathname: '/cart/checkout' });
	}

	function getUniqueListBy<T>(arr: T[], key: string) {
		return [...new Map(arr.map(item => [_.get(item, key), item])).values()]
	}

	const registrationsWithErrors = getUniqueListBy(validationErrors, "RegistrationId");

	if (isCartEmpty())
		return <></>;

	return <>
		<div className="card shadow mb-3 position-sticky cart-summary-card">
			<h5 className="card-header"><FontAwesomeIcon icon={faClipboardList} className="text-muted mr-2" /> Summary</h5>
			<div className="card-body">
				<ItemSummary />
				<CostSummary />
				{showCheckout && 
				<>
					<hr />
					<button onClick={handleCheckout} disabled={validationErrors.length > 0} type="submit" className="btn btn-primary font-weight-bold font-weight-bold btn-block">
						{getIsAuthenticated() === false &&
						<FontAwesomeIcon icon={faLock} className="mr-2" />
						}
						<span>Checkout</span>
						<FontAwesomeIcon icon={faAngleRight} className="ml-2" />
					</button>
					
					{validationErrors.length > 0 &&
					<div className="text-danger font-weight-bold mt-3">
						{registrationsWithErrors.length} reservation{registrationsWithErrors.length === 1 ? '' : 's'} must be fixed in order to check out.
					</div>
					}
				</>
				}
			</div>
		</div>
	</>
}

export default CartSummary

const ItemSummary = () => {
	const { cart } = useCartState();

	return <>
		{cart.Reservations?.map((reservation, index) => {
			var last = index === (cart.Reservations.length ?? 0)-1;
			return <div key={index}>
				<ItemSummaryItem key={index} reservation={reservation} />
				{!last && <hr />}
			</div>
		})}

		{cart.Reservations && cart.Reservations.length > 0 && cart.ProgramRegistrations && cart.ProgramRegistrations.length > 0 && <hr />}

		{cart.ProgramRegistrations?.map((registration, index) => {
			var last = index === (cart.ProgramRegistrations.length ?? 0)-1;
			return <div key={index}>
				<ProgramItemSummaryItem key={index} registration={registration} />
				{!last && <hr />}
			</div>
		})}
	</>
}

interface ItemSummaryItemProps {
	reservation: Reservation;
}

const ItemSummaryItem = ({ reservation }: ItemSummaryItemProps) => {
	const formatDateRange = (reservation: Reservation) => {
		return `${moment(reservation.DateFrom).format('MMM D, YYYY')} - ${moment(reservation.DateTo).format('MMM D, YYYY')}`;
	}

	return <>
		<div className="row">
			<div className="col-8 col-md-12 col-lg-8">
				{reservation.Metadata?.eventTitle}
			</div>			
			<div className="col-4 col-md-12 col-lg-4 pl-xs-0 pl-lg-0 order-md-1 order-lg-0 text-lg-right text-right">
				{currency.format(reservation.Metadata?.cost ?? 0)}
			</div>
		</div>
		<div className="row">
			<div className="col-12">
				<div className="text-muted font-weight-bold"><small>{formatDateRange(reservation)}</small></div>
			</div>
		</div>
	</>
}

interface ProgramItemSummaryItemProps {
	registration: Registration;
}

const ProgramItemSummaryItem = ({ registration }: ProgramItemSummaryItemProps) => {
	const formatDateRange = (registration: Registration) => {
		if (moment(registration.DateFrom).isSame(moment(registration.DateTo), "date"))
			return `${moment(registration.DateFrom).format('MMM D, YYYY')} - ${moment(registration.DateFrom).format('h:mm A')} to ${moment(registration.DateTo).format('h:mm A')}`;	
		
		return `${moment(registration.DateFrom).format('MMM D, YYYY')} - ${moment(registration.DateTo).format('MMM D, YYYY')}`;
	}

	return <>
		<div className="row">
			<div className="col-8 col-md-12 col-lg-8">
				{registration.Metadata?.program}
			</div>			
			<div className="col-4 col-md-12 col-lg-4 pl-xs-0 pl-lg-0 order-md-1 order-lg-0 text-lg-right text-right">
				{(registration.Metadata?.cost ?? 0) > 0 ? currency.format(registration.Metadata?.cost ?? 0) : 'No charge'}
			</div>
		</div>
		<div className="row">
			<div className="col-12">
				<div className="text-muted font-weight-bold"><small>{formatDateRange(registration)}</small></div>
			</div>
		</div>
	</>
}

const CostSummary = () => {
	const { getCartCost } = useCartState();

	let totalCost = getCartCost();

	return <>
		<hr />
		<div className="row">
			<div className="col-8">Subtotal</div>
			<div className="col-4 pl-0 text-right">{currency.format(totalCost)}</div>
		</div>
		<div className="row">
			<div className="col-8">Taxes <small>(13% HST)</small></div>
			<div className="col-4 pl-0 text-right">{currency.format(totalCost * 0.13)}</div>
		</div>
		<div className="row totals">
			<div className="col-6">Total Cost</div>
			<div className="col-6 pl-0 text-right">{currency.format(totalCost * 1.13)}</div>
		</div>
	</>
}