import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { useConfigurationQuery } from "../../../api/configuration.api";
import { ReservationType } from "../../../api/reservation.models";
import { linkTitle } from "../../../common/helpers";
import { createReservation } from "../functions/reservation-functions";
import { useAuthState } from "../../../state/auth.state";
import { useAuthUIState } from "../../../state/authui.state";
import { useEventState } from "../functions/event-state";
import { useReservationState } from "../functions/reservation-state";

interface EventInventoryOffsiteProps {
	inventoryTypeDescription: string,
	showDivider: boolean
}

const EventInventoryOffsite = ({ inventoryTypeDescription, showDivider }: EventInventoryOffsiteProps) => {
	const history = useHistory();
	const location = useLocation();
	const { data: configuration } = useConfigurationQuery();
	const { getIsAuthenticated, useLoginListener } = useAuthState();
	const { showLogin } = useAuthUIState();
	const { event, selectedEventDate, selectedDateRange } = useEventState();
	const { setCurrentReservation, clearInventorySelections } = useReservationState();
	const [bookInitiated, setBookInitiated] = useState(false);
	
	useLoginListener(() => {
		if (bookInitiated === true) {
			completeBook();
		}
	})

	const book = () => {
		if (!getIsAuthenticated()) {
			setBookInitiated(true);
			showLogin();
			return;
		}
		// const params = new URLSearchParams();

		completeBook();
	}

	const completeBook = () => {
		if (event === undefined || selectedEventDate === undefined || selectedDateRange === undefined)
			return;

		clearInventorySelections();
		let newReservation = createReservation(event, selectedEventDate, ReservationType.Offsite, [], [], { dateFrom: moment(selectedDateRange[0]), dateTo: moment(selectedDateRange[1]) });
		if (newReservation)
			setCurrentReservation(newReservation);	

		history.push({ pathname: `/events/${linkTitle(event.Title)}/reservation`, search: location.search })
	}

	const cardImage: React.CSSProperties = {
		width: "100%", 
		height: "100%", 
		maxHeight: "175px",
		objectFit: "cover"
	}

	return <>
		<div className="row">
			<div className="col-12">
				{showDivider && <hr className="mt-4 mb-4" />}
				<a id="offsite"></a>
				<h4 className="mb-4">Stay Off Site</h4>
				<div className="card mb-3 border-0 shadow">
					<div className="row no-gutters">
						<div className="col-sm-3">
							<img src="/images/offsite-thumb.jpg" alt="" style={cardImage} className="rounded-top d-sm-none" />
							<img src="/images/offsite-thumb.jpg" alt="" style={cardImage} className="rounded-left d-none d-sm-block" />
						</div>
						<div className="col-sm-6">
							<div className="card-body mb-0">
								<h5 className="card-title">Not staying in {configuration?.OrganizationShortform} {inventoryTypeDescription ?? "Accommodation"}</h5>
								{/* <p className="card-text font-weight-bold">
									Stay at your own cottage or campsite.
								</p> */}
								<p className="card-text">
									<FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
									You will only be charged for program. Meals may or may not be required, depending on the event.
								</p>
							</div>
						</div>
						<div className="col-sm-3 pl-4 pl-sm-0 pr-4 pt-sm-4">
							<button onClick={book} className="btn btn-primary font-weight-bold btn-block mt-2 mb-3"><FontAwesomeIcon icon={faArrowCircleRight} className="mr-2" /> Book Now</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

export default EventInventoryOffsite