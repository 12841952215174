import { useQuery } from 'react-query';
import { api } from './api';

export interface Referral {
	Id: number;
	Title: string;
}

export const useReferralsQuery = () => { 
	return useQuery('referrals', async () => {
		let res = await api.get<Referral[]>(`/api/common/referrals`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000 });
}