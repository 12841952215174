import moment from "moment-timezone";
import { useReservationHistoryQuery } from "../../api/reservation.api";
import { ReservationHistory, ReservationType } from "../../api/reservation.models";
import { currency, getLocalDate } from "../../common/helpers";

const AccountReservations = () => {
	const { data: reservations, isLoading } = useReservationHistoryQuery();

	const upcomingReservations = () => {
		if (reservations === undefined)
			return [];
		return reservations.filter(r => moment(r.DateFrom) > moment()).sort((a, b) => { return moment(a.DateFrom).isAfter(moment(b.DateFrom)) ? 1 : -1});
	}

	const pastReservations = () => {
		if (reservations === undefined)
			return [];
		return reservations.filter(r => moment(r.DateFrom) < moment()).sort((a, b) => { return moment(a.DateFrom).isBefore(moment(b.DateFrom)) ? 1 : -1});
	}

	const noReservations = upcomingReservations().length === 0 && pastReservations().length === 0;

	if (isLoading)
		return <>
			<h4 className="font-weight-bold mb-4">Loading...</h4>
		</>
		
	return <>
		{noReservations && 
			<>
				<h4 className="font-weight-bold mb-4">Reservations</h4>
				<p className="text-muted">You don't have any upcoming or past reservations.</p>
			</>
		}

		{upcomingReservations().length > 0 &&
			<>
				<h4 className="font-weight-bold mb-4">Upcoming Reservations</h4>
				
				{upcomingReservations()?.map((reservation, index) => {
					return <div key={`upcoming${index}`}>
						<AccountReservation reservation={reservation} />
						{index !== upcomingReservations().length-1 &&
						<hr className="mt-4 mb-4" />
						}
					</div>
				})}	
			</>
		}

		{pastReservations().length > 0 &&
			<>
				<hr className="mt-4 mb-4" />
				<h4 className="font-weight-bold mb-4">Past Reservations</h4>
				{pastReservations()?.map((reservation, index) => {
					return <div key={`past${index}`}>
						<AccountReservation reservation={reservation} />
						{index !== pastReservations().length-1 &&
						<hr className="mt-4 mb-4" />
					}
				</div>
				})}	
			</>
		}
	</>
}

export default AccountReservations;

interface AccountReservationProps {
	reservation: ReservationHistory;
}

const AccountReservation = ({ reservation }: AccountReservationProps) => {

	return <>
		<h5 className="font-weight-bold">{reservation.Metadata?.Event?.Title}</h5>
		<div className="font-weight-bold mb-2">{moment(getLocalDate(reservation.DateFrom)).format("dddd, LL")} - {moment(getLocalDate(reservation.DateTo)).format("dddd, LL")}</div>

		<div className="row">
			<div className="col-sm-4 mb-3">
				<span className="small text-muted">Online Reservation #</span>
				<br />
				{reservation.ReservationNumber}
			</div>
			<div className="col-sm-6 mb-3">
				<span className="small text-muted">Date Submitted</span>
				<br />
				<span>{moment(getLocalDate(reservation.DateSubmitted)).format("dddd, LL")}</span>
			</div>
		</div>

		<div className="row">
			<div className="col-sm-4 mb-3">
				<div className="small text-muted">Adult{reservation.Metadata?.Adults?.length === 1 ? '' : 's'}</div>
				{reservation.Metadata?.Adults?.map((adult, adultIndex) => {
					return <span key={adultIndex}>{adult}
						{adultIndex !== reservation.Metadata?.Adults?.length && <br />}
					</span>
				})}
			</div>
			{(reservation.Metadata?.Children?.length ?? 0) > 0 &&
			<div className="col-sm-6 mb-3">
				<div className="small text-muted">Child{reservation.Metadata?.Children?.length === 1 ? '' : 'ren'}</div>
				{reservation.Metadata?.Children?.map((child, childIndex) => {
					return <span key={childIndex}>{child}
						{childIndex !== (reservation.Metadata?.Children?.length ?? 0) - 1 && <br />}
					</span>
				})}
			</div>
			}
		</div>

		{reservation.Metadata.Inventory?.map((inventory, inventoryIndex) => {
			return <div key={inventoryIndex} className="mb-3">
				<div className="small text-muted">{inventory.Type}</div>
				{inventory.Items?.map((inventoryItem, inventoryItemIndex) => {
					return <span key={inventoryItemIndex}>{inventoryItem.Description}: {currency.format(inventoryItem.Amount ?? 0)}
						{inventoryItemIndex !== (inventory.Items?.length ?? 0) - 1 && <span>, </span>}
					</span>

				})}
			</div>

		})}

		{reservation.Metadata?.Event?.Type === ReservationType.Offsite &&
			<div className="mb-3">
				<div className="small text-muted">Attendance</div>
				<span>Staying Offsite</span>
			</div>
		}

		{reservation.Metadata?.Event?.Type === ReservationType.Virtual &&
			<div className="mb-3">
				<div className="small text-muted">Attendance</div>
				<span>Virtual (online only)</span>
			</div>
		}

		{reservation.Metadata?.Meals && (reservation.Metadata?.Meals?.Count ?? 0) > 0 &&
		<div className="mb-3">
			<div className="small text-muted">Meals</div>
			{reservation.Metadata?.Meals?.Package &&
			<span>Package: {reservation.Metadata.Meals.Package} ({reservation.Metadata.Meals.Count} meal{reservation.Metadata.Meals.Count === 1 ? '' : 's'})</span>
			}
			{!reservation.Metadata.Meals.Package &&
			<span>{reservation.Metadata.Meals.Count} meal{reservation.Metadata.Meals.Count === 1 ? '' : 's'}</span>
			}
			<span>: {currency.format(reservation.Metadata.Meals.Amount ?? 0)}</span>
			{(reservation.Metadata?.Meals?.Discount ?? 0) > 0 && 
			<span><br />Discount: {currency.format(-(reservation.Metadata?.Meals?.Discount ?? 0))}</span>
			}
		</div>
		}

		{reservation.Metadata.Charges && reservation.Metadata.Charges.length > 0 &&
		<div className="mb-3">
			<div className="small text-muted">Charges</div>
			{reservation.Metadata.Charges.map((charge, chargeIndex) => {
				return <span key={chargeIndex}>{charge.Charge}: {currency.format(charge.Amount ?? 0)}
					{chargeIndex !== (reservation.Metadata.Charges?.length ?? 0) - 1 &&
					<span><br/></span>
					}
				</span>
			})}
		</div>
		}

		{reservation.Metadata.Custom && reservation.Metadata.Custom.length > 0 &&
		<div className="mb-3">
			{reservation.Metadata.Custom.map((customFieldGroup, groupIndex) => {
				return <div key={groupIndex} className={groupIndex < reservation.Metadata.Custom!.length - 1 ? "mb-3":""}>
					<div className="small text-muted">{customFieldGroup.Group}:</div>
					{customFieldGroup.Values?.map((field, fieldIndex) => {
						return <span key={fieldIndex}>
							{(field.Value === true || field.Value === false) &&
							<span>{field.Field}: {field.Value ? "Yes" : "No"}</span>
							}
							{(field.Value !== true && field.Value !== false) &&
							<span>{field.Field}: {field.Value}</span>
							}
							{fieldIndex !== (customFieldGroup.Values?.length ?? 0) - 1 && <br/>	}
						</span>

					})}
				</div>
			})}
		</div>
		}

		{reservation.Metadata.PromotionCode &&
		<div className="mb-3">
			<div className="small text-muted">Promotional Discount</div>
			{currency.format(reservation.Metadata.PromotionCode.Amount ?? 0)} (Code: {reservation.Metadata.PromotionCode.Code})
		</div>
		}

		<div className="row">
			<div className="col-sm-4 mb-3 mb-sm-0">
				<div className="small text-muted">Total Cost (including taxes)</div>
				{currency.format(reservation.Total)}
			</div>
			<div className="col-sm-4 mb-3 mb-sm-0">
				<div className="small text-muted">Payments</div>
				{currency.format(reservation.Payments)}
				{reservation.ReceiptURL &&
				<span> <a href={reservation.ReceiptURL} target="_blank" rel="noreferrer">View Receipt</a></span>
				}
			</div>
			<div className="col-sm-4">
				<div className="small text-muted">Balance Owing</div>
				{currency.format(reservation.Balance)}
			</div>
		</div>
	</>
}