import { useEffect, useState } from "react";
import { useAuthState } from "../../../state/auth.state"

const SessionTimeout = () => {
	const { isAuthenticationExpired, getIsTimedOut, setIsTimedOut, logout, showLoggedOut } = useAuthState();

	const [timer, setTimer] = useState<NodeJS.Timer>();

	useEffect(() => {
		if (timer === undefined) {
			let t = setInterval((foo: () => boolean) => {
				if (foo() === true) {
					if (getIsTimedOut() === false) { 
						// console.log(new Date().getSeconds());
						setIsTimedOut(true);
						// console.log('setIsTimedOut(true);')
						logout();
						showLoggedOut();
					}
				}
			}, 5000, isAuthenticationExpired);

			setTimer(t);
		}

		return () => {
			if (timer !== undefined)
				clearInterval(timer);
		}
	}, []);

	return <>
	</>
}

export default SessionTimeout