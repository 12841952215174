import { EventInventory, EventInventoryItem } from "./events.models";

export interface InventoryType {
	Id: number;
	Type: InventoryTypeBase;
	Description: string;
	SortIndex: number;

	InventoryCategories: InventoryCategory[];
	Metadata?: InventoryTypeMetadata;
}

export interface InventoryTypeMetadata {
	Featured: boolean;
	Title: string;
	ShortContent: string;
	Content: string;
	ImageURL: string;
}

export enum InventoryTypeBase {
	Generic = 0,
	Accommodation = 1,
	Campsite = 2
}

export interface InventoryCategory {
	Id: number;
	InventoryTypeId: number;
	Description: string;
	SortIndex: number;

	InventoryItems: InventoryItem[];
	EventInventory?: EventInventory[];

	// ShowDetails?: boolean;
	// ShowItemSelection?: boolean;
	// SelectedItems?: number;
	// SelectedItem?: InventoryItem | number;

	Metadata?: {
		Title: string;
		Description: string;
		Content: string;
		UnitDescription: string;
		Sleeps: string;
		Capacity: number;
		ImageURL: string;
		MediaGalleryId: number;
	}
}

export interface InventoryItem {
	Id: number;
	InventoryTypeId: number;
	InventoryCategoryId: number;
	Description: string;
	EventInventoryItems: EventInventoryItem[];
	InventoryPropertyValues: InventoryPropertyValue[];
	Available: boolean;


	InventoryPolicy?: InventoryPolicyResult;
	Selected?: boolean;
	Filtered?: boolean;
	// Specific?: boolean;

	Metadata?: {
		Title: string;
		Capacity?: number;
		MediaGalleryId: number;
	}
}

export interface InventoryPropertyValue {
	InventoryItemId: number;
	InventoryPropertyId: number;
	Value: string;
	InventoryProperty: InventoryProperty;
}

export interface InventoryProperty {
	Id: number;
	Name: string;
	Category: string;

	InventoryPropertyValues?: InventoryPropertyValue[];
}

export interface InventoryPropertyWithValue extends InventoryProperty {
	Value: string;
}

export interface InventoryAvailability {
	Date: string;
	Available: boolean;
}

export interface InventoryAvailabilityResult {
	From: string;
	To: string;
	Availability: number[];
}

export interface InventoryPolicy {
	Id: number;
	InventoryItemId?: number;
	Title: string;
	Description: string;
	Default: boolean;
	Priority: number;
	PolicyFrom: Date;
	PolicyTo: Date;
	MinimumNights: number;
	MaximumNights: number;
	DateFrom: Date;
	DateTo: Date;
	StartDayOfWeek: number;
	EndDayOfWeek: number;
}

export interface InventoryPolicyResult {
	Message?: string;
	MinimumStayValid?: boolean;
	MaximumStayValid?: boolean;
	DatesValid?: boolean;
	Policy?: InventoryPolicy;
}