import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useAccountInfoQuery } from "../../api/account.api";

const AccountInformation = () => {
	const { data: userInfo } = useAccountInfoQuery();

	return <>
		<h4 className="font-weight-bold">Account Information</h4>
		<Row>
			<Col xs="2">Name:</Col>
			<Col xs="10">{userInfo?.Name}</Col>
		</Row>
		<Row>
			<Col xs="2">Email:</Col>
			<Col xs="10">{userInfo?.Email}</Col>
		</Row>
	</>
}

export default AccountInformation