import { faClipboardList, faCartPlus, faSignInAlt, faSignOutAlt, faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment-timezone"
import { useHistory } from "react-router-dom"
import { ChildParticipant, ParticipantBase, RegistrationMetadata } from "../../../api/program.registration.models"
import { useProgramActivitiesQuery, useProgramActivitySessionsQuery, useProgramGroupsQuery, useProgramsQuery } from "../../../api/programs.api"
import { toastSuccess } from "../../../common/components/toast"
import { currency } from "../../../common/helpers"
import { useCartState } from "../../cart/functions/cart-state"
import { getActivities, getParticipants, getProgramTotal, getRegistrationCost, isRegistrationParticipantsValid } from "../functions/program-functions"
import { useProgramState } from "../functions/program-state"
import { useRegistrationState } from "../functions/registration-state"
import { useState } from "react"
import { PriceType } from "../../../api/programs.models"

// type ProgramRegistrationUrlParams = {
// 	programGroup: string;
// 	program: string;
// }

const ProgramRegistrationSummary = () => {
	const history = useHistory();
	const { registration, validateRegistration, validationErrors, emitRegistrationValidation } = useRegistrationState();
	const { addProgramRegistration, isProgramRegistrationInCart } = useCartState();
	const { program, programGroup } = useProgramState();

	const [isValidating, setIsValidating] = useState(false);

	const { data: programActivities } = useProgramActivitiesQuery();	
	const { data: programActivitySessions } = useProgramActivitySessionsQuery();

	const totalCost = getRegistrationCost(registration, program, programActivities, programActivitySessions, true);
	const totalCostExcludingDiscount = getRegistrationCost(registration, program, programActivities, programActivitySessions);

	const isParticipantsValid = isRegistrationParticipantsValid(registration);

	const handleAddToCart = async () => {
		if (!isParticipantsValid) {
			emitRegistrationValidation();
			return;
		}

		setIsValidating(true);
		let errors = await validateRegistration();
		setIsValidating(false);
		
		if (errors.length > 0) {
			return;
		}

		const registrationMetadata: RegistrationMetadata = {
			program: program?.Title ?? "",
			programGroup: programGroup?.Title ?? "",
			participants: getParticipants(registration),
			activities: getActivities(registration, programActivities, programActivitySessions),
			cost: totalCost,
			total: totalCostExcludingDiscount,
			discount: totalCostExcludingDiscount - totalCost,
			savePaymentMethod: programGroup.SavePaymentMethod ?? false
		}
		
		registration.Metadata = registrationMetadata;

		if (isProgramRegistrationInCart(registration))
			toastSuccess("Your reservation has been updated")
		else
			toastSuccess("Your reservation has been added to the cart")

		addProgramRegistration(registration);

		history.push({ pathname: `/cart` });
	}
	
	return <>
		<div className="card shadow mb-3 position-sticky reservation-details-card">
			<h5 className="card-header"><FontAwesomeIcon icon={faClipboardList} className="text-muted mr-2" /> Registration Summary</h5>
			<div className="card-body">
				<CheckInOutSummary />

				<ProgramSummary />

				<PromotionCodeSummary totalCost={totalCost} totalCostExcludingDiscount={totalCostExcludingDiscount} />

				<ParticipantSummary />

				{/* {totalCost > 0 && */}
				<CostSummary totalCost={totalCost} />
				{/* } */}

				<hr />

				{validationErrors && validationErrors.length > 0 &&
				<div className="text-danger font-weight-bold mb-3">
					Please check the reservation for any missing information or invalid choices.
				</div>
				}

				{!isParticipantsValid && 
				<div className="text-danger font-weight-bold mb-3">
					One or more guests have the same name. All guests must have unique names in order to update the reservation.
				</div>
				}

				<button type="button" onClick={handleAddToCart} className="btn btn-primary font-weight-bold btn-block">
					{isValidating ? <FontAwesomeIcon icon={faCircleNotch} spin className="mr-2" /> : <FontAwesomeIcon icon={faCartPlus} className="mr-2" />}
					{isProgramRegistrationInCart(registration) ? "Update Registration" : "Add Registration to Cart" }
				</button>
			</div>
		</div>
	</>
}

export default ProgramRegistrationSummary

interface ProgramSummaryProps {
	
}

const ProgramSummary = ({  }: ProgramSummaryProps) => {
	const { registration } = useRegistrationState();
	const { data: programs } = useProgramsQuery();
	const { data: programGroups } = useProgramGroupsQuery();

	const program = programs?.find(p => p.Id === registration?.ProgramId);
	const programGroup = programGroups?.find(g => g.Id === (program?.ProgramGroupId ?? 0));

	const cost = getProgramTotal(registration, program);

	return <>
		<div><strong>{programGroup?.Title}:</strong></div>
		<div><strong>{program?.Title}</strong></div>
		<div>{program?.ShortDescription}</div>

		{cost > 0 && 
		<div className="row">
			<div className="col-8">{program?.PriceType === PriceType.PerGroup ? "Group Cost" : "Per person cost"}</div>
			<div className="col-4 pl-0 text-right">{(program?.Price ?? 0) > 0 ? currency.format(program?.Price ?? 0) : 'No charge'}</div>
		</div>
		}

		<hr />
	</>
}

const ParticipantSummary = () => {
	const { registration } = useRegistrationState();

	const getParticipantName = (participant: ParticipantBase) => {
		if (participant.FirstName === "" && participant.LastName === "")
			return `${participant.Type === "adult" ? "Adult" : "Child"} Participant`;

		return `${participant.FirstName} ${participant.LastName}`;	
	}

	const getAge = (child: ChildParticipant) => {
		if (child.Birthdate == null)
			return "";
		
		let age = moment().diff(moment(child.Birthdate), "years");

		return ` (${age})`;
	}

	return <>
		<strong>Participants</strong>
		{registration.Participants.map((participant, index) => {
			let sessions = registration.ProgramSessions.filter(s => s.Participants.map(p => p.ParticipantId).includes(participant.Id)).length;
			return <div key={index} className="row">
				<div className="col-8 text-truncate">
					{getParticipantName(participant)}
					{participant.Type === "child" && getAge(participant as ChildParticipant)}
				</div>
				<div className="col-4 pl-0 text-right">
					{sessions} session{sessions !== 1 ? 's':''}
				</div>
			</div>
		})}
	</>
}

interface CheckInOutSummaryProps {
	
}

const CheckInOutSummary = ({  }: CheckInOutSummaryProps) => { 
	const { registration } = useRegistrationState();

	const sameDay = moment(registration.DateFrom).isSame(moment(registration.DateTo), "day");

	return <div>
		<div className="row">
			<div className="col-6 text-center">
				<div><FontAwesomeIcon icon={faSignInAlt} /> <span className="font-weight-bold"> Starting {sameDay ? "at":"on"}</span></div>
				{sameDay ? moment(registration.DateFrom).format('MMM D, YYYY h:mm A') : moment(registration.DateFrom).format('MMM D, YYYY')}
			</div>
			<div className="col-6 text-center">
				<div><FontAwesomeIcon icon={faSignOutAlt} /> <span className="font-weight-bold"> Ending {sameDay ? "at":"on"}</span></div>
				{sameDay ? moment(registration.DateTo).format('MMM D, YYYY h:mm A') : moment(registration.DateTo).format('MMM D, YYYY')}
			</div>
		</div>

		<hr className="mb-2" />
	</div>
}

interface PromotionCodeSummaryProps {
	totalCost: number;
	totalCostExcludingDiscount: number;
}

const PromotionCodeSummary = ({ totalCost, totalCostExcludingDiscount }: PromotionCodeSummaryProps) => {
	const { registration } = useRegistrationState();
	
	if (registration.PromotionCodeId === 0 || registration.PromotionCode === undefined)
		return <></>
		
	return <>
		<div className="font-weight-bold mt-2">Discounts</div>
		<div className="row">
			<div className="col-8">{registration.PromotionCode.Code}</div>
			<div className="col-4 pl-0 text-right">{currency.format(totalCost - totalCostExcludingDiscount)}</div>
		</div>

		<hr />
	</>
}

interface CostSummaryProps {
	totalCost: number;
}

const CostSummary = ({ totalCost }: CostSummaryProps) => {
	return <>
		<hr className="mt-2 mb-2" />
		<div className="row">
			<div className="col-8">Subtotal</div>
			<div className="col-4 pl-0 text-right">{currency.format(totalCost)}</div>
		</div>
		<div className="row">
			<div className="col-8">Taxes <small>(13% HST)</small></div>
			<div className="col-4 pl-0 text-right">{currency.format(totalCost * 0.13)}</div>
		</div>
		<div className="row totals">
			<div className="col-6">Total Cost</div>
			<div className="col-6 pl-0 text-right">{currency.format(totalCost * 1.13)}</div>
		</div>
	</>
}