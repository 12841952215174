import { useQuery } from 'react-query';
import { api } from './api';

export interface Configuration {
	OrganizationTitle: string;
	OrganizationShortform: string;
	OrganizationWebsite: string;
	OrganizationAddress: string;
	OrganizationEmail: string;
	OrganizationPhone: string;
	SiteTitle: string;
	SearchTitle: string;
	SearchDescription: string;
}

export interface PageContent {
	Page: string|null;
	Item: string|null;
	ComponentType: PageContentComponentType;
	Content: string;
}

export enum PageContentComponentType {
	HeaderText = 1,
	HeaderImage = 2,
	WelcomeImage = 3,
	Description = 5,
	Text = 6,
	HTML = 7,
	IndexFeaturedInventoryTypes = 10,
	IndexFeaturedEvents = 11
}

export const useConfigurationQuery = () => { 
	return useQuery('configuration', async () => {
		let res = await api.get<Configuration>(`/api/common/configuration`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 600000 });
}

export const usePageContentQuery = () => { 
	return useQuery('page-content', async () => {
		let res = await api.get<PageContent[]>(`/api/common/configuration/pagecontent`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 600000 });
}

export const getPageContent = (pageContent: PageContent[]|undefined, page: string) : PageContent[] => {
	return (pageContent ?? []).filter(c => c.Page === page);
}

export const getPageContentItem = (pageContent: PageContent[]|undefined, page: string, item: string|null, contentType: PageContentComponentType) : PageContent => {
	var content = getPageContent(pageContent, page).filter(c => c.ComponentType === contentType);
	if (content === null || content.length === 0)
		content = getPageContent(pageContent, "Default").filter(c => c.ComponentType === contentType);
	if (content != null && content.length > 0) {
		if (item != null)
			return content.filter(c => c.Item === item)[0];
		else
			return content[0];

	}
	// var defaultContent: string = "";
	// if (contentType === PageContentComponentType.HeaderImage)
	// 	defaultContent = defaultWelcomeImageURL;
	// if (contentType === PageContentComponentType.WelcomeImage)
	// 	defaultContent = defaultHeaderImageURL;
	// if (contentType === PageContentComponentType.Text)
	// 	defaultContent = "";
	return { Page: null, Item: null, ComponentType: contentType, Content: "" };
}

// const defaultWelcomeImageURL = "/assets/default-welcome.jpg";
// const defaultHeaderImageURL = "/assets/default-header.jpg";


export const getDefaultHeaderImage = (pageContent: PageContent[]|undefined) => {
	return getPageContentItem(pageContent, "Default", null, PageContentComponentType.HeaderImage);;
}