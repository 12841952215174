import { useEffect, useState } from "react";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment-timezone";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faAngleDoubleDown, faAngleDoubleUp, faUtensils } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useReservationState } from "../functions/reservation-state";
import { useEventState } from "../functions/event-state"
import { useEventMealDiscountsQuery, useEventMealPackagesQuery, useEventMealRequirementOverridesQuery, useEventMealsQuery } from "../../../api/events.api";
import { EventMeal, EventMealDiscount, EventMealRequiredType } from "../../../api/events.models";
import { MealAgeGroup } from "../../../api/meals.models";
import { Reservation, ReservationPageContentType, ReservationType } from "../../../api/reservation.models";
import { getAgeGroupQuantityForMeal, getEventMealMaximumQuantity, getEventMealMinimumQuantity, getEventMealOverride, getFilteredMealPackagesForReservationType, getMealAgeGroups, getMealDiscount, getMealPrice, getMealsDiscountTotalForAgeGroup, getMealsTotal, getMealsTotalForAgeGroup, loadMealsByDate, updateDiscount } from "../functions/reservation-functions";
import { currency } from "../../../common/helpers";
import EventReservationPageContent from "./event-reservation-pagecontent";

const EventReservationMeals = () => {
	const { reservation, updateReservation, useReservationGuestsListener } = useReservationState();
	const { event, selectedEventDate } = useEventState();
	const { data: eventMeals } = useEventMealsQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);
	const { data: eventMealPackages } = useEventMealPackagesQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);
	const { data: eventMealDiscounts } = useEventMealDiscountsQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);
	const { data: eventMealRequirementOverrides } = useEventMealRequirementOverridesQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);
	const [appliedDiscount, setAppliedDiscount] = useState<EventMealDiscount|undefined>(undefined);
	// const [discountAmount, setDiscountAmount] = useState<number>(0);
	var discountAmount = reservation?.MealDiscount ?? 0;

	const mealPackages = getFilteredMealPackagesForReservationType(eventMealPackages, reservation.Type);

	const [mealsByDate, setMealsByDate] = useState<EventMeal[][]>([]);

	const [days, setDays] = useState<Date[]>([]);
	// var mealsByDate: EventMeal[][] = [];

	useEffect(() => {
		// days = [];
		if (reservation.Type !== ReservationType.Virtual) {
			var _days: Date[] = [];
			var start = new Date((reservation).DateFrom);
			var end = new Date((reservation).DateTo);
			while (start <= end) {
				_days.push(new Date(start));
				start = new Date(start.setDate(start.getDate() + 1));
			}
			setDays(_days);
		}
	}, []);

	useEffect(() => {
		if (event !== undefined && eventMeals !== undefined && eventMealRequirementOverrides !== undefined && mealPackages !== undefined && reservation !== undefined) {
			if (mealsByDate.length === 0) {
				var mbd = loadMealsByDate(event, eventMeals, mealPackages, reservation);
				setMealsByDate(mbd);
			}
		}
	}, [event, eventMeals, eventMealRequirementOverrides, mealPackages]);

	useReservationGuestsListener((res: Reservation) => {
		mealPackageChanged(res, res.MealPackageId);
	})

	const getMinimumQuantity = (eventMeal: EventMeal, ageGroup: MealAgeGroup) => {
		return getEventMealMinimumQuantity(reservation, eventMeal, eventMealRequirementOverrides, ageGroup);
	}

	const getMaximumQuantity = (ageGroup: MealAgeGroup) => {
		return getEventMealMaximumQuantity(reservation, ageGroup);
	}

	const anyRequiredMeals = () : boolean => {
		return _.some(eventMeals, meal => meal.RequiredType !== EventMealRequiredType.NotRequired);
	}

	const range = (start: number, end: number) : number[] => {
		return _.range(start, end + 1, 1);
	}

	const noMealsAtStartOfStay = () : boolean => {
		if (mealsByDate == null || mealsByDate.length === 0)
			return false;

		if (mealPackages !== undefined && mealPackages.length > 0 && mealPackages[0].AvailableFrom != null && 
			moment(reservation.DateFrom).isAfter(moment(mealPackages[0].AvailableFrom), 'date'))
			return false;

		return mealsByDate[0].length === 0;
	}

	const noMealsAtEndOfStay = () : boolean => {
		if (mealsByDate === null || mealsByDate.length === 0)
			return false;

		if (mealPackages !== undefined && mealPackages.length > 0 && mealPackages[0].AvailableTo !== null && 
			moment(reservation.DateTo).isBefore(moment(mealPackages[0].AvailableTo), 'date'))
			return false;
			
		return mealsByDate[mealsByDate.length - 1].length === 0;
	}

	const mealsAvailableFrom = () => {
		if (mealPackages === undefined || mealPackages.length === 0 || mealPackages[0].AvailableFrom == null)
			return null;

		return moment(mealPackages[0].AvailableFrom).format('dddd, MMMM Do, YYYY');
	}

	const mealsAvailableTo = () => {
		if (mealPackages === undefined || mealPackages.length === 0 || mealPackages[0].AvailableTo === null)
			return null;

		return moment(mealPackages[0].AvailableTo).format('dddd, MMMM Do, YYYY');
	}

	// const getReservationMealIndex = (eventMealId: number, dateIndex: number) => {
	// 	var date = days[dateIndex];
	// 	if (date == null)
	// 		return -1;
	// 	return reservation.Meals?.findIndex(rm => rm.Date === date.toISOString() && rm.EventMealId === eventMealId) ?? -1;
	// }

	const getMealQuantity = (eventMealId: number, dateIndex: number, ageGroupId: number) => {
		var date = days[dateIndex];
		if (date == null)
			return 0;
		var reservationMeal = reservation.Meals?.find(rm => rm.Date === date.toISOString() && rm.EventMealId === eventMealId);
		return reservationMeal?.Quantities.find(q => q.MealAgeGroupId === ageGroupId)?.Quantity ?? 0;
	}

	const updateMealQuantity = (eventMealId: number, dateIndex: number, ageGroupId: number, quantity: number) => {
		var date = days[dateIndex];
		if (date == null)
			return 0;

		var reservationMeal = reservation.Meals?.find(rm => rm.Date === date.toISOString() && rm.EventMealId === eventMealId);
		reservationMeal!.Quantities.find(q => q.MealAgeGroupId === ageGroupId)!.Quantity = quantity;

		let discount = updateDiscount(reservation, eventMeals ?? [], eventMealDiscounts ?? []);
		setAppliedDiscount(discount);
		// let amount = getMealsDiscount(reservation);
		// setDiscountAmount(updatedReservation.MealDiscount ?? 0);
		updateReservation(reservation);

		// setCurrentReservation(res);
	}

	const getTotal = () => {
		return getMealsTotal(reservation, eventMeals ?? []);
	}

	const mealPackageChanged = (res: Reservation, selectedMealPackageId: number|null) => {
		if (selectedMealPackageId === null)
			return;

		if (selectedMealPackageId === 0) {
			res.MealPackageId = selectedMealPackageId;
			updateReservation(res);
			return;
		}

		res.MealPackageId = selectedMealPackageId;

		res?.Meals?.forEach(reservationMeal => {
			var eventMeal = eventMeals?.find(e => e.Id === reservationMeal.EventMealId);
			if (eventMeal === undefined)
				return;
			for (let reservationMealQuantity of reservationMeal.Quantities) {
				var mealDefinitionItemAgeGroup = eventMeal.MealDefinitionItem.MealDefinitionItemAgeGroups.find(a => a.MealAgeGroupId === reservationMealQuantity.MealAgeGroupId);
				if (mealDefinitionItemAgeGroup != null) {
					var ageGroup = mealDefinitionItemAgeGroup.MealAgeGroup;
					var minimum = getMinimumQuantity(eventMeal, ageGroup);
					var maximum = getMaximumQuantity(ageGroup);

					var mealPackage = mealPackages?.find(p => p.Id === selectedMealPackageId);
					var include = mealPackage?.EventMealPackageItems.find(i => i.EventMealId === eventMeal?.Id);
					
					reservationMealQuantity.Quantity = include ? maximum : minimum;

					var override = getEventMealOverride(reservation, eventMeal, eventMealRequirementOverrides);
					if (override != null && override.Required === false)
						continue;

					if (eventMeal.RequiredType === EventMealRequiredType.NotRequiredIfOtherSelected) {
						var otherMeal = eventMeals?.find(e => e.Id === eventMeal?.RequiredId);
						if (otherMeal !== undefined) {
							var otherReservationMeal = reservation?.Meals?.find(m => m.EventMealId === otherMeal?.Id);
							var otherReservationMealQuantity = otherReservationMeal?.Quantities.find(q => q.MealAgeGroupId === reservationMealQuantity.MealAgeGroupId);
							reservationMealQuantity.Quantity = otherReservationMealQuantity?.Quantity === 0 ? maximum : minimum;
						}
					} else if (eventMeal.RequiredType === EventMealRequiredType.RequiredIfOtherSelected) {
						otherMeal = eventMeals?.find(e => e.Id === eventMeal?.RequiredId);
						if (otherMeal !== undefined) {
							otherReservationMeal = reservation?.Meals?.find(m => m.EventMealId === otherMeal?.Id);
							otherReservationMealQuantity = otherReservationMeal?.Quantities.find(q => q.MealAgeGroupId === reservationMealQuantity.MealAgeGroupId);						
							reservationMealQuantity.Quantity = (otherReservationMealQuantity?.Quantity ?? 0) > 0 ? maximum : minimum;
						}
					}
				}
			}
		});

		// setCurrentReservation(res);

		let discount = updateDiscount(res, eventMeals ?? [], eventMealDiscounts ?? []);
		setAppliedDiscount(discount);
		// let amount = getMealsDiscount(reservation);
		// setDiscountAmount(updatedReservation.MealDiscount ?? 0);
		updateReservation(res);
		// this.appliedDiscount = await this.mealsService.updateDiscount(this.reservationService.Reservation);
		// this.discountAmount = this.mealsService.getMealsDiscount(this.reservationService.Reservation);
	}

	// useEffect(() => {
	// 	if (eventMeals !== undefined) {
	// 		let mealsTotal = getMealsTotal(reservation, eventMeals);
	// 		console.log(mealsTotal);
	// 	}
	// }, [eventMeals, reservation.Meals])

	if (reservation.Meals?.length === 0)
		return <></>

	return <>
		<div className="card shadow mb-3">
			<h5 className="card-header"><FontAwesomeIcon icon={faUtensils} className="text-muted mr-2" />Meals</h5>

			<div className="card-body">
				<EventReservationPageContent type={ReservationPageContentType.Meals} />

				<label className="font-weight-bold">Meal Packages</label>

				{noMealsAtStartOfStay() && 
				<div className="text-muted mb-2">
					Meals are available starting {mealsAvailableFrom()}
				</div>
				}

				{noMealsAtEndOfStay() && 
				<div className="text-muted mb-2">
					Meals are available until {mealsAvailableTo()}
				</div>
				}

				<div className="row">
					<div className="col-12">
						{mealPackages && mealPackages.map((mealPackage, index) => {
							return <div key={index}>
								<label className={classNames(['disabled' ])}>
									<input type="radio" name="package" value={mealPackage.Id} checked={mealPackage.Id === reservation.MealPackageId} onChange={(e) => { mealPackageChanged(reservation, parseInt(e.target.value)); }} style={{marginRight: '0px'}} /> <strong>{mealPackage.Title}</strong>
									<div className="small" style={{marginLeft: '18px'}}>
										{mealPackage.Description}
									</div>
								</label>
							</div>
						})}
						{event.AllowCustomizeMeals &&
						<div>
							<label>
								<input type="radio" name="package" value={0} checked={0 === reservation.MealPackageId} onChange={(e) => { mealPackageChanged(reservation, 0); }} style={{marginRight: '0px'}} /> <strong>Customize Meals</strong>
								<div className="small" style={{marginLeft: '18px'}}>
									Select individual meals for each day
								</div>
							</label>
						</div>
						}
					</div>
				</div>

				{reservation.MealPackageId === 0 && 
					<>
						<div>
							Please select the meals that you would like to include on your reservation.
						</div>
						<hr />
						{days && days.map((day, index) => {
							return <div key={index}>
								<div className="row mb-2">
									<div className="col-6">
										<div className="font-weight-bold">{moment(day).format("dddd, LL")}</div>
									</div>
								
									{getMealAgeGroups(index, days, mealsByDate).map((ageGroup, index2) => {
										return <div key={index2} className="col-2 pr-0">
											<div>{ageGroup.Description}</div>
											<div className="small">
											({ageGroup.MinAge}
											{ageGroup.MaxAge > 99 && <span>+</span>}
											{ageGroup.MaxAge <= 99 && <span>-{ageGroup.MaxAge}</span>})
											</div>
										</div>
									})}
								</div>
								{mealsByDate[index]?.filter(eventMeal => eventMeal.Disabled === false).length === 0 &&
								<div className="row">
									<div className="col-12">
										No meals available to register for on this day.
									</div>
								</div>
								}
								{mealsByDate[index]?.filter(eventMeal => eventMeal.Disabled === false).map((eventMeal, index3) => {
									return <div className="row mb-1" key={index3}>
										<div className="col-6">
											{eventMeal.Meal.Name} {eventMeal.RequiredType > 0 && "*"}
										</div>
										{getMealAgeGroups(index, days, mealsByDate).map((ageGroup, index2) => {
											return <div key={index2} className="col-2">
												<select className="form-control form-control-sm" value={getMealQuantity(eventMeal.Id, index, ageGroup.Id)} onChange={(e) => updateMealQuantity(eventMeal.Id, index, ageGroup.Id, parseInt(e.target.value))}>
													{range(getMinimumQuantity(eventMeal, ageGroup),getMaximumQuantity(ageGroup)).map((value, index4) => {
														return <option value={value} key={index4}>{value}</option>
													})}
												</select>
											</div>
										})}
									</div>
								})}
								
								<hr />
							</div>
						})}
						
						{anyRequiredMeals() && 
						<div className="small text-muted">
							* Indicates a required meal
						</div>
						}
					</>
				}

				<div className="row mt-3">
					<div className="col col-12">
						<p className="font-weight-bold m-0">Total Cost: {currency.format(getTotal())}</p>
						{appliedDiscount && 
						<p className="font-weight-bold m-0">
							Discounts: {currency.format(discountAmount)} (Includes: {appliedDiscount.Title} - {appliedDiscount.Amount*1}% Off)
						</p>
						}
						{appliedDiscount === undefined && discountAmount > 0 &&
						<p className="font-weight-bold m-0">Discounts: <span>{currency.format(discountAmount)}</span>
						</p>
						}
						{discountAmount > 0 &&
						<p className="font-weight-bold m-0">
							Total Cost after Discounts: {currency.format(getTotal() - discountAmount)}
						</p>
						}
					</div>
				</div>

				<CostBreakdown reservation={reservation} days={days} mealsByDate={mealsByDate} eventMeals={eventMeals ?? []} appliedDiscount={appliedDiscount} />
			</div>
		</div>
	</>
}

export default EventReservationMeals

interface CostBreakdownProps {
	reservation: Reservation;
	days: Date[];
	mealsByDate: EventMeal[][];
	eventMeals: EventMeal[];
	appliedDiscount: EventMealDiscount|undefined;
}

const CostBreakdown = ({ reservation, days, mealsByDate, eventMeals, appliedDiscount }: CostBreakdownProps) => {
	const [open, setOpen] = useState(false);

	var mealsTotal = getMealsTotal(reservation, eventMeals ?? []);
	var mealsDiscount = reservation.MealDiscount;

	// const getMealsTotalForAgeGroup2 = (ageGroup: MealAgeGroup) => getMealsTotalForAgeGroup(ageGroup, eventMeals, reservation);
	const showDiscountLine = (eventMeal: EventMeal, dateIndex: number, mealsByDate: EventMeal[][]) : boolean => {
		var ageGroups = getMealAgeGroups(dateIndex, days, mealsByDate);
		for (let ageGroup of ageGroups) {
			if (getMealDiscount(reservation, eventMeal, ageGroups.indexOf(ageGroup), days[dateIndex], appliedDiscount) > 0)
				return true;
		}

		return false;
	}

	if (!open)
		return <div className="row mt-2">
			<div className="col col-12">
				<button onClick={() => setOpen(true)} className="btn btn-link btn-sm p-0">
					<FontAwesomeIcon icon={faAngleDoubleDown} className="mr-1" />
					Show Cost Breakdown
				</button>
			</div>
		</div>

	return <>
		<div className="row mt-2">
			<div className="col col-12">
				<button onClick={() => setOpen(false)} className="btn btn-link btn-sm p-0">
					<FontAwesomeIcon icon={faAngleDoubleUp} className="mr-1" />
					Hide Cost Breakdown
				</button>
			</div>
		</div>
		<div className="mt-2 card shadow-sm">
			<div className="card-header">
				<button type="button" onClick={() => setOpen(false)} className="close pull-right" aria-label="Close">
			 		<span className="text-dark" aria-hidden="true">
						<FontAwesomeIcon icon={faTimesCircle} />
					</span>
				</button>
				<span className="font-weight-bold">Cost Breakdown</span>
			</div>
			<div className="card-body small">
				{days && days.map((day, index) => {
					return <div key={index}>
						<div className="row mb-2">
							<div className="col-sm-6">
								<div className="font-weight-bold">{moment(day).format("dddd, LL")}</div>
							</div>

							{getMealAgeGroups(index, days, mealsByDate).map((ageGroup, index2) => {
								return <div key={index2} className="col-4 col-sm-2 pr-0">
									<div>{ageGroup.Description}</div>
									<div className="small">
									({ageGroup.MinAge}
									{ageGroup.MaxAge > 99 && <span>+</span>}
									{ageGroup.MaxAge <= 99 && <span>-{ageGroup.MaxAge}</span>})
									</div>
								</div>
							})}
						</div>
						{mealsByDate[index]?.filter(eventMeal => eventMeal.Disabled === false).length === 0 &&
							<div className="row">
								<div className="col-12">
									No meals for this day.
								</div>
							</div>
						}
						{mealsByDate[index]?.filter(eventMeal => eventMeal.Disabled === false).map((eventMeal, index3) => {
							return <div className="row mb-1" key={index3}>
								<div className="col-sm-6">
									{eventMeal.Meal.Name} {eventMeal.RequiredType > 0 && "*"}
									{showDiscountLine(eventMeal, index, mealsByDate) &&
										<div className="small text-right mt-1 d-none d-sm-block">Discounts</div>
									}
								</div>
								{getMealAgeGroups(index, days, mealsByDate).map((ageGroup, index4) => {
									return <div key={index4} className="col-4 col-sm-2 pr-0">
										{getAgeGroupQuantityForMeal(reservation, eventMeal, index4, day) === 0 && <>-</>}
										{getAgeGroupQuantityForMeal(reservation, eventMeal, index4, day) > 0 &&
										<>
											{getAgeGroupQuantityForMeal(reservation, eventMeal, index4, day)}
											<span> x </span>
											{currency.format(getMealPrice(eventMeal, index4))}
											<br />
											<div className="small d-block d-sm-none">Discount</div>
											{getMealDiscount(reservation, eventMeal, index4, day, appliedDiscount) > 0 &&
											<span>{currency.format(0-getMealDiscount(reservation, eventMeal, index4, day, appliedDiscount))} off</span>
											}
											{getMealDiscount(reservation, eventMeal, index4, day, appliedDiscount) === 0 &&
											<span>n/a</span>
											}
										</>}
									</div>
								})}
							</div>
						})}
						<hr />
					</div>
				})}

				<div className="row mb-3">
					<div className="col-sm-6">
						<div className="font-weight-bold">Subtotal</div>
					</div>
					{getMealAgeGroups(null, days, mealsByDate).map((ageGroup, index) => {
						return <div className="col-4 col-sm-2 pr-0 font-weight-bold" key={index}>
							{currency.format(getMealsTotalForAgeGroup(ageGroup, eventMeals, reservation))}
						</div>
					})}
				</div>
				<div className="row mb-3">
					<div className="col-sm-6">
						<div className="font-weight-bold">Discounts</div>
					</div>
					{getMealAgeGroups(null, days, mealsByDate).map((ageGroup, index) => {
						return <div className="col-4 col-sm-2 pr-0 font-weight-bold" key={index}>
							{currency.format(getMealsDiscountTotalForAgeGroup(ageGroup, eventMeals, appliedDiscount, reservation))}
						</div>
					})}
				</div>
				<div className="row">
					<div className="col-sm-6">
						<div className="font-weight-bold">Grand Total</div>
					</div>
					<div className="col-6 pr-0 font-weight-bold">
						{currency.format(mealsTotal - mealsDiscount)}
					</div>
				</div>
			</div>
		</div>
	</>
}