import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { currency } from "../../../common/helpers";
import { useEventInventoryQuery, useEventMealsQuery, useEventChargesQuery } from "../../../api/events.api";
import { useEventState } from "../functions/event-state";
import { useReservationState } from "../functions/reservation-state";
import { getReservationCost, getDepositAmountFromTotal, getDepositType, getDepositPercent } from "../functions/reservation-functions";
import { EventDepositType } from "../../../api/events.models";

const EventReservationDeposit = () => {
	const { reservation } = useReservationState();
	const { event, eventDeposits, selectedEventDate, selectedDateRange } = useEventState();
	const { data: inventoryTypes } = useEventInventoryQuery(selectedEventDate, selectedDateRange);
	const { data: eventMeals } = useEventMealsQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);
	const { data: eventCharges } = useEventChargesQuery(event?.Id ?? 0, selectedEventDate?.Id ?? 0);

	let totalCost = getReservationCost(reservation, inventoryTypes ?? [], eventMeals ?? [], eventCharges ?? [], true);
	let deposit = getDepositAmountFromTotal(reservation, totalCost, event, eventDeposits);
	let depositType = getDepositType(reservation, event, eventDeposits);
	let depositPercent = getDepositPercent(reservation, event, eventDeposits);

	return <>
		<div className="card shadow mb-3">
			<h5 className="card-header">
				<FontAwesomeIcon icon={faDollarSign} className="text-muted mr-2" />
				Deposit
			</h5>
			
			<div className="card-body">
				{depositType === EventDepositType.None && deposit === 0 &&
				<span>No deposit is required for this reservation.</span>
				}
				{depositType === EventDepositType.Amount && deposit > 0 &&
				<span>A {currency.format(deposit)} deposit is required to book this reservation.</span>
				}
				{depositType === EventDepositType.Percent && deposit > 0 &&
				<span>A {depositPercent*1}% deposit of {currency.format(deposit)} is required to book this reservation.</span>
				}
			</div>
		
		</div>
	</>
}

export default EventReservationDeposit