import { useEffect, useState } from "react";
import moment from "moment-timezone";
import _ from "lodash";
import DateRangeInput from "../../../common/components/DateRangeInput";
import { useEventState } from "../functions/event-state";

function EventDates() {
	const { event, eventDates, selectedEventDate, selectedDateRange, updateSelectedEventDate, updateSelectedDateRange } = useEventState();

	const [minDate, setMinDate] = useState(moment.max(moment(eventDates[0]?.DateFrom ?? moment()), moment()).toDate() ?? moment().toDate());
	const [maxDate, setMaxDate] = useState(eventDates[0]?.DateTo ?? moment().add(1, 'year').toDate());

	useEffect(() => {
		let min = eventDates[0]?.DateFrom ?? moment();
		if (moment(min) < moment().toDate())
			min = moment().toDate();
		setMinDate(min);
		setMaxDate(eventDates[eventDates.length - 1]?.DateTo ?? moment());
	}, [eventDates]);

	const isPastCutoffTime = moment() > moment().startOf("day").add(17, "hours");

	const isInvalidDate = (date) => {
		if (event === undefined || event.FixedDates === true)
			return false;

		var datesDisabled = [];
		for (var i = 1; i < eventDates.length; i++) {
			var disableFrom = moment(eventDates[i-1].DateTo).add(1, "day");
			var disableTo = moment(eventDates[i].DateFrom);
			
			while (disableFrom.isBefore(disableTo)) {
				datesDisabled.push(disableFrom.toDate());
				disableFrom = disableFrom.add(1, "day");
			}
		}

		if (moment(date).isSame(moment(), "date"))
			datesDisabled.push(date);

		if (moment(date).isSame(moment().add(1, "day"), "date") && isPastCutoffTime) {
			datesDisabled.push(date);
		}

		return _.some(datesDisabled, d => moment(date).isSame(moment(d), "date"));
	}

	const formatEventDate = (eventDate) => {
		// let nights = moment(eventDate.DateTo).diff(moment(eventDate.DateFrom), 'days');
		return `${moment(eventDate.DateFrom).format('MMM D, YYYY')} - ${moment(eventDate.DateTo).format('MMM D, YYYY')} ${eventDate.Description}`;
	}

	return <>
		{event && event.FixedDates === true &&
			<select className="custom-select d-block w-100" value={selectedEventDate?.Id ?? 0} onChange={(e) => updateSelectedEventDate(parseInt(e.target.value))}>
			{!selectedEventDate &&
			<option value={0}>Select the event dates...</option>
			}
			{eventDates.map((eventDate, index) => {
				return <option key={index} value={eventDate.Id}>{formatEventDate(eventDate)}</option>
			})}
			</select>
		}
		{event && event.FixedDates === false &&
			<div>
				<DateRangeInput dateRange={selectedDateRange} minDate={minDate} maxDate={maxDate} onDateRangeSelected={(e) => updateSelectedDateRange(e)} isInvalidDate={isInvalidDate} />
				{isPastCutoffTime &&
				<span className="small text-muted">Bookings for tomorrow must be made by contacting the office.</span>
				}
			</div>
		}
	</>
}

export default EventDates;