import { useEffect, useMemo, useState } from 'react';
import { Formik, ErrorMessage, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import _, { debounce, throttle } from 'lodash';
import classnames from "classnames";
import moment from 'moment-timezone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import { AdultGuest, ChildGuest, GuestBase, Reservation, ReservationItemBase } from "../../../api/reservation.models"
import { Person } from '../../../api/user.models';
import { useReservationState } from '../functions/reservation-state';
import { useAuthState } from '../../../state/auth.state';
import { useUserAddressQuery, useUserPeopleQuery } from '../../../api/user.api';
import { getCountries, getProvincesStates, getProvinceLabel, getPostalCodeLabel } from '../../../common/address';
import { DateInputField } from '../../../common/components/DateInputField';
import { PhoneInputField } from '../../../common/components/PhoneInputField';
import "./event-reservation-guest.css";

const GuestSchema = Yup.object().shape({
	Guest: Yup.object().shape({
		FirstName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Required'),
		LastName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Required'),
		Email: Yup.string().email('Invalid email').required('Required'),
		HomePhone: Yup.string().min(10, 'Not a valid phone number').when('MobilePhone', { is: undefined, then: (schema) => schema.required('Required') }),
		MobilePhone: Yup.string().min(10, 'Not a valid phone number').when('HomePhone', { is: undefined, then: (schema) => schema.required('Required') }),
		SameAddress: Yup.boolean(),
		Address: Yup.object().shape({
			Street1: Yup.string().required('Required'),
			Street2: Yup.string(),
			City: Yup.string().required('Required'),
			Country: Yup.string().required('Required'),
			Province: Yup.string(),
			PostalCode: Yup.string().required('Required'),
		})
	}, [['HomePhone', 'MobilePhone']])
});

interface AutoSaveGuestStateProps {
	ItemIndex: number,
	GuestIndex: number,
	GuestType: "adult"|"child"
}

const AutoSaveGuestState = ({ ItemIndex, GuestIndex, GuestType }: AutoSaveGuestStateProps) => {
	const { reservation, updateReservation, emitReservationGuests, useReservationGuestsListener, useReservationValidationListener } = useReservationState();
	const { values, submitForm, isValidating } = useFormikContext<{ Guest: AdultGuest|ChildGuest }>();

	useReservationValidationListener(() => {
		submitForm();
	});

	// const update = debounce((reservation: Reservation, values: { Guest: AdultGuest|ChildGuest }) => {
	// 	if (values.Guest) {		
	// 		if (GuestType === "adult") { 
	// 			reservation.Items[ItemIndex].AdultGuests[GuestIndex] = values.Guest as AdultGuest;
	// 		}
	// 		if (GuestType === "child") {
	// 			reservation.Items[ItemIndex].ChildGuests[GuestIndex] = values.Guest as ChildGuest;
	// 		}
			
	// 		// if (GuestIndex === 0 && GuestType === "adult") {
	// 			setTimeout(() => {
	// 				updateReservation(reservation);
	// 				emitReservationGuests();
	// 			}, 100);
	// 		// }
	// 	}
	// }, 100);

	const update = useMemo(() => throttle((reservation, values: { Guest: AdultGuest|ChildGuest }) => {
		if (values.Guest) {		
			if (GuestType === "adult") { 
				reservation.Items[ItemIndex].AdultGuests[GuestIndex] = values.Guest as AdultGuest;
			}
			if (GuestType === "child") {
				reservation.Items[ItemIndex].ChildGuests[GuestIndex] = values.Guest as ChildGuest;
			}
			
			// if (GuestIndex === 0 && GuestType === "adult") {
				// setTimeout(() => {
					updateReservation(reservation);
					emitReservationGuests(reservation);
				// }, 100);
			// }
		}
	}, 100), []);

	useEffect(() => {
		update(reservation, values);
	}, [isValidating])

	const updateSameAddress = useMemo(() => debounce((res: Reservation ,values: { Guest: AdultGuest|ChildGuest }) => {
		if (values.Guest.SameAddress) {
			values.Guest.Address = res.Items[0].AdultGuests[0].Address;
		}
		updateReservation(res);
	}, 1000), []);

	useReservationGuestsListener((res: Reservation) => {
		updateSameAddress(res, values);
		// submitForm();
	});

	useEffect(() => {
		if (GuestIndex > 0 || GuestType === "child") {
			updateSameAddress(reservation, values);
		}
	}, [values.Guest.SameAddress])
	
	return <></>
}

interface EventReservationGuestProps {
	GuestNumber: number,
	ItemIndex: number,
	GuestIndex: number,
	GuestType: "adult"|"child",
	Guest: AdultGuest|ChildGuest,
	Item: ReservationItemBase,
	UseAddressFrom: AdultGuest
}

const EventReservationGuest = ({ GuestNumber, ItemIndex, GuestIndex, GuestType, Guest, Item }: EventReservationGuestProps) => {
	// const { values, errors, touched, submitCount } = useFormikContext();
	const { reservation } = useReservationState();
	// const { userInfo } = useAuthState();
	// const { data: userPeople, isLoading: isUserPeopleLoading } = useUserPeopleQuery(userInfo?.email ?? "");

	// const [, updateState] = useState(0);
	// const forceUpdate = useCallback(() => updateState(Date.now()), []);
	
	// useEffect(() => {
	// 	forceUpdate();
	// }, [Guest])

	const isAdult = Guest.Type === "adult";
	const isChild = Guest.Type === "child";
	const salutations = ["", "Mr", "Mrs", "Ms", "Dr", "Rev"];


	const isUnique = (values: { Guest: AdultGuest|ChildGuest }) => {
		var unique = true;
		if (values.Guest) {		
			for (var item of reservation.Items) {
				for (var adultGuest of item.AdultGuests) {
					if (item === reservation.Items[ItemIndex] && GuestType === "adult" && adultGuest === item.AdultGuests[GuestIndex]) {
						// unique = true;
					} else {
						if (adultGuest.FirstName === values.Guest.FirstName &&
							adultGuest.LastName === values.Guest.LastName)
							unique = false;
					}
				}
				for (var childGuest of item.ChildGuests) {
					if (item === reservation.Items[ItemIndex] && GuestType === "child" && childGuest === item.ChildGuests[GuestIndex]) {
							// return true;
					} else {
						if (childGuest.FirstName === values.Guest.FirstName &&
							childGuest.LastName === values.Guest.LastName)
							unique = false;
					}
				}
			}
		}

		return unique;
	}

	const handleCountryChanged = (country: string, values: { Guest: GuestBase }) => {
		if (country === "Canada") {
			values.Guest.Address!.Province = "Ontario";
			values.Guest.Address!.Region = "";
		}
		else if (country === "United States") {
			values.Guest.Address!.Province = "Alabama";
			values.Guest.Address!.Region = "";
		}
		else
			values.Guest.Address!.Province = "";
	}

	const showUseSameAddress = ((GuestType === "adult" && GuestIndex > 0) || (GuestType === "child"));

	return <>
		<hr className="mt-3 mb-3" />
		<p className="lead font-weight-bold mb-2">
			<FontAwesomeIcon icon={faAddressCard} className="mr-2" />
			Please provide information for 
			{isAdult && <span> Adult </span>}
			{isChild && <span> Child </span>}
			#{GuestNumber}:
		</p>

		<Formik
			validationSchema={GuestSchema}
			// enableReinitialize={true}
			initialValues={{
				Guest: _.cloneDeep(Guest),
				GuestType: GuestType
			}}
			onSubmit={(values, { setSubmitting }) => {

			}}
			validateOnBlur={true}
		>
		{({ isSubmitting, submitCount, isValid, values, errors, handleChange }) => <>

		<AutoSaveGuestState ItemIndex={ItemIndex} GuestIndex={GuestIndex} GuestType={GuestType} />
		<div className="form-row">
			<div className="col-12 col-sm-6">
				<AutofillPerson />
			</div>
		</div>

		<div className="form-row">
			{isAdult && 
			<div className={classnames(["col-6 col-sm-2", isChild ? 'pr-sm-0-x':''])}>
				<div className="form-label-group">
					<Field component="select" name="Guest.Salutation" className={classnames(["custom-select", (values.Guest as AdultGuest).Salutation !== '' ? "value-selected":""])}>
						{salutations.map((salutation, i) => {
							return <option key={i}>{salutation}</option>
						})}
					</Field>
					<label className="text-truncate">Salutation</label>
				</div>
			</div>
			}
			<div className={classnames(["col-12", isAdult ? "col-sm-4 pl-sm-0-x":"", isChild ? "col-sm-3 pr-sm-0-x":""])}>
				<div className="form-label-group">
					<Field type="text" name={`Guest.FirstName`} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="off" />
					<label htmlFor={`firstName_${GuestNumber}`}>First Name</label>
					<ErrorMessage name="Guest.FirstName" component="div" className="error-message fw-bold small text-danger" />
					{!isUnique(values) &&
					<div className="error-message fw-bold small text-danger">Name is duplicated</div>
					}
				</div>
			</div>
			<div className={classnames(["col-12 col-sm-4", isAdult ? "pl-sm-0-x":"", isChild ? "pr-sm-0-x":""])}>
				<div className="form-label-group">
					<Field type="text" name={`Guest.LastName`} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="off" />
					<label>Last Name</label>
					<ErrorMessage name="Guest.LastName" component="div" className="error-message fw-bold small text-danger" />
					{!isUnique(values) &&
					<div className="error-message fw-bold small text-danger">Name is duplicated</div>
					}
				</div>
			</div>
			<div className={classnames(["col-6 col-sm-2", isAdult ? "pl-sm-0-x":"", isChild ? 'pr-sm-0-x':''])}>
				<div className="form-label-group">
					<Field component="select" name="Guest.Gender" className={classnames(["custom-select", values.Guest.Gender !== 0 ? "value-selected":""])}>
						<option value="0"></option>
						<option value="1">Male</option>
						<option value="2">Female</option>
					</Field>
					<label className="text-truncate">Gender</label>
				</div>
			</div>
			{isChild &&
				<div className={classnames(["col-12 col-sm-3", "pr-sm-0-x"])}>
					<div className="form-label-group">
						<DateInputField name={`Guest.Birthdate`} minDate={moment().add(-100, "years").toDate()} maxDate={moment().toDate()} className={`form-control`} placeholder={true ? "Required" : ""} autoComplete="off" />
						<label>Date of Birth</label>
						<ErrorMessage name="Guest.Birthdate" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
			}

		</div>

		{isAdult && 
		<div className="form-row">
			<div className="col-12 col-sm-4">
				<div className="form-label-group">
					<Field type="text" name={`Guest.Email`} className={`form-control`} placeholder={true ? "Email (required)" : ""} autoComplete="off" required />
					<label>Email</label>
					<ErrorMessage name="Guest.Email" component="div" className="error-message fw-bold small text-danger" />
				</div>
			</div>
			<div className="col-12 col-sm-4">
				<div className="form-label-group">
					<PhoneInputField name="Guest.HomePhone" className="form-control" placeholder="Required" autoComplete="off" required={(values.Guest as AdultGuest).MobilePhone === "" || (values.Guest as AdultGuest).MobilePhone == null} />
					<label>Home Phone</label>
					<ErrorMessage name="Guest.HomePhone" component="div" className="error-message fw-bold small text-danger" />
				</div>
			</div>
			<div className="col-12 col-sm-4">
				<div className="form-label-group">
					<PhoneInputField name="Guest.MobilePhone" className="form-control" placeholder={(values.Guest as AdultGuest).MobilePhone === "" || (values.Guest as AdultGuest).MobilePhone == null ? "required":"required"} autoComplete="off" required={(values.Guest as AdultGuest).HomePhone === "" || (values.Guest as AdultGuest).HomePhone == null}  />
					<label>Cell Phone</label>
					<ErrorMessage name="Guest.MobilePhone" component="div" className="error-message fw-bold small text-danger" />
				</div>
			</div>
		</div>
		}

		{showUseSameAddress && 
			<>
			<div className="form-row">
				<div className="col-12">
					<div className="custom-control custom-checkbox mb-2">					
						<Field type="checkbox" name="Guest.SameAddress" id={`Guest.SameAddress${GuestIndex}.${GuestType}`} checked={values.Guest.SameAddress} className="custom-control-input" />
						<label htmlFor={`Guest.SameAddress${GuestIndex}.${GuestType}`} className="custom-control-label">Use same address as Adult #1</label>
					</div>		
				</div>
			</div>

			{values.Guest.SameAddress &&
			<div className="form-row">
				<div className="col-12 mb-2">
					<span>Address: </span>
					{values.Guest.Address?.Street1}{values.Guest.Address?.Street1 ? ", " : ""}
					{values.Guest.Address?.Street2}{values.Guest.Address?.Street2 ? ", " : ""}
					{values.Guest.Address?.City}{values.Guest.Address?.City ? ", " : ""}
					{values.Guest.Address?.Province}{values.Guest.Address?.Province ? ", " : ""}
					{values.Guest.Address?.PostalCode}{values.Guest.Address?.PostalCode ? ", " : ""}
					{values.Guest.Address?.Country}
				</div>
			</div>
			}
			</>
		}

		{(showUseSameAddress === false || values.Guest.SameAddress === false) && 
		<>
			<div className="form-row">
				<div className="col-12 col-sm-6">
					<AutofillAddress />
				</div>
			</div>

			<div className="form-row">
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Guest.Address.Street1`} className={`form-control`} placeholder={true ? "required" : ""} autoComplete="off" required />
						<label>Street Address</label>
						<ErrorMessage name="Guest.Address.Street1" component="div" className="fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Guest.Address.Street2`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
						<label>Apt / Unit Number</label>
						<ErrorMessage name="Guest.Address.Street2" component="div" className="fw-bold small text-danger" />
					</div>
				</div>
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Guest.Address.City`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
						<label>City</label>
						<ErrorMessage name="Guest.Address.City" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				<div className={classnames(["col-6 col-sm-4"])}>
					<div className="form-label-group">
						<Field component="select" name="Guest.Address.Country" onChange={(e:any) => { handleCountryChanged(e.target.value, values); handleChange(e); }} className={classnames(["custom-select", values.Guest.Address?.Country !== "" ? "value-selected":""])}>
							{getCountries().map((country, index) => {
								return <option key={index} value={country}>{country}</option>
							})}
						</Field>
						<label className="text-truncate">Country</label>
					</div>
				</div>
				{(values.Guest.Address?.Country === "Canada" || values.Guest.Address?.Country === "United States") &&
				<div className={classnames(["col-6 col-sm-4"])}>
					<div className="form-label-group">
						<Field component="select" name="Guest.Address.Province" className={classnames(["custom-select", values.Guest.Address?.Province !== "" ? "value-selected":""])}>
							{getProvincesStates(values.Guest.Address?.Country ?? "").map((province, index) => {
								return <option key={index} value={province}>{province}</option>
							})}
						</Field>
						<label className="text-truncate">{getProvinceLabel(values.Guest.Address?.Country ?? "")}</label>
					</div>
				</div>
				}
				{(values.Guest.Address?.Country !== "Canada" && values.Guest.Address?.Country !== "United States") &&
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Guest.Address.Region`} className={`form-control`} placeholder={" "} autoComplete="off" required={false} />
						<label>Region</label>
						<ErrorMessage name="Guest.Address.Region" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
				}
				<div className="col-12 col-sm-4">
					<div className="form-label-group">
						<Field type="text" name={`Guest.Address.PostalCode`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
						<label className="text-truncate">{getPostalCodeLabel(values.Guest.Address?.Country ?? "")}</label>
						<ErrorMessage name="Guest.Address.PostalCode" component="div" className="error-message fw-bold small text-danger" />
					</div>
				</div>
			</div>
		</>
		}

		<p className="form-text font-weight-bold mb-2">
			Additional information for 
			{isAdult && <span> Adult </span>}
			{isChild && <span> Child </span>}
			#{GuestNumber}:
		</p>

		<div className="form-row">
			<div className="col-12 col-sm-6">
				<div className="form-label-group mb-sm-0">
					<Field component="textarea" rows="3" maxLength="100" name={`Guest.Allergies`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
					<label>Allergies</label>
					<span className="text-muted small">({100-(values.Guest.Allergies != null ? values.Guest.Allergies.length : 0)}/100 characters remaining)</span>
				</div>
				<ErrorMessage name="Guest.Allergies" component="div" className="fw-bold small text-danger" />
			</div>
			<div className="col-12 col-sm-6">
				<div className="form-label-group mb-0">
					<Field component="textarea" rows="3" maxLength="100" name={`Guest.Dietary`} className={`form-control`} placeholder={false ? "required" : " "} autoComplete="off" required={false} />
					<label>Dietary</label>
					<span className="text-muted small">({100-(values.Guest.Dietary != null ? values.Guest.Dietary.length : 0)}/100 characters remaining)</span>
				</div>
				<ErrorMessage name="Guest.Dietary" component="div" className="fw-bold small text-danger" />
			</div>
		</div>

		</>}

		</Formik>
	</>
}

export default EventReservationGuest

// interface CheckboxInputFieldProps {
// 	name: string
// 	className?: string
// 	required?: boolean,
// 	autoComplete?: "on"|"off",
// 	placeholder?: string;
// }

// const CheckboxInputField = (props: CheckboxInputFieldProps) => {
// 	const [field, meta, helpers] = useField(props.name);
// 	return <>
// 		<input {...field} type="checkbox" id={field.name} checked={meta.value} className="custom-control-input" onChange={(e) => { console.log(e.target.checked); helpers.setValue(e.target.checked) }} />
// 	</>
// }

interface AutofillPersonProps {
	// ItemIndex: number
	// GuestIndex: number
	// GuestType: "adult"|"child"
}

const AutofillPerson = ({ }: AutofillPersonProps) => {
	const { values, setValues, validateForm } = useFormikContext<{ Guest: AdultGuest|ChildGuest, GuestType: string }>();
	const { validateReservation } = useReservationState();
	const { isAuthenticated } = useAuthState();
	const { data: userPeople } = useUserPeopleQuery();

	const getAge = (person: Person) : number|null => {
		if (person.Birthdate == null)
			return null;

		return moment().diff(moment(person.Birthdate), "years");
	}
	
	// const autofillAdults = userPeople?.map(p => p.Person)?.filter(p => { return p != null && (p.Birthdate == null || (getAge(p) != null && getAge(p)! > 18 )) }) ?? [];
	// const autofillChildren = userPeople?.map(p => p.Person)?.filter(p => { return p != null && (p.Birthdate != null && (getAge(p) != null && getAge(p)! <= 18 )) }) ?? [];

	const [autofillPerson, setAutofillPerson] = useState("");
	const autofillPeople = userPeople?.map(p => p.Person).filter(p => ((values.GuestType === "adult" && p.Birthdate === null) || (values.GuestType === "child" && p.Birthdate != null && getAge(p) != null && getAge(p)! <= 18))).map(p => p) ?? [];

	const handleAutofillPerson = async (value: string) => {
		let autofillPerson = autofillPeople.find(a => a.Id === parseInt(value));
		if (autofillPerson == null)
			return;

		if (values.GuestType === "adult") {
			let guest = values.Guest as AdultGuest;
			guest.Salutation = autofillPerson.Salutation;
			guest.FirstName = autofillPerson.FirstName;
			guest.LastName = autofillPerson.LastName;
			guest.Gender = autofillPerson.Gender;
			guest.Email = autofillPerson.PersonalEmail;
			guest.HomePhone = autofillPerson.HomePhone;
			guest.MobilePhone = autofillPerson.MobilePhone;

			setValues(values);
		}
		if (values.GuestType === "child") {
			let guest = values.Guest as ChildGuest;
			guest.FirstName = autofillPerson.FirstName;
			guest.LastName = autofillPerson.LastName;
			guest.Gender = autofillPerson.Gender;
			guest.Birthdate = autofillPerson.Birthdate!;

			setValues(values);
		}

		setAutofillPerson("");

		await validateForm();
		await validateForm();

		await validateReservation();
	}

	return !isAuthenticated() ? <></> : <>
		<select value={autofillPerson} onChange={(e) => handleAutofillPerson(e.target.value)} className="custom-select custom-select-sm w-100 mb-3 text-info font-weight-bold">
			<option>Autofill with person...</option>
			{autofillPeople?.map((person, i) => {
				return values.GuestType === "adult" ? 
					<option key={i} value={person.Id}>{person.FirstName} {person.LastName}, {person.PersonalEmail}, {person.HomePhone}</option> : 
					<option key={i} value={person.Id}>{person.FirstName} {person.LastName}</option>
			})}
		</select>
	</>
}


interface AutofillAddressProps {
	// ItemIndex: number
	// GuestIndex: number
	// GuestType: "adult"|"child"
}

const AutofillAddress = ({ }: AutofillAddressProps) => {
	const { values, setValues, validateForm } = useFormikContext<{ Guest: AdultGuest|ChildGuest }>();
	const { validateReservation } = useReservationState();
	const { isAuthenticated } = useAuthState();
	const { data: userAddresses } = useUserAddressQuery();

	const [autofillAddress, setAutofillAddress] = useState("");
	const autofillAddresses = userAddresses?.map(a => a.Address) ?? [];

	const handleAutofillAddress = async (value: string) => {
		let autofillAddress = autofillAddresses.find(a => a.Id === parseInt(value));
		if (autofillAddress == null)
			return;

		values.Guest.Address = autofillAddress;
		setValues(values);

		setAutofillAddress("");

		await validateForm();
		await validateForm();

		await validateReservation();
	}

	return !isAuthenticated() ? <></> : <>
		<select value={autofillAddress} onChange={(e) => handleAutofillAddress(e.target.value)} className="custom-select custom-select-sm w-100 mb-3 text-info font-weight-bold">
			<option>Autofill with address...</option>
			{autofillAddresses?.map((address, i) => {
				return <option key={i} value={address.Id}>{address.Street1}, {address.City}, {address.Province}, {address.PostalCode}, {address.Country}</option>
			})}
		</select>
	</>
}