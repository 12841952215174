import { Switch, Route, useRouteMatch } from "react-router-dom";
import Explore from "./explore";

const ExploreRoutes = () => {
	let { path } = useRouteMatch();
	return <>
		<Switch>
			<Route path={`${path}/:inventoryType/:inventoryCategory`}>
				<Explore />
			</Route>
			<Route path={`${path}/:inventoryType`}>
				<Explore />
			</Route>
			<Route path={`${path}`} exact>
				<Explore />
			</Route>
			
		</Switch>
	</>
}

export default ExploreRoutes